import {
  EmailTypes,
  Talent,
  TalentProfile,
  Feedback,
  feedbackType,
  JobMatch,
  UpdateTalentProfileWithUsdSalaryMutation,
} from "@remotebase/amplify-constants/API";
import { updateErrorState } from "@remotebase/components";
import { IErrorContextType } from "@remotebase/constants";
import {
  useClientNewJobMatchEmail,
  useTalentJobMatch,
  useListJobs,
  useSendEmail,
  useUpdateTalentProfile,
} from "hooks";
import { omit } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ErrorContext from "state/error/error.context";
import {
  IFeedbackContextType,
  IFeedbackProps,
  IFetchTalentContextType,
  UpdateEngineeringPropsModified,
} from "state/types/talentFeedback.interface";
import { TalentProfileEnum, feedbackHeading, talentMatchRemoveMessage } from "utils";
import { FeedbackContext, FetchTalentContext } from "./talentFeedback.context";
import { getTalentMatchJobs } from "./profileSpecs.helper";

interface DefaultProps {
  engineerId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleResetAndPass: (input: any) => void;
}
export const withUpdateFeedback = <T,>(Component: React.FC): React.FC<T> => {
  return (props: T & DefaultProps): JSX.Element => {
    const { feedbackState, setFeedbackState } = useContext<IFeedbackContextType>(FeedbackContext);
    const { talentState, setTalentState } = useContext<IFetchTalentContextType>(FetchTalentContext);
    const { setErrorState } = useContext<IErrorContextType>(ErrorContext);
    const [pendingMails, setPendingMails] = useState<number>(0);
    const [jobsCompanyId, setJobsCompanyId] = useState<string | null>(null);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const { getJobs, jobs: jobsList, loading: fetchJobsLoading } = useListJobs();

    const updateProfileState = (updatedProfileData: TalentProfile): void => {
      if (updatedProfileData) {
        setTalentState((previous) => ({
          ...previous,
          saveFeedbackLoading: false,
          data: {
            ...(previous.data as Talent),
            profile: updatedProfileData,
          },
        }));
      }
    };

    const talentProfileUpdated = (
      talentProfileMutation: UpdateTalentProfileWithUsdSalaryMutation,
    ): void => {
      if (talentProfileMutation?.updateTalentProfileWithUsdSalary?.data?.talent?.profile) {
        const updatedProfileData: TalentProfile =
          talentProfileMutation?.updateTalentProfileWithUsdSalary.data?.talent?.profile;
        updateProfileState(updatedProfileData);
      }
    };

    const updateMatchLoading = (): void => {
      if (pendingMails > 1) setPendingMails((current) => current - 1);
      else {
        setPendingMails(0);
        setTalentState((current) => ({
          ...current,
          matchTalentLoading: false,
        }));
      }
    };

    const { sendEmail } = useSendEmail(updateMatchLoading);
    const { sendClientJobMatchEmail } = useClientNewJobMatchEmail(updateMatchLoading);

    const talentMatchesUpdated = (jobMatches: Array<JobMatch | null>): void => {
      if (jobMatches?.length) {
        const previousJobsMatchedIds = talentState.matchedJobs.map((jobMatch) => jobMatch?.jobID);
        const newJobMatches = jobMatches.filter(
          (jobMatch) => jobMatch && !previousJobsMatchedIds.includes(jobMatch?.jobID),
        );

        if (newJobMatches.length) setPendingMails(newJobMatches.length * 2);
        else setTalentState((current) => ({ ...current, matchTalentLoading: false }));

        for (const jobMatch of newJobMatches) {
          if (jobMatch?.talent?.email) {
            sendEmail({
              variables: {
                input: {
                  toEmail: jobMatch.talent?.email,
                  emailType: EmailTypes.NewTalentJobMatch,
                  messageData: JSON.stringify({
                    jobName: jobMatch.job?.title,
                    companyName: jobMatch.job?.company?.name,
                    talentName: jobMatch.talent?.fullName,
                  }),
                },
              },
            });
          }

          if (jobMatch?.job?.companyID) {
            sendClientJobMatchEmail({
              variables: {
                jobMatch: {
                  jobMatchID: jobMatch.id,
                  companyID: jobMatch.job?.companyID,
                },
              },
            });
          }
        }

        setTalentState((previous) => ({
          ...previous,
          matchedJobs: [...jobMatches],
        }));
      } else {
        setTalentState((previous) => ({
          ...previous,
          matchedJobs: [],
          matchTalentLoading: false,
        }));
      }
    };

    const {
      updateTalentProfile: updateProfile,
      loading: updateProfileLoading,
      data: updateProfileData,
    } = useUpdateTalentProfile(talentProfileUpdated);

    const { matchTalent } = useTalentJobMatch(talentMatchesUpdated);

    const updateTalentProfile = (label, value): void => {
      setFeedbackState((previous) => ({ ...previous, [label]: value }));
    };

    const removeTalentMatch = (id): void => {
      const updatedData = feedbackState.matchedJobs.filter((item) => item?.jobID !== id);
      setFeedbackState({ ...feedbackState, matchedJobs: updatedData });
      updateErrorState(
        { title: feedbackHeading, message: talentMatchRemoveMessage },
        setErrorState,
      );
    };

    const onCancelFeedback = (type: feedbackType): void => {
      const { talentFeedback, isNetwork } = talentState.data?.profile || {};
      const resetData = talentFeedback?.find((item) => item?.type === type);
      const { talentFeedback: feedbackList } = feedbackState;
      const index = feedbackList?.findIndex((item) => item?.type === type);
      if (index > -1) {
        const newState = [...feedbackList];
        newState[index] = { ...(resetData as Feedback) } || ({} as Feedback);
        setFeedbackState((previous) => ({ ...previous, talentFeedback: [...newState] }));
      }
      if (type === feedbackType.FinalInterviewFeedback)
        setFeedbackState((previous) => ({
          ...previous,
          isNetwork,
          matchedJobs: getTalentMatchJobs(talentState.matchedJobs),
        }));
    };

    const updateFeedback = (updatedData: Feedback): void => {
      const { talentFeedback } = feedbackState;
      const index = talentFeedback?.findIndex((item) => item?.type === updatedData.type);
      if (index > -1) {
        const newState = [...talentFeedback];
        newState[index] = updatedData;
        updateTalentProfile(TalentProfileEnum.talentFeedback, newState);
      } else {
        updateTalentProfile(TalentProfileEnum.talentFeedback, [...talentFeedback, updatedData]);
      }

      if (talentState.data?.profile) {
        const { profile } = talentState.data;
        const { resumeLink, isNetwork, matchedJobs } = feedbackState;
        const talentsFeedback = talentFeedback?.map((item) => omit(item, "__typename"));
        if (index > -1) {
          talentsFeedback[index] = updatedData;
        } else {
          talentsFeedback.push(updatedData);
        }

        setTalentState((previous) => ({
          ...previous,
          saveFeedbackLoading: true,
        }));

        updateProfile({
          variables: {
            input: {
              talentFeedback: talentsFeedback,
              resumeLink,
              expectedVersion: profile?.version,
              id: profile.id,
              isNetwork,
            },
          },
        });

        if (isNetwork) {
          const matchedList = matchedJobs?.map((item) => omit(item, "__typename", "job"));
          setTalentState((current) => ({
            ...current,
            matchTalentLoading: true,
          }));

          matchTalent({
            variables: {
              jobs: matchedList,
              talentID: talentState.data?.id,
              talentEmail: talentState.data?.email,
            },
          });
        }
      }
    };

    const saveFeedback = (): void => {
      if (talentState.data?.profile) {
        const { profile } = talentState.data;
        const { talentFeedback, resumeLink, matchedJobs, isNetwork } = feedbackState;
        const talentsFeedback = talentFeedback?.map((item) => omit(item, "__typename"));
        updateProfile({
          variables: {
            input: {
              talentFeedback: talentsFeedback,
              resumeLink,
              expectedVersion: profile?.version,
              id: profile.id,
              isNetwork,
            },
          },
        });

        if (isNetwork) {
          const matchedList = matchedJobs?.map((item) => omit(item, "__typename", "job"));
          setTalentState((current) => ({
            ...current,
            matchTalentLoading: true,
          }));

          matchTalent({
            variables: {
              jobs: matchedList,
              talentID: talentState.data?.id,
              talentEmail: talentState.data?.email,
            },
          });
        }
      }
    };

    const handleResetAndPass = (input: UpdateEngineeringPropsModified): void => {
      if (talentState.data?.profile) {
        setUpdateInProgress(true);
        const { profile } = talentState.data;
        updateProfile({
          variables: {
            input: {
              ...input,
              expectedVersion: profile?.version,
              id: profile.id,
            },
          },
          onCompleted: () => {
            setUpdateInProgress(false);
          },
        });
      }
    };

    const fetchJobs = (id: string): void => {
      if (id) {
        const jobs = feedbackState.jobs[id];
        if (jobs?.length) return;

        setJobsCompanyId(id);
        getJobs({
          variables: {
            filter: {
              companyID: { eq: id },
            },
          },
        });
      }
    };

    useEffect(() => {
      updateProfileState(updateProfileData);
    }, [updateProfileData]);

    useEffect(() => {
      setTalentState((previous) => ({
        ...previous,
        saveFeedbackLoading: updateProfileLoading,
      }));
    }, [updateProfileLoading]);

    useEffect(() => {
      if (jobsList?.length && !fetchJobsLoading && jobsCompanyId) {
        setFeedbackState((prev) => ({
          ...prev,
          jobs: {
            ...prev.jobs,
            [jobsCompanyId]: jobsList,
          },
        }));
      }
    }, [jobsList, fetchJobsLoading, jobsCompanyId]);

    const feedbackProps: IFeedbackProps = {
      feedbackState,
      talentState,
      updateFeedback,
      updateTalentProfile,
      saveFeedback,
      removeTalentMatch,
      onCancelFeedback,
      fetchJobs,
      handleResetAndPass,
      updateInProgress,
    };

    return <Component {...({ ...feedbackProps, ...props } as T)} />;
  };
};
export default withUpdateFeedback;
