import { feedbackSentiment } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { useMemo } from "react";
import { defaultDropdownValue } from "utils";
import * as Styled from "./styles";

interface Props {
  setValue: (value: string) => void;
  value: string;
  disabled: boolean;
}

export const FeedbackSentiment: React.FC<Props> = ({ setValue, value, disabled }) => {
  const title = useMemo(
    () => (Object.keys(feedbackSentiment)?.find((e) => e === value) ? value : defaultDropdownValue),
    [value],
  );
  return (
    <Styled.DivWrapper>
      <Styled.FeedbackInputLabel>Sentiment</Styled.FeedbackInputLabel>
      <Styled.DropDownList
        className={disabled ? "disable" : ""}
        disabled={disabled}
        onChange={(e): void => setValue(e.target.value)}
      >
        <Styled.ListItem value={title}>{title}</Styled.ListItem>
        {Object.values(feedbackSentiment).map((key, index) => (
          <ShouldRender key={index} if={key !== value && title !== key}>
            <Styled.ListItem value={key}>{key}</Styled.ListItem>
          </ShouldRender>
        ))}
      </Styled.DropDownList>
    </Styled.DivWrapper>
  );
};

export default FeedbackSentiment;
