import Amplify from "aws-amplify";
import GlobalStyles from "@remotebase/common/src/GlobalStyles";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ErrorProvider from "state/error/error.provider";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./assets/css/icon.css";
import "./assets/css/index.css";
import { AuthProvider } from "state/auth";
import config from "./aws-exports";
import MainRouter from "./router";
import "rc-slider/assets/index.css";

Amplify.configure(config);

const App: React.FC = () => {
  return (
    <ErrorProvider>
      <Router>
        <div className="App">
          <GlobalStyles />
          <AuthProvider>
            <Route path="/" component={MainRouter} />
          </AuthProvider>
        </div>
      </Router>
    </ErrorProvider>
  );
};

export default App;
