import { TalentData, ReturnPropsFetchReferral } from "hooks/types";
import { Referral } from "@remotebase/amplify-constants/API";
import { useReferralByReferrer } from "hooks";
import { pageLimit } from "utils";
import { compact } from "lodash";

export const useFetchReferral = (): ReturnPropsFetchReferral => {
  const {
    data: byReferrerData,
    loading,
    nextToken,
    getReferralsByReferrer,
  } = useReferralByReferrer();

  const getDataFromReferral = (rawData: Array<Referral | null>): TalentData =>
    compact(rawData.map((referredEntry) => referredEntry?.referred || null));

  const fetchData = (nextTokenId: string | null, referrerId: string): void => {
    const variables = {
      referrerId,
      nextToken: nextTokenId,
      limit: pageLimit,
    };
    return getReferralsByReferrer({ variables });
  };

  return {
    data: getDataFromReferral(byReferrerData || []),
    nextToken,
    loading,
    fetchData,
  };
};

export default useFetchReferral;
