import { ShouldRender } from "@remotebase/components";
import _ from "lodash";
import { FC, useState, useMemo, Fragment } from "react";
import { withUpdateFeedback } from "state/talentFeedback";
import {
  IFetchTalentFeedbackProps,
  TechDomainSkillsProgressModified,
} from "state/types/talentFeedback.interface";
import { ResetAndPassTestsList, IResetCardProp } from "utils";
import { ResetAndPassCard, ResetAndPassEmpty } from "./components";
import * as S from "./styles";

const ResetPassTests: FC<IFetchTalentFeedbackProps> = ({
  talentState: { data },
  handleResetAndPass,
  updateInProgress,
}) => {
  const { profile } = data || {};
  const possibleTabs = Object.keys(ResetAndPassTestsList);
  const [selectedTest, setSelectedTest] = useState<string>(possibleTabs[0]);

  const extractHRData = (): IResetCardProp => {
    const feedbacks = profile?.talentFeedback || [];
    const hrFeedback = feedbacks.find((feedback) => feedback?.type === "HRFeedback");
    return {
      title: ResetAndPassTestsList.HRScreening,
      isPass: profile?.isPassHR,
      score: hrFeedback?.score,
      percentage: hrFeedback?.score,
      dateAttempted: JSON.parse(profile?.statusMeta || "{}")?.dateOfVideoInterview,
      description: hrFeedback?.description,
    };
  };

  const extractFinalTechData = (): IResetCardProp => {
    const feedbacks = profile?.talentFeedback || [];
    const finalTechFeedback = feedbacks.find(
      (feedback) => feedback?.type === "FinalInterviewFeedback",
    );
    return {
      title: ResetAndPassTestsList.FinalInterview,
      isPass: profile?.isPassFinalTech,
      score: finalTechFeedback?.score,
      percentage: finalTechFeedback?.score,
      dateAttempted: JSON.parse(profile?.statusMeta || "{}")?.dateOfFinalTechStatus,
      description: finalTechFeedback?.description,
    };
  };

  const extractDomainTests = (): IResetCardProp[] => {
    const takenDomainTests = profile?.takenDomainTests || [];
    return takenDomainTests.map((test) => ({
      title: test?.skill,
      isPass: test?.isPass,
      score: test?.score,
      percentage: test?.percentage,
      dateAttempted: test?.testEnd,
    }));
  };

  const testData = useMemo(
    () => ({
      [possibleTabs[0]]: extractDomainTests(),
      [possibleTabs[1]]: extractHRData(),
      [possibleTabs[2]]: {
        title: ResetAndPassTestsList.ProblemSolving,
        isPass: profile?.isPassPS,
        score: profile?.problemSolvingScore,
        percentage: profile?.problemSolvingPercentage,
        dateAttempted: JSON.parse(profile?.statusMeta || "{}")?.dateOfPSStatus,
      },
      [possibleTabs[3]]: extractFinalTechData(),
    }),
    [profile, profile?.takenDomainTests],
  );

  const selectedData: IResetCardProp | IResetCardProp[] = useMemo(
    () => testData[selectedTest],
    [selectedTest, testData],
  );

  const extractKey = (val): string | undefined => {
    return Object.keys(ResetAndPassTestsList).find((key) => ResetAndPassTestsList[key] === val);
  };

  const tabHeaderHandler = (input: string): void => {
    const currTest = extractKey(input);
    if (currTest) {
      setSelectedTest(currTest);
    }
  };

  const passTestHandler = (index?: number): void => {
    if (selectedTest === "DomainTests" && typeof index === "number") {
      const mainDomainTests = profile?.takenDomainTests
        ? profile?.takenDomainTests?.map((item) => _.omit(item, "__typename"))
        : [];
      const modifiedDomainTests = mainDomainTests?.map((item) => _.omit(item, "__typename"));

      if (modifiedDomainTests) {
        modifiedDomainTests[index] = {
          ...mainDomainTests[index],
          isPass: true,
        };
        handleResetAndPass({ takenDomainTests: modifiedDomainTests });
      }
    }
    if (selectedTest === "ProblemSolving") {
      handleResetAndPass({ isPassPS: true });
    }
    if (selectedTest === "HRScreening") {
      handleResetAndPass({ isPassHR: true });
    }
    if (selectedTest === "FinalInterview") {
      handleResetAndPass({ isPassFinalTech: true });
    }
  };
  const resetTestHandler = (index?: number): void => {
    const currTest = testData[selectedTest];
    // Remove __typeame from relevant fields
    const mainDomainTests = profile?.takenDomainTests?.map((item) => _.omit(item, "__typename"));
    const feedbacks = profile?.talentFeedback?.map((item) => _.omit(item, "__typename"));
    const mainResetTests = _.omit(profile?.resetTests, "__typename");
    Object.keys(mainResetTests).forEach((ele) => {
      mainResetTests[ele] = mainResetTests[ele]?.map((item) => _.omit(item, "__typename"));
    });

    if (selectedTest === "DomainTests" && typeof index === "number") {
      const intialDomainsResets = mainResetTests?.domainSkillTests;
      const domainTestToReset = mainDomainTests?.splice(index, 1);

      const newDomainResets: (TechDomainSkillsProgressModified | null)[] = [];
      if (intialDomainsResets) {
        intialDomainsResets.forEach((item) => newDomainResets.push(item));
      }
      if (domainTestToReset) newDomainResets.push(domainTestToReset[0]);

      const resultingDomainResets = mainResetTests
        ? {
            ...mainResetTests,
            domainSkillTests: newDomainResets,
          }
        : { domainSkillTests: newDomainResets };
      handleResetAndPass({
        resetTests: resultingDomainResets,
        takenDomainTests: mainDomainTests,
      });
    }
    if (!Array.isArray(currTest)) {
      if (selectedTest === "ProblemSolving") {
        const psTestToReset = _.omit(profile?.problemSolvingTest, "__typename");
        const problemSolvingTests = mainResetTests?.problemSolvingTests
          ? [...mainResetTests?.problemSolvingTests, psTestToReset]
          : [psTestToReset];
        const resultingResetTests = mainResetTests
          ? { ...mainResetTests, problemSolvingTests }
          : { problemSolvingTests };
        handleResetAndPass({
          resetTests: resultingResetTests,
          isPassPS: null,
          problemSolvingScore: null,
          problemSolvingPercentage: null,
          problemSolvingTest: null,
        });
      }
      if (selectedTest === "FinalInterview") {
        const finalTechFeedback = feedbacks?.find(
          (feedback) => feedback?.type === "FinalInterviewFeedback",
        );
        const extractedFinalTech = {
          isPass: currTest.isPass || false,
          dateAttempted: currTest.dateAttempted,
          feedback: finalTechFeedback,
        };
        const finalInterviews = mainResetTests?.finalInterviews
          ? [...mainResetTests?.finalInterviews, extractedFinalTech]
          : [extractedFinalTech];

        const resultingResetTests = mainResetTests
          ? { ...mainResetTests, finalInterviews }
          : { finalInterviews };

        handleResetAndPass({
          resetTests: resultingResetTests,
          isPassFinalTech: null,
          talentFeedback: feedbacks?.filter(
            (feedback) => feedback?.type !== "FinalInterviewFeedback",
          ),
        });
      }
      if (selectedTest === "HRScreening") {
        const hrFeedback = feedbacks?.find((feedback) => feedback?.type === "HRFeedback");
        const extractedFinalTech = {
          isPass: currTest.isPass || false,
          dateAttempted: currTest.dateAttempted,
          feedback: hrFeedback,
          hrVideoId: profile?.hrInterviewVideoId,
          dateOfHRStatus: JSON.parse(profile?.statusMeta || "{}")?.dateOfHRStatus || null,
        };
        const hrInterviews = mainResetTests?.hrInterviews
          ? [...mainResetTests?.hrInterviews, extractedFinalTech]
          : [extractedFinalTech];

        const resultingResetTests = mainResetTests
          ? { ...mainResetTests, hrInterviews }
          : { hrInterviews };

        handleResetAndPass({
          resetTests: resultingResetTests,
          isPassHR: null,
          talentFeedback: feedbacks?.filter((feedback) => feedback?.type !== "HRFeedback"),
        });
      }
    }
  };

  return (
    <S.ResetPassContainer>
      <S.HeaderContainer>
        <S.ResetPassHeader>Manage Tests</S.ResetPassHeader>
      </S.HeaderContainer>
      <S.TabHeaders>
        {Object.values(ResetAndPassTestsList).map((singleTest, index) => (
          <S.TabContent
            key={index}
            onClick={(): void => tabHeaderHandler(singleTest)}
            $active={selectedTest === extractKey(singleTest)}
          >
            <S.TabHeaderText>{singleTest}</S.TabHeaderText>
          </S.TabContent>
        ))}
      </S.TabHeaders>
      <S.ResetPassContent>
        <ShouldRender if={selectedData && !Array.isArray(selectedData)}>
          <Fragment>
            <ShouldRender
              if={!Array.isArray(selectedData) && typeof selectedData?.isPass === "boolean"}
            >
              <Fragment>
                {!Array.isArray(selectedData) && (
                  <ResetAndPassCard
                    resetCardProp={selectedData}
                    selectedTest={selectedTest}
                    isLoading={!!updateInProgress}
                    passTestHandler={passTestHandler}
                    resetTestHandler={resetTestHandler}
                  />
                )}
              </Fragment>
            </ShouldRender>
            <ShouldRender
              if={!Array.isArray(selectedData) && typeof selectedData?.isPass !== "boolean"}
            >
              <ResetAndPassEmpty />
            </ShouldRender>
          </Fragment>
        </ShouldRender>

        <ShouldRender if={selectedData && Array.isArray(selectedData)}>
          <Fragment>
            <ShouldRender if={Array.isArray(selectedData) && selectedData?.length === 0}>
              <ResetAndPassEmpty />
            </ShouldRender>
            <ShouldRender if={Array.isArray(selectedData) && selectedData?.length > 0}>
              <Fragment>
                {Array.isArray(selectedData) &&
                  selectedData?.map((item, index) => (
                    <ResetAndPassCard
                      resetCardProp={item}
                      key={index}
                      selectedTest={selectedTest}
                      index={index}
                      isLoading={!!updateInProgress}
                      passTestHandler={passTestHandler}
                      resetTestHandler={resetTestHandler}
                    />
                  ))}
              </Fragment>
            </ShouldRender>
          </Fragment>
        </ShouldRender>
      </S.ResetPassContent>
    </S.ResetPassContainer>
  );
};

export default withUpdateFeedback(ResetPassTests);
