import React from "react";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import * as Styled from "../talents/styles";
import { TableType } from "./utils";

interface HeaderProps {
  onClickDownload: (toDownload: TableType) => void;
  devCount: number;
  setSelectedCountry: (country: string) => void;
  selectedCountry?: string;
  countriesList: string[];
}

const Header: React.FC<HeaderProps> = ({
  onClickDownload,
  devCount,
  setSelectedCountry,
  selectedCountry,
  countriesList,
}) => {
  return (
    <Container>
      <Row>
        <Col>
          <em>Total number of developers referred by recruiters: {devCount}</em>
        </Col>
        <Col className="d-flex justify-content-end" xs={8}>
          <Styled.DropdownBtn
            title={"Select Country"}
            onSelect={(country): void => setSelectedCountry(country)}
          >
            {countriesList.map((country) => (
              <Dropdown.Item key={country} eventKey={country} active={selectedCountry === country}>
                {country}
              </Dropdown.Item>
            ))}
          </Styled.DropdownBtn>
          <Button
            onClick={(): void => onClickDownload(TableType.Recruiters)}
            variant="outline-primary"
            style={{ height: 38, marginLeft: 5 }}
            size="sm"
          >
            Download Recruiter List
          </Button>
          <Button
            onClick={(): void => onClickDownload(TableType.Marketing)}
            variant="outline-secondary"
            style={{ height: 38, marginLeft: 5 }}
            size="sm"
          >
            Download Marketing List
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
