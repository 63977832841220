import { ShouldRender } from "@remotebase/components";
import { FC } from "react";
import * as Styled from "../styles";

export const RowData: FC<{
  data: { [key: string]: number | string };
  removeRow?: (unknown) => void;
  index?: number;
  range?: string;
}> = (props) => {
  const { range, data, removeRow, index } = props;
  return (
    <tr key={index}>
      <ShouldRender if={range}>
        <td>{range}</td>
      </ShouldRender>
      <ShouldRender if={data.date}>
        <td>{data.date}</td>
      </ShouldRender>
      <td>{data.developersJoined}</td>
      <td>{data.developersCompletedProfiles}</td>
      <td>{data.hiredToday}</td>
      <td>{data.recruitersJoined}</td>
      <td>{data.takenDomainTests}</td>
      <td>{data.passedDomainTests}</td>
      <td>{data.takenProblemSolvingTests}</td>
      <td>{data.passedProblemSolving}</td>
      <td>{data.developersReferredByRecruiters}</td>
      <ShouldRender if={!!removeRow}>
        <td>
          <p onClick={(): void => removeRow?.(index)}>
            <Styled.Cross>x</Styled.Cross>
          </p>
        </td>
      </ShouldRender>
    </tr>
  );
};

export default RowData;
