import React from "react";
import { ShouldRender } from "@remotebase/components";
import { ButtonType, Color } from "utils";
import * as Styled from "./styles";

interface Props {
  label: string;
  isLoading?: boolean;
  small?: boolean;
  type?: ButtonType;
  onClick?: () => void;
  disabled?: boolean;
}

export const FormButton: React.FC<Props> = ({
  label,
  isLoading = false,
  small = false,
  type = ButtonType.BUTTON,
  onClick,
  disabled,
}) => {
  function handleClick(e): void {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  return (
    <Styled.Btn type={type} disabled={isLoading || disabled} $small={small} onClick={handleClick}>
      {label}
      <ShouldRender if={isLoading}>
        <Styled.LoadingSpinner color={Color.WHITE} />
      </ShouldRender>
    </Styled.Btn>
  );
};

export default FormButton;
