import { csvFile, csvTypeError, ErrorProps } from "@remotebase/constants";
import { DocumentUploaderCard } from "@remotebase/components";
import { useFileUpload } from "hooks";
import { FC, useEffect, useState, useCallback } from "react";
import { useSWRConfig } from "swr";
import { Modal } from "react-bootstrap";
import { IDocumentState } from "@remotebase/components/documentUploader/types";
import useCreateAndValidateInvoiceBatch from "hooks/fileUpload/useCreateAndValidateInvoiceBatch";
import withError from "state/error/withErrorHoc";
import { LIST_BATCH_KEY } from "pages/invoices/utils";

type Props = {
  shouldShow: boolean;
  handleOnHide: () => void;
} & ErrorProps;

const ClientInvoiceModal: FC<Props> = ({ handleOnHide, shouldShow, showError }) => {
  const {
    res: { isLoading: loading, data, error },
    uploadClientInvoice,
  } = useFileUpload();
  const [invoiceBatch, setInvoiceBatch] = useState<IDocumentState>({ isUploaded: false });
  const onHandleSubmit = async (file: File): Promise<void> => uploadClientInvoice(file);
  const {
    createAndValidateInvoiceBatch,
    loading: batchIsLoading,
    error: invoiceBatchError,
  } = useCreateAndValidateInvoiceBatch();
  const { mutate } = useSWRConfig();

  const handleCreateAndValidateInvoiceBatch = useCallback(async () => {
    if (!data) return;
    showError({ title: "Invoice Batch", message: "Creating invoice batch..." });

    await createAndValidateInvoiceBatch({
      variables: { payload: data },
    });

    if (invoiceBatchError) {
      showError({ title: "Invoice Batch", message: "Error creating invoice batch" });
      return;
    }

    showError({ title: "Invoice Batch", message: "Invoice batch created successfully" });
    mutate(LIST_BATCH_KEY);
  }, [data, invoiceBatchError]);

  useEffect(() => {
    if (!loading && data) setInvoiceBatch((prev) => ({ ...prev, isSubmitted: true }));
  }, [loading, data]);

  useEffect(() => {
    const handleInvoiceUpload = (): void => {
      if (error) {
        showError({ title: "Invoice Upload", message: "Error uploading invoice" });
        return;
      }
      showError({ title: "Invoice Upload", message: "Invoice uploaded successfully" });
      handleCreateAndValidateInvoiceBatch();
      // Close modal after upload
      handleOnHide();
    };

    if (invoiceBatch?.isSubmitted) handleInvoiceUpload();
  }, [invoiceBatch?.isSubmitted]);

  return (
    <Modal show={shouldShow} onHide={handleOnHide}>
      <Modal.Body>
        <DocumentUploaderCard
          handleSubmit={onHandleSubmit}
          isLoading={loading || batchIsLoading}
          document={invoiceBatch}
          setDocument={setInvoiceBatch}
          allowedTypes={[csvFile]}
          allowedTypesError={csvTypeError}
          noSizeLimit
        />
      </Modal.Body>
    </Modal>
  );
};

export default withError(ClientInvoiceModal);
