import { FC, Fragment, useEffect, useState } from "react";
import { IDashboard } from "state/dashboard";
import Highcharts from "highcharts";
import { ShouldRender } from "@remotebase/components";
import { Container } from "react-bootstrap";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import withDashboard from "state/dashboard/withDashboardHoc";
import moment, { Moment } from "moment";
import { adjustDays } from "utils";
import { OperationDays } from "utils/constants/dashboard";
import { DatePickerComponent } from "./components";
import * as Styled from "./styles";

exporting(Highcharts);
exportData(Highcharts);

export const AdminDashboard: FC<IDashboard> = ({ data, graph, showData, dateHandler }) => {
  const [toDate, setToDate] = useState<Moment>(moment());
  const [fromDate, setFromDate] = useState<Moment>(adjustDays(7, OperationDays.subtract));

  useEffect(() => {
    if (toDate && fromDate) dateHandler(toDate, fromDate);
  }, [toDate, fromDate]);

  const clickFromDate = (date): void =>
    setFromDate(
      toDate && date >= toDate ? adjustDays(1, OperationDays.subtract, toDate) : moment(date),
    );

  const clickToDate = (date): void => {
    setToDate(() => {
      if (date > moment()) return moment();
      if (fromDate && date <= fromDate) return adjustDays(1, OperationDays.add, fromDate);
      return moment(date);
    });
  };

  return (
    <Fragment>
      <Styled.DateAlignGraph>
        <DatePickerComponent text="From" newDate={fromDate} changeDate={clickFromDate} />
        <DatePickerComponent text="To" newDate={toDate} changeDate={clickToDate} />
      </Styled.DateAlignGraph>
      <ShouldRender if={!showData.loading && !data.isData}>
        <Styled.NoData>No Data Available</Styled.NoData>
      </ShouldRender>
      <ShouldRender if={!showData.loading && data.isData && !showData.nextToken}>
        <Container fluid>
          <Styled.GraphButton highcharts={Highcharts} options={graph.options} />
        </Container>
      </ShouldRender>
    </Fragment>
  );
};

export default withDashboard(AdminDashboard);
