import { useClientList } from "hooks";
import { ReturnPropsFetchClients } from "hooks/types";
import { ListClientsQueryVariables } from "API";

export const useFetchClient = (): ReturnPropsFetchClients => {
  const { data, loading, nextToken, getClientList } = useClientList();

  const fetchData = (nextTokenId?: string | null, filter = {}): void => {
    const variables: ListClientsQueryVariables = {
      nextToken: nextTokenId,
    };

    if (Object.keys(filter).length > 0) variables.filter = filter;

    getClientList({ variables });
  };

  return {
    data,
    nextToken,
    loading,
    fetchData,
  };
};

export default useFetchClient;
