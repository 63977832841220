import "assets/css/icon.css";
import { AuthenticationHeader } from "@remotebase/components";
import { Login } from "components";
import { FC } from "react";
import { UserType } from "@remotebase/constants";

export const Authentication: FC = () => {
  const getAuthComponent = (): JSX.Element => <Login />;

  return <AuthenticationHeader authComponent={getAuthComponent()} userType={UserType.Admin} />;
};
export default Authentication;
