import styled from "styled-components";
import { Button } from "react-bootstrap";

export const EditButton = styled(Button)`
  position: relative;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 6px;
  min-height: 42px;
  width: 92px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  margin-left: 10px;

  @media screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const CancelButton = styled(EditButton)`
  background-color: #6c757d;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
`;
