import { Button, DropdownButton, Form, Pagination, Table } from "react-bootstrap";
import styled from "styled-components";

export const TableHeading = styled.h3`
  font-size: 24px;
  color: #11142d;
  font-weight: 600;
  margin: 0px 24px 0px 0px;
`;

export const MainTableHeading = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableDate = styled.div`
  font-size: 12px;
  color: #9995b6;
  display: flex;
  align-items: center;
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableContent = styled.div`
  padding: 20px 20px 110px;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  position: relative;
`;

export const TableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableSubHeading = styled.h4`
  font-size: 20px;
  color: #11142d;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  margin-right: auto;
`;

export const TableForm = styled(Form)`
  flex-grow: 1;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const SearchGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;

  @media only screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const SearchMain = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1279px) {
    width: 100%;
    margin-top: 24px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const SearchGroupSearch = styled.span`
  position: absolute;
  left: 4px;
  top: 6px;
  bottom: 6px;
  border-right: 1px solid #dddfe2;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: #7f808fff;
  }
`;

export const SearchGroupMain = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 1279px) {
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 20px;
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const SearchGroupField = styled(Form.Control)`
  font-size: 14px !important;
  font-weight: normal !important;
  border: 1px solid #dddfe2 !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  height: 40px !important;
  width: 100% !important;
  max-width: 200px;
  min-width: 200px !important;
  padding: 8px 12px !important;
  margin: 0px 16px !important;

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 1279px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0 !important;
  }

  @media only screen and (max-width: 575px) {
    margin: 0px 0px 16px 0px !important;
    min-width: 150px !important;
    padding: 8px 10px;
  }
`;

export const SearchButton = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 120px;
  min-height: 40px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const MainTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 40px;
  min-width: 860px;

  thead tr th {
    font-size: 14px;
    font-weight: 500;
    color: #474d58;
    padding: 10px 16px;
    border-top: 1px solid #e5ecff;
    border-bottom: 1px solid #e5ecff;
    word-break: break-word;
  }

  thead tr th:first-child {
    border-radius: 5px 0px 0px 5px;
    border-left: 1px solid #e5ecff;
  }
  thead tr th:last-child {
    border-radius: 0px 5px 5px 0px;
    border-right: 1px solid #e5ecff;
  }
  tbody tr td,
  tfoot tr td {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    border: none;
    padding: 10px 15px;
    word-break: break-word;
  }

  tbody tr:first-child td {
    padding-top: 20px;
  }

  tbody tr td:nth-of-type(1) {
    cursor: pointer;
  }
  thead tr th:nth-of-type(1),
  tbody tr td:nth-of-type(1) {
    width: 15%;
  }

  thead tr th:nth-of-type(2),
  tbody tr td:nth-of-type(2) {
    width: 14%;
  }
  thead tr th:nth-of-type(3),
  tbody tr td:nth-of-type(3) {
    width: 14%;
  }
  thead tr th:nth-of-type(4),
  tbody tr td:nth-of-type(4) {
    width: 16%;
  }
  thead tr th:nth-of-type(5),
  tbody tr td:nth-of-type(5) {
    width: 14%;
  }
  thead tr th:nth-of-type(6),
  tbody tr td:nth-of-type(6) {
    width: 14%;
  }
  thead tr th:nth-of-type(7),
  tbody tr td:nth-of-type(7) {
    width: 13%;
  }
`;

export const TableLink = styled.a`
  font-size: 12px;
  color: #007bff;
`;

export const TableOpt = styled.a`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 0 10px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #6c5dd3;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: #6c5dd3;
  }

  &:hover svg path,
  &:focus svg path {
    fill: #fff;
  }
`;

export const PaginationMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const PaginationTable = styled(Pagination)`
  position: absolute;
  left: auto;
  right: 20px;
  bottom: 30px;
  text-align: center;
  justify-content: center;

  .page-link {
    font-size: 14px;
    color: #6c5dd3 !important;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    border: none;
    margin: 0 6px;
  }

  .page-item.active .page-link {
    color: #fff !important;
    background-color: #6c5dd3 !important;
  }
  .page-link svg {
    width: 14px;
    height: 14px;
  }
  .page-link svg path {
    color: #6c5dd3;
  }

  .page-item.disabled .page-link {
    opacity: 0.5;
    border: none !important;
    background-color: none;
  }
`;

export const DropdownBtn = styled(DropdownButton)`
  display: inline-flex;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #dddfe2;
      color: #474d58;
    }
  }

  & .dropdown-toggle {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    padding: 4px 12px;
    min-width: 120px;
    min-height: 40px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #dddfe2;
    font-family: "Poppins", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #fff !important;
      border: 1px solid #dddfe2 !important;
      color: #474d58 !important;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  & .dropdown-menu {
    border-radius: 6px;
    padding: 6px 0;

    @media only screen and (max-width: 767px) {
      width: 100%;
      left: 0 !important;
    }
  }

  & .dropdown-item {
    color: #474d58;
    font-size: 14px;
    padding: 6px 12px;
  }
`;
