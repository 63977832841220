import { FC, useEffect, useState } from "react";
import { useTalentJobMatchContext } from "state/talentjobMatch";
import { cleanArray, ShouldRender } from "@remotebase/components";
import { useParams } from "react-router-dom";
import { useFetchTalentJobMatches } from "hooks";
import * as Styled from "../styles";
import { NewTalentJobMatch, ViewTalentJobMatches } from "./components";

export const MatchTalentSection: FC = () => {
  const { id: talentID } = useParams<{ id: string }>();
  const { jobMatches, setJobMatches } = useTalentJobMatchContext();
  const [fetching, setFetching] = useState(false);

  const {
    getTalentMatches,
    talentJobMatches,
    loading: matchesLoading,
    nextToken: matchesNextToken,
  } = useFetchTalentJobMatches();

  useEffect(() => {
    if (!talentID) return;

    getTalentMatches({ variables: { filter: { talentID: { eq: talentID } } } });
    setFetching(true);
  }, [talentID]);

  // save and fetch more talent job matches if any
  useEffect(() => {
    if (!matchesLoading && talentJobMatches && fetching) {
      setJobMatches((prev) => [...(prev ?? []), ...cleanArray(talentJobMatches)]);
      if (matchesNextToken)
        getTalentMatches({
          variables: { filter: { talentID: { eq: talentID } }, nextToken: matchesNextToken },
        });
      else setFetching(false);
    }
  }, [matchesLoading, talentJobMatches, matchesNextToken, fetching]);

  return (
    <Styled.MatchTalentWrapper>
      <Styled.MatchTalentTop>
        <Styled.MatchTalentTitle>Match Talent</Styled.MatchTalentTitle>
      </Styled.MatchTalentTop>
      <NewTalentJobMatch />
      <hr />
      <ShouldRender if={jobMatches.length}>
        <ViewTalentJobMatches />
      </ShouldRender>
      <ShouldRender if={!jobMatches.length}>
        <Styled.MatchTalentItem>
          <Styled.MatchTalentImg>
            <img src="/images/match-talent.png" alt="match talent" />
          </Styled.MatchTalentImg>
          <Styled.MatchTalentSbHd>No match published yet!</Styled.MatchTalentSbHd>
          <Styled.MatchTalentPara>
            Match a talent and publish the profile to a client in order to match talent
          </Styled.MatchTalentPara>
        </Styled.MatchTalentItem>
      </ShouldRender>
    </Styled.MatchTalentWrapper>
  );
};

export default MatchTalentSection;
