import React from "react";
import { withUpdateFeedback } from "state/talentFeedback";
import { IFeedbackProps } from "state/types/talentFeedback.interface";
import ResumeInfo from "./ResumeInfo";

const ResumeCard: React.FC<IFeedbackProps> = ({
  feedbackState: { resumeLink },
  updateTalentProfile,
}) => {
  return <ResumeInfo resumeLink={resumeLink} updateTalentProfile={updateTalentProfile} />;
};

export default withUpdateFeedback(ResumeCard);
