import { useFetchTalentList } from "hooks";
import withApolloProvider from "hooks/apollo/withApollo";
import { compact } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ITalentContextType } from "state/types/talent.interface";
import {
  TalentFilterType,
  TalentProps,
  FilterOptions,
  pageLimit,
  getStateData,
  pageLimitLastPage,
  getLastPagePtr,
} from "utils";
import { TalentContext, TalentFilterContext } from ".";

export function withTalent<T>(Component: React.FC<T & TalentProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { talentState, setTalentState } = useContext<ITalentContextType>(TalentContext);
    const { filters, setFilters } = useContext(TalentFilterContext);
    const { fetchData, data, loading, nextToken } = useFetchTalentList();

    const [apiCalled, setApiCalled] = useState(false);
    const [fetchAll, setFetchAll] = useState(false);

    const callAPI = (): void => {
      setTalentState({
        ...talentState,
        loading: true,
        recruiterId: filters.filterOptions.searchText,
      });
      fetchData(nextToken || null, filters);
    };

    const prevBtnClick = (): void => {
      const prevPtr = talentState?.currentPtr - pageLimit;
      if (prevPtr >= 0) setTalentState({ ...talentState, currentPtr: prevPtr });
    };

    const nextBtnClick = (): void => {
      const nextPtr = talentState.currentPtr + pageLimit;
      const engrSize = talentState.data?.length || 0;

      setTalentState({ ...talentState, loading: true, currentPtr: nextPtr });
      if (engrSize < nextPtr + pageLimit && nextToken) {
        setApiCalled(true);
      } else {
        setTimeout(() => {
          setTalentState({ ...talentState, loading: false, currentPtr: nextPtr });
        }, 700);
      }
    };

    const searchByFilter = (type?: TalentFilterType, options?: FilterOptions): void => {
      setFilters({
        filterType: type || TalentFilterType.ListTalent,
        filterOptions: options || {},
      });
      setTalentState({ ...talentState, data: [], currentPtr: 0 });
      setApiCalled(true);
    };

    const isNextDisable = (): boolean => {
      const { nextToken: nxtTkn, data: engrData = [], currentPtr: ptr } = talentState;
      return !(nxtTkn || engrData.length > ptr + pageLimit);
    };

    const isPrevDisable = (): boolean => talentState?.currentPtr - pageLimit < 0;

    const state = useMemo(
      () => ({
        filterOptions: filters.filterOptions,
        prevBtnDisable: isPrevDisable(),
        nextBtnDisable: isNextDisable(),
      }),
      [talentState, filters],
    );

    const updateTalentData = (engrData, loadingArg, nextTokenArg, crtPtr?): void => {
      if (talentState?.data?.length) {
        const updatedEngState = {
          ...talentState,
          data: compact([...talentState.data, ...engrData]),
          loading: loadingArg,
          nextToken: nextTokenArg,
          currentPtr: crtPtr || talentState.currentPtr,
        };
        setTalentState(updatedEngState);
      } else {
        setTalentState({
          ...talentState,
          data: compact(engrData),
          loading: loadingArg,
          nextToken: nextTokenArg,
          currentPtr: crtPtr || talentState.currentPtr,
        });
      }
    };

    const fetchMoreTalents = (isAll: boolean, token: string): void => {
      const limit = isAll ? pageLimitLastPage : pageLimit;
      updateTalentData(data, true, nextToken);
      fetchData(token, filters, limit, isAll);
    };

    const lastPageBtn = (): void => {
      setFetchAll(true);
      if (nextToken) fetchData(nextToken, filters, pageLimitLastPage, true);
      else
        setTalentState({
          ...talentState,
          currentPtr: getLastPagePtr(talentState.data?.length),
        });
    };

    useEffect(() => {
      const engrSize = talentState?.data?.length || 0;
      if (fetchAll) {
        if (nextToken) fetchMoreTalents(fetchAll, nextToken);
        else {
          updateTalentData(data, loading, nextToken, engrSize + getLastPagePtr(data?.length));
          setFetchAll(false);
        }
      }
      if (!fetchAll && apiCalled && !loading && data !== undefined) {
        if (!nextToken || engrSize + data.length >= talentState.currentPtr + pageLimit) {
          setApiCalled(false);
          updateTalentData(data, loading, nextToken);
        } else {
          fetchMoreTalents(fetchAll, nextToken);
        }
      }
    }, [nextToken, data]);

    useEffect(() => {
      if (!talentState.data || apiCalled) {
        setApiCalled(true);
        callAPI();
      }
    }, [apiCalled]);

    const talentProps: TalentProps = {
      talentState: { ...talentState, ...getStateData(talentState, pageLimit) },
      prevBtnClick,
      nextBtnClick,
      lastPageBtn,
      searchByFilter,
      state,
    };
    return <Component {...props} {...talentProps} />;
  });
}
export default withTalent;
