import styled from "styled-components";

export const ChatPreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px;
  margin-bottom: 16px;
  border: 1px solid #f4f4f4;
  background: #ffffff;
  border-radius: 5px;
  @media only screen and (min-width: 576px) {
    padding: 25px 20px 25px 17px;
  }

  cursor: pointer;
  &:hover,
  &:focus,
  &.active {
    background: #eff4ff;
  }
`;

export const ChatPreviewContent = styled.div`
  width: 100%;
`;

export const ChatPreviewAvatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 65px;
  height: 65px;
  border-radius: 3px;
  border: none;
  margin-right: 18px;
  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 3px;
    object-fit: cover;
  }
  &::after {
    position: absolute;
    // content: "";
    top: auto;
    bottom: -3px;
    left: auto;
    right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #d6d6d6;
    background: transparent;
  }
  &.active {
    &::after {
      border: none;
      background: #16f1ca;
    }
  }
`;

export const ChatPreviewTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3px;
`;

export const ChatPreviewName = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  font-weight: 800;
  color: #1c1b1f;
  letter-spacing: 0.2px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (min-width: 420px) {
    font-size: 18px;
    line-height: 23px;
  }
`;

export const ChatPreviewDay = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  color: #344054;
  text-align: right;
  margin: 0px 0px 0px 2px;
  @media only screen and (min-width: 420px) {
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 0px 8px;
  }
`;

export const ChatPreviewDesignation = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #344054;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0px 0px 3px 0px;
`;

export const ChatPreviewBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ChatPreviewText = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 800;
  color: #1c1b1f;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  &.read__msg {
    font-weight: 500;
  }
`;

export const ChatPreviewTickIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: #27ae60;
  margin: 0px 0px 0px 6px;
  & i {
    &::before {
      color: #27ae60;
    }
  }
`;
