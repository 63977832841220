import { useMutation } from "@apollo/client";
import {
  CreateTalentProfileVersionMutation,
  CreateTalentProfileVersionMutationVariables,
  TalentProfileVersion,
} from "@remotebase/amplify-constants/API";
import { createTalentProfileVersion } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "../utils";
import { ICreateTalentProfileVersionReturnProps } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useCreateTalentProfileVersion = (): ICreateTalentProfileVersionReturnProps => {
  const [createTalentVersion, { data, loading, error }] = useMutation<
    CreateTalentProfileVersionMutation,
    CreateTalentProfileVersionMutationVariables
  >(getQuery(createTalentProfileVersion));

  return {
    createTalentVersion,
    data: data?.createTalentProfileVersion as TalentProfileVersion,
    loading,
    error,
  };
};
