import { FC, useEffect, useRef, useState } from "react";
import { useProfileVersionContext } from "state/profileVersion";
import { EditRoundedIcon } from "assets/icons";
import { UpdateSkillModal, UpdateOtherInfo } from "components";
import {
  ProfileInfoSection,
  ProfileInnerDetailsSection,
  ProfileSkillSection,
  CameraIcon,
  acceptedImageTypes,
  acceptedImgSize,
  UploadPhotoModal,
} from "@remotebase/components";
import { useFileUpload } from "hooks";
import * as Styled from "../styles";

export const ProfileDetailsSection: FC = () => {
  const { selectedVersion, talent, setSelectedVersion } = useProfileVersionContext();
  const { expectedSalaryInUsd, experience, englishProficiency, title, imgLink, isAvailable } =
    selectedVersion || {};
  const { fullName, country } = talent || {};
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [showSkillsEditModal, setShowSkillsEditModal] = useState(false);
  const [showOtherInfoEditModal, setShowOtherInfoEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserImg, setSelectedUserImg] = useState<File | null>(null);
  const { uploadImage, res } = useFileUpload();

  const toggleSkillsModal = (): void => setShowSkillsEditModal((prev) => !prev);
  const toggleOtherInfoModal = (): void => setShowOtherInfoEditModal((prev) => !prev);

  const togglePhotoModal = (): void => setShowModal((val) => !val);

  const handleNewPhotoUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files || !event.target.files[0]) return;

    const newImg = event.target.files[0];
    if (acceptedImageTypes.includes(newImg.type) && newImg.size <= acceptedImgSize) {
      setSelectedUserImg(newImg);
      togglePhotoModal();
    }
  };
  const handleFileInputClick = (): void => uploadImageRef.current?.click();

  const getSkillEditSection = (): JSX.Element => {
    return (
      <Styled.ExpertiseEditIcon onClick={toggleSkillsModal}>
        <EditRoundedIcon />
      </Styled.ExpertiseEditIcon>
    );
  };

  const getProfileDetailsEditSection = (): JSX.Element => {
    return (
      <Styled.ProfileDetailEditIcon onClick={toggleOtherInfoModal}>
        <EditRoundedIcon />
      </Styled.ProfileDetailEditIcon>
    );
  };

  const getProfileEditInfoSection = (): JSX.Element => {
    return (
      <Styled.EditTalentAvatarIcon onClick={handleFileInputClick}>
        <Styled.UploadImgInput
          ref={uploadImageRef}
          type="file"
          onChange={handleNewPhotoUpload}
          accept={acceptedImageTypes.join(", ")}
        />
        <CameraIcon />
      </Styled.EditTalentAvatarIcon>
    );
  };

  const saveAvailability = (availability: boolean): void => {
    setSelectedVersion((prev) => (prev ? { ...prev, isAvailable: availability } : null));
  };

  useEffect(() => {
    if (res.isLoading) return;

    if (res.data) {
      setSelectedVersion((prev) => (prev ? { ...prev, imgLink: res.data } : null));
      togglePhotoModal();
      setSelectedUserImg(null);
    }
  }, [res]);

  return (
    <Styled.EditTalentInfoWrapper>
      <ProfileInfoSection
        fullName={fullName}
        country={country}
        title={title}
        imageLink={imgLink}
        children={getProfileEditInfoSection()}
        domainTests={talent?.profile?.takenDomainTests}
        feedbackLength={0}
        isAvailable={isAvailable}
        saveAvailability={saveAvailability}
      />
      <ProfileSkillSection
        skills={selectedVersion?.skills || []}
        children={getSkillEditSection()}
      />
      <ProfileInnerDetailsSection
        expectedSalaryInUsd={expectedSalaryInUsd}
        experience={experience || ""}
        englishProficiency={englishProficiency || ""}
        children={getProfileDetailsEditSection()}
      />

      <UpdateOtherInfo open={showOtherInfoEditModal} close={toggleOtherInfoModal} />
      <UpdateSkillModal open={showSkillsEditModal} close={toggleSkillsModal} />

      {selectedUserImg && (
        <UploadPhotoModal
          show={showModal}
          close={togglePhotoModal}
          selectedImage={selectedUserImg}
          uploader={uploadImage}
          uploading={res.isLoading}
        />
      )}
    </Styled.EditTalentInfoWrapper>
  );
};

export default ProfileDetailsSection;
