import { useAnalyticsList } from "hooks";
import { AnalyticsData } from "hooks/types";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { adjustDays, edgeOfDay, getDate } from "utils";
import {
  AnalyticsDistributedInitial,
  analyticsEnum,
  AnalyticsInitial,
  ComponentType,
  OperationDays,
  OperationTime,
  TimePeriod,
} from "utils/constants/dashboard";
import {
  getChartData,
  getTableData,
  IAnalyticsType,
  IDashboard,
  IDashboardHoc,
  IDashboardHOCProp,
  IDatesType,
  IGraph,
} from ".";

export function withDashboard<T>(
  Component: React.FC<T & IDashboard>,
): React.FC<T & IDashboardHOCProp> {
  const Dashboard = (props: T & IDashboardHOCProp): JSX.Element => {
    const { name } = props;
    const [graph, setGraph] = useState<IGraph>({ options: {} });
    const [weekdata, setWeekData] = useState<IAnalyticsType>(AnalyticsInitial);
    const [monthdata, setMonthData] = useState<IAnalyticsType>(AnalyticsInitial);
    const [additionalData, setAdditionalData] = useState<IAnalyticsType[]>([]);
    const [isData, setIsData] = useState<boolean>(true);
    const [dates, setDates] = useState<IDatesType>();
    const [allAnalyticsList, setAllAnalyticsList] = useState<AnalyticsData | null>(null);
    const { getAnalyticList, analyticsList, nextToken, loading } = useAnalyticsList();
    const variables = useMemo(() => {
      return {
        filter: {
          createdAt: {
            ge: edgeOfDay(
              OperationTime.startOf,
              dates?.fromDate || adjustDays(30, OperationDays.subtract),
            ),
            le: edgeOfDay(OperationTime.endOf, dates?.toDate || moment()),
          },
        },
        nextToken: nextToken || null,
      };
    }, [nextToken, dates]);

    const getDateFormat = (dateA, dateB): string => `${dateA.format("ll")} - ${dateB.format("ll")}`;

    useEffect(() => {
      if ((dates?.toDate && dates?.fromDate) || (!dates?.toDate && !dates?.fromDate)) {
        getAnalyticList({ variables });
        if (dates?.toDate && dates?.fromDate)
          setAllAnalyticsList(() => {
            getAnalyticList({ variables });
            return null;
          });
      }
    }, [dates]);
    useEffect(() => {
      if (nextToken || ((analyticsList?.length || allAnalyticsList?.length) && !loading)) {
        setIsData(true);
        const graphAnalytics = cloneDeep(AnalyticsDistributedInitial);
        if (!allAnalyticsList && analyticsList) {
          [...analyticsList]
            .sort((a, b) => getDate(a) - getDate(b))
            .forEach((entry) =>
              Object.keys(analyticsEnum).forEach((e) => graphAnalytics[e].push(entry?.[e] ?? 0)),
            );
        } else if (allAnalyticsList && analyticsList) {
          [...allAnalyticsList, ...analyticsList]
            .sort((a, b) => getDate(a) - getDate(b))
            .forEach((entry) =>
              Object.keys(analyticsEnum).forEach((e) => graphAnalytics[e].push(entry?.[e] ?? 0)),
            );
        }
        if (nextToken) {
          setAllAnalyticsList(() => {
            getAnalyticList({ variables });
            return analyticsList;
          });
        }
        if (name === ComponentType.Graph) setGraph(getChartData(graphAnalytics));
        else if (name === ComponentType.Table && !nextToken) {
          if (!dates?.toDate && !dates?.fromDate) {
            setWeekData(getTableData(graphAnalytics, TimePeriod.Week));
            setMonthData(getTableData(graphAnalytics, TimePeriod.Month));
          } else if (dates.toDate && dates.fromDate) {
            setAdditionalData([
              ...additionalData,
              {
                ...getTableData(graphAnalytics, graphAnalytics.createdAt.length),
                date: getDateFormat(dates.fromDate, dates.toDate),
              },
            ]);
          }
        }
      } else if (analyticsList?.length === 0 && !loading) {
        if (name === ComponentType.Graph) setIsData(false);
        else if (!weekdata && !monthdata && name === ComponentType.Table) setIsData(false);
      }
    }, [analyticsList, loading]);

    const dateHandler = (toDate: Moment, fromDate: Moment): void => {
      setDates({
        toDate,
        fromDate,
      });
    };

    const removeRow = (index: number): void => {
      setAdditionalData(additionalData.filter((item) => item !== additionalData[index]));
    };

    const dashboardProps: IDashboardHoc = {
      data: { weekdata, monthdata, additionalData, isData },
      graph,
      showData: { loading, nextToken },
      removeRow,
      dateHandler,
    };
    return <Component {...props} {...dashboardProps} />;
  };
  return Dashboard;
}

export default withDashboard;
