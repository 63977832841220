import { Client } from "API";
import React from "react";
import { formattedDate } from "utils";

interface Props {
  client: Client;
}

export const ClientTableRowData: React.FC<Props> = ({ client }) => {
  return (
    <tr>
      <td>{client.email}</td>
      <td>{client.fullName}</td>
      <td>{client.phone}</td>
      <td>{client.company?.name}</td>
      <td>{formattedDate({ date: client.createdAt })}</td>
      <td>{client.id}</td>
    </tr>
  );
};

export default ClientTableRowData;
