import { Storage } from "aws-amplify";
import { useCallback, useState } from "react";
import {
  ApiCustomHookStateType,
  apiInitialState,
  getErrorResponse,
  getSuccessResponse,
  UploadHookReturnType,
} from "hooks/utils";
import { generate as generateShortUUID } from "short-uuid";
import awsConfig from "../../aws-exports";

export const useFileUpload = (): UploadHookReturnType<string, string | null | undefined, File> => {
  const [res, setRes] = useState<ApiCustomHookStateType<string>>(apiInitialState);

  const uploadFile = useCallback(
    async (
      file: File,
      fileName: string,
      bucket: string,
      accessLevel?: Storage["AccessLevel"],
      shouldStrip?: boolean,
    ): Promise<void> => {
      setRes((prev) => ({ ...prev, isLoading: true }));
      try {
        console.log("entered");
        const response = await Storage.put(fileName, file, {
          contentType: file?.type,
          bucket,
          level: accessLevel,
        });

        if (!response?.key) setRes(getErrorResponse("Error uploading file"));
        else {
          let uploadedFile = await Storage.get(response.key, { bucket, level: accessLevel });
          if (shouldStrip) {
            const [, stripedUploadedFile] = uploadedFile.split(".com/");
            uploadedFile = decodeURIComponent(stripedUploadedFile);
          }
          setRes(getSuccessResponse(uploadedFile.split("?")[0]));
        }
      } catch (error) {
        setRes(getErrorResponse(error?.message));
      }
    },
    [],
  );

  const uploadImage = useCallback(async (file: File, fileName?: string | null): Promise<void> => {
    const bucketName = awsConfig.aws_user_files_s3_bucket.replace("resumes", "images");
    const uploadFileName = fileName || `dp-${generateShortUUID()}.png`;
    return uploadFile(file, uploadFileName, bucketName);
  }, []);

  const uploadResume = useCallback(async (file: File): Promise<void> => {
    const bucketName = awsConfig.aws_user_files_s3_bucket;
    const uploadFileName = `resumes/${generateShortUUID()}-${file?.name}`;
    return uploadFile(file, uploadFileName, bucketName);
  }, []);

  const uploadClientInvoice = useCallback(async (file: File): Promise<void> => {
    const bucketName = awsConfig.aws_user_files_s3_bucket.replace("resumes", "finance");
    const uploadFileName = `batchInvoice/${generateShortUUID()}-${file?.name}`;
    return uploadFile(file, uploadFileName, bucketName, "private", true);
  }, []);

  return { res, uploadResume, uploadImage, uploadClientInvoice };
};

export default useFileUpload;
