import { Moment } from "moment";
import { FC, Fragment } from "react";
import DatePicker from "react-datepicker";
import * as Styled from "../styles";

export const DatePickerComponent: FC<{
  text: string;
  newDate?: Moment | null;
  isReport?: boolean;
  changeDate: (date: Date) => void;
}> = (props) => {
  const { text, newDate, changeDate, isReport } = props;
  const date = newDate?.toDate();
  return (
    <Fragment>
      <Styled.DatePickerText $report={isReport}>{text}</Styled.DatePickerText>
      <Styled.DatePickerStyle $report={isReport}>
        <DatePicker selected={date} onChange={changeDate} />
      </Styled.DatePickerStyle>
    </Fragment>
  );
};

export default DatePickerComponent;
