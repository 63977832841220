import HighchartsReact from "highcharts-react-official";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";

export const Graph = styled.div`
  display: flex;
  min-width: 1000px;
`;

export const Loading = styled.div`
  padding-left: 500px;
  padding-right: 500px;
`;

export const Label = styled.label`
  padding: 0px 30px;
`;

export const Span = styled.span`
  padding-left: 5px;
`;

export const Cross = styled.span`
  color: red;
  font-size: 22px;
  font-weight: 900;
`;

export const GraphButton = styled(HighchartsReact)`
  &.highcharts-contextbutton {
    margin-bottom: 100px;
  }
`;

export const NoData = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
`;

export const SearchGroupField = styled(Form.Control)`
  font-size: 14px !important;
  font-weight: normal !important;
  border: 1px solid #dddfe2 !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  height: 40px !important;
  width: 100% !important;
  max-width: 200px;
  min-width: 200px !important;
  padding: 8px 12px !important;
  margin: 0 16px !important;
  &:focus {
    box-shadow: none !important;
  }
  @media only screen and (max-width: 1279px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 1199px) {
    margin-left: 0 !important;
  }
  @media only screen and (max-width: 575px) {
    margin: 0 0 16px 0 !important;
    min-width: 150px !important;
    padding: 8px 10px;
  }
`;

export const SearchGroupMain = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const SearchButton = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 120px;
  min-height: 40px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;
