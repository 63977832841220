import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 22px 32px 28px;
  margin-bottom: 46px;
  font-family: "Poppins", sans-serif;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProfileHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProfileBlockTitle = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -1px;
  margin-bottom: 24px;
  display: inline-flex;

  @media only screen and (max-width: 991px) {
    font-size: 30px !important;
    line-height: 1.125 !important;
  }

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
`;

export const DropDownList = styled.select`
  padding: 14px 25px !important;
  margin: 0;
  border: 2px solid #e5e5e5;
  height: 55px !important;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 27.5px !important;

  &.disable {
    background-color: #ededed;
  }
`;

export const ListItem = styled.option`
  list-style: none;
  margin-bottom: 0.8em;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex: 1 1 0px;
`;

export const TalentRoleDiv = styled.div``;

export const TalentStatusDiv = styled.div`
  width: 225px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContainerStatus = styled.div`
  display: flex !important;
`;

export const ScoreInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
`;

export const FeedbackInputWrapper = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const HeadOpt = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: auto;
  padding-top: 5px;
  margin-bottom: 11px;

  @media only screen and (max-width: 575px) {
    padding-top: 3px;
  }
`;

export const OptButton = styled.button`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: #3f73f6;
  border: none;
  transition: all 0.25s;
  margin-left: 13px;

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #e4e4e4;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }
`;

export const ScoreDropDownWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 20px;
`;

export const FeedbackInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
`;

export const DivWrapper = styled.div`
  opacity: 1;
`;

export const ActionIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;

export const MatchEngLabel = styled.label`
  font-size: 16px;
  color: #11142d;
  font-weight: 600;
  display: inline-flex;
  margin-bottom: 0;
`;

export const DivWrapperHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const NetworkHeadOpt = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const NetworkButton = styled(Button)`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #3f73f6;
  padding: 4px;
  margin-left: 8px;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff;
    }
  }
`;
