import React, { useEffect, useState } from "react";
import { ShouldRender } from "@remotebase/components";
import { SelectOption } from "utils";
import Select from "react-select";
import * as Styled from "./styles";

interface Props {
  label?: string;
  placeholder: string;
  items: SelectOption[];
  small?: boolean;
  onSelect: (val: string) => void;
}

export const CustomDropdown: React.FC<Props> = ({
  items,
  label,
  placeholder,
  onSelect,
  small = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption>();

  useEffect(() => {
    if (selectedOption) onSelect(selectedOption.label);
  }, [selectedOption]);

  return (
    <Styled.InputGroup $small={small}>
      <ShouldRender if={!small}>
        <Styled.InputLabel>{label}</Styled.InputLabel>
      </ShouldRender>
      <Styled.DropdownInputCover>
        <Select
          placeholder={placeholder}
          options={items}
          onChange={setSelectedOption}
          value={null}
          classNamePrefix={"dropdown"}
        />
      </Styled.DropdownInputCover>
    </Styled.InputGroup>
  );
};

export default CustomDropdown;
