import React from "react";
import { TableHeader } from "components";
import { ShouldRender } from "@remotebase/components";
import { AdminUserData } from "hooks/types";
import * as Styled from "../styles";
import AdminUserTableData from "./adminUserTableData";

interface Props {
  data: AdminUserData[];
}
export const AdminUsersTable: React.FC<Props> = ({ data }) => {
  const columns = {
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    role: "Role",
  };

  return (
    <Styled.Table>
      <TableHeader columnNames={columns} />
      <tbody>
        <ShouldRender if={data?.length}>
          <AdminUserTableData data={data} />
        </ShouldRender>
      </tbody>
    </Styled.Table>
  );
};

export default AdminUsersTable;
