import styled from "styled-components";

export const UploadResumeWrapper = styled.div`
  background-color: #fff;
  border-radius: 24px;
  padding: 22px 32px 28px;
  margin-bottom: 46px;
  text-align: -webkit-center;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const UploadBoxHeading = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 24px;

  @media only screen and (max-width: 991px) {
    font-size: 30px !important;
    line-height: 1.125 !important;
  }

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
`;

export const SampleSpan = styled.span`
  font-size: 12px;
`;

export const ResumeWrapper = styled.div`
  position: relative;
`;

export const ResumeContainer = styled.div`
  height: auto;
`;

export const ResumeRemoveBtn = styled.button`
  background: #5f75ee;
  font-weight: 700;
  color: #fff;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 5%;
  right: 32px;
  font-size: 12px;
  padding: 7px 16px;
  border-radius: 6px;
  transition: 0.3s;

  &:hover {
    background: #6c5dd3;
  }
`;
