import { withClient } from "state/clients";
import React, { useMemo } from "react";
import {
  ButtonType,
  ClientFilterBy,
  ClientFilterSchema,
  DropdownItems,
  emailLabel,
  filterByLabel,
  fullNameLabel,
  WithClientProps,
} from "utils";
import { FormInput, FormSelect, FormButton } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../styles";

const TableFilter: React.FC<WithClientProps> = () => {
  const formHook = useForm({ resolver: yupResolver(ClientFilterSchema), mode: "onChange" });

  const items: DropdownItems = useMemo(() => {
    return [
      { key: filterByLabel, value: "" },
      { key: emailLabel, value: ClientFilterBy.email },
      { key: fullNameLabel, value: ClientFilterBy.fullName },
    ];
  }, []);

  return (
    <Styled.TableFilters onReset={(): void => formHook.reset()}>
      <FormSelect
        title={"filterBy"}
        handler={formHook}
        placeholder={"Filter By"}
        items={items}
        small={true}
      />
      <FormInput title={"query"} handler={formHook} placeholder={"Search"} small={true} />
      <FormButton type={ButtonType.SUBMIT} label={"SEARCH"} small={true} />
      <FormButton type={ButtonType.RESET} label={"CLEAR"} small={true} />
    </Styled.TableFilters>
  );
};

export default withClient(TableFilter);
