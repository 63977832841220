import React, { useEffect, useState } from "react";
import { useProfileVersionContext } from "state/profileVersion";
import { cleanArray, ShouldRender } from "@remotebase/components";
import { TalentProfileVersionShimmer } from "components/shimmerPages";
import { useParams } from "react-router-dom";
import { useFetchTalent, useFetchTalentProfileVersions } from "hooks";
import * as Styled from "./styles";
import { TalentVersionBody } from "./talentVersionBody";
import { TalentVersionHeader } from "./talentVersionHeader";
import { TalentVersionFooter } from "./talentVersionFooter";

export const TalentProfileVersion: React.FC = () => {
  const { id: talentID } = useParams<{ id: string }>();

  const [fetching, setFetching] = useState(false);
  const { getVersionsByTalent, data, loading, nextToken } = useFetchTalentProfileVersions();
  const { getTalent, data: talent, loading: talentLoading } = useFetchTalent();

  const { setTalent, selectedVersion, setProfileVersions, setSelectCurrentVersion } =
    useProfileVersionContext();

  useEffect(() => {
    if (talentID) getTalent({ variables: { id: talentID } });
  }, [talentID]);

  useEffect(() => {
    if (talent?.profile && !talentLoading) {
      const { profile } = talent;

      const defaultProfile = {
        bio: profile.bio,
        educationHistory: profile.educationHistory,
        employmentHistory: profile.employmentHistory,
        englishProficiency: profile.englishProficiency,
        expectedSalary: profile.expectedSalary,
        expectedSalaryInUsd: profile.expectedSalaryInUsd,
        experience: profile.experience,
        imgLink: profile.imgLink,
        interests: profile.interests,
        isAvailable: profile.isAvailable,
        name: "Original Profile",
        skills: profile.skills,
        social: profile.social,
        socialProfiles: profile.socialProfiles,
        talentID: talent.id,
        title: profile.title,
        version: 0,
      };

      setProfileVersions([defaultProfile]);
      setTalent(talent);
      getVersionsByTalent({ variables: { talentID: talent.id } });
      setFetching(true);
    }
  }, [talent, talentLoading, getVersionsByTalent]);

  useEffect(() => {
    if (!loading && talent?.id && fetching) {
      if (data) setProfileVersions((prev) => [...(prev ?? []), ...(cleanArray(data) ?? [])]);
      if (nextToken)
        getVersionsByTalent({
          variables: {
            nextToken,
            talentID: talent.id,
          },
        });
      else setFetching(false);
    }
  }, [talent?.id, nextToken, loading, data, fetching, getVersionsByTalent]);

  useEffect(() => {
    if (!fetching) setSelectCurrentVersion(true);
  }, [fetching]);

  return (
    <>
      <ShouldRender if={loading || talentLoading}>
        <TalentProfileVersionShimmer />
      </ShouldRender>
      <ShouldRender if={!loading && !talentLoading}>
        <>
          {talent && selectedVersion && (
            <Styled.EditTalentBodyWrapper>
              <TalentVersionHeader />
              <Styled.EditTalentMainWrapper>
                <TalentVersionBody />
              </Styled.EditTalentMainWrapper>
              <TalentVersionFooter />
            </Styled.EditTalentBodyWrapper>
          )}
        </>
      </ShouldRender>
    </>
  );
};

export default TalentProfileVersion;
