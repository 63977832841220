import styled from "styled-components";

export const TelInputCover = styled.div`
  & .PhoneInput {
    background: transparent;
    border-radius: 0.25rem !important;
    outline: none !important;
    box-shadow: none !important;
  }

  & .PhoneInputInput {
    margin-bottom: 0 !important;
    background: transparent;
    border: none !important;
    outline: none !important;
    padding: 0 !important;
    height: auto !important;
  }
`;

export default TelInputCover;
