import { IReturnPropsFetchReferredBy } from "hooks/types";
import { Recruiter, Referral, Talent } from "@remotebase/amplify-constants/API";
import { useEffect, useState } from "react";
import { compact } from "lodash";
import { useReferred } from "hooks";

export const useFetchReferredBy = (): IReturnPropsFetchReferredBy => {
  const { data: byReferrerData, loading, nextToken, getReferralReferredBy } = useReferred();

  const [data, setData] = useState<(Talent | Recruiter)[]>();
  const [stopFetching, setStopFetching] = useState<boolean>(false);

  const getDataFromReferral = (rawData: Array<Referral | null>): (Talent | Recruiter)[] =>
    compact(
      rawData.map((referredEntry) => referredEntry?.referrer || referredEntry?.recruiter || null),
    );
  const updateData = (rawData: Array<Referral | null>): void => {
    const newData = rawData.length ? getDataFromReferral(rawData) : [];
    setData(newData);
  };

  useEffect(() => {
    if (byReferrerData) {
      setStopFetching(!byReferrerData.length);
      updateData(byReferrerData);
    }
  }, [byReferrerData]);

  const fetchData = (talentId: string): void => {
    const variables = {
      filter: { referralReferredId: { eq: talentId } },
      nextToken,
      limit: 300, // as it takes too much time to load potentially no records
    };
    return getReferralReferredBy({ variables });
  };

  return {
    data,
    stopFetching,
    loading,
    fetchData,
  };
};

export default useFetchReferredBy;
