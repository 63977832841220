import { FC } from "react";
import { Feedback, feedbackType } from "@remotebase/amplify-constants/API";
import { IFeedbackProps } from "state/types/talentFeedback.interface";
import { ShouldRender } from "@remotebase/components";
import { withUpdateFeedback } from "state/talentFeedback";
import { QuestionIcon } from "assets/images";
import { PensilIcon, PlusIcon } from "assets/icons";
import { SentimentBadge } from "./SentimentBadge";
import * as Styled from "./styles";
import ViewFeedbackIcon from "./viewFeedbackIcon";

interface Props {
  feedback?: Feedback | null;
  isNetwork: boolean;
  label: string;
  onSetEditMode: () => void;
}

const ViewFeedbackHeader: FC<Props & IFeedbackProps> = ({
  feedback,
  isNetwork,
  label,
  talentState: { matchedJobs },
  onSetEditMode,
}) => {
  return (
    <Styled.ProfileHead>
      <Styled.ProfileBlockTitle>
        {label}
        <Styled.HelpIcon>
          <QuestionIcon width={13.33} height={13.33} />
        </Styled.HelpIcon>
      </Styled.ProfileBlockTitle>
      <Styled.IconBlock>
        <SentimentBadge sentiment={feedback?.sentiment} />
        <Styled.OptButton onClick={onSetEditMode}>
          <ShouldRender if={feedback?.description || feedback?.score}>
            <PensilIcon />
          </ShouldRender>
          <ShouldRender if={!feedback?.description && !feedback?.score}>
            <PlusIcon />
          </ShouldRender>
        </Styled.OptButton>
        <ShouldRender
          if={
            isNetwork &&
            feedback?.type === feedbackType.FinalInterviewFeedback &&
            matchedJobs.length
          }
        >
          <ViewFeedbackIcon matchedJobs={matchedJobs} />
        </ShouldRender>
      </Styled.IconBlock>
    </Styled.ProfileHead>
  );
};

export default withUpdateFeedback<Props>(ViewFeedbackHeader);
