import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateClientMutation,
  CreateClientMutationVariables,
  ListClientsQuery,
  ListClientsQueryVariables,
  SendClientNewJobMatchEmailMutation,
  SendClientNewJobMatchEmailMutationVariables,
} from "API";
import { createClient, sendClientNewJobMatchEmail } from "graphql/mutations";
import { listClients } from "graphql/queries";
import {
  ReturnPropsClient,
  ReturnPropsClientList,
  ReturnPropsClientNewJobMatchEmail,
} from "../types";
import { getQuery } from "../utils";

export const useClient = (): ReturnPropsClient => {
  const [createInfo, { data: createInfoData, loading: isLoading, error }] = useMutation<
    CreateClientMutation,
    CreateClientMutationVariables
  >(getQuery(createClient));

  return {
    createInfo,
    data: createInfoData?.createClient,
    isLoading,
    error,
  };
};

export const useClientList = (): ReturnPropsClientList => {
  const [getClientList, { data, loading }] = useLazyQuery<
    ListClientsQuery,
    ListClientsQueryVariables
  >(getQuery(listClients));
  const clientList = data?.listClients?.items || null;
  const nextToken = data?.listClients?.nextToken || null;

  return { getClientList, data: clientList, loading, nextToken };
};

export const useClientNewJobMatchEmail = (
  completionHandler?: () => void,
): ReturnPropsClientNewJobMatchEmail => {
  const [sendClientJobMatchEmail, { loading, data }] = useMutation<
    SendClientNewJobMatchEmailMutation,
    SendClientNewJobMatchEmailMutationVariables
  >(getQuery(sendClientNewJobMatchEmail), {
    onCompleted: () => {
      if (completionHandler) completionHandler();
    },
  });

  return { sendClientJobMatchEmail, loading, data };
};
