import { Talent } from "@remotebase/amplify-constants/API";
import { FC, Fragment, useMemo } from "react";
import { domainTestStartTime, getFormatedDate, inString, isPassDomain } from "utils";
import * as Styled from "../styles";

interface TableRowProps {
  talent: Talent;
  index: number;
  showReferredBy?: boolean;
  onNameClick?: (engrId?: string) => void;
  onReferredByClick?: (engrId?: string) => void;
  main?: boolean;
}

export const TableRowRecruiter: FC<TableRowProps> = ({
  talent,
  index,
  onNameClick,
  onReferredByClick,
  showReferredBy = true,
}) => {
  const talentNameColumn = useMemo(
    () =>
      onNameClick ? (
        <Styled.StrongText onClick={(): void => onNameClick(talent.id)}>
          {talent.fullName}
        </Styled.StrongText>
      ) : (
        <td>{talent.fullName}</td>
      ),
    [onNameClick],
  );

  const referredByColumn = useMemo(
    () =>
      showReferredBy ? (
        <td>
          <Styled.ReferredByBtn onClick={(): void => onReferredByClick?.(talent.id)}>
            Referred By
          </Styled.ReferredByBtn>
        </td>
      ) : (
        <Fragment />
      ),
    [showReferredBy],
  );

  if (talent) {
    const { createdAt, id, phone, email, profile } = talent;
    const talentMeta = talent?.profile?.statusMeta && JSON.parse(talent?.profile?.statusMeta);

    return (
      <tr key={id}>
        {talentNameColumn}
        <td>{phone}</td>
        <td>{email}</td>
        <td>{getFormatedDate(createdAt)}</td>
        <td>{inString(isPassDomain(profile?.takenDomainTests || []))}</td>
        <td>{domainTestStartTime(profile?.takenDomainTests?.[0] || null)}</td>
        <td>{inString(profile?.isPassPS)}</td>
        <td>{getFormatedDate(talentMeta?.dateOfPSStatus)}</td>
        <td>{inString(profile?.isPassFinalTech)}</td>
        <td>{inString(profile?.isPassHR)}</td>
        <td>{getFormatedDate(talentMeta?.dateOfHiring)}</td>
        {referredByColumn}
      </tr>
    );
  }
  return <Fragment key={index} />;
};

export default TableRowRecruiter;
