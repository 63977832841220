import { FC, useCallback, useState } from "react";
import { UserContext } from "./user.context";
import { AdminUserData } from "../../hooks/types";

const UserProvider: FC = ({ children }) => {
  const [users, setUsers] = useState<AdminUserData[]>([]);

  const addUser = useCallback((newUser: AdminUserData) => {
    setUsers((prev) => [...prev, newUser]);
  }, []);

  return (
    <UserContext.Provider value={{ users, setUsers, addUser }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
