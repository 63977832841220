import { FC, useEffect } from "react";
import { ShouldRender } from "@remotebase/components";
import { IFetchTalentFeedbackProps } from "state/types/talentFeedback.interface";
import classNames from "classnames";
import { withFetchTalent } from "state/talentFeedback";
import * as Styled from "./styles";
import SubmitFeedbackButton from "./SubmitFeedbackButton";

export const TalentProfileFilter: FC<IFetchTalentFeedbackProps> = (props): JSX.Element => {
  const {
    talentState: { isEditable, fetchFeedbackLoading, saveFeedbackLoading },
    toggleIsEditable,
  } = props;

  useEffect(() => {
    if (!fetchFeedbackLoading && !saveFeedbackLoading && isEditable) {
      toggleIsEditable();
    }
  }, [fetchFeedbackLoading, saveFeedbackLoading]);

  const onCancel = (): void => {
    toggleIsEditable();
  };

  return (
    <Styled.FilterContainer>
      <ShouldRender if={isEditable}>
        <Styled.CancelButton
          onClick={onCancel}
          disabled={saveFeedbackLoading}
          className={classNames({ disabled: saveFeedbackLoading })}
        >
          Cancel
        </Styled.CancelButton>
      </ShouldRender>
      <SubmitFeedbackButton />
    </Styled.FilterContainer>
  );
};

export default withFetchTalent(TalentProfileFilter);
