import React from "react";
import * as Styled from "../styles";
import { CertificationSectionShimmer } from "./certificationSectionShimmer";
import { DownloadResumeSectionShimmer } from "./downloadResumeSectionShimmer";
import { ProfileDetailsSectionShimmer } from "./profileDetailsSectionShimmer";
import { TalentSummarySectionShimmer } from "./summarySectionShimmer";
import { TalentEducationSectionShimmer } from "./educationSectionShimmer";
import { TalentEmploymentSectionShimmer } from "./employmentSectionShimmer";
import MatchTalentSectionShimmer from "./matchtalentSectionShimmer";

export const TalentVersionBody: React.FC = () => {
  return (
    <>
      <ProfileDetailsSectionShimmer />
      <TalentSummarySectionShimmer />
      <Styled.ProfileQualificationWrapperShimmer>
        <Styled.ProfileAcademicWrapperShimmer>
          <CertificationSectionShimmer />
          <TalentEducationSectionShimmer />
        </Styled.ProfileAcademicWrapperShimmer>
        <TalentEmploymentSectionShimmer />
      </Styled.ProfileQualificationWrapperShimmer>
      <MatchTalentSectionShimmer />
      <DownloadResumeSectionShimmer />
    </>
  );
};

export default TalentVersionBody;
