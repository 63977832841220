import { useLazyQuery } from "@apollo/client";
import { ListReferralsQuery, ListReferralsQueryVariables } from "@remotebase/amplify-constants/API";
import { listReferrals } from "@remotebase/amplify-constants/graphql/queries";
import { IReturnPropsReferralReferredBy } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

// eslint-disable-next-line import/prefer-default-export
export const useReferred = (): IReturnPropsReferralReferredBy => {
  const [getReferralReferredBy, { data, loading }] = useLazyQuery<
    ListReferralsQuery,
    ListReferralsQueryVariables
  >(getQuery(listReferrals));
  const referralsList = data?.listReferrals?.items || null;
  const nextToken = data?.listReferrals?.nextToken || null;

  return { getReferralReferredBy, data: referralsList, loading, nextToken };
};
