import { FC } from "react";
import { useProfileVersionContext } from "state/profileVersion";
import * as Styled from "./styles";

interface Props {
  loading: boolean;
}

export const DiscardChangesBtn: FC<Props> = ({ loading }) => {
  const { hasUnsavedData, discardChanges } = useProfileVersionContext();

  return (
    <>
      {hasUnsavedData && (
        <Styled.DiscardBtnWrapper>
          <Styled.DiscardBtn onClick={discardChanges} disabled={loading}>
            Discard changes
          </Styled.DiscardBtn>
        </Styled.DiscardBtnWrapper>
      )}
    </>
  );
};

export default DiscardChangesBtn;
