import styled from "styled-components";
import { imageUrl } from "utils";

export const EditTalentBodyWrapper = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const EditTalentMainWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  background: #faf6ff;
  padding: 28px 0px;
  z-index: 2;

  &::before {
    position: absolute;
    content: "";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 20px 20px 5px 5px;
    background: url(${imageUrl.talentVersionImg}) no-repeat center;
    background-size: cover;
    padding: 0px;
    margin: 0px;
    border: none;
    z-index: 1;
    height: 200px;
    overflow: hidden;

    @media only screen and (min-width: 576px) {
      padding: 32px 24px;
    }

    @media only screen and (min-width: 768px) {
      height: 266px;
    }
  }

  @media only screen and (min-width: 992px) {
    padding: 32px 35px;
  }
`;

export const ProfileQualificationWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1.15fr;
  }
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1.25fr;
  }
  @media only screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1.4fr;
  }
`;

export const ProfileAcademicWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`;
