import { ShouldRender } from "@remotebase/components";
import { CheckIcon, PensilIcon, XmarkIcon } from "assets/icons";
import { FC, Fragment } from "react";
import * as Styled from "./styles";

interface Props {
  isEdit: boolean;
  toggleIsEdit: () => void;
  onUpdateFeedback: () => void;
  cancelFeedback: () => void;
}

export const FeedbackIcons: FC<Props> = ({
  isEdit,
  toggleIsEdit,
  onUpdateFeedback,
  cancelFeedback,
}) => {
  const onCancelFeedback = (): void => {
    toggleIsEdit();
    cancelFeedback();
  };
  return (
    <Styled.HeadOpt>
      <ShouldRender if={isEdit}>
        <Fragment>
          <Styled.OptButton onClick={onUpdateFeedback}>
            <CheckIcon />
          </Styled.OptButton>
          <Styled.OptButton onClick={onCancelFeedback}>
            <XmarkIcon />
          </Styled.OptButton>
        </Fragment>
      </ShouldRender>
      <ShouldRender if={!isEdit}>
        <Styled.OptButton onClick={toggleIsEdit}>
          <PensilIcon />
        </Styled.OptButton>
      </ShouldRender>
    </Styled.HeadOpt>
  );
};

export default FeedbackIcons;
