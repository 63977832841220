import { SidebarProps } from "utils";
import { AdminDashboard, AnalyticsTable } from "components";
import "react-datepicker/dist/react-datepicker.css";
import { ComponentType } from "utils/constants/dashboard";
import * as Styled from "./styles";

const Dashboard: React.FC<SidebarProps> = () => {
  return (
    <Styled.DashboardWrapper>
      <Styled.DashboardHead>
        <Styled.MainHeading>
          <Styled.SubHeading>Dashboard</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.DashboardHead>
      <Styled.DashboardInner>
        <AdminDashboard name={ComponentType.Graph} />
      </Styled.DashboardInner>
      <Styled.DashboardInner>
        <AnalyticsTable name={ComponentType.Table} />
      </Styled.DashboardInner>
    </Styled.DashboardWrapper>
  );
};

export default Dashboard;
