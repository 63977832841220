import { ShouldRender } from "@remotebase/components";
import { FC } from "react";
import { PersonInfo } from "../API";
import * as S from "../styles";

interface Props {
  currentUser: PersonInfo;
  closeChat?: () => void;
}
const ChatHeader: FC<Props> = ({ currentUser, closeChat }) => {
  return (
    <S.ChatHeaderWrapper>
      <S.ChatHeaderDetail>
        <S.ChatHeaderAvatar className="active">
          <img src="/images/default-image.png" />
        </S.ChatHeaderAvatar>
        <S.ChatHeaderContent>
          <S.ChatHeaderName>{currentUser?.fullName}</S.ChatHeaderName>
          <S.ChatHeaderDesignation>{currentUser?.role}</S.ChatHeaderDesignation>
        </S.ChatHeaderContent>
      </S.ChatHeaderDetail>
      {/* <S.ChatHeaderToggleIcon>
        <i className="icon-toggle" />
      </S.ChatHeaderToggleIcon> */}
      <ShouldRender if={closeChat}>
        <S.ChatHeaderCrossIcon onClick={(): void => closeChat?.()}>
          <i className="icon-cross" />
        </S.ChatHeaderCrossIcon>
      </ShouldRender>
    </S.ChatHeaderWrapper>
  );
};

export default ChatHeader;
