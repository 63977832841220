import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ProgressBarCanvas = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileScores = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 28px 32px;

  @media only screen and (max-width: 575px) {
    padding: 26px 20px;
  }
`;

export const ScoresHeading = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 12px;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 10px;
  }
`;

export const ProfileExperience = styled.p`
  font-size: 16px;
  color: #7b8292;
  font-weight: 400;
  margin-bottom: 0;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 15px;
  font-size: 0.8rem;
`;

export const AddTestButton = styled(Button)`
  position: relative;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 6px;
  min-height: 42px;
  width: 92px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  margin-left: 10px;
  margin-bottom: 22px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;
