import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { Button } from "react-bootstrap";

type StyledProps = FlattenSimpleInterpolation | string;

export const TalentVersionFooter = styled.div`
  position: fixed;
  width: 100%;
  height: 84px;
  left: 256px;
  bottom: 0;
  padding: 18px 35px;

  background: #ffffff;
  z-index: 9;

  @media (max-width: 1023px) {
    left: 180px;
  }

  @media (max-width: 767px) {
    left: 0px;
  }
`;

export const SaveAndViewVersionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 93px;

  @media (max-width: 1023px) {
    margin-left: 0px;
  }
`;

export const ViewVersionsBtnWraper = styled.div`
  width: 140px;
  margin-right: 10px;

  @media (max-width: 767px) {
    max-width: 120px;
  }
`;

export const SaveVersionsBtnWrapper = styled.div`
  position: relative;
  width: 150px;
  margin-right: 10px;

  @media (max-width: 767px) {
    max-width: 125px;
  }
`;

export const ViewVersionsBtn = styled(Button)`
  font-size: 14px;
  align-items: center;
  padding: 14px 25px;
  gap: 5px;

  color: #000000;
  display: flex;

  width: 100%;
  height: 47px;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 8px;

  @media (max-width: 767px) {
    font-size: 12px;
    padding: 10px 17px;
    height: 38px;
  }

  &:disabled {
    background: #ffffff;
    color: #000000 !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
`;

export const SaveVersionsBtn = styled(Button)`
  font-size: 14px;

  align-items: center;
  padding: 14px 20px;
  gap: 5px;

  color: #ffffff;
  display: flex;

  width: 100%;
  height: 47px;
  background: #3617ba;
  border: 0.5px solid #3617ba;
  border-radius: 8px;

  @media (max-width: 767px) {
    font-size: 12px;
    padding: 10px 17px;
    height: 38px;
  }

  &:disabled {
    background: #3617ba;
    color: #ffffff !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
`;

export const SaveOptionsDiv = styled.div`
  position: absolute;
  width: 140px;
  left: 0px;
  top: -110px;

  padding: 14px 20px;

  background: #ffffff;
  /* Shadow 2 */

  box-shadow: 0px -19.15px 61.6981px rgba(52, 64, 84, 0.14);
  border-radius: 4px;
`;

export const SaveOptionsText = styled.p<{ $disabled?: boolean }>`
  width: 80px;

  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #312a50;
  cursor: pointer;

  ${(props): StyledProps =>
    props.$disabled
      ? css`
          user-select: none;
          cursor: default;
          color: #8d8d8d;
        `
      : css``}
  }
`;

export const DiscardBtnWrapper = styled.div`
  position: relative;
  width: 160px;
  margin-right: 10px;

  @media (max-width: 767px) {
    max-width: 125px;
  }
`;

export const DiscardBtn = styled(Button)`
  font-size: 14px;
  align-items: center;
  padding: 14px 25px;
  gap: 5px;

  color: #ffffff;
  display: flex;

  width: 100%;
  height: 47px;
  background: red;
  border: 0.5px solid #000000;
  border-radius: 8px;

  @media (max-width: 767px) {
    font-size: 12px;
    padding: 10px 17px;
    height: 38px;
  }

  &:disabled {
    background: #ffffff;
    color: #000000 !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
`;
