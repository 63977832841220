import React, { Fragment, useState } from "react";
import { Feedback, feedbackType } from "@remotebase/amplify-constants/API";
import { ViewFeedbackHeader, EditTalentFeedback } from "components";
import { ShouldRender, ViewFeedback, ViewFeedbackRating } from "@remotebase/components";
import * as Styled from "./styles";

interface Props {
  feedback?: Feedback | null;
  label: string;
  type: feedbackType;
  isNetwork: boolean;
}

export const TalentProfileFeedback: React.FC<Props> = ({ feedback, label, type, isNetwork }) => {
  const [isEdit, setIsEdit] = useState(false);

  const setIsEditMode = (): void => {
    setIsEdit(!isEdit);
  };
  return (
    <Styled.ProfileBlock>
      <ShouldRender if={!isEdit}>
        <Fragment>
          <ViewFeedbackHeader
            feedback={feedback}
            label={label}
            isNetwork={isNetwork}
            onSetEditMode={setIsEditMode}
          />
          <ShouldRender if={feedback?.description}>
            <ViewFeedback feedback={feedback} />
          </ShouldRender>
          <ShouldRender if={feedback?.score !== undefined && feedback?.score !== null}>
            <ViewFeedbackRating feedback={feedback} />
          </ShouldRender>
        </Fragment>
      </ShouldRender>
      <ShouldRender if={isEdit}>
        <Fragment>
          <EditTalentFeedback
            label={label}
            data={feedback}
            type={type}
            isEdit={isEdit}
            onUpdateEditable={setIsEditMode}
          />
        </Fragment>
      </ShouldRender>
    </Styled.ProfileBlock>
  );
};

export default TalentProfileFeedback;
