import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { PayloadType } from "@remotebase/constants";
import {
  AuthForm,
  passwordPlaceholder,
  passwordLabel,
  SetPasswordSchema,
  setPasswordText,
} from "../../utils";
import { AuthenticationInput, AuthSubmitBtn } from "../..";
import * as Styled from "./styles";

interface Props {
  isLoading: boolean;
  setPassword: (payload: PayloadType) => void;
}

export const SetPasswordForm: FC<Props> = ({ isLoading, setPassword }) => {
  const handler = useForm({ resolver: yupResolver(SetPasswordSchema()), mode: "onChange" });

  return (
    <form onSubmit={handler.handleSubmit(setPassword)}>
      <Styled.FormGroups>
        <AuthenticationInput
          type="password"
          placeholder={passwordPlaceholder}
          label={passwordLabel}
          title={AuthForm.password}
          handler={handler}
        />
      </Styled.FormGroups>
      <AuthSubmitBtn isLoading={isLoading} buttonText={setPasswordText} />
    </form>
  );
};

export default SetPasswordForm;
