import { SearchFieldProps } from "utils";
import * as Styled from "../styles";

export const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { searchText, setSearchText, handleSubmit } = props;

  return (
    <Styled.SearchGroupMain>
      <Styled.SearchGroupField
        type="text"
        value={searchText}
        placeholder="Enter Email..."
        onChange={(e): void => setSearchText(e.target.value.trim())}
        onSubmit={handleSubmit}
      />
      <Styled.SearchButton onClick={handleSubmit} disabled={!searchText || !searchText.length}>
        Submit
      </Styled.SearchButton>
    </Styled.SearchGroupMain>
  );
};

export default SearchField;
