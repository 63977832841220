import styled from "styled-components";

export const NewTalentForm = styled.form`
  padding: 1.75rem 0;
`;
export const FormGroup = styled.div``;

export const InputCover = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-bottom: 1.25rem;

  @media only screen and (max-width: 1058px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const SelectInput = styled.div`
  width: 23rem;

  @media only screen and (max-width: 1058px) {
    width: 100%;
  }
`;

export const SubmitBtnCover = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  @media only screen and (max-width: 1360px) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0.5rem;
  }
`;
