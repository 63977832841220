import { FC } from "react";
import * as Styled from "../styles";

export const MatchTalentSectionShimmer: FC = () => {
  return (
    <Styled.MatchTalentWrapperShimmer>
      <Styled.MatchTalentTopShimmer>
        <Styled.MatchTalentTitleShimmer />
        <Styled.MatchTalentTopIconWrapperShimmer>
          <Styled.MatchTalentTopIconShimmer />
          <Styled.MatchTalentTopIconShimmer />
        </Styled.MatchTalentTopIconWrapperShimmer>
      </Styled.MatchTalentTopShimmer>
      <Styled.MatchTalentItemShimmer>
        <Styled.MatchTalentImgShimmer />
        <Styled.MatchTalentSbHdShimmer />
        <Styled.MatchTalentParaShimmer />
        <Styled.MatchTalentBtnShimmer />
      </Styled.MatchTalentItemShimmer>
    </Styled.MatchTalentWrapperShimmer>
  );
};

export default MatchTalentSectionShimmer;
