import { useCallback, useEffect, useMemo, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";
import { useErrorContext } from "state/error";
import { UserRoles } from "@remotebase/amplify-constants/API";
import { AdminUserData, FetchUserInGroupResponse, UseFetchAdminUsersReturnType } from "../types";

const useFetchAdminUsers = (): UseFetchAdminUsersReturnType => {
  const { showError } = useErrorContext();
  const [calledApi, setCalledApi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<AdminUserData[]>([]);
  const [responseData, setResponseData] = useState<FetchUserInGroupResponse | null>();

  const fetchRoles = useMemo(
    () => [
      UserRoles.SUPERADMIN,
      UserRoles.ADMIN,
      UserRoles.SALES,
      UserRoles.CUSTOMERSUPPORT,
      UserRoles.CUSTOMERSUCCESS,
      UserRoles.FINANCE,
      UserRoles.TECHRECRUITER,
    ],
    [],
  );

  const fetchUsersFromGroup = useCallback(async (currentRoleIndex: number, NextToken?: string) => {
    try {
      if (!fetchRoles[currentRoleIndex]) {
        setLoading(false);
        return;
      }

      const GroupName = fetchRoles[currentRoleIndex];
      const credentials = await Auth.Credentials.get();
      const { userPoolId: UserPoolId, region } = await Auth.Credentials.Auth._config;

      const cognitoIDProvider = new CognitoIdentityProvider({
        apiVersion: "latest",
        region,
        credentials,
      });

      const fetchedUsers: AdminUserData[] = [];

      const { Users, NextToken: newNextToken } = await cognitoIDProvider.listUsersInGroup({
        UserPoolId,
        GroupName,
        Limit: 60,
        NextToken,
      });

      for (const user of Users || []) {
        if (user) {
          const {
            email,
            given_name: firstName,
            family_name: lastName,
          } = user.Attributes?.reduce<Record<string, string>>((prev, curr) => {
            return { ...prev, [curr.Name ?? ""]: curr.Value ?? "" };
          }, {}) ?? {};

          if (email)
            fetchedUsers.push({ email, firstName, lastName, password: "", role: GroupName });
        }
      }

      setResponseData({ users: fetchedUsers, nextToken: newNextToken, currentRoleIndex });
    } catch (e) {
      console.error("e: ", e);
      showError?.({ message: "Error fetching users", title: "Users Error" });
    }
  }, []);

  const callApi = (): void => {
    if (loading) return;
    setCalledApi(true);
  };

  useEffect(() => {
    if (!calledApi) return;

    setCalledApi(false);
    setLoading(true);
    setUsers([]);
    setResponseData(null);
    fetchUsersFromGroup(0).then();
  }, [calledApi, fetchUsersFromGroup]);

  useEffect(() => {
    if (!responseData) return;

    const { users: fetchedUsers, nextToken, currentRoleIndex } = responseData;
    setResponseData(null);

    if (fetchedUsers.length) setUsers((prev) => [...prev, ...fetchedUsers]);
    if (nextToken) fetchUsersFromGroup(currentRoleIndex, nextToken).then();
    else fetchUsersFromGroup(currentRoleIndex + 1).then();
  }, [responseData, fetchUsersFromGroup]);

  return { callApi, loading, users };
};

export default useFetchAdminUsers;
