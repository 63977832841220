import { useLazyQuery } from "@apollo/client";
import {
  CompaniesByAssociationQuery,
  CompaniesByAssociationQueryVariables,
} from "@remotebase/amplify-constants/API";
import { companiesByAssociation } from "graphql/queries";
import { ReturnPropsCompanies } from "hooks/types";
import { getQuery } from "hooks/utils";

export const useListCompanies = (): ReturnPropsCompanies => {
  const [getCompanies, { data, loading }] = useLazyQuery<
    CompaniesByAssociationQuery,
    CompaniesByAssociationQueryVariables
  >(getQuery(companiesByAssociation));

  const companiesList = data?.companiesByAssociation?.items || null;
  const nextToken = data?.companiesByAssociation?.nextToken;

  return { getCompanies, companiesList, loading, nextToken };
};

export default useListCompanies;
