/* eslint-disable prefer-const */
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { getQuery } from "hooks/utils";
import {
  ListReferralsQuery,
  ListReferralsQueryVariables,
  Referral,
  UserRoles,
} from "@remotebase/amplify-constants/API";
import { codeToCountry } from "@remotebase/constants";
import listReferralsOptimized from "hooks/utils/quries";
import { marketingEmailsUrl } from "utils";
import { LeaderboardRecruiter, MarketingRecruiter } from "../utils/types/recruiters";

interface RecruitersLeaderboardReturn {
  data: LeaderboardRecruiter[];
  marketingData: MarketingRecruiter[];
  loading: boolean;
  devCount: number;
}

export const useRecruitersLeaderboard = (): RecruitersLeaderboardReturn => {
  const [nextToken, setNextToken] = useState<string>();
  const [done, setDone] = useState<boolean>();
  const [recruitersList, setRecruitersList] = useState<LeaderboardRecruiter[]>([]);
  const [recruitersHash, setRecruitersHash] = useState<Record<string, LeaderboardRecruiter>>({});
  const [devCount, setDevCount] = useState(0);
  const [marketingEmails, setMarketingEmails] = useState<string[]>();
  const [marketingData, setMarketingData] = useState<MarketingRecruiter[]>([]);

  const getMarketingList = async (): Promise<void> => {
    try {
      const response = await fetch(marketingEmailsUrl);
      const emailsList = await response.json();
      setMarketingEmails([...emailsList]);
    } catch {
      setMarketingEmails([]);
    }
  };

  useEffect(() => {
    getMarketingList();
  }, []);

  const prepareLeaderboardItem = (data: Referral[], nextPageToken): void => {
    const leaderboardReferrals = data?.filter(
      (item) =>
        item?.recruiter?.id &&
        item.referred?.id &&
        !marketingEmails?.includes(item.recruiter.email),
    );
    const marketingReferrals = data.filter(
      (item) =>
        item.referred?.id &&
        marketingEmails?.includes(
          (item.recruiter?.email as string) || (item.referrer?.email as string),
        ),
    );
    marketingReferrals.forEach((referralObj) => {
      setMarketingData((current) => {
        const marketer = referralObj.recruiter?.id ? referralObj.recruiter : referralObj.referrer;
        const index = current?.findIndex((item) => item.email === marketer?.email);
        const clearedDomainTest = referralObj.referred?.profile?.takenDomainTests?.some(
          (test) => test?.isPass,
        )
          ? 1
          : 0;
        const clearedProblemSovlingTest = referralObj.referred?.profile?.isPassPS ? 1 : 0;
        const clreadFinalTech = referralObj.referred?.profile?.isPassFinalTech ? 1 : 0;
        const isHired = referralObj.referred?.profile?.isHired ? 1 : 0;
        const clreadHR = referralObj.referred?.profile?.isPassHR ? 1 : 0;
        const country = codeToCountry[referralObj.referred?.country || "PK"];
        if (index === -1) {
          const temp: MarketingRecruiter = {
            email: marketer?.email as string,
            accountType: (marketer?.role as UserRoles) || UserRoles.ENGINEER,
            devsInvited: 1,
            devsClearedDomainTest: clearedDomainTest ? 1 : 0,
            devsClearedProblemSovlingTest: clearedProblemSovlingTest ? 1 : 0,
            devsClearedFinalTech: clreadFinalTech ? 1 : 0,
            devsHired: isHired ? 1 : 0,
            devsClearedHR: clreadHR ? 1 : 0,
            countryWise: JSON.stringify({ [country as string]: 1 }),
          };

          return [...current, { ...temp }];
        }
        const temp: MarketingRecruiter = { ...current[index] };
        temp.devsInvited += 1;
        temp.devsClearedDomainTest += clearedDomainTest ? 1 : 0;
        temp.devsClearedProblemSovlingTest += clearedProblemSovlingTest ? 1 : 0;
        temp.devsClearedFinalTech += clreadFinalTech ? 1 : 0;
        temp.devsHired += isHired ? 1 : 0;
        const countriesDist = JSON.parse(temp.countryWise);
        countriesDist[country as string] = (countriesDist[country as string] ?? 0) + 1;
        temp.countryWise = JSON.stringify(countriesDist);
        return [
          ...current.slice(0, index),
          { ...temp },
          ...current.slice(index + 1, current.length),
        ];
      });
    });
    leaderboardReferrals?.forEach((referralObj) => {
      setDevCount((current) => current + 1);
      setRecruitersHash((currentHash) => {
        const recruiterId = referralObj?.recruiter?.id as string;
        let {
          name = referralObj?.recruiter?.fullName as string,
          email = referralObj?.recruiter?.email as string,
          country = codeToCountry[referralObj?.recruiter?.country as string] ||
            (codeToCountry.PK as string),
          devsInvited = 0,
          devsClearedProblemSovlingTest = 0,
          devsClearedFinalTech = 0,
          devsHired = 0,
          devsNetworked = 0,
          devsClearedDomainTest = 0,
        } = currentHash[recruiterId] || {};
        devsInvited += 1;
        if (referralObj?.referred?.profile?.isHired) devsHired += 1;
        else if (referralObj?.referred?.profile?.isNetwork) devsNetworked += 1;
        else if (referralObj?.referred?.profile?.isPassFinalTech) devsClearedFinalTech += 1;
        else if (referralObj?.referred?.profile?.isPassPS) devsClearedProblemSovlingTest += 1;
        else if (referralObj?.referred?.profile?.takenDomainTests?.some((test) => test?.isPass))
          devsClearedDomainTest += 1;
        const temp = {
          name,
          email,
          country,
          devsInvited,
          devsClearedProblemSovlingTest,
          devsNetworked,
          devsClearedFinalTech,
          devsHired,
          devsClearedDomainTest,
          // devs,
        };
        return { ...currentHash, [recruiterId]: temp };
      });
    });
    if (nextPageToken) setNextToken(nextPageToken);
    else setDone(true);
  };
  useQuery<ListReferralsQuery, ListReferralsQueryVariables>(getQuery(listReferralsOptimized), {
    onCompleted: ({ listReferrals: data }) => {
      prepareLeaderboardItem(data?.items as Referral[], data?.nextToken);
    },
    variables: {
      nextToken,
      limit: 300,
    },
    skip: done || !marketingEmailsUrl,
  });

  useEffect(() => {
    if (done)
      setRecruitersList(() => [...Object.keys(recruitersHash).map((key) => recruitersHash[key])]);
    setMarketingData((current) => [
      ...current.map((item) => ({
        ...item,
        countryWise: item.countryWise.replace(/[^a-zA-Z0-9:,]/g, "").replaceAll(",", " "),
      })),
    ]);
  }, [done]);

  return {
    marketingData,
    data: recruitersList,
    devCount,
    loading: !recruitersList.length,
  };
};
export default useRecruitersLeaderboard;
