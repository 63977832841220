import { TalentList } from "components";
import React from "react";
import { TalentProvider } from "state/talent";
import * as Styled from "./styles";

const Talents: React.FC = () => {
  return (
    <Styled.TalentsWrapper>
      <Styled.TalentHead>
        <Styled.MainHeading>
          <Styled.SubHeading>Talents</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.TalentHead>
      <TalentProvider>
        <TalentList shouldShowFiltersList shouldShowReferredByColumn />
      </TalentProvider>
    </Styled.TalentsWrapper>
  );
};

export default Talents;
