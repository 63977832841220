import { useCallback, useState } from "react";
import { useErrorContext } from "state/error";
import { Auth } from "@aws-amplify/auth";
import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";
import { accountCreationError, accountCreationFailed } from "utils";
import { AdminUserData, CreateUserReturnType } from "../types";

const useCreateAdminUser = (): CreateUserReturnType => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<AdminUserData | null>();
  const { showError } = useErrorContext();

  const createUser = useCallback(async (payload: AdminUserData) => {
    setIsLoading(true);
    setUser(null);

    try {
      const { password, email, firstName, lastName, role } = payload;

      const credentials = await Auth.Credentials.get();
      const { userPoolId: UserPoolId, region } = await Auth.Credentials.Auth._config;

      const cognitoIDProvider = new CognitoIdentityProvider({
        apiVersion: "latest",
        region,
        credentials,
      });

      // Create new user with temporary password
      const response = await cognitoIDProvider.adminCreateUser({
        Username: email,
        UserPoolId,
        MessageAction: "SUPPRESS",
        DesiredDeliveryMediums: ["EMAIL"],
        UserAttributes: [
          { Name: "email", Value: email },
          { Name: "given_name", Value: firstName },
          { Name: "family_name", Value: lastName },
          { Name: "email_verified", Value: "true" },
          { Name: "custom:role", Value: role },
        ],
      });

      if (!response.User?.Username) {
        showError?.({ message: accountCreationFailed, title: accountCreationError });
        setIsLoading(false);
      } else {
        // Set permanent password for new user
        await cognitoIDProvider.adminSetUserPassword({
          Password: password,
          Permanent: false,
          Username: email,
          UserPoolId,
        });

        // Add new user to group
        await cognitoIDProvider.adminAddUserToGroup({
          GroupName: role,
          Username: response.User.Username,
          UserPoolId,
        });

        setUser({ email, firstName, lastName, password, role });
      }
    } catch (e) {
      console.error(`${accountCreationFailed}: `, e);
      showError?.({ message: e.message, title: accountCreationError });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { createUser, isLoading, user };
};

export default useCreateAdminUser;
