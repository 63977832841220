import { FC } from "react";
import * as Styled from "../styles";

export const TalentEducationSectionShimmer: FC = () => {
  return (
    <Styled.EducationDetailWrapperShimmer>
      <Styled.EducationDetailTopShimmer>
        <Styled.EducationDetailTitleShimmer />
        <Styled.EducationDetailTopIconShimmer />
      </Styled.EducationDetailTopShimmer>
      <Styled.EducationDetailListShimmer>
        <Styled.EducationDetailItemShimmer>
          <Styled.EducationInnerShimmer>
            <Styled.EducationDetailSbHdShimmer />
          </Styled.EducationInnerShimmer>
          <Styled.EducationDetailParaShimmer>
            <Styled.EducationDetailParaTextShimmer />
            <Styled.EducationDetailParaTextShimmer />
            <Styled.EducationDetailParaTextShimmer />
          </Styled.EducationDetailParaShimmer>
        </Styled.EducationDetailItemShimmer>
      </Styled.EducationDetailListShimmer>
    </Styled.EducationDetailWrapperShimmer>
  );
};

export default TalentEducationSectionShimmer;
