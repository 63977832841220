import { ShouldRender } from "@remotebase/components";
import downloadAsCSV from "@remotebase/helpers/downloadAsCSV";
import { isEmpty } from "lodash";
import { FC, useMemo, useState, Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { withTalent } from "state/talent";
import {
  TalentByName,
  TalentByEmail,
  ReferredByRecruiter,
  PSPassed,
  HRPassed,
  TalentProps,
  TalentFilterType as type,
  FilterOptions,
  defaultDropdownValue,
  dropdownLabel,
  inString,
  isPassDomain,
  domainTestStartTime,
  getFormatedDate,
} from "utils";
import * as Styled from "../styles";

const TalentSearchDropdown: FC<TalentProps> = ({
  searchByFilter,
  talentState: { loading, allData },
}) => {
  const [filterType, setFilterType] = useState(type.ListTalent);
  const [searchText, setSearchText] = useState("");
  const [dropdownTitle, setDropdownTitle] = useState(defaultDropdownValue);
  const [dropdownSelect, setDropdownSelect] = useState<FilterOptions>({});

  const getEventType = (event?: string): FilterOptions => {
    switch (event) {
      case TalentByName:
        setFilterType(type.ListTalent);
        setDropdownTitle(TalentByName);
        return { searchByName: true };
      case TalentByEmail:
        setFilterType(type.TalentByEmail);
        setDropdownTitle(TalentByEmail);
        return { searchByEmail: true };
      case ReferredByRecruiter:
        setFilterType(type.SearchRecruiter);
        setDropdownTitle(ReferredByRecruiter);
        return { searchRecruiter: true };
      case PSPassed:
        setFilterType(type.SearchTalentProfile);
        setDropdownTitle(PSPassed);
        return { isPassPS: true };
      case HRPassed:
        setFilterType(type.SearchTalentProfile);
        setDropdownTitle(HRPassed);
        return { isPassHR: true };
      default:
        setDropdownTitle(defaultDropdownValue);
        return {};
    }
  };

  const handleSubmit = (): void => {
    searchByFilter(filterType, { ...dropdownSelect, searchText: searchText.trim() });
  };
  const handleSelect = (event?: string): void => {
    const eventType = getEventType(event);
    setDropdownSelect(eventType);
    setSearchText("");
  };
  const handleReset = (): void => {
    handleSelect();
    searchByFilter();
  };

  const onClickDownload = async (): Promise<void> => {
    const cleanedData = allData?.filter((d) => d);
    const dataCsv = cleanedData?.map((eng) => {
      const talentMeta = eng?.profile?.statusMeta && JSON.parse(eng?.profile?.statusMeta);
      const data = {
        ...eng,
        signupAt: eng?.createdAt,
        isPassDomain: inString(isPassDomain(eng?.profile?.takenDomainTests || [])),
        dtTime: domainTestStartTime(eng?.profile?.takenDomainTests?.[0] || null),
        isPassPS: inString(eng?.profile?.isPassPS),
        psTime: getFormatedDate(talentMeta?.dateOfPSStatus),
        isPassHR: inString(eng?.profile?.isPassPS),
        isPassFinalTech: inString(eng?.profile?.isPassFinalTech),
        isHired: inString(eng?.profile?.isHired),
        hiredAt: getFormatedDate(talentMeta?.dateOfHiring),
      };
      delete data.createdAt;
      delete data.profile;
      return data;
    });
    downloadAsCSV({ data: dataCsv as unknown as Record<string, string>[] });
  };

  const shouldShowSearch = useMemo(() => {
    const searchOption = dropdownSelect.isPassHR || dropdownSelect.isPassPS;
    if (searchOption) handleSubmit();
    return !(searchOption || isEmpty(dropdownSelect));
  }, [dropdownSelect]);

  return (
    <Fragment>
      <Styled.SearchGroupMain>
        <Styled.DropdownLabel>{dropdownLabel}</Styled.DropdownLabel>
        <Styled.DropdownBtn title={dropdownTitle} onSelect={handleSelect}>
          <Styled.Divider>
            <Dropdown.Item eventKey={HRPassed} active={dropdownSelect.isPassHR}>
              {HRPassed}
            </Dropdown.Item>
            <Dropdown.Item eventKey={PSPassed} active={dropdownSelect.isPassPS}>
              {PSPassed}
            </Dropdown.Item>
          </Styled.Divider>
          <Dropdown.Item eventKey={TalentByName} active={dropdownSelect.searchByName}>
            {TalentByName}
          </Dropdown.Item>
          <Dropdown.Item eventKey={TalentByEmail} active={dropdownSelect.searchByEmail}>
            {TalentByEmail}
          </Dropdown.Item>
          <Dropdown.Item eventKey={ReferredByRecruiter} active={dropdownSelect.searchRecruiter}>
            {ReferredByRecruiter}
          </Dropdown.Item>
        </Styled.DropdownBtn>
        <ShouldRender if={!loading && allData?.length}>
          <Styled.DownloadBtn onClick={onClickDownload} variant="outline-secondary" size="sm">
            Download CSV
          </Styled.DownloadBtn>
        </ShouldRender>
      </Styled.SearchGroupMain>
      <ShouldRender if={shouldShowSearch}>
        <Styled.SearchGroupMain>
          <Styled.SearchGroupField
            type="text"
            value={searchText}
            placeholder="Enter Name or Email..."
            onChange={(e): void => setSearchText(e.target.value)}
          />
          <Styled.SearchButton onClick={handleSubmit} disabled={!searchText.length}>
            Search
          </Styled.SearchButton>
        </Styled.SearchGroupMain>
      </ShouldRender>
      <ShouldRender if={!isEmpty(dropdownSelect)}>
        <Styled.ClearFilters onClick={handleReset}>Clear Filters</Styled.ClearFilters>
      </ShouldRender>
    </Fragment>
  );
};
export default withTalent(TalentSearchDropdown);
