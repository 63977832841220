import React, { FC, useEffect } from "react";
import { ShouldRender } from "@remotebase/components";
import { useFetchAdminUsers } from "hooks/user";
import { useUsersContext } from "state/user";
import { AuthRoutes, createUserLabel } from "utils";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles";
import { FormButton } from "../inputs";
import { TableShimmer } from "../shimmerPages";
import AdminUsersTable from "./components/adminUsersTable";

const AdminUsersList: FC = () => {
  const { push } = useHistory();
  const { callApi, users: fetchedUsers, loading } = useFetchAdminUsers();
  const { users, setUsers } = useUsersContext();

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => setUsers(fetchedUsers), [fetchedUsers]);

  return (
    <Styled.TableCover>
      <Styled.TableIntro>
        <Styled.ActionButtons>
          <FormButton
            label={createUserLabel}
            small={true}
            onClick={(): void => push(AuthRoutes.CreateAdminUser)}
          />
        </Styled.ActionButtons>
      </Styled.TableIntro>
      <ShouldRender if={!loading}>
        <AdminUsersTable data={users} />
      </ShouldRender>
      <ShouldRender if={loading}>
        <TableShimmer />
      </ShouldRender>
    </Styled.TableCover>
  );
};

export default AdminUsersList;
