import styled from "styled-components";
import { Table as BTable } from "react-bootstrap";

export const TableCover = styled.div`
  background: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px -7.5px rgba(0, 0, 0, 0.2);
`;

export const Table = styled(BTable)`
  background: #ffffff;
`;

export const TableFilters = styled.form`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

export const TableIntro = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 0.75rem;
`;
