import withApolloProvider from "hooks/apollo/withApollo";
import { useFetchRecruiter } from "hooks/recruiter";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IRecruiterContextType } from "state/types/recruiter.interface";
import { RecruiterProps, FilterOptions, pageLimit, getStateData } from "utils";
import { RecruiterContext, RecruiterFilterContext } from ".";

export function withRecruiter<T>(Component: React.FC<T & RecruiterProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { recruiterState, setRecruiterState } =
      useContext<IRecruiterContextType>(RecruiterContext);
    const { fetchData, data, loading, nextToken, downloadCSV } = useFetchRecruiter();
    const [apiCalled, setApiCalled] = useState(false);
    const { filters, setFilters } = useContext(RecruiterFilterContext);

    const callAPI = (): void => {
      setRecruiterState({ ...recruiterState, loading: true });
      fetchData(nextToken || null, filters);
    };

    const prevBtnClick = (): void => {
      const prevPtr = recruiterState?.currentPtr - pageLimit;
      if (prevPtr >= 0) setRecruiterState({ ...recruiterState, currentPtr: prevPtr });
    };

    const nextBtnClick = (): void => {
      const nextPtr = recruiterState.currentPtr + pageLimit;
      const engrSize = recruiterState.data?.length || 0;
      setRecruiterState({ ...recruiterState, loading: true, currentPtr: nextPtr });
      if (engrSize < nextPtr + pageLimit && nextToken) {
        setApiCalled(true);
      } else {
        setTimeout(() => {
          setRecruiterState({ ...recruiterState, loading: false, currentPtr: nextPtr });
        }, 700);
      }
    };

    const searchByFilter = (filterOptions: FilterOptions): void => {
      setFilters({ filterOptions });
      setRecruiterState({ ...recruiterState, data: [], currentPtr: 0 });
      setApiCalled(true);
    };

    const isNextDisable = (): boolean => {
      const { nextToken: nxtTkn, data: recrtData = [], currentPtr: ptr } = recruiterState;
      return !(nxtTkn || recrtData.length > ptr + pageLimit);
    };

    const isPrevDisable = (): boolean => recruiterState?.currentPtr - pageLimit < 0;

    const state = useMemo(
      () => ({
        prevBtnDisable: isPrevDisable(),
        nextBtnDisable: isNextDisable(),
      }),
      [recruiterState],
    );

    const updateRecruiterData = (recruitData, loadingArg, nextTokenArg): void => {
      if (recruiterState?.data?.length) {
        const updatedEngState = {
          ...recruiterState,
          data: [...recruiterState.data, ...recruitData],
          loading: loadingArg,
          nextToken: nextTokenArg,
        };
        setRecruiterState(updatedEngState);
      } else {
        setRecruiterState({
          ...recruiterState,
          data: recruitData,
          loading: loadingArg,
          nextToken: nextTokenArg,
        });
      }
    };

    useEffect(() => {
      if (apiCalled && !loading && data !== undefined) {
        const rctrSize = recruiterState?.data?.length || 0;
        if (!nextToken || rctrSize + data.length >= recruiterState.currentPtr + pageLimit) {
          setApiCalled(false);
          updateRecruiterData(data, loading, nextToken);
        } else {
          updateRecruiterData(data, true, nextToken);
          fetchData(nextToken, filters);
        }
      }
    }, [data]);

    useEffect(() => {
      if (!recruiterState.data || apiCalled) {
        setApiCalled(true);
        callAPI();
      }
    }, [apiCalled]);

    const initDownloadCSV = (): void => {
      downloadCSV(nextToken);
    };

    const recruiterProps: RecruiterProps = {
      recruiterState: { ...recruiterState, ...getStateData(recruiterState, pageLimit) },
      prevBtnClick,
      nextBtnClick,
      searchByFilter,
      state,
      downloadCSV: initDownloadCSV,
    };

    return <Component {...props} {...recruiterProps} />;
  });
}
export default withRecruiter;
