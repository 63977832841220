export const emailLabel = "Email";
export const passwordPlaceholder = "************";
export const emailPlaceholder = "name@company.com";
export const passwordLabel = "Password";
export const phonePlaceholder = "+123 XXX-XX-XXX";
export const phoneLabel = "Phone Number";
export const fullNamePlaceholder = "Jon Doe";
export const fullNameLabel = "Full Name";
export const companyPlaceholder = "Select Company";
export const companyLabel = "Company";
export const createdAtLabel = "Date Created";
export const clientCreatedMessage = "Client account was successfully created";
export const successMessage = "Success";
export const filterByLabel = "Filter By";
export const createClientLabel = "CREATE CLIENT";
export const createUserLabel = "NEW USER";
export const generateRecruitersReportSuccess =
  "Sending generate report request successfully sent. Report would be sent to entered emails when ready.";
