import React, { useEffect } from "react";
import { autoScrollOnTop } from "utils";
import { Chat } from "components";
// import { FullPageLoader } from "@remotebase/components/chat/fullPageLoader";
// import { ClientJobContext } from "state/profileSteps";
import { ChatProvider } from "state/chat";
import * as Styled from "./styles";

export const ChatPage: React.FC = () => {
  // const { jobID } = useContext(ClientJobContext);

  useEffect(() => {
    autoScrollOnTop();
  }, []);

  // if (!jobID) return <FullPageLoader />;
  return (
    <ChatProvider>
      <Styled.DashboardWrapper>
        <Chat />
      </Styled.DashboardWrapper>
    </ChatProvider>
  );
};

export default ChatPage;
