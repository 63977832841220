import { FC } from "react";
import * as S from "../styles";

interface Props {
  onChange: (e: string) => void;
}
const SearchTalent: FC<Props> = ({ onChange }) => {
  return (
    <S.ChatSearchInputWrapper>
      <S.ChatSearchInput
        placeholder="Search People"
        type="text"
        onChange={(e): void => onChange(e.target.value)}
      />
      <S.ChatSearchInputIcon>
        <i className="icon-search" />
      </S.ChatSearchInputIcon>
    </S.ChatSearchInputWrapper>
  );
};

export default SearchTalent;
