import React, { FC } from "react";
import { CreateClientForm } from "utils";
import { UseFormReturn } from "react-hook-form";
import { ShouldRender } from "@remotebase/components";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import { invalidNumber } from "@remotebase/constants";
import * as Styled from "./styles";

interface Props {
  label?: string;
  title: CreateClientForm;
  countryTitle: CreateClientForm;
  handler: UseFormReturn;
  placeholder: string;
  small?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const FormTel: FC<Props> = ({ label, title, countryTitle, handler, placeholder, small }) => {
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = handler;

  const localPhoneNo = watch(title);

  const setCountry = (country: CountryCode): void => setValue(countryTitle, country);

  const setTel = (phoneNo: E164Number): void => {
    if (!phoneNo) return;
    if (!isValidPhoneNumber(phoneNo)) setError(title, { type: "string", message: invalidNumber });
    else clearErrors(title);
    setValue(title, phoneNo);
  };

  return (
    <div>
      <Styled.InputGroup $small={!!small}>
        <ShouldRender if={!small}>
          <Styled.InputLabel>{label}</Styled.InputLabel>
        </ShouldRender>
        <Styled.TelInputCover>
          <PhoneInput
            onCountryChange={setCountry}
            placeholder={placeholder}
            value={localPhoneNo}
            onChange={setTel}
          />
        </Styled.TelInputCover>
      </Styled.InputGroup>
      <ShouldRender if={errors[title]?.message}>
        <Styled.ErrorMessage>{errors[title]?.message}</Styled.ErrorMessage>
      </ShouldRender>
    </div>
  );
};
