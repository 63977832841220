import React from "react";
import { TalentJobMatchProvider } from "state/talentjobMatch";
import * as Styled from "../styles";
import { DownloadResumeSection } from "./downloadResumeSection";
import { ProfileDetailsSection } from "./profileDetailsSection";
import { TalentSummarySection } from "./summarySection";
import { TalentEducationSection } from "./educationSection";
import { TalentEmploymentSection } from "./employmentSection";
import { MatchTalentSection } from "./matchtalentSection";

export const TalentVersionBody: React.FC = () => {
  return (
    <>
      <ProfileDetailsSection />
      <TalentSummarySection />
      <Styled.ProfileQualificationWrapper>
        <Styled.ProfileAcademicWrapper>
          <TalentEducationSection />
        </Styled.ProfileAcademicWrapper>
        <TalentEmploymentSection />
      </Styled.ProfileQualificationWrapper>
      <TalentJobMatchProvider>
        <MatchTalentSection />
      </TalentJobMatchProvider>
      <DownloadResumeSection />
    </>
  );
};

export default TalentVersionBody;
