import { FC, useState } from "react";
import * as Styled from "./styles";
import SaveVersionBtn from "./saveVersion";
import { ViewVersionsBtn } from "./viewVersions";
import DiscardChangesBtn from "./discardChangesBtn";

export const TalentVersionFooter: FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Styled.TalentVersionFooter>
        <Styled.SaveAndViewVersionsWrapper>
          <ViewVersionsBtn loading={loading} />
          <SaveVersionBtn setLoading={setLoading} />
          <DiscardChangesBtn loading={loading} />
        </Styled.SaveAndViewVersionsWrapper>
      </Styled.TalentVersionFooter>
    </>
  );
};

export default TalentVersionFooter;
