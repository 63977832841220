import { FC } from "react";
import * as Styled from "../styles";

export const WarningBadge: FC = () => {
  return (
    <>
      <Styled.WarningBadge>
        <Styled.WarningBadgeContent>
          <Styled.WarningBadgePara>
            !!! Please note that you have unsaved changes. Discard or save them before switching
            versions
          </Styled.WarningBadgePara>
        </Styled.WarningBadgeContent>
      </Styled.WarningBadge>
    </>
  );
};

export default WarningBadge;
