import { useHistory } from "react-router-dom";
import { Talent } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { TalentsModal, NoTableData, TableRow } from "components";
import { FC, Fragment, useState } from "react";
import { withTalent } from "state/talent";
import { TalentProps, AuthRoutes } from "utils";
import { TalentsReferredByModal } from "../../modals/talentModal";

export const TalentTableBody: FC<TalentProps> = ({ talentState: { data } }) => {
  const { push } = useHistory();
  const [showReferralsModal, setShowReferralsModal] = useState(false);
  const [showReferredByModal, setShowReferredByModal] = useState(false);
  const [talentId, setTalentId] = useState("");
  const handleModalShow = (id?: string): void => {
    if (id) setTalentId(id);
    setShowReferralsModal(typeof id === "string");
  };

  const handleReferredByClick = (id?: string): void => {
    if (id) setTalentId(id);
    setShowReferredByModal(typeof id === "string");
  };

  const handleEditBtnClick = (id?: string): void => {
    if (id) push(AuthRoutes.TalentsFeedback.replace(":id", id));
  };

  const handleTalentVersionBtnClick = (id?: string): void => {
    if (id) push(AuthRoutes.TalentProfileVersion.replace(":id", id));
  };

  return (
    <tbody>
      <ShouldRender if={data?.length}>
        <Fragment>
          {data?.map(
            (value: Talent, index: number): JSX.Element => (
              <TableRow
                key={index}
                index={index}
                talent={value}
                onNameClick={handleModalShow}
                onReferredByClick={handleReferredByClick}
                onEditBtnClick={handleEditBtnClick}
                onTalentMatchBtnClick={handleTalentVersionBtnClick}
              />
            ),
          )}
        </Fragment>
      </ShouldRender>
      <ShouldRender if={!data?.length}>
        <NoTableData />
      </ShouldRender>
      <ShouldRender if={showReferralsModal}>
        <TalentsModal referrerId={talentId} handleShow={handleModalShow} />
      </ShouldRender>
      <ShouldRender if={showReferredByModal}>
        <TalentsReferredByModal talentId={talentId} handleShow={handleReferredByClick} />
      </ShouldRender>
    </tbody>
  );
};

export default withTalent(TalentTableBody);
