import styled, { css, keyframes } from "styled-components";
import { Button, Table } from "react-bootstrap";
import { StyledProps } from "utils";

export const ViewJobTableWrapper = styled.div`
  width: 100%;
  & .table-responsive {
    overflow: auto;
    max-height: 350px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
`;

export const TalentTable = styled(Table)`
  width: 100%;
  min-width: 550px;
  margin-bottom: 0;

  & thead {
    & tr {
      & th {
        font-size: 16px;
        color: #11142d;
        padding: 16px 12px;
        border: none;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.05);

        &:first-child {
          text-align: left;
          border-radius: 8px 0 0 8px;
        }

        &:nth-child(5) {
          text-align: right;
          border-radius: 0 8px 8px 0;
        }

        &:nth-child(5) {
          width: 10%;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        font-size: 16px;
        color: #11142d;
        padding: 12px;
        border: none;
        text-align: center;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    text-align: left;
  }

  td + td,
  th + th {
    border-left: 1px solid #c1c1c1 !important;
  }
`;

export const MatchStatusActionDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  text-size: 0.75rem;
`;

export const PublishJobMatchBtn = styled(Button)`
  margin-left: 3px;
  align-items: center;
  font-size: 10px;
  padding: 0px;

  color: #ffffff;
  display: flex;

  width: 45px;
  height: 35px;
  background: rgb(63 115 246);
  border: 0.5px solid #000000;
  border-radius: 8px;
`;

export const OptionsSelector = styled.div`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  background-color: rgb(63 115 246);
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 10px;
`;

export const OptionsEllipse = styled.p``;

export const ActionsOptionsDiv = styled.div`
  position: absolute;
  z-index: 10;
  width: 100px;
  padding: 14px 10px;
  left: -50px;
  top: -70px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #312a50;
  border: 0.5px solid #000000;
  // border-radius: 5px;
`;

export const ActionOption = styled.p<{
  margin?: boolean;
  $disabled?: boolean;
}>`
  margin: 0px;
  cursor: pointer;

  ${(props): StyledProps =>
    props.margin
      ? css`
          margin-top: 10px;
        `
      : css``}
  }

  ${(props): StyledProps =>
    props.$disabled
      ? css`
          user-select: none;
          cursor: default;
          color: #8d8d8d;
        `
      : css``}
  }
`;

export const EngRowCross = styled.span`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(63 115 246);
  border-radius: 50%;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    box-shadow: none;
    border: none;
  }

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff;
    }
  }
`;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div<{ color?: string }>`
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  opacity: 1;
  transition-delay: 200ms;
  width: 1.1rem;
  height: 1.1rem;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  ${(props): StyledProps =>
    css`
      border-top-color: ${props.color};
    `}
`;
