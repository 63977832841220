import { Fragment, useCallback, useMemo, useState, FC, useContext } from "react";
import useSWR, { useSWRConfig } from "swr";
import { Badge } from "react-bootstrap";
import Select from "react-select";
import { useListInvoiceBatches, useUpdateInvoiceBatches, useProcessBatch } from "hooks";
import {
  GetInvoiceBatchBySortHashKeyQuery,
  GetInvoiceBatchBySortHashKeyQueryVariables,
  InvoiceBatchStatus,
} from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { AuthContextType } from "state/types";
import { AuthContext } from "state/auth";
import InvoiceListModal from "components/modals/invoiceListModal";
import { QueryResult } from "@apollo/client";
import * as Styled from "./styles";
import { LIST_BATCH_KEY, actions, getStatusBg } from "./utils";

export default function InvoiceBatches(): ReturnType<FC> {
  const {
    authState: { email: userId },
  } = useContext<AuthContextType>(AuthContext);
  const { listBatches } = useListInvoiceBatches();
  const { updateBatch, loading: updateLoading, error: updateError } = useUpdateInvoiceBatches();
  const { processBatch, loading: processIsLoading } = useProcessBatch();
  const [activeBatchId, setActiveBatchId] = useState<string | undefined>();
  const fetcher = (
    args,
  ): Promise<
    QueryResult<GetInvoiceBatchBySortHashKeyQuery, GetInvoiceBatchBySortHashKeyQueryVariables>
  > => listBatches(args).then((res) => res);
  const {
    data,
    error: swrError,
    isLoading,
  } = useSWR(LIST_BATCH_KEY, fetcher, {
    revalidateOnFocus: false,
  });
  const { mutate } = useSWRConfig();
  const loading = data?.loading || isLoading;
  const error = data?.error || swrError;
  const result = data?.data?.getInvoiceBatchBySortHashKey;

  const [isHandlingApprove, setIsHandlingApprove] = useState(false);
  const approveIsLoading = useMemo(
    () => isHandlingApprove && updateLoading,
    [isHandlingApprove, updateLoading],
  );

  const [isHandlingReject, setIsHandlingReject] = useState(false);
  const rejectIsLoading = useMemo(
    () => isHandlingReject && updateLoading,
    [isHandlingReject, updateLoading],
  );

  const [selectId, setSelectId] = useState<string | undefined>(undefined);

  const handleSelectedAction = useCallback(async (selectedOption): Promise<void> => {
    setSelectId(selectedOption?.batchId);

    switch (selectedOption.value) {
      case "view":
        if (!selectedOption?.batchId) return;
        setActiveBatchId(selectedOption?.batchId);
        break;
      case "approve":
        if (!selectedOption?.batchId) return;
        setIsHandlingApprove(true);
        await updateBatch({
          variables: {
            input: {
              status: InvoiceBatchStatus.APPROVED,
              id: selectedOption?.batchId,
              approvedBy: userId,
              expectedVersion: selectedOption?.version,
            },
          },
        });

        // Force reload
        mutate(LIST_BATCH_KEY);
        setIsHandlingApprove(false);
        break;
      case "reject":
        if (!selectedOption?.batchId) return;

        setIsHandlingReject(true);
        await updateBatch({
          variables: {
            input: {
              status: InvoiceBatchStatus.REJECTED,
              id: selectedOption?.batchId,
              expectedVersion: selectedOption?.version,
            },
          },
        });

        // Force reload
        mutate(LIST_BATCH_KEY);
        setIsHandlingReject(false);
        break;
      case "process":
        if (!selectedOption?.batchId) return;

        await processBatch({
          variables: {
            batchId: selectedOption?.batchId,
          },
        });

        // Force reload after 5 seconds
        setTimeout(() => {
          mutate(LIST_BATCH_KEY);
        }, 1000);
        break;
      default:
        break;
    }
  }, []);
  const handleModalClose = (): void => setActiveBatchId(undefined);
  const getActionIsDisabled = (action: string, status: string): boolean => {
    switch (action) {
      case "approve":
        return (
          isHandlingApprove ||
          status === InvoiceBatchStatus.APPROVED ||
          status === InvoiceBatchStatus.COMPLETE ||
          status === InvoiceBatchStatus.INCOMPLETE ||
          status === InvoiceBatchStatus.PROCESSING
        );
      case "reject":
        return (
          isHandlingReject ||
          status === InvoiceBatchStatus.REJECTED ||
          status === InvoiceBatchStatus.COMPLETE ||
          status === InvoiceBatchStatus.INCOMPLETE ||
          status === InvoiceBatchStatus.PROCESSING
        );
      case "process":
        return status !== InvoiceBatchStatus.APPROVED;
      default:
        return false;
    }
  };

  if (loading) return <p>Loading batches...</p>;
  if ((!loading && error) || (!updateLoading && updateError))
    return <p>Error loading invoice batches</p>;

  return (
    <Fragment>
      <Styled.ContentTable responsive="md">
        <thead>
          <tr>
            <th>Batch Number</th>
            <th>File Name</th>
            <th>Status</th>
            <th>Actions</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {result?.items &&
            result.items.map(
              (batch) =>
                batch && (
                  <tr key={batch.id}>
                    <td>{batch.invoiceBatchNumber}</td>
                    <td>{batch.csvFileUrl.split("batchInvoice/")[1]}</td>
                    <td>
                      <Badge variant={getStatusBg(batch.status)}>{batch.status}</Badge>
                    </td>
                    <td>
                      <Select
                        options={actions.map((action) => ({
                          ...action,
                          batchId: batch.id,
                          version: batch.version,
                          isDisabled: getActionIsDisabled(action.value, batch.status),
                        }))}
                        onChange={handleSelectedAction}
                        placeholder="Select action"
                        isDisabled={
                          batch.status === InvoiceBatchStatus.INVALID ||
                          batch.status === InvoiceBatchStatus.PENDING
                        }
                        isLoading={
                          (approveIsLoading || rejectIsLoading || processIsLoading) &&
                          selectId === batch.id
                        }
                        className="select-action"
                      />
                    </td>
                    <td>
                      {batch.approvedBy
                        ? `${batch.status} by ${batch.approvedBy}`
                        : batch?.errorMsg}
                    </td>
                  </tr>
                ),
            )}
        </tbody>
      </Styled.ContentTable>
      <ShouldRender if={!result?.items?.length}>
        <Styled.NoInvoiceBatch>No invoice batch available</Styled.NoInvoiceBatch>
      </ShouldRender>

      <ShouldRender if={activeBatchId}>
        <InvoiceListModal handleOnHide={handleModalClose} batchId={activeBatchId} />
      </ShouldRender>
    </Fragment>
  );
}
