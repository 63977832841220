import React, { useEffect, useMemo } from "react";
import { useCreateClientAccount } from "hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneLabel, phoneNumberPlaceholder } from "@remotebase/constants";
import { FormButton, FormInput, FormPassword, FormSelect, FormTel } from "components";
import { useHistory } from "react-router-dom";
import { companyAssociation } from "@remotebase/amplify-constants/API";
import * as Styled from "./styles";

import {
  AuthRoutes,
  ButtonType,
  companyLabel,
  companyPlaceholder,
  CreateClientForm,
  CreateClientSchema,
  DropdownItems,
  emailLabel,
  emailPlaceholder,
  fullNameLabel,
  fullNamePlaceholder,
  passwordLabel,
} from "../../utils";

const CreateClient: React.FC = () => {
  const { push } = useHistory();
  const { createClient, getCompanies, companies, isLoading, client } = useCreateClientAccount();
  const formHook = useForm({ resolver: yupResolver(CreateClientSchema), mode: "onChange" });
  const companiesDropdownItems = useMemo(() => {
    const items: DropdownItems = [];
    for (const company of companies) {
      if (company) items.push({ key: company.name, value: company.id });
    }

    return items;
  }, [companies]);

  useEffect(() => {
    if (client && !isLoading) {
      formHook.reset();
      push(AuthRoutes.Clients);
    }
  }, [isLoading, client]);

  useEffect(() => {
    getCompanies({ variables: { associatedWith: companyAssociation.client } });
  }, []);

  return (
    <form onSubmit={formHook.handleSubmit(createClient)}>
      <Styled.CreateForm>
        <FormInput
          type={"email"}
          label={emailLabel}
          handler={formHook}
          placeholder={emailPlaceholder}
          title={CreateClientForm.email}
        />
        <FormInput
          label={fullNameLabel}
          handler={formHook}
          placeholder={fullNamePlaceholder}
          title={CreateClientForm.fullName}
        />
        <FormTel
          handler={formHook}
          label={phoneLabel}
          placeholder={phoneNumberPlaceholder}
          title={CreateClientForm.phone}
          countryTitle={CreateClientForm.country}
        />
        <FormSelect
          handler={formHook}
          label={companyLabel}
          placeholder={companyPlaceholder}
          title={CreateClientForm.companyID}
          items={companiesDropdownItems}
        />
        <FormPassword
          handler={formHook}
          label={passwordLabel}
          title={CreateClientForm.password}
          placeholder={"********"}
          showGenerate={true}
        />
        <Styled.SubmitButtonCover>
          <FormButton isLoading={isLoading} label={"Create Client"} type={ButtonType.SUBMIT} />
        </Styled.SubmitButtonCover>
      </Styled.CreateForm>
    </form>
  );
};

export default CreateClient;
