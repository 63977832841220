import { FC } from "react";
import * as S from "../styles";

export const ResetAndPassEmpty: FC = () => {
  return (
    <S.ResetPassEmptyWrapper>
      <S.EmptyHeader>Nothing to see here</S.EmptyHeader>
      <S.EmptyParagraph>No test has been created at the moment</S.EmptyParagraph>
    </S.ResetPassEmptyWrapper>
  );
};

export default ResetAndPassEmpty;
