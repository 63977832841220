import { FC, useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { DropdownItems } from "utils";
import { FormSelect } from "components/inputs";
import { useTalentJobMatchContext } from "state/talentjobMatch";
import { cleanArray } from "@remotebase/components";
import { useListCompanyJobs } from "hooks";
import { JobStatus } from "@remotebase/amplify-constants/API";

interface Props {
  handler: UseFormReturn;
  title: string;
  companyID?: string | null;
}

// eslint-disable-next-line import/prefer-default-export
export const JobsSelect: FC<Props> = ({ handler, title, companyID }) => {
  const [fetched, setFetched] = useState<{ [key: string]: boolean }>({});
  const [fetching, setFetching] = useState(false);
  const { jobs, setJobs, setJobsLoading } = useTalentJobMatchContext();

  const { setValue } = handler;

  const jobsDropdown = useMemo<DropdownItems>(
    () =>
      companyID ? jobs[companyID]?.map((val) => ({ key: val.title, value: val.id })) ?? [] : [],
    [companyID, jobs],
  );

  const {
    getJobs,
    jobs: jobsList,
    loading: jobsLoading,
    nextToken: jobsNextToken,
  } = useListCompanyJobs();

  useEffect(() => setJobsLoading(fetching), [fetching]);

  // Fetch jobs on company change if not previously fetched
  useEffect(() => {
    setValue(title, "");
    if (fetching || !companyID || fetched[companyID]) return;

    setFetching(true);
    getJobs({ variables: { companyID, filter: { status: { eq: JobStatus.Published } } } });
  }, [companyID, fetched, fetching]);

  // Update jobs list and fetch if more
  useEffect(() => {
    if (!companyID || !fetching || jobsLoading || !jobsList) return;

    setJobs((prev) => ({
      ...(prev ?? {}),
      [companyID]: [...(prev[companyID] ?? []), ...cleanArray(jobsList)],
    }));

    if (jobsNextToken)
      getJobs({
        variables: { companyID, nextToken: jobsNextToken },
      });
    else {
      setFetched((prev) => ({ ...prev, [companyID]: true }));
      setFetching(false);
    }
  }, [jobsLoading, jobsList, jobsNextToken, fetching, companyID]);

  return <FormSelect title={title} handler={handler} placeholder={"Job"} items={jobsDropdown} />;
};
