import { ReturnPropsFetchFeedback } from "hooks/types";
import { useFetchTalentJobMatches, useListCompanies } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { Company, companyAssociation, JobMatch } from "@remotebase/amplify-constants/API";

export const useFetchTalentMatch = (): ReturnPropsFetchFeedback => {
  const { getCompanies, companiesList, loading: fetchCompanyLoading } = useListCompanies();
  const { getTalentMatches, loading, talentJobMatches } = useFetchTalentJobMatches();

  const [companies, setCompanies] = useState<Array<Company | null>>([]);
  const [talentMatch, setTalentMatch] = useState<Array<JobMatch | null>>([]);

  useEffect(() => {
    if (talentJobMatches?.length) setTalentMatch(talentJobMatches);
  }, [talentJobMatches]);

  useEffect(() => {
    if (companiesList?.length) setCompanies(companiesList);
  }, [companiesList]);

  const isLoading = useMemo(() => fetchCompanyLoading || loading, [fetchCompanyLoading, loading]);

  const fetchData = (id: string): void => {
    if (id) {
      getTalentMatches({
        variables: { filter: { talentID: { eq: id } } },
      });
      getCompanies({ variables: { associatedWith: companyAssociation.client } });
    }
  };

  return {
    companies,
    talentMatch,
    isLoading,
    fetchData,
  };
};

export default useFetchTalentMatch;
