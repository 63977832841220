import { Modal, Table } from "react-bootstrap";
import styled from "styled-components";

export const TalentModal = styled(Modal)`
  .modal-dialog {
    max-width: 1100px;
    padding: 20px;

    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }

  .modal-header {
    padding: 40px 50px;
    align-items: center;

    @media only screen and (max-width: 767px) {
      padding: 30px 24px;
    }

    & .close {
      font-size: 32px;
      color: #fff;
      width: 40px;
      height: 40px;
      background-color: #6c5dd3;
      margin: 0;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 1;
      &:hover,
      &:focus {
        background-color: #5040bf;
      }
    }
  }

  .modal-title {
    font-size: 24px;
    color: #11142d;
    font-family: "Poppins", sans-serif;
  }

  .modal-body {
    padding: 0;
    & > div {
      padding: 40px 50px 90px;
      background-color: #f9f9f9;
      border: none;
      box-shadow: none;

      @media only screen and (max-width: 767px) {
        padding: 30px 24px 90px;
      }
    }
    & .pagination {
      & .page-link {
        background-color: #e9ecef;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
`;

export const TableContent = styled.div`
  padding: 20px 20px 90px;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  position: relative;
`;

export const MainTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 40px;
  min-width: 960px;
  thead tr th {
    font-size: 14px;
    font-weight: 500;
    color: #474d58;
    padding: 10px 14px;
    border-top: 1px solid #e5ecff;
    border-bottom: 1px solid #e5ecff;
    word-break: break-word;
  }
  thead tr th:first-child {
    border-radius: 5px 0px 0px 5px;
    border-left: 1px solid #e5ecff;
  }
  thead tr th:last-child {
    border-radius: 0px 5px 5px 0px;
    border-right: 1px solid #e5ecff;
  }
  tbody tr td,
  tfoot tr td {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    border: none;
    padding: 10px 14px;
    word-break: break-word;
  }
  tbody tr:first-child td {
    padding-top: 20px;
  }
  thead tr th:nth-of-type(1),
  tbody tr td:nth-of-type(1) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(2),
  tbody tr td:nth-of-type(2) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(3),
  tbody tr td:nth-of-type(3) {
    min-width: 256px;
  }
  thead tr th:nth-of-type(4),
  tbody tr td:nth-of-type(4) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(5),
  tbody tr td:nth-of-type(5) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(6),
  tbody tr td:nth-of-type(6) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(7),
  tbody tr td:nth-of-type(7) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(8),
  tbody tr td:nth-of-type(8) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(9),
  tbody tr td:nth-of-type(9) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(10),
  tbody tr td:nth-of-type(10) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(11),
  tbody tr td:nth-of-type(11) {
    min-width: 146px;
  }
`;

export const CloseButton = styled.button`
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #6c5dd3;
  margin: 0;
  position: relative;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  border: none;
  opacity: 1;
  &:hover,
  &:focus {
    background-color: #5040bf;
  }

  p {
    position: absolute;
    top: -5%;
    left: 35%;
  }
`;
