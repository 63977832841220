import { FC, Fragment } from "react";
import { NoTableData, TableHeader } from "components";
import { TalentReferredByColumns, TalentTableColumns } from "utils";
import { ShouldRender } from "@remotebase/components";
import { Recruiter, Talent } from "@remotebase/amplify-constants/API";

interface ReferrerTableProps {
  data?: Array<Talent | Recruiter>;
}

const ReferredByTable: FC<ReferrerTableProps> = ({ data }) => {
  return (
    <Fragment>
      <TableHeader columnNames={TalentReferredByColumns} />
      <tbody>
        <ShouldRender if={data?.length}>
          <Fragment>
            {data?.map(
              (value: Talent, index: number): JSX.Element => (
                <tr key={index}>
                  <td>{value.fullName}</td>
                  <td>{value.phone}</td>
                  <td>{value.email}</td>
                  <td>{value.country}</td>
                </tr>
              ),
            )}
          </Fragment>
        </ShouldRender>
        <ShouldRender if={!data?.length}>
          <NoTableData span={Object.keys(TalentTableColumns).length} />
        </ShouldRender>
      </tbody>
    </Fragment>
  );
};

export default ReferredByTable;
