import { createContext, useContext } from "react";
import { ITalentJobMatchContext } from "../types";

export const TalentJobMatchContext = createContext<ITalentJobMatchContext>({
  companies: [],
  setCompanies: () => null,
  jobs: {},
  setJobs: () => null,
  jobMatches: [],
  setJobMatches: () => null,
  jobsLoading: false,
  setJobsLoading: () => null,
});

export const useTalentJobMatchContext = (): ITalentJobMatchContext =>
  useContext(TalentJobMatchContext);
