import { FC } from "react";
import { pageLimit } from "utils";

import * as Styled from "./styles";

export const TableShimmer: FC = () => {
  return (
    <Styled.TableWrapper>
      <Styled.TableBox>
        {[...Array(pageLimit)].map((e, i) => {
          return <Styled.MainTable key={`refbox${i}`} />;
        })}
      </Styled.TableBox>
    </Styled.TableWrapper>
  );
};

export default TableShimmer;
