import { FilterParamsOperators } from "hooks/types";
import { AuthStateType } from "hooks/utils";
import { IProfile, IProfileDetails, IProfileState } from "state/profileSteps";
import { ITalentHocState } from "state/types/talent.interface";
import { FlattenSimpleInterpolation } from "styled-components";

export type StyledProps = FlattenSimpleInterpolation | string;
export type AuthProps = {
  authState: AuthStateType;
  getAuth: () => void;
};
export type ProfileProps = {
  profileState: IProfileState;
  refetchProfile: () => void;
  updateUserInfo: (data: IProfile) => void;
  updateUserProfile: (profile: IProfileDetails) => void;
  cleanProfileState: () => void;
};

export interface FilterOptions {
  isPassPS?: boolean;
  isPassHR?: boolean;
  searchByName?: boolean;
  searchByEmail?: boolean;
  searchRecruiter?: boolean;
  searchText?: string;
  reset?: boolean;
  referrerId?: string;
}

export type QueryfilterType = {
  isPassPS?: FilterParamsOperators;
  isPassHR?: FilterParamsOperators;
  fullName?: FilterParamsOperators;
  email?: FilterParamsOperators;
};

export interface HocStateProps {
  prevBtnClick: () => void;
  nextBtnClick: () => void;
  lastPageBtn?: () => void;
  state: ITalentHocState;
}

export interface HocTalentStateProps {
  prevBtnClick: () => void;
  nextBtnClick: () => void;
  lastPageBtn?: () => void;
  state: ITalentHocState;
}

export interface FormatDate {
  date?: string | null;
  showTime?: boolean;
}

export type CanNullOrUndefined<T> = T | null | undefined;

export const defaultRoute = "#";
export const purpleBlueColor = "#4c40f7";

export const dropdownLabel = "Filters:";

export const defaultCountryCode = "PK";

export const superUser = "SUPERADMIN";

export type DropdownItems = {
  key: string;
  value: string;
}[];

export const alphanumericCharacters =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const PasswordLength = 16;

export const DefaultPageLimit = 1;

export enum Color {
  WHITE = "#FFFFFF",
}

export interface PaginatedStateProps<F> {
  prevBtnClick: () => void;
  nextBtnClick: () => void;
  lastPageBtn?: () => void;
  downloadCsv?: () => void;
  state: PaginatedState<F>;
}

export interface PaginatedState<F> {
  filterOptions?: PaginatedFilterOptions<F>;
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
}

export interface PaginatedFilterOptions<F> {
  filterBy?: F;
  query?: string;
}

export enum ButtonType {
  SUBMIT = "submit",
  BUTTON = "button",
  RESET = "reset",
}

export const shouldShowMaintenence = process.env.REACT_APP_MAINTENANCE_MODE === "true";

export interface SelectOption {
  value: string;
  label: string;
}
