import React, { Fragment, useEffect, useState } from "react";
import { TextDraft, IconLoader, ShouldRender } from "@remotebase/components";
import { IFeedbackProps } from "state/types/talentFeedback.interface";
import { TalentProfileEnum, scoreLabel, defaultDropdownValue } from "utils";
import { withUpdateFeedback } from "state/talentFeedback";
import { Feedback, feedbackType } from "@remotebase/amplify-constants/API";
import { MatchTalent, Slider } from "components";
import FeedbackIcons from "./FeedbackIcons";
import SentimentDropdown from "./SentimentDropdown";
import * as Styled from "./styles";

interface Props {
  data?: Feedback | null;
  type: feedbackType;
  isEdit: boolean;
  label: string;
  onUpdateEditable: () => void;
}

const EditTalentFeedback: React.FC<Props & IFeedbackProps> = ({
  data,
  type,
  feedbackState: { isNetwork },
  talentState: { saveFeedbackLoading, matchTalentLoading },
  label,
  isEdit,
  onUpdateEditable,
  updateFeedback,
  updateTalentProfile,
  onCancelFeedback,
}) => {
  const { description, score, sentiment: talentSentiment } = data || {};
  const [profileDetails, setProfileDetails] = useState(description);
  const [profileScore, setProfileScore] = useState(
    score !== undefined && score !== null && score < 10 ? score * 10 : score,
  );
  const [sentiment, setSentiment] = useState<string>(talentSentiment || defaultDropdownValue);
  const [networked, setNetworked] = useState(isNetwork || false);
  const [startedSaving, setStartedSaving] = useState(false);

  const setIsEditMode = (): void => {
    onUpdateEditable();
    setStartedSaving(false);
  };

  const onUpdateFeedback = (): void => {
    const updatedData = {
      type,
      description: profileDetails,
      score: profileScore,
      givenAt: new Date().toISOString(),
      meta: "",
      sentiment,
    } as Feedback;
    setStartedSaving(true);
    updateTalentProfile(TalentProfileEnum.isNetwork, networked);
    updateFeedback(updatedData);
  };

  useEffect(() => {
    updateTalentProfile(TalentProfileEnum.isNetwork, networked);
  }, [networked]);

  useEffect(() => {
    if (data && !isEdit) {
      setProfileDetails(data.description);
      setProfileScore(data.score || 0);
      setSentiment(data.sentiment || defaultDropdownValue);
      setNetworked(isNetwork || false);
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (saveFeedbackLoading) {
      return;
    }
    if (!saveFeedbackLoading && !matchTalentLoading && startedSaving) {
      setIsEditMode();
    }
  }, [saveFeedbackLoading, matchTalentLoading, startedSaving]);

  return (
    <Fragment>
      <Styled.ProfileHead>
        <Styled.ProfileBlockTitle>{label}</Styled.ProfileBlockTitle>
        <ShouldRender if={(saveFeedbackLoading || matchTalentLoading) && startedSaving}>
          <Styled.OptButton>
            <IconLoader />
          </Styled.OptButton>
        </ShouldRender>
        <ShouldRender if={!startedSaving}>
          <FeedbackIcons
            isEdit={!startedSaving}
            toggleIsEdit={setIsEditMode}
            onUpdateFeedback={onUpdateFeedback}
            cancelFeedback={(): void => onCancelFeedback(type)}
          />
        </ShouldRender>
      </Styled.ProfileHead>
      <TextDraft
        disabled={startedSaving}
        initialValue={profileDetails || ""}
        setValue={setProfileDetails}
      />
      <Styled.ScoreDropDownWrapper>
        <Styled.SliderDivWrapper>
          <Slider
            draggable={true}
            disabled={startedSaving}
            label={scoreLabel}
            setValue={setProfileScore}
            value={profileScore || 0}
          />
        </Styled.SliderDivWrapper>
        <Styled.DivWrapper>
          <SentimentDropdown disabled={startedSaving} setValue={setSentiment} value={sentiment} />
        </Styled.DivWrapper>
      </Styled.ScoreDropDownWrapper>
      <ShouldRender if={type === feedbackType.FinalInterviewFeedback}>
        <MatchTalent isEdit={!startedSaving} setIsNetwork={setNetworked} isNetwork={networked} />
      </ShouldRender>
    </Fragment>
  );
};

export default withUpdateFeedback<Props>(EditTalentFeedback);
