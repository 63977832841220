import { Payment } from "API";
import { ShouldRender } from "@remotebase/components";
import { NoTableData, TableRowPayments, TableShimmer } from "components";
import { FC, Fragment, useMemo } from "react";
import { getPaymentLists } from "hooks";
import { withTalent } from "state/talent";
import { TalentProps } from "utils";

export const TalentTableBody: FC<TalentProps> = () => {
  const { data, loading } = getPaymentLists();

  const payments = useMemo(() => {
    return (
      data
        ?.slice()
        .sort(
          (p1, p2) => new Date(p1?.updatedAt!).getTime() - new Date(p2?.updatedAt!).getTime(),
        ) || []
    );
  }, [data]);
  return (
    <tbody>
      <ShouldRender if={loading}>
        <TableShimmer />
      </ShouldRender>
      <ShouldRender if={payments?.length}>
        <Fragment>
          {payments?.map(
            (value: Payment, index: number): JSX.Element => (
              <TableRowPayments key={index} index={index} payment={value} />
            ),
          )}
        </Fragment>
      </ShouldRender>
      <ShouldRender if={!payments?.length}>
        <NoTableData />
      </ShouldRender>
    </tbody>
  );
};

export default withTalent(TalentTableBody);
