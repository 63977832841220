import { useMutation } from "@apollo/client";
import {
  TalentProfileVersion,
  UpdateTalentProfileVersionMutation,
  UpdateTalentProfileVersionMutationVariables,
} from "@remotebase/amplify-constants/API";
import { updateTalentProfileVersion } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "../utils";
import { IUpdateTalentProfileVersionReturnProps } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useUpdateTalentProfileVersion = (): IUpdateTalentProfileVersionReturnProps => {
  const [updateTalentVersion, { data, loading, error }] = useMutation<
    UpdateTalentProfileVersionMutation,
    UpdateTalentProfileVersionMutationVariables
  >(getQuery(updateTalentProfileVersion));

  return {
    updateTalentVersion,
    data: data?.updateTalentProfileVersion as TalentProfileVersion,
    loading,
    error,
  };
};
