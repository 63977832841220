import { ShouldRender } from "@remotebase/components";
import { ReferralsCsvButton, TableFooter, TableShimmer } from "components";
import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withReferrals } from "state/referrals";
import { IReferralProps } from "state/types/referrals.interface";
import { recruiterModalTitle } from "utils";
import ReferrerTable from "./referrerTable";
import * as Styled from "./styles";

interface Props {
  referrerId: string;
  handleShow: (event) => void;
}
const TalentsModal: FC<Props & IReferralProps> = ({
  referralState: { loading, data, allData },
  referrerId,
  handleShow,
  searchByReferrerId,
  footerProps,
}) => {
  useEffect(() => {
    searchByReferrerId(referrerId);
  }, [referrerId]);

  return (
    <Styled.TalentModal show onHide={handleShow} animation={false} scrollable>
      <Modal.Header>
        <Modal.Title>{recruiterModalTitle}</Modal.Title>
        <Styled.CloseButton onClick={handleShow}>
          <p>x</p>
        </Styled.CloseButton>
      </Modal.Header>
      <Modal.Body>
        <ShouldRender if={!loading}>
          <Styled.TableContent>
            <div className="d-flex flex-row-reverse">
              <ReferralsCsvButton data={allData} loading={loading} />
            </div>
            <Styled.MainTable responsive>
              <ReferrerTable data={data} showReferredBy={false} />
              <div className="mt-2">
                <TableFooter {...footerProps} />
              </div>
            </Styled.MainTable>
          </Styled.TableContent>
        </ShouldRender>
        <ShouldRender if={loading}>
          <TableShimmer />
        </ShouldRender>
      </Modal.Body>
    </Styled.TalentModal>
  );
};
export default withReferrals(TalentsModal);
