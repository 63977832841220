import { FC } from "react";
import { CustomModal, ReadOnlyInput } from "components";
import { useProfileVersionContext } from "state/profileVersion";
import { ITalentProfileVersion } from "state/types";
import * as Styled from "../styles";
import WarningBadge from "./warningBadge";

interface Props {
  open: boolean;
  close: () => void;
}

export const ViewVersionsModal: FC<Props> = ({ open, close }) => {
  const { profileVersions, setSelectedVersion, selectedVersion, hasUnsavedData } =
    useProfileVersionContext();

  const selectVersion = (version: ITalentProfileVersion): void => {
    setSelectedVersion(version);
    close();
  };

  const isActive = (version: ITalentProfileVersion): boolean => version.id === selectedVersion?.id;

  return (
    <CustomModal showModal={open} onClose={close} header={"Profile versions"}>
      <Styled.OtherInfoCover>
        {hasUnsavedData ? <WarningBadge /> : null}
        {profileVersions?.map((version: ITalentProfileVersion, index: number) => (
          <Styled.FormGroup onClick={(): void => selectVersion(version)} key={index}>
            <ReadOnlyInput value={version?.name || "Original"} active={isActive(version)} />
          </Styled.FormGroup>
        ))}
      </Styled.OtherInfoCover>
    </CustomModal>
  );
};

export default ViewVersionsModal;
