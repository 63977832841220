import styled from "styled-components";

export const FeedbackSummaryContainer = styled.div`
  background-color: #ffffff;
  border-radius: 24px;
  padding: 6px 29px;
  overflow: scroll;
  max-height: 30vh;
`;

export const FeedbackSummaryTitle = styled.h3`
  font-size: 1.4rem;
  color: #11142d;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: -1px;
  margin-bottom: 2px;

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 10px;
  }
`;

export const FeedbackSummaryScoreCover = styled.div`
  margin: 0.5rem 0 1.5rem;
`;

export const FeedbackSummaryScore = styled.p`
  color: #7b8292;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;

  span {
    color: #c4c4c4;
    margin-left: 0.75rem;
    font-size: 0.85rem;
    position: relative;

    &:after {
      content: "";
      height: 0.25rem;
      width: 0.25rem;
      background: #c4c4c4;
      border-radius: 50%;
      left: -0.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
