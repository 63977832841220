import { FC } from "react";
import * as Styled from "../styles";

export const TalentSummarySectionShimmer: FC = () => {
  return (
    <Styled.ProfileSummaryWrapperShimmer>
      <Styled.ProfileSummaryHdShimmer />
      <Styled.ProfileSummaryTextAreaShimmer />
      <Styled.SummarySaveBtnShimmer>
        <Styled.SummarySaveBtnTextShimmer />
      </Styled.SummarySaveBtnShimmer>
    </Styled.ProfileSummaryWrapperShimmer>
  );
};

export default TalentSummarySectionShimmer;
