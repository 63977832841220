import { RecruiterData, ReturnPropsFetchRecruiter } from "hooks/types";
import { useEffect, useMemo, useState } from "react";
import { useRecruiterList, useRecruiterByEmail } from "hooks";
import { IRecruiterFilters } from "state/types/recruiter.interface";
import { getQueryFilter, pageLimit } from "utils";
import { isEmpty } from "lodash";
import downloadAsCSV from "@remotebase/helpers/downloadAsCSV";

export const useFetchRecruiter = (): ReturnPropsFetchRecruiter => {
  const {
    data: recruitersData,
    loading: recruitersLoading,
    nextToken: recruiterNextToken,
    getRecruiterList,
  } = useRecruiterList();
  const {
    data: recruiterByEmail,
    loading: recruiterByEmailLoading,
    nextToken: recruiterByEmailNextToken,
    getRecruiterByEmail,
  } = useRecruiterByEmail();

  const [data, setData] = useState<RecruiterData>();
  const [downloadCsv, setDownloadCsv] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [queryVariables, setQueryVariables] = useState({});
  const loading = useMemo(() => {
    return recruitersLoading || recruiterByEmailLoading;
  }, [recruitersLoading, recruiterByEmailLoading]);

  const resetState = (): void => {
    setData([]);
    setNextToken(null);
  };

  useEffect(() => {
    if (recruitersData?.length && !recruitersLoading) {
      if (downloadCsv) {
        downloadAsCSV({ data: recruitersData as unknown as Record<string, string>[] });
        setDownloadCsv(false);
      }

      setData(recruitersData);
      setNextToken(recruiterNextToken);
    } else if (recruitersData && !recruitersData.length && queryVariables && recruiterNextToken) {
      getRecruiterList({ variables: { ...queryVariables, nextToken: recruiterNextToken } });
    } else resetState();
  }, [recruitersData, recruitersLoading, downloadCsv]);

  useEffect(() => {
    if (recruiterByEmail?.length) {
      setData(recruiterByEmail);
      setNextToken(recruiterByEmailNextToken);
    } else resetState();
  }, [recruiterByEmail]);

  const getEmailRecruiter = (email?: string): void => {
    const variables = {
      email,
      nextToken: recruiterByEmailNextToken || null,
    };
    getRecruiterByEmail({ variables });
  };

  const fetchData = (nextTokenId: string | null, filters: IRecruiterFilters): void => {
    const { filterOptions } = filters;
    const filter = getQueryFilter(filterOptions);
    const isFilter = isEmpty(filter);
    const variables = {
      nextToken: nextTokenId,
      filter: !isFilter ? filter : null,
      limit: isFilter ? pageLimit : null,
    };

    if (filterOptions.searchByEmail) getEmailRecruiter(filterOptions.searchText);
    else {
      setQueryVariables(variables);
      getRecruiterList({ variables });
    }
  };

  const downloadCSV = (nextTokenId: string | null): void => {
    setDownloadCsv(true);
    getRecruiterList({ nextToken: nextTokenId });
  };

  return {
    data,
    nextToken,
    loading,
    fetchData,
    downloadCSV,
  };
};

export default useFetchRecruiter;
