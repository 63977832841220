import { FC, useEffect } from "react";
import { CustomModal, FormInput } from "components";
import { useProfileVersionContext } from "state/profileVersion";
import { SaveNewVersionForm, SaveNewVersionSchema } from "utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ITalentProfileVersion } from "state/types";
import * as Styled from "../styles";

interface Props {
  open: boolean;
  close: () => void;
  saveProfileVersion: (profileVersion: ITalentProfileVersion) => void;
}

export const SaveNewVersionModal: FC<Props> = ({ open, close, saveProfileVersion }) => {
  const { selectedVersion } = useProfileVersionContext();

  const formHook = useForm({
    resolver: yupResolver(SaveNewVersionSchema),
    mode: "onChange",
  });

  const {
    reset,
    getValues,
    formState: { isValid },
  } = formHook;

  useEffect(() => {
    reset({
      [SaveNewVersionForm.name]: selectedVersion?.name,
    });
  }, [selectedVersion]);

  const saveAsNew = async (): Promise<void> => {
    if (!isValid) return;
    const [name] = getValues([SaveNewVersionForm.name]);
    if (selectedVersion) {
      const { id, ...profileVersionData } = selectedVersion;
      const saveVersionData = { ...profileVersionData, name };
      console.log("Saving profile version...", saveVersionData, id);
      saveProfileVersion(saveVersionData);

      close();
    }
  };

  return (
    <CustomModal showModal={open} onClose={close} header={"New Version"}>
      <Styled.OtherInfoCover>
        <Styled.FormGroup>
          <FormInput
            label={"Name"}
            title={SaveNewVersionForm.name}
            handler={formHook}
            placeholder={"Name of version"}
          />
        </Styled.FormGroup>
        <Styled.SubmitButton onClick={saveAsNew}>Save and Update</Styled.SubmitButton>
      </Styled.OtherInfoCover>
    </CustomModal>
  );
};

export default SaveNewVersionModal;
