import { SidebarProps, sidebarItemTestId } from "utils";
import { FC, Fragment } from "react";
import { sidebarMenu, ISidebarElements, withSidebar } from "state/sidebar";
import SidebarItem from "./SidebarItem";

const SidebarMenu: FC<SidebarProps> = ({ sidebarType }) => {
  const getMenuObjects = (objValue: ISidebarElements, hasSubMenu?: boolean): Array<JSX.Element> => {
    const elems = [] as JSX.Element[];
    objValue.map((element, index) => {
      if (typeof element === "string") {
        elems.push(
          <SidebarItem
            key={`${hasSubMenu ? `subMenu_${objValue}` : "sidebar"}-${index}`}
            text={element}
            testingId={`${sidebarItemTestId}-${element}`}
            isSubMenu={hasSubMenu}
          />,
        );
      } else if (Array.isArray(element)) elems.push(...getMenuObjects(element, true));
      return <Fragment />;
    });

    return elems;
  };
  const getSidebarMenu = (): Array<JSX.Element> => {
    const menu = sidebarMenu[sidebarType];
    return menu ? getMenuObjects(menu) : [];
  };

  return <Fragment>{getSidebarMenu()}</Fragment>;
};

export default withSidebar(SidebarMenu) as FC;
