import { getRecruiterProfile } from "hooks";
import withApolloProvider from "hooks/apollo/withApollo";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "state/auth";
import { AuthContextType } from "state/types/authTypes";
import { ProfileProps } from "utils";
import { IProfile, ProfileContextType, IProfileDetails } from "./profileSteps.interface";
import { ProfileContext } from "./profile.context";

export function withProfile<T>(Component: React.FC<T & ProfileProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { profileState, setProfileState } = useContext<ProfileContextType>(ProfileContext);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const {
      authState: { userId },
    } = useContext<AuthContextType>(AuthContext);
    const { getProfile, profileData, error, loading } = getRecruiterProfile();

    const refetchProfile = useCallback((): void => {
      if (userId) {
        setProfileState({ isLoading: true });
        getProfile({ variables: { id: userId } });
      }
    }, [userId]);

    const updateUserProfile = (profile: IProfileDetails): void => {
      if (profileState?.data) {
        const { fullName, email } = profileState?.data;
        if (fullName && email)
          setProfileState({ ...profileState, data: { ...profileState.data, profile } });
      }
    };

    const cleanProfileState = (): void => {
      setProfileState({ isLoading: false });
    };

    const updateUserInfo = (data: IProfile): void => {
      setProfileState({ ...profileState, data });
    };

    useEffect(() => {
      if (profileState.data === undefined) refetchProfile();
    }, [userId]);

    useEffect(() => {
      if (!loading && shouldUpdate) {
        if (profileData) setProfileState({ data: profileData, isLoading: false, error: undefined });
        else if (error) setProfileState({ isLoading: false, error, data: null });
        setShouldUpdate(false);
      }
    }, [profileData, loading, error]);

    useEffect(() => {
      if (loading) setShouldUpdate(true);
    }, [loading]);

    const profileProps: ProfileProps = {
      profileState: { ...profileState },
      refetchProfile,
      updateUserProfile,
      updateUserInfo,
      cleanProfileState,
    };
    return <Component {...props} {...profileProps} />;
  });
}
export default withProfile;
