import { createContext } from "react";
import { recruiterFilterDefault, recruiterStateDefault } from "utils";
import { IRecruiterContextType, IRecruiterFiltersContext } from "../types/recruiter.interface";

export const RecruiterContext = createContext<IRecruiterContextType>({
  recruiterState: recruiterStateDefault,
  setRecruiterState: (): void => {},
});

export const RecruiterFilterContext = createContext<IRecruiterFiltersContext>({
  filters: recruiterFilterDefault,
  setFilters: (): void => {},
});
