import React from "react";
import { ClientProvider } from "state/clients";
import { ClientList } from "components";
import * as Styled from "./styles";

const Clients: React.FC = () => {
  return (
    <Styled.ClientsWrapper>
      <Styled.ClientHead>
        <Styled.MainHeading>
          <Styled.SubHeading>Clients</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.ClientHead>
      <ClientProvider>
        <ClientList />
      </ClientProvider>
    </Styled.ClientsWrapper>
  );
};

export default Clients;
