import { ITalentFilters, ITalentStateType } from "state/types/talent.interface";
import { FilterOptions, HocTalentStateProps } from "utils";

export const TalentTableColumns: { [key: string]: string } = {
  FullName: "Full Name",
  PhoneNo: "Phone No",
  Email: "Email",
  CreatedAt: "Signed Up",
  isPassDomain: "Domain Test",
  DTTime: "DT Time",
  isPassPS: "Problem Solving",
  PSTime: "PS Time",
  isPassFinalTech: "Final Technical",
  isPassHR: "HR",
  HiredAt: "Hired At",
};

export const ReferredByColumn = {
  referredBy: "Referred By",
  addFeedback: "Add Feedback",
  matchTalent: "Match Talent",
};

export const TalentReferredByColumns = {
  FullName: "Full Name",
  PhoneNo: "Phone No",
  Email: "Email",
  country: "Country",
};

export enum TalentTableMainColumns {
  FullName = "Full Name",
  PhoneNo = "Phone No",
  Email = "Email",
  Country = "Country",
  CreatedAt = "Signed Up",
  isPassDomain = "Domain Test",
  DTTime = "DT Time",
  isPassPS = "Problem Solving",
  PSTime = "PS Time",
  isPassHR = "HR",
  isPassFinalTech = "Final Technical",
  isHired = "Hired",
  HiredAt = "Hired At",
}
export enum PaymentTableMainColumns {
  Amount = "Amount (USD)",
  Date = "Date",
  Company = "Company",
  ClientName = "Client Name",
  Email = "Email",
  Status = "Status",
  DownloadLink = "Invoice",
}

export enum TalentTableHideColumns {
  PhoneNo = "Phone No",
  Country = "Country",
  CreatedAt = "Signed Up",
  isPassDomain = "Domain Test",
  DTTime = "DT Time",
  isPassPS = "Problem Solving",
  PSTime = "PS Time",
  isPassHR = "HR",
  isPassFinalTech = "Final Technical",
  isHired = "Hired",
  HiredAt = "Hired At",
}

export const TalentTableDisplayInitial = {
  PhoneNo: true,
  Country: false,
  CreatedAt: true,
  isPassDomain: true,
  DTTime: true,
  isPassPS: false,
  PSTime: true,
  isPassHR: true,
  isPassFinalTech: false,
  isHired: false,
  HiredAt: false,
};

export interface ITalTableDisplay {
  PhoneNo: boolean;
  Country: boolean;
  CreatedAt: boolean;
  isPassDomain: boolean;
  DTTime: boolean;
  isPassPS: boolean;
  PSTime: boolean;
  isPassHR: boolean;
  isPassFinalTech: boolean;
  isHired: boolean;
  HiredAt: boolean;
}

export enum TalentFilterType {
  ListTalent = "List Talent",
  SearchTalentProfile = "Search Talent Profile",
  SearchRecruiter = "Search Recruiter",
  GetReferrals = "Get Referrals",
  ReferralsByReferrer = "Referrals by Referrer",
  TalentByEmail = "Get Talent By Email",
}

export const talentTableHeading = "Talent List";

export interface TalentProps extends HocTalentStateProps {
  talentState: ITalentStateType;
  searchByFilter: (type?: TalentFilterType, text?: FilterOptions) => void;
}

export const talentStateDefault: ITalentStateType = {
  loading: true,
  currentPtr: 0,
};

export const talentFilterDefault: ITalentFilters = {
  filterType: "",
  filterOptions: {},
};
export const TalentByName = "Talent By Name";
export const TalentByEmail = "Talent By Email";
export const ReferredByRecruiter = "Referred By Recruiter";
export const PSPassed = "PS Test Passed";
export const HRPassed = "HR Interview Passed";
export const Reset = "Reset";

export const pageLimit = 10;
export const passed = "Pass";
export const failed = "Fail";
export const isPending = "Pending";
export const defaultDropdownValue = "neutral";
export const pageLimitLastPage = 300;
