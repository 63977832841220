import React, { useState } from "react";
import { IErrorStateType } from "@remotebase/constants";
import ErrorContext from "./error.context";

export const ErrorProvider: React.FC = (props) => {
  const [errorState, setErrorState] = useState<Array<IErrorStateType>>([]);

  const showError = (data: IErrorStateType): void => {
    setErrorState((current) => [...current, { id: Math.floor(Math.random() * 100), ...data }]);
  };

  const value = {
    errorState,
    setErrorState,
    showError,
  };

  return <ErrorContext.Provider value={value}>{props.children}</ErrorContext.Provider>;
};

export default ErrorProvider;
