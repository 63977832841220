import { SaveNewVersionModal } from "components/modals";
import { FC, useState } from "react";
import { ITalentProfileVersion } from "state/types";
import * as Styled from "../../styles";

interface Props {
  loading: boolean;
  saveProfileVersion: (profileVersion: ITalentProfileVersion) => void;
}
export const SaveAsNewVersion: FC<Props> = ({ loading, saveProfileVersion }) => {
  const [showSaveVersionModal, setShowSaveVersionModal] = useState(false);

  const toggleSaveVersionModal = (): void => {
    if (loading) return;
    setShowSaveVersionModal((prev) => !prev);
  };

  return (
    <>
      <Styled.SaveOptionsText onClick={toggleSaveVersionModal}>Save as New</Styled.SaveOptionsText>

      <SaveNewVersionModal
        open={showSaveVersionModal}
        close={toggleSaveVersionModal}
        saveProfileVersion={saveProfileVersion}
      />
    </>
  );
};

export default SaveAsNewVersion;
