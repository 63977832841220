import { FC, useState } from "react";
import { Company, Job, JobMatch } from "@remotebase/amplify-constants/API";
import { TalentJobMatchContext } from "./talentJobMatch.context";

const TalentJobMatchProvider: FC = ({ children }) => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [jobs, setJobs] = useState<{ [key: string]: Array<Job> }>({});
  const [jobMatches, setJobMatches] = useState<Array<JobMatch>>([]);
  const [jobsLoading, setJobsLoading] = useState(false);

  return (
    <TalentJobMatchContext.Provider
      value={{
        companies,
        setCompanies,
        jobs,
        setJobs,
        jobMatches,
        setJobMatches,
        jobsLoading,
        setJobsLoading,
      }}
    >
      {children}
    </TalentJobMatchContext.Provider>
  );
};

export default TalentJobMatchProvider;
