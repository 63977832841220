import { useMutation } from "@apollo/client";
import {
  GenerateRecruitersReportMutation,
  GenerateRecruitersReportMutationVariables,
  GenerateLeaderBoardReportMutation,
  GenerateLeaderBoardReportMutationVariables,
} from "@remotebase/amplify-constants/API";
import { generateRecruitersReport, generateLeaderBoardReport } from "graphql/mutations";
import { ReturnGenerateRecruitersReport, ReturnGenerateLeaderboardReport } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

export const useGenerateRecruitersReport = (): ReturnGenerateRecruitersReport => {
  const [generateReport, { data, loading: isLoading, error }] = useMutation<
    GenerateRecruitersReportMutation,
    GenerateRecruitersReportMutationVariables
  >(getQuery(generateRecruitersReport));

  return { generateReport, data, isLoading, error };
};

export const useGenerateLeaderBoardReport = (): ReturnGenerateLeaderboardReport => {
  const [generateLeaderBoard, { data, loading: isLoading, error }] = useMutation<
    GenerateLeaderBoardReportMutation,
    GenerateLeaderBoardReportMutationVariables
  >(getQuery(generateLeaderBoardReport));

  return { generateLeaderBoard, data, isLoading, error };
};
