import styled from "styled-components";

export const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClientHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SubHeading = styled.h3`
  font-size: 24px;
  color: #11142d;
  margin: 0 24px 0 0;
  font-weight: bold;
`;

export const Back = styled.p`
  text-decoration: underline;
  color: #0056b3;
  font-size: 0.9rem;
  margin-right: auto;

  &:hover {
    cursor: pointer;
  }
`;
