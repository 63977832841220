import { FC, useMemo } from "react";
import { AdminUserData } from "hooks/types";
import { UserRoles } from "@remotebase/amplify-constants/API";

interface Props {
  user: AdminUserData;
}

export const AdminUserTableRowData: FC<Props> = ({ user }) => {
  const roleLabels = useMemo(
    () => ({
      [UserRoles.ADMIN]: "Admin",
      [UserRoles.SUPERADMIN]: "Super Admin",
      [UserRoles.FINANCE]: "Finance",
      [UserRoles.TECHRECRUITER]: "Tech Recruiter",
      [UserRoles.CUSTOMERSUPPORT]: "Customer Support",
      [UserRoles.CUSTOMERSUCCESS]: "Customer Success",
      [UserRoles.SALES]: "Sales",
    }),
    [],
  );

  return (
    <tr>
      <td>{user.email}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{roleLabels[user.role]}</td>
    </tr>
  );
};

export default AdminUserTableRowData;
