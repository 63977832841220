import { FC } from "react";
import { withUpdateFeedback } from "state/talentFeedback";
import { IFeedbackProps } from "state/types/talentFeedback.interface";
import { Feedback } from "@remotebase/amplify-constants/API";
import { feedBackTypeText } from "utils";
import Slider from "components/slider";
import * as Styled from "../styles";

export const FeedbackSummary: FC<IFeedbackProps> = ({ feedbackState: { talentFeedback } }) => {
  return (
    <Styled.FeedbackSummaryContainer>
      <Styled.FeedbackSummaryTitle>Feedback Summary</Styled.FeedbackSummaryTitle>
      {talentFeedback.map(({ type, score }: Feedback) => (
        <Styled.FeedbackSummaryScoreCover key={type}>
          <Styled.FeedbackSummaryScore>{feedBackTypeText[type]}</Styled.FeedbackSummaryScore>
          <Slider value={score || 0} draggable={false} />
        </Styled.FeedbackSummaryScoreCover>
      ))}
    </Styled.FeedbackSummaryContainer>
  );
};

export default withUpdateFeedback(FeedbackSummary);
