import { TalentList } from "components";
import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withTalent } from "state/talent";
import { TalentFilterType, TalentProps, recruiterModalTitle } from "utils";
import * as Styled from "./styles";

interface Props {
  recruiterId: string;
  handleShow: (event) => void;
}
const RecruitersModal: FC<Props & TalentProps> = (props) => {
  const { recruiterId, handleShow, searchByFilter } = props;
  useEffect(() => {
    searchByFilter(TalentFilterType.GetReferrals, {
      searchText: recruiterId,
    });
  }, [recruiterId]);
  return (
    <Styled.TalentModal show={true} animation={false} scrollable={true}>
      <Modal.Header>
        <Modal.Title>{recruiterModalTitle}</Modal.Title>
        <Styled.CloseButton onClick={handleShow}>
          <p>x</p>
        </Styled.CloseButton>
      </Modal.Header>
      <Modal.Body>
        <TalentList shouldHideLastPageBtn />
      </Modal.Body>
    </Styled.TalentModal>
  );
};
export default withTalent(RecruitersModal);
