import { useLazyQuery, useMutation } from "@apollo/client";
import { getQuery } from "hooks/utils";
import { ReturnPropsCompanyJobsList, ReturnPropsJobsList, ReturnPropsMatchJob } from "hooks/types";
import {
  GetJobsByCompanyIDQuery,
  GetJobsByCompanyIDQueryVariables,
  JobMatch,
  ListJobsQuery,
  ListJobsQueryVariables,
  MatchTalentToJobsMutation,
  MatchTalentToJobsMutationVariables,
} from "@remotebase/amplify-constants/API";
import { getJobsByCompanyID, listJobs } from "graphql/queries";
import { matchTalentToJobs } from "graphql/mutations";

export const useListJobs = (): ReturnPropsJobsList => {
  const [getJobs, { data, loading }] = useLazyQuery<ListJobsQuery, ListJobsQueryVariables>(
    getQuery(listJobs),
  );

  const jobs = data?.listJobs?.items || null;
  return { getJobs, loading, jobs };
};

export const useListCompanyJobs = (): ReturnPropsCompanyJobsList => {
  const [getJobs, { data, loading }] = useLazyQuery<
    GetJobsByCompanyIDQuery,
    GetJobsByCompanyIDQueryVariables
  >(getQuery(getJobsByCompanyID));

  const jobs = data?.getJobsByCompanyID?.items || null;
  return { getJobs, loading, jobs };
};

export const useTalentJobMatch = (
  completionHandler: (jobMatches: Array<JobMatch | null>) => void,
): ReturnPropsMatchJob => {
  const [matchTalent, { loading, data }] = useMutation<
    MatchTalentToJobsMutation,
    MatchTalentToJobsMutationVariables
  >(getQuery(matchTalentToJobs), {
    onCompleted: (matches) =>
      completionHandler((matches.matchTalentToJobs?.data as Array<JobMatch | null>) || []),
  });

  return {
    matchTalent,
    loading,
    talentJobMatches: data?.matchTalentToJobs?.data as Array<JobMatch | null>,
  };
};
