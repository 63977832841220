import React from "react";
import { IAuthProps } from "@remotebase/constants";
import { setPasswordTitle, setPasswordFormTagline } from "../..";
import SetPasswordForm from "./setPasswordForm";
import { RemotebaseLogo } from "../commons";
import * as Styled from "./styles";

export const AuthCommonSetPass: React.FC<IAuthProps> = ({
  formProps: { isLoading, performAction },
}) => {
  return (
    <Styled.LoginWrapper>
      <RemotebaseLogo />
      <Styled.LoginHeading>{setPasswordTitle}</Styled.LoginHeading>
      <Styled.LoginDescription>{setPasswordFormTagline}</Styled.LoginDescription>
      <SetPasswordForm isLoading={isLoading} setPassword={performAction} />
    </Styled.LoginWrapper>
  );
};

export default AuthCommonSetPass;
