import { FC } from "react";
import { SidebarProps, sidebarCaptionTestId } from "utils";
import { withSidebar } from "state/sidebar";
import SidebarMenu from "./SidebarMenu";
import * as Styled from "./styles";

const SidebarList: FC<SidebarProps> = () => {
  return (
    <Styled.SidebarList>
      <Styled.SidebarGroup>
        <Styled.Caption data-testid={sidebarCaptionTestId}></Styled.Caption>
        <SidebarMenu />
      </Styled.SidebarGroup>
    </Styled.SidebarList>
  );
};

export default withSidebar(SidebarList);
