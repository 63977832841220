import { FC } from "react";
import * as Styled from "./styles";

interface Props {
  title: string;
  tags?: Array<string>;
  removeTag: (index: number) => void;
}

export const TagInput: FC<Props> = ({ tags, removeTag, title }) => {
  return (
    <Styled.TagInputCover tabIndex={0}>
      {tags?.map((tag, index) => (
        <Styled.TagInputSelectedTag
          key={`${title}-selected-tag-${index}`}
          onClick={(): void => removeTag(index)}
        >
          <p>{tag}</p>
        </Styled.TagInputSelectedTag>
      ))}
    </Styled.TagInputCover>
  );
};

export default TagInput;
