import styled from "styled-components";

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0.6;
`;

export const SliderValue = styled.div`
  flex: 0.3;
  margin-right: 0.8rem;
`;

export const NumberInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
`;

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
