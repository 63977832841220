import styled, { css, FlattenSimpleInterpolation } from "styled-components";

type StyledProps = FlattenSimpleInterpolation | string;

export const EditTalentBodyWrapper = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const EmploymentContainer = styled.div`
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1280px) {
    padding: 40px;
  }
`;

export const EditTalentBackIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 0px;
  cursor: pointer;
  &:hover,
  &:focus {
    & div {
      color: #000 !important;
      & svg {
        & path {
          stroke: #000;
        }
      }
    }
  }
`;

export const EditTalentBackIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 12px;
  background: transparent;
  cursor: pointer;
  & svg {
    width: 8px;
    height: 16px;
    & path {
      stroke: #1a0224;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #000;
      }
    }
  }
`;

export const EditTalentBackText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #4d526d;
  font-weight: 400;
  margin: 0px;
  &:hover,
  &:focus {
    color: #000;
  }
`;

export const EditTalentMainWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  background: #faf6ff;
  padding: 28px 0px;
  z-index: 2;
  &::before {
    position: absolute;
    content: "";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 20px 20px 5px 5px;
    background: url("./images/purple-lines.png") no-repeat center;
    background-size: cover;
    padding: 0px;
    margin: 0px;
    border: none;
    z-index: 1;
    height: 200px;
    overflow: hidden;

    @media only screen and (min-width: 576px) {
      padding: 32px 24px;
    }

    @media only screen and (min-width: 768px) {
      height: 266px;
    }
  }

  @media only screen and (min-width: 992px) {
    padding: 32px 35px;
  }
`;

// Talent profile Info

export const EditTalentInfoWrapper = styled.div`
  row-gap: 32px;
  width: 100%;
  background: #f8fced;
  border-radius: 16px;
  z-index: 2;
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    padding: 1.5rem 2rem;
  }
  @media only screen and (min-width: 1198px) {
    padding: 2.875rem 3.5rem;
  }
`;

export const EditTalentInfoTop = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (min-width: 520px) {
    flex-direction: row;
  }
`;

export const EditTalentInfoTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (min-width: 520px) {
    flex-direction: row;
  }
`;

export const EditTalentAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-top: 16px;
  /* margin-top: 20px;
  width: 100%; */
  @media only screen and (min-width: 520px) {
    width: auto;
    margin-right: 16px;
  }

  @media only screen and (min-width: 576px) {
    margin-right: 20px;
    margin-top: 0px;
  }

  @media only screen and (min-width: 1280px) {
    margin-right: 38px;
  }
`;

export const EditTalentAvatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 110px;
  height: 110px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  @media only screen and (min-width: 520px) {
    width: 90px;
    height: 90px;
  }

  @media only screen and (min-width: 576px) {
    width: 110px;
    height: 110px;
  }

  @media only screen and (min-width: 992px) {
    width: 137px;
    height: 137px;
  }
  &:hover,
  &:focus {
    & div {
      display: flex !important;
    }
  }
`;

export const EditTalentAvatarIcon = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  background: rgba(0, 0, 0, 0.5);

  & svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: #fff;
    }
  }
`;

export const UploadImgInput = styled.input`
  display: none;
`;

export const EditTalentAvatarBadge = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  min-height: 24px;
  margin: 0px;
  border: none;
  border-radius: 4px;
  background: #41b883;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 400;
  color: #fff;
  padding: 6px 8px;
  overflow: hidden;
  padding: 0px;
  margin: -8px 0px 0px 0px;
  border: none;
  @media only screen and (min-width: 520px) {
    min-width: 90px;
  }

  @media only screen and (min-width: 576px) {
    min-width: 110px;
    font-size: 14px;
    line-height: 18px;
  }

  @media only screen and (min-width: 992px) {
    min-width: 131px;
  }
`;

export const EditTalentDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  @media only screen and (min-width: 520px) {
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
  }
`;

export const EditTalentName = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #312a50;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin: 0px;
  text-align: center;

  @media only screen and (min-width: 520px) {
    text-align: left;
  }
  @media only screen and (min-width: 576px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 34px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 38px;
  }
`;

export const EditTalentDesignation = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #4d526d;
  margin: 9px 0px 0px 0px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  @media only screen and (min-width: 520px) {
    text-align: left;
  }
`;

export const EditTalentScore = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #312a50;
  margin: 17px 0px 0px 0px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  @media only screen and (min-width: 520px) {
    text-align: left;
  }

  & span {
    font-weight: 800;
  }
`;

export const EditTalentFeedbackBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  min-height: 47px;
  border: none;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  background: #3617ba;
  border-radius: 8px;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  font-weight: 500;
  color: #fff;
  padding: 4px 8px;
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  border: none;
  cursor: pointer;
  @media only screen and (min-width: 520px) {
    min-width: 110px;
  }
  @media only screen and (min-width: 576px) {
    min-width: 134px;
    font-size: 14px;
  }

  @media only screen and (min-width: 1200px) {
    min-width: 163px;
  }
  &:hover,
  &:focus {
    background: rgba(54, 23, 186, 0.8);
    border: none;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
`;

export const ExpertiseWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 34px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 24px 4px 20px;
`;

export const ExpertiseEditIcon = styled.div`
  position: absolute;
  inset: 13px 16px auto auto;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1rem;
  height: 1rem;
  width: 16px;
  height: 16px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  & svg {
    width: 13.4px;
    height: 13.4px;
    & path {
      stroke: #312a50;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #767676;
      }
    }
  }
`;

export const ExpertiseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

export const ExpertiseBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 20px;
  padding: 5px 15px;
  margin: 0px 11px 14px 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #312a50;
  &:last-child {
    margin: 0px 0px 14px 0px;
  }
`;

export const ProfileDetailWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  margin-top: 16px;
`;

export const ProfileDetailEditIcon = styled.div`
  position: absolute;
  inset: 13px 1px auto auto;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1rem;
  height: 1rem;
  cursor: pointer;

  & svg {
    width: 13.4px;
    height: 13.4px;
    & path {
      stroke: #312a50;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #767676;
      }
    }
  }
`;

export const EditTalentInfoIcon = styled.div`
  display: inline-flex;
  cursor: pointer;

  & svg {
    width: 16px;
    height: 16px;
    & path {
      stroke: #312a50;
    }
  }
`;

export const ProfileDetailList = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin: 0px auto;

  @media only screen and (min-width: 576px) {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    margin: 0px;
  }
`;

export const ProfileDetailItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  margin: 0px;
  padding: 0px;
  @media only screen and (min-width: 576px) {
    justify-content: center;
  }
  &:nth-child(2) {
    border-top: 0.5px solid rgba(217, 217, 217, 0.34);
    border-bottom: 0.5px solid rgba(217, 217, 217, 0.34);
    border-left: none;
    border-right: none;
    padding: 24px 0px;
    @media only screen and (min-width: 576px) {
      border-left: 0.5px solid rgba(217, 217, 217, 0.34);
      border-right: 0.5px solid rgba(217, 217, 217, 0.34);
      border-top: none;
      border-bottom: none;
      padding: 0px 10px;
    }
  }
  &:nth-child(3) {
    border-top: none;
    border-bottom: 0.5px solid rgba(217, 217, 217, 0.34);
    border-left: none;
    border-right: none;
    padding: 24px 0px;
    @media only screen and (min-width: 576px) {
      border-left: none;
      border-right: 0.5px solid rgba(217, 217, 217, 0.34);
      border-top: none;
      border-bottom: none;
      padding: 0px 10px;
    }
  }
`;

export const ProfileDetailContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  text-align: left;
  margin: 0px;
  @media only screen and (min-width: 576px) {
    margin: 0px auto;
  }
`;

export const ProfileDetailSbHd = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #312a50;
  margin: 0px 0px 12px 0px;
  @media only screen and (min-width: 576px) {
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 7px 0px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
    line-height: 19px;
    margin: 0px 0px 12px 0px;
  }
`;

export const ProfileDetailPara = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #312a50;
  margin: 0px;
  @media only screen and (min-width: 576px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
    line-height: 130%;
  }
`;

export const ProfileSummaryWrapper = styled.div`
  width: 100%;
  background: #f6efff;
  border-radius: 16px;
  margin-top: 24px;
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    padding: 1.5rem 2rem;
  }
  @media only screen and (min-width: 1198px) {
    padding: 2.875rem 3.5rem;
  }
`;

export const ProfileSummaryHd = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #312a50;
  margin: 0px;
`;

export const ProfileSummaryPara = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #312a50;
  margin: 16px 0px 20px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ProfileSummaryLink = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #312a50;
  margin: 0px;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #767676;
    text-decoration: underline;
  }
`;

export const ProfileSummaryTextArea = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #312a50;
  min-height: 122px;
  min-width: 122px;
  margin: 16px 0px 14px 0px;
  padding: 22px;
  @media only screen and (min-width: 576px) {
    padding: 22px 27px;
  }
`;

export const EditSummaryFooter = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SummarySaveBtn = styled.button<{ $cancel?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 99px;
  min-height: 33px;
  border: none;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  background: #3617ba;
  border-radius: 8px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  padding: 4px 8px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  border: none;
  cursor: pointer;
  margin-right: 10px;

  ${(props): StyledProps =>
    props.$cancel
      ? css`
          background: red;
        `
      : css``}

  &:hover,
  &:focus {
    background: rgba(54, 23, 186, 0.8);
    border: none;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
`;

export const CertificationDetailWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    padding: 1.5rem 2rem;
  }
  @media only screen and (min-width: 1198px) {
    padding: 2.875rem 3.5rem;
  }
`;

export const CertificationDetailTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
`;

export const CertificationDetailTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #312a50;
  width: 100%;
  text-transform: capitalize;
  margin: 0px;
  @media only screen and (min-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const CertificationDetailTopIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: #312a50;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #767676;
      }
    }
  }
`;

export const CertificationDetailList = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const CertificationDetailItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 28px;
`;

export const CertificationDetailIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #6fcf97;
  background: #219653;
  border: none;
  border-radius: 50%;
  margin-right: 12px;
  & svg {
    width: 21px;
    height: 28px;
    & path {
      fill: #6fcf97;
    }
  }
`;

export const CertificationDetailContent = styled.div`
  width: 100%;
`;

export const CertificationDetailSbHd = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #312a50;
  margin: 0px 0px 4px 0px;
  font-family: "Avenir LT Std";
`;

export const CertificationDetailPara = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #455a64;
  margin: 0px;
  font-family: "Avenir LT Std";

  & span {
    margin: 0px 6px 0px 0px;
  }
  & svg {
    width: 14px;
    height: 14px;
    & path {
      fill: #3615bd;
    }
  }
`;

export const EducationDetailWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    padding: 1.5rem 2rem;
  }
  @media only screen and (min-width: 1198px) {
    padding: 2.875rem 3.5rem;
  }
`;

export const EducationDetailTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
`;

export const EducationDetailTopIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: #312a50;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #767676;
      }
    }
  }
`;

export const EducationDetailTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #312a50;
  width: 100%;
  letter-spacing: 0.175px;
  text-transform: capitalize;
  margin: 0px;

  @media only screen and (min-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const EducationDetailList = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const EducationDetailItem = styled.div`
  width: 100%;
  margin-top: 28px;
`;

export const EducationDetailSbHd = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #312a50;
  margin: 0px 0px 8px 0px;
  font-family: "Avenir LT Std";
`;

export const EducationDetailPara = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #455a64;
  margin: 0px;
  font-family: "Avenir LT Std";
`;

export const JobHistoryWrapper = styled.div`
  row-gap: 32px;
  width: 100%;
  background: #fff;
  border-radius: 16px;
  z-index: 2;
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    padding: 1.5rem 2rem;
  }
  @media only screen and (min-width: 1198px) {
    padding: 2.875rem 3.5rem;
  }
`;

export const JobHistoryTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #312a50;
  width: 100%;
  text-transform: capitalize;
  margin: 0px;
  padding: 0px 0px 17px 0px;
  @media only screen and (min-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const JobHistoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const HistoryCardWrapper = styled.div`
  width: 100%;
  padding: 0px;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #f8fced;
  border-radius: 10px;
  padding: 24px;
`;

export const HistoryCardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HistoryCardTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  color: #212121;
  margin: 0px 10px 0px 0px;
  & span {
    font-weight: 400;
  }
`;

export const HistoryTitleText = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export const HistoryCardDate = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #312a50;
  margin: 10px 0px 8px 0px;
`;

export const HistoryCardPara = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #455a64;
  width: 100%;
  margin: 0px;
`;

export const HistoryCardBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  min-height: 24px;
  margin: 0px;
  border: none;
  background: rgba(65, 184, 131, 0.05);
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 400;
  color: #41b883;
  padding: 3px 6px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  &:hover,
  &:focus {
    background: rgba(65, 184, 131, 0.15);
    color: #41b883;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    border: none;
  }
`;

export const AddEmployementBtn = styled.div`
  padding: 8px 12px;
`;

export const HistoryEditIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0px;
  margin: 0px 0px 0px 6.8px;

  & svg {
    width: 10px;
    height: 10px;
    & path {
      stroke: #41b883;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #41b883;
      }
    }
  }
`;

export const MatchTalentWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 32px 20px;
  margin-top: 16px;
  @media only screen and (min-width: 1280px) {
    padding: 32px 40px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 37px 56px 52px 56px;
  }
`;

export const MatchTalentTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MatchTalentTopIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MatchTalentTopIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  &:last-child {
    margin-left: 16px;
    @media only screen and (min-width: 992px) {
      margin-left: 27px;
    }
  }

  & svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: #312a50;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #767676;
      }
    }
  }
`;

export const MatchTalentTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #312a50;
  width: 100%;
  text-transform: capitalize;
  margin: 0px;

  @media only screen and (min-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const MatchTalentList = styled.div`
  width: 100%;
`;

export const MatchTalentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 46px; ;
`;

export const MatchTalentSbHd = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #312a50;
  margin: 5.2px 0px 0px 0px;
  font-family: "Avenir LT Std";
`;

export const MatchTalentPara = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #455a64;
  margin: 16px 0px 0px 0px;
  font-family: "Avenir LT Std";
`;

export const MatchTalentImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  max-width: 109px;
  max-height: 109px;
  padding: 0px;
  margin: 0px auto;
  border: none;

  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

export const MatchTalentBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 144px;
  min-height: 44px;
  border: none;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-weight: 500;
  padding: 4px 8px;
  overflow: hidden;
  padding: 0px;
  margin: 16px auto 0px auto;
  background: transparent;
  border: 1px solid #312a50;
  color: #312a50;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #312a50;
    border: none;
    color: #ffffff;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
`;

export const DownloadResumeWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 32px 20px;
  margin-top: 16px;
  @media only screen and (min-width: 1280px) {
    padding: 38px 40px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 47px 56px 49px 56px;
  }
`;

export const DownloadResumeTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #312a50;
  width: 100%;
  text-transform: capitalize;
  margin: 0px;

  @media only screen and (min-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const DownloadResumePara = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #312a50;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;

export const DownloadResumeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 555px;
  width: 100%;
  margin-top: 31px;
  background: rgba(242, 242, 242, 0.72);
  border: 1px solid rgba(143, 143, 143, 0.25);
  border-radius: 4px;
  padding: 4px 12px 20px 12px;
  flex-wrap: wrap;
  @media only screen and (min-width: 430px) {
    flex-wrap: nowrap;
  }
  @media only screen and (min-width: 576px) {
    padding: 4px 20px 20px 20px;
  }
`;

export const DownloadResumeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const DownloadResumeInfoContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 0px;
  @media only screen and (min-width: 430px) {
    margin-right: 16px;
  }
`;

export const DownloadResumeImg = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  border: none;
  border-radius: 50%;

  @media only screen and (min-width: 576px) {
    margin: 0px 13px 0px 0px;
    width: 56px;
    height: 56px;
  }

  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`;

export const DownloadResumeName = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #312a50;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin: 0px;

  @media only screen and (min-width: 576px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const DownloadResumeDate = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4d526d;
  margin: 3px 0px 0px 0px;
  font-family: "Avenir LT Std";
  word-break: break-all;
  @media only screen and (min-width: 576px) {
    margin: 6px 0px 0px 0px;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const DownloadResumeBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  border: none;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-weight: 500;
  padding: 4px 8px;
  overflow: hidden;
  padding: 0px;
  margin: 16px 0px 0px 0px;
  background: transparent;
  border: 1px solid #312a50;
  color: #312a50;
  cursor: pointer;
  width: 100%;
  min-width: auto;

  @media only screen and (min-width: 430px) {
    width: auto;
    min-width: 143px;
  }

  &:hover,
  &:focus {
    background: #312a50;
    border: none;
    color: #ffffff;
    outline: none;
    box-shadow: none;
    text-decoration: none;

    & svg {
      & path {
        stroke: #fff !important;
      }
    }
  }
`;

export const DownloadResumeBtnIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0px;
  margin: 0px 0px 0px 12px;

  & svg {
    width: 14px;
    height: 14px;
    & path {
      stroke: #312a50;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #fff;
      }
    }
  }
`;

export const TalentProfileTopIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TalentProfileTopIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  background: #ffffff;
  border: 0.5px solid rgba(49, 42, 80, 0.64);
  border-radius: 4px;
  margin-left: 16px;
  @media only screen and (min-width: 992px) {
    margin-left: 20px;
  }
  & svg {
    width: 18px;
    height: 18px;
  }
  &:nth-child(2) {
    & svg {
      width: 12px;
      height: 15px;
    }
  }
  &:hover,
  &:focus {
    background: #c6c6c6;
  }
`;

// Talent profile Info
