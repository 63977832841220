/* eslint-disable no-nested-ternary */
import { TableShimmer } from "components";
import { FC, useEffect, useMemo, useState } from "react";
import { useRecruitersLeaderboard } from "hooks";
import { ShouldRender } from "@remotebase/components";
import { downloadAsCSV } from "@remotebase/helpers/downloadAsCSV";
import Styles from "./styles";
import LeaderboardTable from "./LeaderboardTable";
import LeaderBoardHeader from "./header";
import { recruitersColumns, marketingColumns, TableType } from "./utils";

const Leaderboard: FC = () => {
  const { data, loading, devCount, marketingData } = useRecruitersLeaderboard();
  const [selectedCountry, setSelectedCountry] = useState<string>("All");
  const [countriesList, setCountriesList] = useState<string[]>(["All"]);
  const memdata = useMemo(
    () =>
      selectedCountry === "All"
        ? data
        : data.filter((recruiter) => recruiter.country === selectedCountry),
    [data, selectedCountry],
  );
  const marketingMem = useMemo(() => marketingData, [marketingData]);
  const download = async (dataToDownload: TableType): Promise<void> => {
    downloadAsCSV({
      data:
        dataToDownload === TableType.Recruiters
          ? (memdata as unknown as Record<string, string>[])
          : (marketingMem as unknown as Record<string, string>[]),
    });
  };

  useEffect(() => {
    setCountriesList((current) => {
      const countries = data.map((item) => item.country);
      return [
        ...current,
        ...countries.filter((country, index) => countries.indexOf(country) === index),
      ];
    });
  }, [data]);

  return (
    <>
      <ShouldRender if={loading}>
        <>
          <TableShimmer />;
        </>
      </ShouldRender>
      <ShouldRender if={!loading}>
        <>
          <LeaderBoardHeader
            devCount={devCount}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            onClickDownload={download}
            countriesList={countriesList}
          />
          <Styles>
            <LeaderboardTable data={memdata} columns={recruitersColumns} />
            <br />
            <br />
            <b>Marketing Analytics</b>
            <LeaderboardTable showAll data={marketingMem} columns={marketingColumns} />
          </Styles>
        </>
      </ShouldRender>
    </>
  );
};

export default Leaderboard;
