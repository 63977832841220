import { useLazyQuery } from "@apollo/client";
import {
  ProfileVersionsForTalentQuery,
  ProfileVersionsForTalentQueryVariables,
} from "@remotebase/amplify-constants/API";
import { profileVersionsForTalent } from "@remotebase/amplify-constants/graphql/queries";
import { getQuery } from "../utils";
import { IFetchTalentProfileVersionReturnProps } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useFetchTalentProfileVersions = (): IFetchTalentProfileVersionReturnProps => {
  const [getVersionsByTalent, { data, loading, error }] = useLazyQuery<
    ProfileVersionsForTalentQuery,
    ProfileVersionsForTalentQueryVariables
  >(getQuery(profileVersionsForTalent));

  return {
    getVersionsByTalent,
    data: data?.profileVersionsForTalent?.items,
    nextToken: data?.profileVersionsForTalent?.nextToken,
    loading,
    error,
  };
};
