import React, { useState } from "react";
import { IRecruiterStateType } from "state/types/recruiter.interface";
import { recruiterFilterDefault, recruiterStateDefault } from "utils";
import { RecruiterContext, RecruiterFilterContext } from ".";

export const RecruiterProvider: React.FC = (props) => {
  const [recruiterState, setRecruiterState] = useState<IRecruiterStateType>(recruiterStateDefault);
  const [filters, setFilters] = useState(recruiterFilterDefault);

  return (
    <RecruiterContext.Provider value={{ recruiterState, setRecruiterState }}>
      <RecruiterFilterContext.Provider value={{ filters, setFilters }}>
        {props.children}
      </RecruiterFilterContext.Provider>
    </RecruiterContext.Provider>
  );
};

export default RecruiterProvider;
