import { FC } from "react";
import { Modal } from "react-bootstrap";
import { JobMatch } from "@remotebase/amplify-constants/API";
import { CrossIcon } from "assets/icons";
import { ITalentJobsInput } from "state/types/talentFeedback.interface";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "./styles";
import TableBody from "./TableBody";

interface Props {
  handleShow: () => void;
  matchedList: Array<JobMatch | ITalentJobsInput | null>;
  removeTalentMatch?: (jobID: string) => void;
}
export const TalentMatchModal: FC<Props> = (props) => {
  const { handleShow, removeTalentMatch, matchedList } = props;

  return (
    <Styled.TalentModal show={true} animation={false} scrollable={true}>
      <Modal.Header>
        <Modal.Title>Talent Match</Modal.Title>
        <Styled.CloseButton onClick={handleShow} aria-label={"close-modal-icon"}>
          <CrossIcon />
        </Styled.CloseButton>
      </Modal.Header>
      <Modal.Body>
        <Styled.TalentTable responsive>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Job Name</th>
              <th>Rockstar</th>
              <th>Published</th>
              <ShouldRender if={removeTalentMatch}>
                <th></th>
              </ShouldRender>
            </tr>
          </thead>
          <TableBody matchedList={matchedList} removeTalentMatch={removeTalentMatch} />
        </Styled.TalentTable>
      </Modal.Body>
    </Styled.TalentModal>
  );
};

export default TalentMatchModal;
