import withApolloProvider from "hooks/apollo/withApollo";
import { LoginPage, MaintenancePage, NotFound } from "pages";
import { Route, Switch } from "react-router-dom";
import { UnAuthRoutes as path } from "utils";

const UnAuthRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path={path.Maintenance} component={MaintenancePage} />
      <Route exact path={path.Login} component={LoginPage} />
      <Route exact path={path.NotFound} component={NotFound} />
    </Switch>
  );
};

export default withApolloProvider(UnAuthRouter);
