import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";

export const Graph = styled.div`
  display: flex;
  min-width: 1000px;
`;

export const DatePickerStyle = styled.div`
  max-width: 140px;
`;

export const DatePickerText = styled.div`
  padding: 10px;
  padding-bottom: 40px;
  padding-left: 40px;
`;

export const DateAlign = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: right;
  padding-top: 70px;
  padding-bottom: 30px;
`;

export const DateAlignGraph = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: right;
  padding-top: 0px;
  padding-bottom: 30px;
`;

export const Label = styled.label`
  padding: 0px 30px;
`;

export const Span = styled.span`
  padding-left: 5px;
`;

export const Cross = styled.span`
  color: red;
  font-size: 22px;
  font-weight: 900;
`;

export const GraphButton = styled(HighchartsReact)`
  &.highcharts-contextbutton {
    margin-bottom: 100px;
  }
`;

export const NoData = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
`;
