import {
  InvoiceBatch,
  InvoiceBatchStatus,
  ModelSortDirection,
  SortHashKey,
} from "@remotebase/amplify-constants/API";

const invoices = [
  {
    id: 1,
    customerName: "Customer 1",
    customerId: "C1",
    amount: 10000,
    billingMonth: "Jan-23",
    remarks: "This is a remark",
  },
  {
    id: 2,
    customerName: "Customer 2",
    customerId: "C2",
    amount: 2000,
    billingMonth: "Jan-23",
    remarks: "This is a remark",
  },
  {
    id: 1,
    customerName: "Customer 1",
    customerId: "C1",
    amount: 5000,
    billingMonth: "Jan-23",
    remarks: "This is a remark",
  },
  {
    id: 2,
    customerName: "Customer 2",
    customerId: "C2",
    amount: 6000,
    billingMonth: "Jan-23",
    remarks: "This is a remark",
  },
];

export const csvLink = {
  filename: "invoice-batch-template.csv",
  headers: [
    { label: "Customer Name", key: "customerName" },
    { label: "Customer Id", key: "customerId" },
    { label: "Amount (USD)", key: "amount" },
    { label: "Billing Month", key: "billingMonth" },
    { label: "Remarks", key: "remarks" },
  ],
  data: invoices,
};

export const getStatusBg = (status: InvoiceBatch["status"]): string => {
  switch (status) {
    case InvoiceBatchStatus.PENDING:
      return "light";
    case InvoiceBatchStatus.INVALID:
      return "warning";
    case InvoiceBatchStatus.READY:
      return "dark";
    case InvoiceBatchStatus.APPROVED:
      return "primary";
    case InvoiceBatchStatus.REJECTED:
      return "danger";
    case InvoiceBatchStatus.PROCESSING:
      return "info";
    case InvoiceBatchStatus.COMPLETE:
      return "success";
    case InvoiceBatchStatus.INCOMPLETE:
      return "secondary";
    default:
      return "secondary";
  }
};

export const actions = [
  {
    value: "view",
    label: "View",
  },
  {
    value: "approve",
    label: "Approve",
  },
  {
    value: "reject",
    label: "Reject",
  },
  {
    value: "process",
    label: "Process",
  },
];

export const LIST_BATCH_KEY = {
  variables: {
    sortHashKey: SortHashKey.BATCH_INVOICE,
    sortDirection: ModelSortDirection.DESC,
  },
};
