// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useLazyQuery } from "@apollo/client";
import {
  TalentByEmailQuery,
  TalentByEmailQueryVariables,
  GetTalentQuery,
  GetTalentQueryVariables,
  ListTalentsQuery,
  ListTalentsQueryVariables,
} from "@remotebase/amplify-constants/API";
import {
  ReturnPropsTalentList,
  ReturnPropsTalent,
  ReturnPropsTalentByEmail,
  TalentData,
} from "hooks/types";
import { getQuery } from "hooks/utils/helpers";
import {
  listTalents,
  talentByEmail as talentByEmailQ,
  getTalent as getTalentQ,
} from "@remotebase/amplify-constants/graphql/queries";

export const useTalentList = (): ReturnPropsTalentList => {
  const [getTalentList, { data: TalentList, loading }] = useLazyQuery<
    ListTalentsQuery,
    ListTalentsQueryVariables
  >(getQuery(listTalents));
  const talentList = (TalentList?.listTalents?.items as TalentData) || null;
  const nextToken = TalentList?.listTalents?.nextToken || null;

  return { getTalentList, talentsList: talentList, loading, nextToken };
};

export const useTalentByEmail = (): ReturnPropsTalentByEmail => {
  const [getTalentByEmail, { data: TalentList, loading }] = useLazyQuery<
    TalentByEmailQuery,
    TalentByEmailQueryVariables
  >(getQuery(talentByEmailQ));
  const talentByEmail = (TalentList?.talentByEmail?.items as TalentData) || null;
  const nextToken = TalentList?.talentByEmail?.nextToken || null;

  return { getTalentByEmail, talentByEmail, loading, nextToken };
};

export const useFetchTalent = (): ReturnPropsTalent => {
  const [getTalent, { data, loading }] = useLazyQuery<GetTalentQuery, GetTalentQueryVariables>(
    getQuery(getTalentQ),
  );
  const talent = data?.getTalent || null;

  return { getTalent, data: talent, loading };
};
