import { LeaderboardRecruiter, MarketingRecruiter } from "hooks/utils";
import { FC } from "react";
import { useTable, useSortBy } from "react-table";

type LeaderboardTableProps = {
  data: LeaderboardRecruiter[] | MarketingRecruiter[];
  columns: unknown[];
  showAll?: boolean;
};
const LeaderboardTable: FC<LeaderboardTableProps> = ({ data, columns, showAll }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                {/* eslint-disable-next-line no-nested-ternary */}
                <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {(showAll ? rows : rows.slice(0, 10)).map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default LeaderboardTable;
