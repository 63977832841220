import styled from "styled-components";

export const TalentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TalentsInner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

export const TalentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SubHeading = styled.h3`
  font-size: 24px;
  color: #11142d;
  font-weight: 600;
  margin: 0px 24px 0px 0px;
`;
