import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import styled from "styled-components";

export const TableContent = styled.div`
  padding: 20px 20px 60px;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  position: relative;
`;

export const MainTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
  max-width: 1460px;
  min-width: 860px;
  thead tr th {
    font-size: 14px;
    font-weight: 500;
    color: #474d58;
    padding: 10px 16px;
    border-top: 1px solid #e5ecff;
    border-bottom: 1px solid #e5ecff;
    word-break: break-word;
  }
  thead tr th:first-child {
    border-radius: 5px 0px 0px 5px;
    border-left: 1px solid #e5ecff;
  }
  thead tr th:last-child {
    border-radius: 0px 5px 5px 0px;
    border-right: 1px solid #e5ecff;
  }
  tbody tr td,
  tfoot tr td {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    border: none;
    padding: 10px 15px;
    word-break: break-word;
  }
  tbody tr:first-child td {
    padding-top: 20px;
  }
  thead tr th:nth-of-type(1),
  tbody tr td:nth-of-type(1) {
    min-width: 146px;
    width: 10%;
  }
  thead tr th:nth-of-type(2),
  tbody tr td:nth-of-type(2) {
    min-width: 132px;
    display: ${({ col }): string => (col.ProfilesCreated ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(3),
  tbody tr td:nth-of-type(3) {
    min-width: 132px;
    display: ${({ col }): string => (col.ProfilesCompleted ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(4),
  tbody tr td:nth-of-type(4) {
    min-width: 132px;
    display: ${({ col }): string => (col.Hired ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(5),
  tbody tr td:nth-of-type(5) {
    min-width: 132px;
    display: ${({ col }): string => (col.Recruiters ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(6),
  tbody tr td:nth-of-type(6) {
    min-width: 132px;
    display: ${({ col }): string => (col.DomainTestsTaken ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(7),
  tbody tr td:nth-of-type(7) {
    min-width: 132px;
    display: ${({ col }): string => (col.DomainTestsPassed ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(8),
  tbody tr td:nth-of-type(8) {
    min-width: 132px;
    display: ${({ col }): string => (col.PSTestsTaken ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(9),
  tbody tr td:nth-of-type(9) {
    min-width: 132px;
    display: ${({ col }): string => (col.PSTestsPassed ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(10),
  tbody tr td:nth-of-type(10) {
    min-width: 132px;
    display: ${({ col }): string => (col.ReferredByRecruiters ? "show" : "none")};
    width: 9%;
  }
  thead tr th:nth-of-type(11),
  tbody tr td:nth-of-type(11) {
    min-width: 100px;
    width: 9%;
  }
  tbody tr td:nth-of-type(11) {
    cursor: pointer;
  }
`;

export const DropdownDashboard = styled(Dropdown)`
  width: 300px;
`;

export const DropdownLabel = styled.label`
  padding: 8px 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  font-weight: normal !important;
`;

export const DropdownBtn = styled(DropdownButton)`
  width: 250px;
  // overflow-y: scroll;
  display: inline-flex;
  margin-bottom: 10px;
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #dddfe2;
      color: #474d58;
    }
  }
  & .dropdown-toggle {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    padding: 4px 12px;
    min-width: 120px;
    min-height: 40px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #dddfe2;
    font-family: "Poppins", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #fff !important;
      border: 1px solid #dddfe2 !important;
      color: #474d58 !important;
    }
    @media only screen and (max-width: 575px) {
      width: 100%;
    }
  }
  & .dropdown-menu {
    border-radius: 6px;
    padding: 6px 0;
    max-height: 200px;
    overflow-y: scroll;
    @media only screen and (max-width: 1199px) {
      right: 0 !important;
      left: initial !important;
    }
    @media only screen and (max-width: 575px) {
      width: 100%;
      left: 0 !important;
    }
  }
  & .dropdown-item {
    color: #474d58;
    font-size: 14px;
    padding: 6px 12px;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #dcdfe2;
`;
