import styled from "styled-components";

export const ResetPassEmptyWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 0.85rem 2rem 0.85rem 1.5rem;
  height: 214px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  gap: 0.5rem;
`;

export const EmptyParagraph = styled.p`
  font-size: 0.75rem;
  color: #7b7b7b;
  margin-bottom: 0;
`;

export const EmptyHeader = styled.h4`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
`;
