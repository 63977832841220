import React, { FC } from "react";
import { ShouldRender } from "@remotebase/components";
import { UseFormReturn } from "react-hook-form";
import * as Styled from "./styles";
import { alphanumericCharacters, PasswordLength } from "../../utils";

interface Props {
  handler: UseFormReturn;
  title: string;
  small?: boolean;
  placeholder?: string;
  label?: string;
  showGenerate?: boolean;
}
export const FormPassword: FC<Props> = ({
  handler,
  title,
  small,
  placeholder,
  label,
  showGenerate,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = handler;

  const generatePassword = (): void => {
    let password = "";
    for (let i = 0; i < PasswordLength; i += 1)
      password += alphanumericCharacters[Math.floor(Math.random() * alphanumericCharacters.length)];

    setValue(title, password);
  };

  return (
    <div>
      <Styled.InputGroup $small={!!small}>
        <ShouldRender if={!small}>
          <Styled.InputLabel>{label}</Styled.InputLabel>
        </ShouldRender>
        <Styled.InputBtnCover>
          <Styled.Input type={"text"} placeholder={placeholder} {...register(title)} />
          <ShouldRender if={showGenerate}>
            <Styled.InputBtn onClick={generatePassword}>Generate</Styled.InputBtn>
          </ShouldRender>
        </Styled.InputBtnCover>
      </Styled.InputGroup>
      <ShouldRender if={errors[title]?.message}>
        <Styled.ErrorMessage>{errors[title]?.message}</Styled.ErrorMessage>
      </ShouldRender>
    </div>
  );
};

export default FormPassword;
