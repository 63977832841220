export const loginErrorHeading = "Login Error";
export const authFailedErrorHeading = "Authentication Error";
export const authFailedErrorMessage = "Your email is associated with a non ADMIN Profile";
export const feedbackHeading = "Feedback ";
export const talentMatchRemoveMessage = "Talent Match removed";
export const clientCreationError = "Client creation error";
export const accountCreationError = "Account creation error";
export const accountCreationFailed = "Error occurred creating account";
export const JobNotSelectedError = "Please select a job";
export const generateRecruitersReportError = "Sending generate report request error";
export const generateLeaderBoardReportError = "Sending generate leader board request error";
