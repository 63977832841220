import { ShouldRender } from "@remotebase/components";
import { ArrowLeftIcon } from "assets/icons";
import { TalentFeedback } from "components";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TalentFetchProvider, FeedbackProvider } from "state/talentFeedback";
import * as Styled from "./styles";

const TalentsFeedback: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const goBackHandler = (): void => {
    goBack();
  };
  return (
    <Styled.TalentsWrapper>
      <Styled.TalentHead>
        <Styled.BackLink onClick={goBackHandler}>
          <Styled.BackLinkIcon>
            <ArrowLeftIcon />
          </Styled.BackLinkIcon>
          <Styled.BackLinkText>Back</Styled.BackLinkText>
        </Styled.BackLink>
      </Styled.TalentHead>
      <TalentFetchProvider>
        <FeedbackProvider>
          <ShouldRender if={id}>
            <TalentFeedback talentId={id} />
          </ShouldRender>
        </FeedbackProvider>
      </TalentFetchProvider>
    </Styled.TalentsWrapper>
  );
};

export default TalentsFeedback;
