import { FC } from "react";
import { useProfileVersionContext } from "state/profileVersion";
import { ITalentProfileVersion } from "state/types";
import * as Styled from "../../styles";

interface Props {
  loading: boolean;
  saveProfileVersion: (profileVersion: ITalentProfileVersion) => void;
}

export const JustSaveVersion: FC<Props> = ({ saveProfileVersion, loading }) => {
  const { selectedVersion } = useProfileVersionContext();
  const justSave = (): void => {
    if (!selectedVersion?.id || loading) return;
    if (selectedVersion) {
      saveProfileVersion(selectedVersion);
    }
  };
  return (
    <>
      <Styled.SaveOptionsText onClick={(): void => justSave()} $disabled={!selectedVersion?.id}>
        Just save
      </Styled.SaveOptionsText>
    </>
  );
};

export default JustSaveVersion;
