import {
  FullPageLoader,
  ShouldRender,
  ViewProfile,
  ProfileResumeDownload,
  ProfileFeedbackDetails,
} from "@remotebase/components";
import { FC, Fragment } from "react";
import { withFetchTalent } from "state/talentFeedback";
import { IFetchTalentFeedbackProps } from "state/types/talentFeedback.interface";
import * as Styled from "./styles";
import { ProfileSpecs, ResetAndPassTests } from "./components";

interface Props {
  talentId: string;
}

const TalentsFeedback: FC<Props & IFetchTalentFeedbackProps> = ({
  talentState: { fetchFeedbackLoading, data },
}) => {
  return (
    <Fragment>
      <ShouldRender if={fetchFeedbackLoading}>
        <FullPageLoader />
      </ShouldRender>
      <ShouldRender if={!fetchFeedbackLoading}>
        <Fragment>
          {/* TO DO */}
          {/* <ProfilePageHeader /> */}
          <ViewProfile data={data} />

          <Styled.ProfilePageBody>
            {/* All of these components should be created in shared components
            except ProfileFeedbackDetails */}

            <ProfileFeedbackDetails data={data}>
              <ProfileSpecs />
            </ProfileFeedbackDetails>

            <ResetAndPassTests />
            {/* TO DO. [Waiting for dynamic data] */}
            {/* <ProfileEmploymentHistory data={data} /> */}

            {/* TO DO [Waiting for dynamic data] */}
            {/* <ProfilePortfolio /> */}

            {/* TO DO */}
            <ProfileResumeDownload data={data} />
          </Styled.ProfilePageBody>
        </Fragment>
      </ShouldRender>
    </Fragment>
  );
};

export default withFetchTalent<Props>(TalentsFeedback);
