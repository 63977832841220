import styled from "styled-components";
import { Button } from "react-bootstrap";

export const FooterContainer = styled.form`
  margin-top: auto;
`;

export const ChatFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  margin: auto 0px 0px 0px;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  min-height: 86px;
  @media only screen and (min-width: 576px) {
    padding: 20px 45px 20px 38px;
  }
`;

export const ChatFooterInput = styled.input`
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  color: rgba(52, 64, 84, 0.8);
  font-weight: 400;
  background: transparent;
  border: none;
  border-radius: 0px;
  padding: 0px;
  width: 100%;
  height: 40px;
  font-family: "Avenir LT Std";

  &:focus {
    outline: none;
    box-shadow: none;
    background: transparent;
    border: none;
    border-radius: 0px;
  }

  &::-webkit-input-placeholder {
    color: rgba(52, 64, 84, 0.4);
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-family: "Avenir LT Std";
  }
  &:-ms-input-placeholder {
    color: rgba(52, 64, 84, 0.4);
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-family: "Avenir LT Std";
  }
  &::placeholder {
    color: rgba(52, 64, 84, 0.4);
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-family: "Avenir LT Std";
  }
`;

export const ChatFooterBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;
`;

export const ChatFooterBtn = styled(Button)`
  font-size: 16px;
  line-height: 20px;
  position: relative;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  border: none;
  border-radius: 4px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;
  background: #eff4ff;
  color: #21214e;
  margin-right: 10px;
  @media only screen and (min-width: 375px) {
    margin-right: 16px;
  }
  @media only screen and (min-width: 576px) {
    margin-right: 24px;
  }
  @media only screen and (min-width: 992px) {
    margin-right: 16px;
  }
  @media only screen and (min-width: 1200px) {
    margin-right: 24px;
  }
  &:last-child {
    margin-right: 0px;
  }
  & {
    i {
      &::before {
        color: #21214e;
      }
    }
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active,
  &:not(.btn-check).btn:hover,
  &.btn:focus-visible,
  &:hover,
  &:focus {
    background: #d2e0fd;
    border: none;
    box-shadow: none;
    color: #21214e;
    & {
      i {
        &::before {
          color: #21214e;
        }
      }
    }
  }
  &.send__btn {
    background: #3617ba;
    color: #ffffff;
    & {
      i {
        &::before {
          color: #ffffff;
        }
      }
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active,
    &:not(.btn-check).btn:hover,
    &.btn:focus-visible,
    &:hover,
    &:focus {
      background: #5138ed;
      border: none;
      box-shadow: none;
      color: #ffffff;
      & {
        i {
          &::before {
            color: #ffffff;
          }
        }
      }
    }
  }
`;
