import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const CustomModal = styled(Modal)`
  padding: 2rem;
  .modal-dialog {
    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 8px;
    border: none;
    overflow: inherit;
  }
  .modal-title {
    margin: 0 auto;
  }
  .modal-header {
    padding: 2rem;
    align-items: center;
    border-bottom: none;
    justify-content: normal;
    position: relative;
    @media only screen and (max-width: 767px) {
      padding: 1.5rem;
    }
  }
  .modal-title {
    font-size: 1.35rem;
    color: #11142d;
    font-family: "poppins600", sans-serif;
  }
  .modal-body {
    padding: 0 2rem 3rem;
    @media only screen and (max-width: 767px) {
      padding: 30px 24px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
`;

export const ModalHeaderIcon = styled.button`
  position: absolute;
  right: -4rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: 999;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background: rgba(255, 255, 255, 0.3);
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }

  & svg {
    width: 1rem;
    height: 22px;
  }
`;
