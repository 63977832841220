import { FC, useCallback, useEffect, useState } from "react";
import { FormButton } from "components/inputs";
import { ButtonType, MatchJobForm, MatchJobSchema } from "utils";
import { useProfileVersionContext } from "state/profileVersion";
import { useTalentJobMatchContext } from "state/talentjobMatch";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveJobMatchData } from "hooks/types";
import { useCreateOrUpdateTalentJobMach } from "hooks";
import { useErrorContext } from "state/error";
import { matchProcess, matchStage } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "../styles";
import { CompaniesSelect, JobsSelect } from "./components";

// eslint-disable-next-line import/prefer-default-export
export const NewTalentJobMatch: FC = () => {
  const [matching, setMatching] = useState(false);

  const {
    createOrUpdateJobMatch,
    loading: createLoading,
    jobMatch,
  } = useCreateOrUpdateTalentJobMach();

  const { showError } = useErrorContext();
  const { selectedVersion } = useProfileVersionContext();
  const { jobMatches, setJobMatches } = useTalentJobMatchContext();

  const formHook = useForm({ mode: "onChange", resolver: yupResolver(MatchJobSchema) });
  const { watch, handleSubmit, reset } = formHook;
  const [companyID] = watch([MatchJobForm.companyID]);

  // publish job match
  const publishJobMatch = useCallback(
    (payload: SaveJobMatchData): void => {
      const talentProfileVersionID = selectedVersion?.id;
      const talentID = selectedVersion?.talentID;
      const { jobID, isRockstar } = payload;

      if (!talentProfileVersionID || !talentID) return;

      // check if match exist already
      const exists = jobMatches.find(
        (match) => match.talentID === talentID && match.jobID === jobID,
      );

      if (exists) {
        showError?.({ title: "Failed", message: "Talent already matched with selected job" });
        return;
      }

      createOrUpdateJobMatch({
        variables: {
          input: {
            jobID,
            isRockstar,
            isPublished: false,
            talentID,
            talentProfileVersionID,
            lastActivity: new Date().toISOString(),
            stage: matchStage.referred,
            process: matchProcess.manual,
          },
        },
      });

      setMatching(true);
    },
    [selectedVersion?.id, selectedVersion?.talentID, jobMatches],
  );

  // Clear form and update job matches list
  useEffect(() => {
    if (matching && !createLoading && jobMatch) {
      setMatching(false);

      setJobMatches((prev) => [...prev, jobMatch]);
      showError?.({ title: "Success", message: "Job match created successfully" });
      reset();
    }
  }, [matching, createLoading, jobMatch]);

  return (
    <Styled.NewTalentForm onSubmit={handleSubmit(publishJobMatch)}>
      <Styled.InputCover>
        <Styled.SelectInput>
          <CompaniesSelect handler={formHook} title={MatchJobForm.companyID} />
        </Styled.SelectInput>
        <Styled.SelectInput>
          <JobsSelect handler={formHook} title={MatchJobForm.jobID} companyID={companyID} />
        </Styled.SelectInput>
      </Styled.InputCover>
      <Styled.SubmitBtnCover>
        <FormButton
          type={ButtonType.SUBMIT}
          label={"Create Match"}
          isLoading={matching}
          disabled={!selectedVersion?.id}
        />
        <ShouldRender if={!selectedVersion?.id}>
          <Styled.TalentBadge>
            <Styled.TalentBadgeIcon>🔔</Styled.TalentBadgeIcon>
            <Styled.TalentBadgePara>
              Talent can't be matched using original profile, please select or create a profile
              version to match.
            </Styled.TalentBadgePara>
          </Styled.TalentBadge>
        </ShouldRender>
      </Styled.SubmitBtnCover>
    </Styled.NewTalentForm>
  );
};
