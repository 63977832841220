import styled from "styled-components";

export const ChatWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  padding: 0px;
`;
export const ChatInner = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-height: calc(100vh - 85px);
  min-height: calc(100vh - 85px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  padding: 0px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (min-width: 576px) {
    max-height: calc(100vh - 93px);
    min-height: calc(100vh - 93px);
  }
`;
