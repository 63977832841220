import React, { useContext, useEffect } from "react";
import withApolloProvider from "hooks/apollo/withApollo";
import { ConversationResponse, MessageResponse, PersonInfo } from "API";
import { AuthContextType } from "state/types";
import { AuthContext } from "state/auth";
import { ProfileContext } from "state/profileSteps";
import { useHistory } from "react-router-dom";
import { AuthRoutes, getDyanamicPath, initialCurrentUser, initialRecentMessages } from "utils";
import { CurrentChatUserContext, RecentMessagesContext } from "./chat.context";
import { getReadBy } from "./chat.helper";
import { CurrentChatUserProps, IChatMessage, IMyInfo } from "./chat.interface";

export function withChatUser<T>(Component: React.FC<T & CurrentChatUserProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const history = useHistory();
    const {
      authState: { isLoggedIn },
    } = useContext<AuthContextType>(AuthContext);
    const {
      profileState: { data },
    } = useContext(ProfileContext);
    const { recentMessages, setRecentMessages } = useContext(RecentMessagesContext);
    const { currentUser, setCurrentUser } = useContext(CurrentChatUserContext);

    const addToRecentMessages = (message?: MessageResponse | null): void => {
      if (message?.conversationId) {
        const isRead = message ? getReadBy(message, data?.id || "") : true;
        const newChatMsg: IChatMessage = {
          conversationId: message?.conversationId,
          message,
          isReadConversation: isRead,
        };
        setRecentMessages((previous) => [...previous, newChatMsg]);
      }
    };

    const getParticipant = (talent?: ConversationResponse | null): PersonInfo | null | undefined =>
      talent?.conversationParticipants?.find((item) => item?.id !== data?.id);

    const openChat = (convoId?: string | null, user?: PersonInfo | null): void => {
      if (convoId) history.push(getDyanamicPath(AuthRoutes.ChatWithId, convoId));
      if (user) setCurrentUser(user);
    };

    const getMyInfo = (): IMyInfo => ({
      id: data?.id,
      fullName: data?.fullName,
      email: data?.email,
      phone: data?.phone,
      role: data?.role,
    });

    useEffect(() => {
      if (!isLoggedIn) {
        setRecentMessages(initialRecentMessages);
        setCurrentUser(initialCurrentUser as unknown as PersonInfo);
      }
    }, [isLoggedIn]);

    const chatProps: CurrentChatUserProps = {
      currentUser,
      recentMessages,
      openChat,
      getMyInfo,
      getParticipant,
      addToRecentMessages,
      setRecentMessages,
    };

    return <Component {...props} {...chatProps} />;
  });
}
export default withChatUser;
