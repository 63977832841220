export enum UnAuthRoutes {
  Login = "/login",
  NotFound = "/404",
  Maintenance = "/maintenance",
}

export enum AuthRoutes {
  Dashbord = "/dashboard",
  Talents = "/talents",
  TalentProfileVersion = "/talentProfileVersion/:id",
  TalentsFeedback = "/talentsFeedback/:id",
  Recruiters = "/recruiters",
  RecruitersReports = "/recruiters-reports",
  RecruitersPerformance = "/generate-recruiters-report",
  RecruitersLeaderboard = "/recruiters-leaderboard",
  RecruitersNewLeaderboard = "/recruiters-new-leaderboard",
  ReferredBy = "/referred-by",
  Clients = "/clients",
  AdminUsers = "/users",
  CreateAdminUser = "/users/new",
  CreateClient = "/clients/new",
  Logout = "/logout",
  Chats = "/chats",
  ChatWithId = "/chat/:conversationId",
  NotFound = "/404",
  Invoices = "/invoices",
  PaymentsHistory = "/paymentshistory",
}

export const editRouteStr = "talentsFeedback";
