import styled, { css, keyframes } from "styled-components";
import { Button } from "react-bootstrap";
import { StyledProps } from "utils";
import { PropType } from "@remotebase/components/loader/btnLoader";

export const Btn = styled(Button)<{ $small?: boolean }>`
  background-color: #3617ba !important;
  border: none;
  box-shadow: none;
  position: relative;
  border-radius: 0.5rem;
  display: flex;
  column-gap: 0.85rem;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    transition: all 0.2s;
    background-color: #3617ba !important;
    box-shadow: none !important;
  }

  ${(props): StyledProps =>
    props.$small
      ? css`
          font-size: 0.8rem;
          padding: 0.5rem 1.25rem;
        `
      : css`
          font-size: 0.9rem;
          padding: 0.75rem 1.5rem;
        `}
`;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div<PropType>`
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  opacity: 1;
  transition-delay: 200ms;
  width: 1.1rem;
  height: 1.1rem;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  ${(props): StyledProps =>
    props.color === "#4c40f7"
      ? css`
          border-top-color: #4c40f7;
        `
      : css``}
`;

export default Btn;
