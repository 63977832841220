import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import {
  getMessagesByConversation,
  getMessagesCountByConversationId,
  getAllConversations,
} from "graphql/queries";
import { convertMessageToMessageResponse, getQuery } from "hooks/utils";
import {
  UseGetTalentChatProps,
  UseSendMessageProps,
  UseGetConvoMessageProps,
  UseMarkAsReadProps,
  UseGetTotalCountProps,
  UseCreateChatResponse,
} from "hooks/types";
import {
  GetAllConversationsQuery,
  GetAllConversationsQueryVariables,
  SendMessageMutation,
  SendMessageMutationVariables,
  GetMessagesByConversationQueryVariables,
  GetMessagesByConversationQuery,
  MarkMessageAsReadMutationVariables,
  MarkMessageAsReadMutation,
  GetMessagesCountByConversationIdQuery,
  GetMessagesCountByConversationIdQueryVariables,
  MessageResponse,
  BuildConversationMutation,
  BuildConversationMutationVariables,
} from "API";
import { buildConversation, markMessageAsRead, sendMessage } from "graphql/mutations";
import { useState, useEffect } from "react";
import { onCreateMessage } from "@remotebase/amplify-constants/graphql/subscriptions";

export const useGetAllChatList = (): UseGetTalentChatProps => {
  const [getMoreChats, { data, loading, error }] = useLazyQuery<
    GetAllConversationsQuery,
    GetAllConversationsQueryVariables
  >(getQuery(getAllConversations));
  const res = data?.getAllConversations?.data || null;
  const nextToken = data?.getAllConversations?.nextToken || null;
  return {
    getMoreChats,
    data: res,
    nextToken,
    loading,
    error,
  };
};

export const useSendMessage = (): UseSendMessageProps => {
  const [sendNewMessage, { data, loading, error }] = useMutation<
    SendMessageMutation,
    SendMessageMutationVariables
  >(getQuery(sendMessage));
  const res = data?.sendMessage?.data || null;
  return {
    sendNewMessage,
    data: res,
    loading,
    error,
  };
};

export const useGetConvoMessages = (): UseGetConvoMessageProps => {
  const [getMessages, { data, loading, error }] = useLazyQuery<
    GetMessagesByConversationQuery,
    GetMessagesByConversationQueryVariables
  >(getQuery(getMessagesByConversation));
  const res = data?.getMessagesByConversation?.data || null;
  const nextToken = data?.getMessagesByConversation?.nextToken;
  return {
    getMessages,
    data: res,
    loading,
    error,
    nextToken,
  };
};

export const useGetPollingMessages = (): UseGetConvoMessageProps => {
  const [getMessages, { data, loading, error, startPolling, stopPolling }] = useLazyQuery<
    GetMessagesByConversationQuery,
    GetMessagesByConversationQueryVariables
  >(getQuery(getMessagesByConversation));
  const res = data?.getMessagesByConversation?.data || null;
  const nextToken = data?.getMessagesByConversation?.nextToken || null;
  return {
    startPolling,
    stopPolling,
    getMessages,
    data: res,
    loading,
    error,
    nextToken,
  };
};

export const useMarkAsRead = (): UseMarkAsReadProps => {
  const [markAsRead, { data, loading, error }] = useMutation<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >(getQuery(markMessageAsRead));
  const res = data?.markMessageAsRead?.data || null;
  return {
    markAsRead,
    data: res,
    loading,
    error,
  };
};

export const useGetTotalChatCount = (): UseGetTotalCountProps => {
  const [getTotalMsgCount, { data, loading, error }] = useLazyQuery<
    GetMessagesCountByConversationIdQuery,
    GetMessagesCountByConversationIdQueryVariables
  >(getQuery(getMessagesCountByConversationId));
  const res = data?.getMessagesCountByConversationId?.data || null;
  return {
    getTotalMsgCount,
    data: res,
    loading,
    error,
  };
};

export const useChatSubscription = (convoId: string): MessageResponse | null => {
  const { data, loading } = useSubscription(getQuery(onCreateMessage), {
    variables: { conversationId: convoId },
  });

  const [newMessage, setNewMessage] = useState<MessageResponse | null>(null);

  useEffect(() => {
    if (data && !loading) {
      setNewMessage(convertMessageToMessageResponse(data.onCreateMessage));
    }
  }, [data, loading]);

  return newMessage;
};

export const useCreateChat = (): UseCreateChatResponse => {
  const [createChat, { data, loading, error }] = useMutation<
    BuildConversationMutation,
    BuildConversationMutationVariables
  >(getQuery(buildConversation));

  return {
    createChat,
    response: data?.buildConversation?.data,
    apiInProgress: loading,
    error,
  };
};
