import { ShouldRender } from "@remotebase/components";
import { ArrowLeftIcon, ArrowRightIcon, DoubleArrowRightIcon } from "assets/icons";
import { FC } from "react";
import { Pagination } from "react-bootstrap";
import * as Styled from "../styles";

interface Props {
  nextBtnDisable: boolean;
  prevBtnDisable: boolean;
  prevBtnClick: () => void;
  nextBtnClick: () => void;
  lastPageBtn?: () => void;
  shouldHideLastPageBtn?: boolean;
}

export const TableFooter: FC<Props> = ({
  nextBtnDisable,
  prevBtnDisable,
  prevBtnClick,
  nextBtnClick,
  lastPageBtn,
  shouldHideLastPageBtn,
}) => {
  return (
    <ShouldRender if={!nextBtnDisable || !prevBtnDisable}>
      <tfoot>
        <tr>
          <td colSpan={8}>
            <Styled.PaginationTable>
              <Pagination.Item onClick={prevBtnClick} disabled={prevBtnDisable}>
                <ArrowLeftIcon />
              </Pagination.Item>
              <Pagination.Item onClick={nextBtnClick} disabled={nextBtnDisable}>
                <ArrowRightIcon />
              </Pagination.Item>
              <ShouldRender if={lastPageBtn && !shouldHideLastPageBtn}>
                <Pagination.Item onClick={lastPageBtn} disabled={nextBtnDisable}>
                  <DoubleArrowRightIcon />
                </Pagination.Item>
              </ShouldRender>
            </Styled.PaginationTable>
          </td>
        </tr>
      </tfoot>
    </ShouldRender>
  );
};

export default TableFooter;
