import { Auth } from "aws-amplify";
import { ProfileProps } from "utils";
import { FC, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withProfile } from "state/profileSteps";

export const LogoutPage: FC<ProfileProps> = ({ cleanProfileState }) => {
  const history = useHistory();
  useEffect(() => {
    Auth.signOut();
    history.push("/login");
    cleanProfileState();
  }, []);
  return <Fragment />;
};

export default withProfile(LogoutPage);
