import { createContext } from "react";
import { clientStateDefault } from "../../utils";
import { IClientContextType } from "../types";

export const ClientContext = createContext<IClientContextType>({
  clientState: clientStateDefault,
  setClientState: (): void => {},
});

export default ClientContext;
