import { useQuery } from "@apollo/client";
import { ListPaymentsQuery } from "API";
import { getQuery } from "hooks/utils/helpers";
import { ReturnPaymentList } from "hooks/types";
import { listPayments } from "graphql/queries";

export const getPaymentLists = (): ReturnPaymentList => {
  const { data, loading, error } = useQuery<ListPaymentsQuery>(getQuery(listPayments));
  return {
    data: data?.listPayments?.items,
    loading,
    error,
  };
};

export default getPaymentLists;
