import { useRefferedBy } from "hooks/referredBy";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { IGraph } from "state/dashboard";
import { getDate, GraphDataInitialize, GraphDataKeys, momentFormatted, ProfileKeys } from "utils";
import { getGraphData, IRefferedHoc } from ".";

export function withReferred<T>(Component: React.FC<T & IRefferedHoc>): React.FC<T> {
  const Referred: React.FC = (props: T) => {
    const [graphDataFinal, setGraphDataFinal] = useState<null | IGraph>(null);
    const { fetchData: handleSubmit, data: sortedList, loading, searchfields } = useRefferedBy();
    const { setSearchText, searchText } = searchfields;

    const graphData = cloneDeep(GraphDataInitialize);

    const getAllDates = (metaData, profile, createdAt): void => {
      if (createdAt) {
        if (momentFormatted(createdAt) in graphData.newCreatedSort)
          graphData.newCreatedSort[momentFormatted(createdAt)] += 1;
        else graphData.newCreatedSort[momentFormatted(createdAt)] = 1;
      }
      if (profile?.takenDomainTests) {
        profile.takenDomainTests?.forEach((test) => {
          if (test?.isPass && momentFormatted(test?.testEnd) in graphData.newDomain)
            graphData.newDomain[momentFormatted(test?.testEnd)] += 1;
          else if (test?.isPass && !(momentFormatted(test?.testEnd) in graphData.newDomain))
            graphData.newDomain[momentFormatted(test?.testEnd)] = 1;
        });
      }
      if (metaData) {
        const meta = JSON.parse(metaData);
        Object.keys(meta).forEach((key) => {
          if (
            meta[key] &&
            momentFormatted(meta[key]) in graphData.newHRTest &&
            profile?.[ProfileKeys[key]]
          )
            graphData[GraphDataKeys[key]][momentFormatted(meta[key])] += 1;
          else if (
            meta[key] &&
            !(momentFormatted(meta[key]) in graphData.newHRTest) &&
            profile?.[ProfileKeys[key]]
          )
            graphData[GraphDataKeys[key]][momentFormatted(meta[key])] = 1;
        });
      }
    };

    useEffect(() => {
      sortedList.sort((a, b) => getDate(b) - getDate(a));
      sortedList.forEach((referral) => {
        const profile = referral?.referred?.profile;
        const metaData = referral?.referred?.profile?.statusMeta;
        const createdAt = referral?.createdAt;
        getAllDates(metaData, profile, createdAt);
      });
      setGraphDataFinal(getGraphData(graphData));
    }, [sortedList]);

    const referredProps = {
      searchFields: { handleSubmit, setSearchText, searchText },
      graphDataFinal,
      sortedList,
      loading,
    };
    return <Component {...props} {...referredProps} />;
  };
  return Referred;
}

export default withReferred;
