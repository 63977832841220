import React, { useState } from "react";
import { ClientContext } from "state/clients";
import { IClientStateType } from "state/types/client.interface";
import { clientStateDefault } from "utils";

export const ClientProvider: React.FC = (props) => {
  const [clientState, setClientState] = useState<IClientStateType>(clientStateDefault);
  const value = {
    clientState,
    setClientState,
  };

  return <ClientContext.Provider value={value}>{props.children}</ClientContext.Provider>;
};

export default ClientProvider;
