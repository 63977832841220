import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  LazyQueryExecFunction,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { getQuery } from "hooks/utils";
import {
  InvoiceBatch,
  UpdateInvoiceBatchMutation,
  UpdateInvoiceBatchMutationVariables,
  ModelInvoiceBatchConnection,
  ModelInvoiceConnection,
  GetInvoicesByBatchQuery,
  GetInvoicesByBatchQueryVariables,
  GetInvoiceBatchBySortHashKeyQuery,
  GetInvoiceBatchBySortHashKeyQueryVariables,
  ConsumeBatchIdMutation,
  ConsumeBatchIdMutationVariables,
  LambdaStringResponse,
} from "@remotebase/amplify-constants/API";
import {
  getInvoiceBatchBySortHashKey,
  getInvoicesByBatch,
} from "@remotebase/amplify-constants/graphql/queries";
import {
  updateInvoiceBatch,
  consumeBatchId,
} from "@remotebase/amplify-constants/graphql/mutations";

export type ReturnPropsListInvoiceBatches = {
  listBatches: LazyQueryExecFunction<
    GetInvoiceBatchBySortHashKeyQuery,
    GetInvoiceBatchBySortHashKeyQueryVariables
  >;
  loading: boolean;
  result: ModelInvoiceBatchConnection | null;
  error?: string;
};

export type ReturnPropsUpdateInvoiceBatches = {
  updateBatch: (
    options?:
      | MutationFunctionOptions<
          UpdateInvoiceBatchMutation,
          UpdateInvoiceBatchMutationVariables,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<UpdateInvoiceBatchMutation, Record<string, unknown>, Record<string, unknown>>
  >;
  loading: boolean;
  result: InvoiceBatch | null;
  error?: string;
};

export type ReturnPropsFetchInvoices = {
  fetchInvoices: LazyQueryExecFunction<GetInvoicesByBatchQuery, GetInvoicesByBatchQueryVariables>;
  loading: boolean;
  result: ModelInvoiceConnection | null;
  error?: string;
};

export type ReturnPropsProcessBatch = {
  processBatch: (
    options?:
      | MutationFunctionOptions<
          ConsumeBatchIdMutation,
          ConsumeBatchIdMutationVariables,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<ConsumeBatchIdMutation, Record<string, unknown>, Record<string, unknown>>
  >;
  loading: boolean;
  result: LambdaStringResponse | null;
  error?: string;
};

export function useListInvoiceBatches(): ReturnPropsListInvoiceBatches {
  const [listBatches, { data, loading, error }] = useLazyQuery<
    GetInvoiceBatchBySortHashKeyQuery,
    GetInvoiceBatchBySortHashKeyQueryVariables
  >(getQuery(getInvoiceBatchBySortHashKey));
  const result = data?.getInvoiceBatchBySortHashKey || null;

  return {
    listBatches,
    loading,
    result,
    error: error?.message,
  };
}

export function useUpdateInvoiceBatches(): ReturnPropsUpdateInvoiceBatches {
  const [updateBatch, { data, loading, error }] = useMutation<
    UpdateInvoiceBatchMutation,
    UpdateInvoiceBatchMutationVariables
  >(getQuery(updateInvoiceBatch));

  const result = data?.updateInvoiceBatch || null;

  return {
    updateBatch,
    loading,
    result,
    error: error?.message,
  };
}

export function useFetchInvoices(): ReturnPropsFetchInvoices {
  const [fetchInvoices, { data, loading, error }] = useLazyQuery<
    GetInvoicesByBatchQuery,
    GetInvoicesByBatchQueryVariables
  >(getQuery(getInvoicesByBatch));

  const result = data?.getInvoicesByBatch || null;

  return {
    fetchInvoices,
    loading,
    result,
    error: error?.message,
  };
}

export function useProcessBatch(): ReturnPropsProcessBatch {
  const [processBatch, { data, loading, error }] = useMutation<
    ConsumeBatchIdMutation,
    ConsumeBatchIdMutationVariables
  >(getQuery(consumeBatchId));

  const result = data?.consumeBatchId || null;

  return {
    processBatch,
    loading,
    result,
    error: error?.message,
  };
}
