import { FC } from "react";
import * as Styled from "../styles";

export const CertificationSectionShimmer: FC = () => {
  return (
    <Styled.CertificationDetailWrapperShimmer>
      <Styled.CertificationDetailTopShimmer>
        <Styled.CertificationDetailTitleShimmer />
        <Styled.CertificationDetailTopIconShimmer />
      </Styled.CertificationDetailTopShimmer>
      <Styled.CertificationDetailListShimmer>
        <Styled.CertificationDetailItemShimmer>
          <Styled.CertificationDetailIconShimmer>
            <span></span>
          </Styled.CertificationDetailIconShimmer>
          <Styled.CertificationDetailContentShimmer>
            <Styled.CertificationDetailSbHdShimmer />
            <Styled.CertificationDetailParaShimmer />
          </Styled.CertificationDetailContentShimmer>
        </Styled.CertificationDetailItemShimmer>
        <Styled.CertificationDetailItemShimmer>
          <Styled.CertificationDetailIconShimmer>
            <span></span>
          </Styled.CertificationDetailIconShimmer>
          <Styled.CertificationDetailContentShimmer>
            <Styled.CertificationDetailSbHdShimmer />
            <Styled.CertificationDetailParaShimmer />
          </Styled.CertificationDetailContentShimmer>
        </Styled.CertificationDetailItemShimmer>
      </Styled.CertificationDetailListShimmer>
    </Styled.CertificationDetailWrapperShimmer>
  );
};

export default CertificationSectionShimmer;
