import styled from "styled-components";

export const TagInputCover = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.75rem 0;
  flex-wrap: wrap;
  position: relative;
  .suggestion-input {
    min-width: 40%;
    flex-shrink: 0;
    flex-grow: 1;
  }
  &:focus-within {
    .tags-suggested-cover {
      opacity: 1;
      height: auto;
      display: flex;
    }
  }
`;

export const TagInputTag = styled.div`
  flex-shrink: 0;
  font-size: 0.8rem;
  background: #f2f2f2;
  border-radius: 1rem;
  padding: 0.25rem 1.2rem;
  font-family: poppins400, sans-serif;
  transition: all 3s;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

export const TagInputSelectedTag = styled(TagInputTag)`
  p {
    margin: 0;
    transition: all 0.3s;
    position: relative;
    left: 0;

    &:after {
      content: "";
      margin-left: 0;
      color: transparent;
      font-family: poppins600, sans-serif;
      transition: all 0.3s;
      position: absolute;
      right: 0;
    }
  }

  &:hover {
    p {
      left: -0.4rem;

      &:after {
        right: -0.75rem;
        content: "x";
        color: #212121;
      }
    }
  }
`;
