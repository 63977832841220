import { UseFormReturn } from "react-hook-form";
import React, { useMemo } from "react";
import { ShouldRender } from "@remotebase/components";
import { DropdownItems, SelectOption } from "utils";
import Select from "react-select";
import * as Styled from "./styles";

interface Props {
  label?: string;
  title: string;
  handler: UseFormReturn;
  placeholder: string;
  items: DropdownItems;
  small?: boolean;
  disabled?: boolean;
}

export const FormSelect: React.FC<Props> = ({
  items,
  handler,
  title,
  label,
  placeholder,
  disabled,
  small = false,
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = handler;

  const selectedValue = watch(title, false);

  const formattedItems = useMemo(
    () =>
      items.reduce<Record<string, SelectOption>>(
        (prev, { key, value }) => ({ ...prev, [value]: { label: key, value } }),
        {},
      ),
    [items],
  );

  const updateSelectedSelect = (option: SelectOption): void => setValue(title, option.value);

  return (
    <div>
      <Styled.InputGroup $small={small}>
        <ShouldRender if={!small}>
          <Styled.InputLabel>{label}</Styled.InputLabel>
        </ShouldRender>
        <Styled.DropdownInputCover>
          <Select
            options={Object.values(formattedItems)}
            placeholder={placeholder}
            onChange={updateSelectedSelect}
            isDisabled={disabled}
            classNamePrefix={"dropdown"}
            value={selectedValue ? formattedItems[selectedValue] : null}
          />
        </Styled.DropdownInputCover>
      </Styled.InputGroup>
      <ShouldRender if={errors[title]?.message}>
        <Styled.ErrorMessage>{errors[title]?.message}</Styled.ErrorMessage>
      </ShouldRender>
    </div>
  );
};

export default FormSelect;
