import { FC, useEffect } from "react";
import { CurrentChatProps, CurrentChatUserProps, IChatParam } from "state/chat";
import { useChatSubscription } from "hooks";
import { useParams } from "react-router-dom";
import { ShouldRender } from "@remotebase/components";
import ChatHeader from "@remotebase/components/chat/currentUserChatPanel/chatHeader";
import NoUserSelected from "@remotebase/components/chat/currentUserChatPanel/noCurrentUser";
import * as S from "@remotebase/components/chat/styles";
import ChatPanel from "./chatPanel";

const CurrentUserChatPanel: FC<CurrentChatProps & CurrentChatUserProps> = ({
  getMyInfo,
  currentUser,
  updateLocalMessage,
  updateSubscribedMessage,
  ...props
}) => {
  const { conversationId } = useParams<IChatParam>();
  const subscribedMsg = useChatSubscription(conversationId || "");

  useEffect(() => {
    if (subscribedMsg) updateSubscribedMessage(subscribedMsg);
  }, [subscribedMsg]);

  if (!currentUser) return <NoUserSelected />;
  return (
    <S.ChatContentWrapper>
      <ShouldRender if={currentUser}>
        <S.ChatContentInner>
          <ChatHeader currentUser={currentUser} />
          <ChatPanel {...props} />
        </S.ChatContentInner>
      </ShouldRender>
    </S.ChatContentWrapper>
  );
};

export default CurrentUserChatPanel;
