import { Modal, Table } from "react-bootstrap";
import styled from "styled-components";

export const TalentModal = styled(Modal)`
  .modal-dialog {
    max-width: 1100px;
    padding: 20px;

    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }

  .modal-header {
    padding: 40px 50px;
    align-items: center;

    @media only screen and (max-width: 767px) {
      padding: 30px 24px;
    }

    & .close {
      font-size: 24px;
      color: #fff;
      width: 40px;
      height: 40px;
      background-color: #6c5dd3;
      margin: 0;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 1;
      &:hover,
      &:focus {
        background-color: #5040bf;
      }
    }
  }

  .modal-title {
    font-size: 24px;
    color: #11142d;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }

  .modal-body {
    padding: 40px 50px 72px;

    @media only screen and (max-width: 767px) {
      padding: 30px 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
`;

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #6c5dd3;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  opacity: 1;
  padding: 4px;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #5040bf;
  }

  & svg {
    width: 22px;
    height: 22px;

    & path {
      fill: #fff;
    }
  }
`;

export const TalentTable = styled(Table)`
  width: 100%;
  min-width: 860px;
  margin-bottom: 0;

  & thead {
    & tr {
      & th {
        font-size: 16px;
        color: #11142d;
        padding: 16px 12px;
        border: none;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.05);

        &:first-child {
          text-align: left;
          border-radius: 8px 0 0 8px;
        }

        &:nth-child(5) {
          text-align: right;
          border-radius: 0 8px 8px 0;
        }

        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 10%;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        font-size: 16px;
        color: #11142d;
        padding: 12px;
        border: none;
        text-align: center;

        &:first-child {
          text-align: left;
        }

        &:nth-child(5) {
          min-width: 80px;
          text-align: right;
          padding-right: 32px;
        }

        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 10%;
        }
      }
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    text-align: left;
  }

  td + td,
  th + th {
    border-left: 1px solid #c1c1c1 !important;
  }
`;

export const TalentModalHeading = styled.h4`
  font-size: 20px;
  color: #11142d;
  margin-bottom: 24px;
`;

export const EngPublishIcon = styled.span`
  display: inline-flex;
  position: relative;
  top: 4px;

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: #3f73f6;
    }
  }
`;

export const EngRowCross = styled.span`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(63 115 246);
  border-radius: 50%;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    box-shadow: none;
    border: none;
  }

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff;
    }
  }
`;

export default TalentModal;
