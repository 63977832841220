export enum GraphLabels {
  PSTestpassed = "PS Test passed",
  SignUp = "Sign Up",
  HRpassed = "HR passed",
  FinalTechpassed = "Final Tech passed",
  Hired = "Hired",
  Domaintests = "Domain tests",
}

export enum ProfileKeys {
  dateOfPSStatus = "isPassPS",
  dateOfHiring = "isPassHR",
  dateOfFinalTechStatus = "isPassFinalTech",
  dateOfHRStatus = "isHired",
}

export enum GraphDataKeys {
  dateOfPSStatus = "newPSTest",
  dateOfHRStatus = "newHRTest",
  dateOfFinalTechStatus = "newFinalTest",
  dateOfHiring = "newHired",
}

export const GraphDataInitialize = {
  newPSTest: {},
  newHRTest: {},
  newFinalTest: {},
  newHired: {},
  newDomain: {},
  newCreatedSort: {},
};

export const GraphFrequencyInitialize = {
  newCreatedSort: [],
  newPSTest: [],
  newHRTest: [],
  newFinalTest: [],
  newHired: [],
  newDomain: [],
};

export interface SearchFieldProps {
  searchText: string;
  handleSubmit: (unknown) => void;
  setSearchText: (unknown) => void;
}
