import { ShouldRender, SummarySection, TextDraft } from "@remotebase/components";
import { CrossIcon, EditRoundedIcon } from "assets/icons";
import { FC, useEffect, useMemo, useState } from "react";
import { useProfileVersionContext } from "state/profileVersion";
import * as Styled from "../styles";

export const TalentSummarySection: FC = () => {
  const { selectedVersion, setSelectedVersion } = useProfileVersionContext();
  const [summary, setSummary] = useState<string>(selectedVersion?.bio || "");
  const [editMode, setEditMode] = useState(false);

  const handleEditMode = (): void => setEditMode(!editMode);

  const getEditSection = useMemo(() => {
    return (
      <Styled.EducationDetailTopIcon onClick={(): void => handleEditMode()}>
        <ShouldRender if={!editMode}>
          <EditRoundedIcon />
        </ShouldRender>
        <ShouldRender if={editMode}>
          <CrossIcon />
        </ShouldRender>
      </Styled.EducationDetailTopIcon>
    );
  }, [editMode]);

  const setSummaryInState = (): void => {
    setSelectedVersion((prev) => (prev ? { ...prev, bio: summary } : null));
    handleEditMode();
  };

  const cancelEdit = (): void => {
    setSummary(selectedVersion?.bio || "");
    handleEditMode();
  };

  useEffect(() => {
    setSummary(selectedVersion?.bio || "");
  }, [selectedVersion?.bio]);

  return editMode ? (
    <Styled.ProfileSummaryWrapper>
      <Styled.ProfileSummaryHd>Summary</Styled.ProfileSummaryHd>
      <Styled.ProfileSummaryTextArea>
        <TextDraft setValue={setSummary} initialValue={summary} />
      </Styled.ProfileSummaryTextArea>

      <Styled.EditSummaryFooter>
        <Styled.SummarySaveBtn onClick={(): void => setSummaryInState()}>
          Save Summary
        </Styled.SummarySaveBtn>
        <Styled.SummarySaveBtn onClick={cancelEdit} $cancel={true}>
          Cancel
        </Styled.SummarySaveBtn>
      </Styled.EditSummaryFooter>
    </Styled.ProfileSummaryWrapper>
  ) : (
    <SummarySection summary={summary} client={false}>
      {getEditSection}
    </SummarySection>
  );
};

export default TalentSummarySection;
