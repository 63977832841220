import { IClientStateType } from "state/types/client.interface";
import { PaginatedStateProps } from "./common";

export const clientStateDefault: IClientStateType = {
  loading: true,
  currentPtr: 0,
  companies: [],
};

export interface WithClientProps extends PaginatedStateProps<FilterTypes> {
  clientState: IClientStateType;
  fetch: (all?: boolean, param?: QueryParam) => void;
}

export interface QueryParam {
  query?: string;
  filterBy?: FilterTypes;
}

export enum FilterTypes {
  EMAIL = "email",
  FULL_NAME = "fullName",
}

// export interface ClientProps extends HocStateProps {}
