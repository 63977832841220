import { createContext, useContext } from "react";
import { IProfileVersionContext } from "../types";

export const ProfileVersionContext = createContext<IProfileVersionContext>({
  talent: null,
  setTalent: () => null,
  profileVersions: [],
  setProfileVersions: () => null,
  selectedVersion: null,
  setSelectedVersion: () => null,
  getTransformedData: () => null,
  setSelectCurrentVersion: () => null,
});

export const useProfileVersionContext = (): IProfileVersionContext =>
  useContext(ProfileVersionContext);
