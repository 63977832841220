import { FC, Fragment, useEffect, useState } from "react";
import { Talent } from "@remotebase/amplify-constants/API";
import { Spinner } from "react-bootstrap";
import { downloadReferralsCsv } from "utils";
import { useReferralByRecruiter } from "hooks";
import * as Styled from "./styles";

interface Props {
  data?: Array<Talent | null>;
  loading: boolean;
  recruiterId?: string;
}

export const ReferralsCsvButton: FC<Props> = ({ recruiterId }): JSX.Element => {
  const {
    getReferralsByRecruiter,
    data: referralsList,
    loading: referralLoading,
    nextToken: referralToken,
  } = useReferralByRecruiter();
  const [token, setNextToken] = useState<string | null>(null);
  const [referralTalents, setReferralTalents] = useState<Array<Talent | null>>([]);
  const referedTalent = (referralsLists): Array<Talent> => {
    return referralsLists.map((referral) => referral.referred);
  };
  const onClickDownload = async (): Promise<void> => {
    const variables = {
      nextToken: token,
      recruiterId,
    };
    getReferralsByRecruiter({ variables });
  };
  useEffect(() => {
    if (!referralLoading && referralsList) {
      setReferralTalents([...referralTalents, ...referedTalent(referralsList)]);
      setNextToken(referralToken);
    }
  }, [referralLoading, referralsList]);
  useEffect(() => {
    if (token) {
      const variables = {
        nextToken: token,
        recruiterId,
      };
      getReferralsByRecruiter({ variables });
    } else {
      downloadReferralsCsv([...referralTalents]);
    }
  }, [token]);
  return (
    <Fragment>
      <Styled.DownloadBtn onClick={onClickDownload} variant="outline-secondary" size="sm">
        {referralLoading ? <Spinner animation="border" /> : <span>Download CSV </span>}
      </Styled.DownloadBtn>
    </Fragment>
  );
};

export default ReferralsCsvButton;
