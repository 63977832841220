import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const TalentModal = styled(Modal)`
  .modal-dialog {
    max-width: 1100px;
    padding: 20px;

    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }

  .modal-header {
    padding: 40px 50px;
    align-items: center;

    @media only screen and (max-width: 767px) {
      padding: 30px 24px;
    }

    & .close {
      font-size: 24px;
      color: #fff;
      width: 40px;
      height: 40px;
      background-color: #6c5dd3;
      margin: 0;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 1;
      &:hover,
      &:focus {
        background-color: #5040bf;
      }
    }
  }

  .modal-title {
    font-size: 24px;
    color: #11142d;
    font-family: "Poppins", sans-serif;
  }

  .modal-body {
    padding: 0;
    & > div {
      padding: 40px 50px 90px;
      background-color: #f9f9f9;
      border: none;
      box-shadow: none;

      @media only screen and (max-width: 767px) {
        padding: 30px 24px 90px;
      }
    }
    & .pagination {
      & .page-link {
        background-color: #e9ecef;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
`;

export const CloseButton = styled.button`
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #6c5dd3;
  margin: 0;
  position: relative;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  border: none;
  opacity: 1;
  &:hover,
  &:focus {
    background-color: #5040bf;
  }

  p {
    position: absolute;
    top: -5%;
    left: 35%;
  }
`;

export default TalentModal;
