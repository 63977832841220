import React, { Fragment, useState } from "react";
import { JobMatch } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { TalentMatchModal } from "components";
import { EyeIcon } from "assets/icons";
import * as Styled from "./styles";

interface Props {
  matchedJobs: Array<JobMatch | null>;
}

export const ViewFeedbackIcon: React.FC<Props> = ({ matchedJobs }) => {
  const [talentMatchModal, setTalentMatchModal] = useState<boolean>(false);

  const handleModalShow = (): void => setTalentMatchModal(!talentMatchModal);
  return (
    <Fragment>
      <Styled.CompanyMatchingHeadOpt>
        <Styled.EyeButton>
          <EyeIcon onClick={handleModalShow} />
        </Styled.EyeButton>
      </Styled.CompanyMatchingHeadOpt>
      <ShouldRender if={talentMatchModal}>
        <TalentMatchModal handleShow={handleModalShow} matchedList={matchedJobs} />
      </ShouldRender>
    </Fragment>
  );
};

export default ViewFeedbackIcon;
