import { Job } from "@remotebase/amplify-constants/API";
import React, { FC } from "react";
import * as Styled from "./styles";

interface Props {
  setSelectedJob: (value: Job | null) => void;
  job: Job | null;
  disabled: boolean;
  jobs: Array<Job | null>;
}

export const JobList: FC<Props> = ({ disabled, setSelectedJob, job, jobs }) => {
  const handleChange = (id: string): void => {
    const selectedJob = jobs.find((currentJob) => currentJob?.id === id);
    setSelectedJob(selectedJob || null);
  };

  return (
    <Styled.DivWrapper>
      <Styled.DropdownBtn
        disabled={disabled}
        title={job?.title || "Select job"}
        onSelect={handleChange}
      >
        {jobs.map(({ title, id }: Job) => (
          <Styled.DropdownItem key={id} eventKey={id} value={id}>
            {title}
          </Styled.DropdownItem>
        ))}
      </Styled.DropdownBtn>
    </Styled.DivWrapper>
  );
};

export default JobList;
