import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "./styles";
import { referredByModalTitle } from "../../../utils";
import { TableShimmer } from "../../shimmerPages";
import { withReferredBy } from "../../../state/referred-by";
import ReferredByTable from "./referredByTable";
import { IReferredByProps } from "../../../state/types/referrals.interface";

interface Props {
  talentId: string;
  handleShow: (event) => void;
}

const TalentsReferredByModal: FC<Props & IReferredByProps> = ({
  referredByState: { loading, data },
  talentId,
  handleShow,
  setTalentId,
}) => {
  useEffect(() => setTalentId(talentId), [talentId]);

  return (
    <Styled.TalentModal show onHide={handleShow} animation={false} scrollable>
      <Modal.Header closeButton onClick={handleShow}>
        <Modal.Title>{referredByModalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ShouldRender if={!loading}>
          <Styled.TableContent>
            <Styled.MainTable responsive>
              <ReferredByTable data={data} />
            </Styled.MainTable>
          </Styled.TableContent>
        </ShouldRender>
        <ShouldRender if={loading}>
          <TableShimmer />
        </ShouldRender>
      </Modal.Body>
    </Styled.TalentModal>
  );
};

export default withReferredBy(TalentsReferredByModal);
