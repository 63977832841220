import { UserRoles } from "@remotebase/amplify-constants/API";

export enum Sidebar {
  Dashboard = "Dashboard",
  Talents = "Talents",
  Recruiters = "Recruiters",
  RecruitersReports = "Recruiters Reports",
  RecruitersPerformance = "Performance",
  RecruitersLeaderboard = "Leaderboard",
  RecruitersNewLeaderboard = "New Leaderboard",
  ReferredBy = "Referred By",
  Clients = "Clients",
  Chats = "Chats",
  Invoices = "Invoices",
  AdminUsers = "Users",
  PaymentsHistory = "Payments History",
}

export enum SidebarType {
  Login = "login",
}

export type SidebarProps = {
  sidebarType: SidebarType | UserRoles;
  setSidebarType: React.Dispatch<React.SetStateAction<SidebarType | UserRoles>>;
};
