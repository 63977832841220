import { cloneDeep } from "lodash";
import moment from "moment";
import { adjustDays, GraphFrequencyInitialize, GraphLabels, momentFormatted } from "utils";
import { OperationDays } from "utils/constants/dashboard";
import { IRefferedData, IGraph } from "./refferedBy.interfaces";

const getDatesBetweenDates = (startD, stopDate): string[] => {
  const dateArray: string[] = [];
  let currentDate = moment(startD, "ll");
  const stop = moment(stopDate, "ll");
  while (currentDate.valueOf() <= stop.valueOf()) {
    dateArray.push(momentFormatted(currentDate));
    currentDate = adjustDays(1, OperationDays.add, currentDate);
  }
  return dateArray;
};

export const getGraphData = (data: IRefferedData): IGraph => {
  const dates: string[] = [];
  const getDate = (e): number => moment(e, "ll").valueOf();

  const graphFrequency = cloneDeep(GraphFrequencyInitialize);

  Object.keys(data)?.forEach((key) => {
    dates.push(...Object.keys(data[key]));
  });
  const minMax = dates.sort((a, b) => getDate(a) - getDate(b));

  const startDate = minMax[0];
  const endDate = minMax[minMax.length - 1];

  const rangeDates = getDatesBetweenDates(startDate, endDate);
  rangeDates.forEach((date, i) => {
    Object.keys(graphFrequency).forEach((key) => {
      if (date in data[key]) {
        graphFrequency[key][i] = data[key][date];
      } else {
        graphFrequency[key][i] = 0;
      }
    });
  });

  const chartData: IGraph = {
    options: {
      chart: {
        marginTop: 50,
      },
      title: {
        text: " ",
      },
      yAxis: {
        title: {
          text: "Number",
        },
      },
      xAxis: {
        title: {
          text: "dates",
        },
        categories: rangeDates,
      },
      series: [
        {
          name: GraphLabels.PSTestpassed,
          data: graphFrequency.newPSTest,
          visible: false,
        },
        {
          name: GraphLabels.SignUp,
          data: graphFrequency.newCreatedSort,
        },
        {
          name: GraphLabels.HRpassed,
          data: graphFrequency.newHRTest,
          visible: false,
        },
        {
          name: GraphLabels.FinalTechpassed,
          data: graphFrequency.newFinalTest,
        },
        {
          visible: false,
          name: GraphLabels.Hired,
          data: graphFrequency.newHired,
        },
        {
          visible: false,
          name: GraphLabels.Domaintests,
          data: graphFrequency.newDomain,
        },
      ],
      exporting: {
        fallbackToExportServer: false,
        enabled: true,
        allowHTML: true,
        filename: "myFile",
        buttons: {
          contextButton: {
            symbol: "download",
            menuItems: ["downloadCSV"],
          },
        },
      },
    },
  };
  return chartData;
};

export default getGraphData;
