import { Button, DropdownButton, Form, Pagination, Table } from "react-bootstrap";
import styled from "styled-components";

export const TableHeading = styled.h3`
  font-size: 24px;
  color: #11142d;
  font-weight: 600;
  margin: 0 24px 0 0;
`;

export const MainTableHeading = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableDate = styled.div`
  font-size: 12px;
  color: #9995b6;
  display: flex;
  align-items: center;
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableContent = styled.div`
  padding: 20px 20px 90px;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 3px rgb(34 39 44 / 10%);
  border-radius: 4px;
  position: relative;
`;

export const TableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableSubHeading = styled.h4`
  display: inline-flex;
  font-size: 20px;
  color: #11142d;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
`;

export const TableForm = styled(Form)`
  flex-grow: 1;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const CheckBox = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
  & .form-check {
    display: inline-flex;
    align-items: center;
  }
  & label {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    margin: 0;
  }
  & input {
    margin-top: 0;
  }
  @media only screen and (max-width: 1199px) {
    justify-content: flex-start;
    align-items: center;
  }
  @media only screen and (max-width: 575px) {
    margin: 0 0 20px 0;
  }
  & .btn-primary {
    background-color: transparent;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
  }
`;

export const SearchGroup = styled(Form.Group)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchGroupBoth = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-top: 24px;
  }
  @media only screen and (max-width: 1199px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchGroupSearch = styled.span`
  position: absolute;
  left: 4px;
  top: 6px;
  bottom: 6px;
  border-right: 1px solid #dddfe2;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: #7f808fff;
  }
`;

export const SearchGroupMain = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const DropdownLabel = styled.label`
  padding: 8px 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  font-weight: normal !important;
`;

export const SearchGroupField = styled(Form.Control)`
  font-size: 14px !important;
  font-weight: normal !important;
  border: 1px solid #dddfe2 !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  height: 40px !important;
  width: 100% !important;
  max-width: 200px;
  min-width: 200px !important;
  padding: 8px 12px !important;
  margin: 0 16px !important;
  &:focus {
    box-shadow: none !important;
  }
  @media only screen and (max-width: 1279px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 1199px) {
    margin-left: 0 !important;
  }
  @media only screen and (max-width: 575px) {
    margin: 0 0 16px 0 !important;
    min-width: 150px !important;
    padding: 8px 10px;
  }
`;

export const SearchButton = styled(Button)`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: 120px;
  min-height: 40px;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background: #3f73f6;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const ClearFilters = styled.a`
  font-size: 14px;
  color: #009cff;
  font-weight: 500;
  padding: 4px 12px;
  min-width: auto;
  border: none;
  border-radius: 0;
  font-family: "Poppins", sans-serif;
  background: transparent;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 1px;
  min-height: auto;
  &:hover,
  &:focus {
    border: none;
    background: transparent;
    color: #007dcc;
    cursor: pointer;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    text-align: center;
  }
`;

export const MainTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 40px;
  min-width: 960px;
  thead tr th {
    font-size: 14px;
    font-weight: 500;
    color: #474d58;
    padding: 10px 14px;
    border-top: 1px solid #e5ecff;
    border-bottom: 1px solid #e5ecff;
    word-break: break-word;
  }
  thead tr th:first-child {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #e5ecff;
  }
  thead tr th:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #e5ecff;
    text-align: center;
  }
  tbody tr td,
  tfoot tr td {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    border: none;
    padding: 10px 14px;
    word-break: break-word;
  }
  tbody tr:first-child td {
    padding-top: 20px;
  }
  thead tr th:nth-of-type(1),
  tbody tr td:nth-of-type(1) {
    min-width: 146px;
  }
  thead tr th:nth-of-type(2),
  tbody tr td:nth-of-type(2) {
    display: ${({ col }): string => (col.PhoneNo ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(3),
  tbody tr td:nth-of-type(3) {
    min-width: 220px;
  }
  thead tr th:nth-of-type(4),
  tbody tr td:nth-of-type(4) {
    display: ${({ col }): string => (col.Country ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(5),
  tbody tr td:nth-of-type(5) {
    display: ${({ col }): string => (col.CreatedAt ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(6),
  tbody tr td:nth-of-type(6) {
    display: ${({ col }): string => (col.isPassDomain ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(7),
  tbody tr td:nth-of-type(7) {
    display: ${({ col }): string => (col.DTTime ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(8),
  tbody tr td:nth-of-type(8) {
    display: ${({ col }): string => (col.isPassPS ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(9),
  tbody tr td:nth-of-type(9) {
    display: ${({ col }): string => (col.PSTime ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(10),
  tbody tr td:nth-of-type(10) {
    display: ${({ col }): string => (col.isPassHR ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(11),
  tbody tr td:nth-of-type(11) {
    display: ${({ col }): string => (col.isPassFinalTech ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(12),
  tbody tr td:nth-of-type(12) {
    display: ${({ col }): string => (col.isHired ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(13),
  tbody tr td:nth-of-type(13) {
    display: ${({ col }): string => (col.HiredAt ? "show" : "none")};
    min-width: 146px;
  }
  thead tr th:nth-of-type(14),
  tbody tr td:nth-of-type(14) {
    min-width: 146px;
    text-align: center;
  }
  thead tr th:nth-of-type(15),
  tbody tr td:nth-of-type(15) {
    min-width: 146px;
    text-align: center;
  }
  thead tr th:nth-of-type(16),
  tbody tr td:nth-of-type(16) {
    min-width: 130px;
    text-align: center;
  }
`;

export const TableLink = styled.a`
  font-size: 12px;
  color: #007bff;
`;

export const TableOpt = styled.a`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 0 10px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #6c5dd3;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: #6c5dd3;
  }
  &:hover svg path,
  &:focus svg path {
    fill: #fff;
  }
`;

export const PaginationTable = styled(Pagination)`
  position: absolute;
  left: auto;
  right: 20px;
  bottom: 50px;
  margin-bottom: 0;
  text-align: center;
  justify-content: center;
  .page-link {
    font-size: 14px;
    color: #6c5dd3 !important;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    border: none;
    margin: 0 6px;
  }
  .page-item.active .page-link {
    color: #fff !important;
    background-color: #6c5dd3 !important;
  }
  .page-link svg {
    width: 14px;
    height: 14px;
  }
  .page-link svg path {
    color: #6c5dd3;
  }
  .page-item.disabled .page-link {
    opacity: 0.5;
    border: none !important;
    background-color: transparent;
  }
`;

export const DropdownBtn = styled(DropdownButton)`
  display: inline-flex;
  margin-bottom: 10px;
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #dddfe2;
      color: #474d58;
    }
  }
  & .dropdown-toggle {
    font-size: 14px;
    font-weight: 400;
    color: #474d58;
    padding: 4px 12px;
    min-width: 120px;
    min-height: 40px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #dddfe2;
    font-family: "Poppins", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #fff !important;
      border: 1px solid #dddfe2 !important;
      color: #474d58 !important;
    }
    @media only screen and (max-width: 575px) {
      width: 100%;
    }
  }
  & .dropdown-menu {
    max-height: 40vh;
    overflow: scroll;
    border-radius: 6px;
    padding: 6px 0;
    width: 200px;
    @media only screen and (max-width: 1199px) {
      right: 0 !important;
      left: initial !important;
    }
    @media only screen and (max-width: 575px) {
      width: 100%;
      left: 0 !important;
    }
  }
  & .dropdown-item {
    color: #474d58;
    font-size: 14px;
    padding: 6px 12px;
  }
`;

export const StrongText = styled.td`
  font-weight: 600 !important;
  text-decoration: underline;
  cursor: pointer;
`;

export const DownloadBtn = styled(Button)`
  height: 38px;
  margin-left: 5px;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #dcdfe2;
`;

export const Label = styled.label`
  padding: 0px 8px;
  display: block;
`;

export const Span = styled.span`
  padding-left: 5px;
`;

export const ReferredByBtn = styled(Button)`
  cursor: pointer;
  font-size: 14px;
  background: transparent !important;
  padding: 5px;
  border: 1px solid #2522ba;
  border-radius: 4px;
  line-height: 20px;
  width: 40px;
  height: 34px;
  svg path {
    fill: #2522ba;
  }
`;

export const EditIconEngineer = styled.div`
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  svg path {
    fill: #2522ba;
  }
`;
