import { Talent } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { codeToCountry } from "@remotebase/constants";
import { EyeIcon, EditIcon, HandShakeIcon } from "assets/icons";
import moment from "moment";
import { FC, Fragment, useContext, useMemo } from "react";
import { AuthContext } from "state/auth";
import { inString, isPassDomain, domainTestStartTime, getFormatedDate, superUser } from "utils";
import { AuthContextType } from "state/types/authTypes";
import * as Styled from "../styles";

interface TableRowProps {
  talent: Talent;
  showReferredBy?: boolean;
  index: number;
  onNameClick?: (engrId?: string) => void;
  onReferredByClick?: (engrId?: string) => void;
  onEditBtnClick?: (engrId?: string) => void;
  onTalentMatchBtnClick?: (engrId?: string) => void;
}

const TableRow: FC<TableRowProps> = ({
  talent,
  index,
  onNameClick,
  onReferredByClick,
  showReferredBy = true,
  onEditBtnClick,
  onTalentMatchBtnClick,
}) => {
  const {
    authState: { userRole },
  } = useContext<AuthContextType>(AuthContext);
  const superAdmin = useMemo(() => userRole?.find((item) => item === superUser), [userRole]);
  const talentNameColumn = useMemo(
    () =>
      onNameClick ? (
        <Styled.StrongText onClick={(): void => onNameClick(talent.id)}>
          {talent?.fullName}
        </Styled.StrongText>
      ) : (
        <td>{talent?.fullName}</td>
      ),
    [onNameClick, talent],
  );

  const referredByColumn = useMemo(
    () =>
      showReferredBy ? (
        <Styled.ReferredByBtn onClick={(): void => onReferredByClick?.(talent.id)}>
          <EyeIcon width="20px" />
        </Styled.ReferredByBtn>
      ) : (
        <Fragment />
      ),
    [showReferredBy],
  );
  const feedbackButton = useMemo(
    () =>
      onEditBtnClick ? (
        <Styled.ReferredByBtn onClick={(): void => onEditBtnClick?.(talent.id)}>
          <EditIcon width="20px" />
        </Styled.ReferredByBtn>
      ) : (
        <Fragment />
      ),
    [onEditBtnClick],
  );

  const talentVersionButton = useMemo(
    () =>
      onTalentMatchBtnClick ? (
        <Styled.ReferredByBtn onClick={(): void => onTalentMatchBtnClick?.(talent.id)}>
          <HandShakeIcon width="20px" />
        </Styled.ReferredByBtn>
      ) : (
        <Fragment />
      ),
    [onTalentMatchBtnClick],
  );

  if (talent) {
    const { id, phone, country, createdAt, email, profile } = talent;
    const { takenDomainTests, statusMeta, isPassPS, isPassHR, isPassFinalTech, isHired } =
      profile || {};
    const talentMeta = statusMeta && JSON.parse(statusMeta);
    return (
      <tr key={id}>
        {talentNameColumn}
        <td>{phone}</td>
        <td>{email}</td>
        <td>{country && codeToCountry[country]}</td>
        <td>{moment(new Date(createdAt)).format("ll")}</td>
        <td>{inString(isPassDomain(takenDomainTests || []))}</td>
        <td>{domainTestStartTime(takenDomainTests?.[0] || null)}</td>
        <td>{inString(isPassPS)}</td>
        <td>{getFormatedDate(talentMeta?.dateOfPSStatus)}</td>
        <td>{inString(isPassHR)}</td>
        <td>{inString(isPassFinalTech)}</td>
        <td>{inString(isHired)}</td>
        <td>{getFormatedDate(talentMeta?.dateOfHiring)}</td>
        <td>{referredByColumn}</td>
        <ShouldRender if={superAdmin && profile}>
          <td>{feedbackButton}</td>
        </ShouldRender>
        <ShouldRender if={!(superAdmin && profile)}>
          <td></td>
        </ShouldRender>
        <td>{talentVersionButton}</td>
      </tr>
    );
  }
  return <Fragment key={index} />;
};

export default TableRow;
