import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ChatMenuWrapper = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  background: #ffffff;
  border-right: 1px solid #e0e0e0;
  box-shadow: none;
  border-radius: 0px;
  z-index: 2;
  overflow: hidden;
  padding: 27px 19px 0px 19px;
  @media only screen and (min-width: 768px) {
    min-width: 330px;
    max-width: 330px;
  }
  @media only screen and (min-width: 992px) {
    padding: 27px 10px 0px 10px;
  }
  @media only screen and (min-width: 1200px) {
    min-width: 413px;
    max-width: 413px;
    padding: 27px 19px 0px 19px;
  }
`;

export const ChatMenuInner = styled.div`
  width: 100%;
`;

export const ChatSearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 10px;
`;

export const ChatSearchInput = styled.input`
  font-size: 12px;
  line-height: 16px;
  color: rgb(66, 57, 113);
  background: #f7f9fa;
  border: 0.5px solid rgba(101, 101, 101, 0.2);
  border-radius: 0px;
  padding: 6px 56px 6px 12px;
  width: 100%;
  height: 40px;
  font-family: "Avenir LT Std";
  font-weight: 400;

  &:focus {
    outline: none;
    box-shadow: none;
    background: #f7f9fad9;
    border: 0.5px solid rgba(101, 101, 101, 0.5);
    border-radius: 0px;
  }

  &.error {
    border: 1px solid red;
    background: red;
  }

  &::-webkit-input-placeholder {
    color: #93989a;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "Avenir LT Std";
  }
  &:-ms-input-placeholder {
    color: #93989a;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "Avenir LT Std";
  }
  &::placeholder {
    color: #93989a;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "Avenir LT Std";
  }
`;

export const ChatSearchInputIcon = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: auto;
  right: 32px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: auto 0px;
  font-size: 20px;
  line-height: 20px;
  color: #93989a;
  font-weight: 400;
  & i {
    &::before {
      color: #93989a;
    }
  }
`;

export const ChatMenuList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 152px);
  min-height: calc(100vh - 152px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 10px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (min-width: 576px) {
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
  }
`;

export const LoadMoreButton = styled(Button)`
  background: #6c5dd3;
  min-height: 3rem;
  min-width: 7.5rem;
  border: none;
  box-shadow: none;
  position: relative;
  font-weight: 500;
  margin-bottom: 20px;

  &:hover,
  &:active,
  &:focus {
    transition: all 0.2s;
    background-color: #6c5dd3 !important;
    box-shadow: none !important;
  }
`;
