import { createContext } from "react";
import { initialRecentMessages, initialCurrentUser, initialAllUsers } from "utils";
import {
  ICurrentUserContext,
  IRecentMessageContext,
  IChatUsersListContext,
} from "./chat.interface";

export const RecentMessagesContext = createContext<IRecentMessageContext>({
  recentMessages: initialRecentMessages,
  setRecentMessages: () => {},
});

export const CurrentChatUserContext = createContext<ICurrentUserContext>({
  currentUser: initialCurrentUser,
  setCurrentUser: () => {},
});

export const ChatUsersListContext = createContext<IChatUsersListContext>({
  allUsers: initialAllUsers,
  setAllUsers: () => {},
});
