import { Client } from "API";
import React, { Fragment } from "react";
import { ShouldRender } from "@remotebase/components";
import ClientTableRowData from "./clientTableRowData";

interface Props {
  data?: (Client | null)[];
}

export const ClientTableData: React.FC<Props> = ({ data }) => {
  return (
    <ShouldRender if={data}>
      <Fragment>
        {data?.map((client: Client, id: number) => (
          <ClientTableRowData client={client} key={`client-${id}`} />
        ))}
      </Fragment>
    </ShouldRender>
  );
};

export default ClientTableData;
