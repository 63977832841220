import { useLazyQuery, useMutation } from "@apollo/client";
import {
  TalentProfile,
  ListTalentProfilesQuery,
  ListTalentProfilesQueryVariables,
  UpdateTalentProfileWithUsdSalaryMutation,
  UpdateTalentProfileWithUsdSalaryMutationVariables,
} from "@remotebase/amplify-constants/API";
import { listTalentProfiles } from "@remotebase/amplify-constants/graphql/queries";
import { updateTalentProfileWithUsdSalary } from "@remotebase/amplify-constants/graphql/mutations";
import { ReturnPropsUpdateTalentProfile } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";
// eslint-disable-next-line
export const useTalentsProfileList = (): any => {
  const [getTalentProfileList, { data, loading }] = useLazyQuery<
    ListTalentProfilesQuery,
    ListTalentProfilesQueryVariables
  >(getQuery(listTalentProfiles));
  const talentList = data?.listTalentProfiles?.items || null;
  const nextToken = data?.listTalentProfiles?.nextToken || null;

  return { getTalentProfileList, data: talentList, loading, nextToken };
};

export const useUpdateTalentProfile = (
  updateTalentState: (updatedProfileData: UpdateTalentProfileWithUsdSalaryMutation) => void,
): ReturnPropsUpdateTalentProfile => {
  const [updateTalentProfile, { data, loading }] = useMutation<
    UpdateTalentProfileWithUsdSalaryMutation,
    UpdateTalentProfileWithUsdSalaryMutationVariables
  >(getQuery(updateTalentProfileWithUsdSalary), {
    onCompleted: (returnedData) => {
      updateTalentState(returnedData);
    },
  });

  return {
    updateTalentProfile,
    data: data?.updateTalentProfileWithUsdSalary?.data?.talent?.profile as TalentProfile,
    loading,
  };
};
