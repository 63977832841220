import * as yup from "yup";
import { UserRoles } from "@remotebase/amplify-constants/API";

export enum CreateClientForm {
  email = "email",
  password = "password",
  fullName = "fullName",
  phone = "phone",
  country = "country",
  companyID = "companyID",
}

export enum ClientFilterForm {
  filterBy = "filterBy",
  query = "query",
}

export enum ClientFilterBy {
  email = "email",
  fullName = "fullName",
}

export enum UpdateVersionOtherInfoForm {
  experience = "experience",
  englishProficiency = "englishProficiency",
  expectedSalaryInUsd = "expectedSalaryInUsd",
}

export enum UpdateVersionBasicInfoForm {
  imageLink = "imageLink",
  title = "title",
}

export enum SaveNewVersionForm {
  name = "name",
}

export enum MatchJobForm {
  companyID = "companyID",
  jobID = "jobID",
  isRockstar = "isRockstar",
}

export enum CreateAdminUserForm {
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PASSWORD = "password",
  ROLE = "role",
}

export const CreateClientSchema = yup.object().shape({
  [CreateClientForm.email]: yup.string().email().required().trim(),
  [CreateClientForm.password]: yup.string().required().min(8).max(20),
  [CreateClientForm.fullName]: yup.string().required().trim(),
  [CreateClientForm.phone]: yup.string().required().trim(),
  [CreateClientForm.country]: yup.string().required().trim(),
  [CreateClientForm.companyID]: yup.string().required().trim(),
});

export const ClientFilterSchema = yup.object().shape({
  [ClientFilterForm.filterBy]: yup.string().optional(),
  [ClientFilterForm.query]: yup.string().optional(),
});

export const UpdateVersionOtherInfoSchema = yup.object().shape({
  [UpdateVersionOtherInfoForm.experience]: yup.string().required().trim(),
  [UpdateVersionOtherInfoForm.englishProficiency]: yup.string().required().trim(),
  [UpdateVersionOtherInfoForm.expectedSalaryInUsd]: yup
    .number()
    .typeError("Expected salary must be a number")
    .required("Expected salary is required")
    .min(0)
    .label("Expected Salary"),
});

export const UpdateVersionBasicInfoSchema = yup.object().shape({
  [UpdateVersionBasicInfoForm.imageLink]: yup.string().url().required().label("Image"),
  [UpdateVersionBasicInfoForm.title]: yup.string().required("Title is required"),
});

export const MatchJobSchema = yup.object().shape({
  [MatchJobForm.companyID]: yup.string().required().label("Company"),
  [MatchJobForm.jobID]: yup.string().required().label("Job"),
  [MatchJobForm.isRockstar]: yup.boolean().default(false),
});

export const SaveNewVersionSchema = yup.object().shape({
  [SaveNewVersionForm.name]: yup.string().required(),
});

export const CreateAdminUserSchema = yup.object().shape({
  [CreateAdminUserForm.EMAIL]: yup.string().email().required(),
  [CreateAdminUserForm.PASSWORD]: yup.string().min(8).required(),
  [CreateAdminUserForm.FIRST_NAME]: yup.string().required(),
  [CreateAdminUserForm.LAST_NAME]: yup.string().required(),
  [CreateAdminUserForm.ROLE]: yup.string().oneOf(Object.values(UserRoles)).required(),
});
