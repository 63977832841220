import React, { Fragment } from "react";
import { FeedbackInput, ShouldRender } from "@remotebase/components";
import { networkLabel } from "utils";
import TalentMatchStatus from "./TalentMatchStatus";
import * as Styled from "./styles";

interface Props {
  isEdit?: boolean;
  setIsNetwork: React.Dispatch<React.SetStateAction<boolean>>;
  isNetwork: boolean;
}

const MatchTalent: React.FC<Props> = ({ isEdit, setIsNetwork, isNetwork }) => {
  return (
    <Fragment>
      <Styled.DivWrapperHead>
        <Styled.ContainerStatus>
          <FeedbackInput
            checked={isNetwork}
            inputClassName="ml-2"
            label={networkLabel}
            onChange={setIsNetwork}
            type="checkbox"
            disabled={!isEdit}
          />
        </Styled.ContainerStatus>
      </Styled.DivWrapperHead>
      <ShouldRender if={isNetwork}>
        <Fragment>
          <TalentMatchStatus isEdit={!!isEdit} />
        </Fragment>
      </ShouldRender>
    </Fragment>
  );
};

export default MatchTalent;
