import withApolloProvider from "hooks/apollo/withApollo";
import SidebarLayout from "layouts/sidebar_layout";
import {
  AdminUsers,
  ChatPage,
  Clients,
  CreateAdminUserPage,
  CreateClient,
  DashboardPage,
  NotFound,
  RecruitersLeaderboard,
  GenerateRecruitersReport,
  RecruitersPage,
  ReferredBy,
  TalentProfileVersionPage,
  InvoicesPage,
  TalentsFeedback,
  TalentsPage,
  PaymentHistory,
} from "pages";
import { Fragment, useContext, useEffect, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import { FullPageLoader, ShouldRender } from "@remotebase/components";
import { withSidebar } from "state/sidebar";
import withProfile from "state/profileSteps/withProfileHoc";
import { AuthRoutes as path, combineHOCs, ProfileProps, SidebarProps } from "utils";
import { UserRoles } from "@remotebase/amplify-constants/API";
import { AuthContextType } from "../state/types";
import { AuthContext } from "../state/auth";

interface Props extends ProfileProps, SidebarProps {}
const AuthRouter: React.FC<Props> = (props) => {
  const {
    authState: { userRole },
  } = useContext<AuthContextType>(AuthContext);

  const {
    profileState: { data, isLoading },
    setSidebarType,
    sidebarType,
  } = props;

  const adminRoutes = useMemo(
    () => ({
      [UserRoles.ADMIN]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Clients,
        path.CreateClient,
        path.PaymentsHistory,
        path.Chats,
        path.ChatWithId,
      ],
      [UserRoles.SUPERADMIN]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Clients,
        path.AdminUsers,
        path.PaymentsHistory,
        path.CreateAdminUser,
        path.CreateClient,
        path.Chats,
        path.ChatWithId,
      ],
      [UserRoles.TECHRECRUITER]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Chats,
        path.ChatWithId,
      ],
      [UserRoles.SALES]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Chats,
        path.ChatWithId,
      ],
      [UserRoles.FINANCE]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Chats,
        path.ChatWithId,
        path.PaymentsHistory,
        path.Clients,
      ],
      [UserRoles.CUSTOMERSUCCESS]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Chats,
        path.ChatWithId,
      ],
      [UserRoles.CUSTOMERSUPPORT]: [
        path.Dashbord,
        path.Talents,
        path.TalentProfileVersion,
        path.Recruiters,
        path.RecruitersLeaderboard,
        path.RecruitersNewLeaderboard,
        path.RecruitersPerformance,
        path.ReferredBy,
        path.TalentsFeedback,
        path.Chats,
        path.ChatWithId,
      ],
    }),
    [],
  );

  const routes = useMemo(
    () => ({
      [path.Dashbord]: DashboardPage,
      [path.Talents]: TalentsPage,
      [path.TalentProfileVersion]: TalentProfileVersionPage,
      [path.Recruiters]: RecruitersPage,
      [path.RecruitersPerformance]: GenerateRecruitersReport,
      [path.RecruitersNewLeaderboard]: GenerateRecruitersReport,
      [path.RecruitersLeaderboard]: RecruitersLeaderboard,
      [path.ReferredBy]: ReferredBy,
      [path.TalentsFeedback]: TalentsFeedback,
      [path.Clients]: Clients,
      [path.AdminUsers]: AdminUsers,
      [path.PaymentsHistory]: PaymentHistory,
      [path.CreateAdminUser]: CreateAdminUserPage,
      [path.CreateClient]: CreateClient,
      [path.Chats]: ChatPage,
      [path.ChatWithId]: ChatPage,
    }),
    [],
  );

  useEffect(() => {
    if (!isLoading && userRole?.[0]) {
      setSidebarType(userRole[0] as UserRoles);
    }
  }, [isLoading, userRole]);

  return (
    <Fragment>
      <ShouldRender if={isLoading}>
        <FullPageLoader />
      </ShouldRender>

      <ShouldRender if={!isLoading && data !== undefined}>
        <Switch>
          <ThemeProvider theme={theme}>
            <SidebarLayout>
              {(adminRoutes[sidebarType] ?? []).map((route, index) => (
                <Route exact path={route} component={routes[route]} key={`route-${index}`} />
              ))}
              <Route exact path={path.NotFound} component={NotFound} />
              <Route exact path={path.Invoices} component={InvoicesPage} />
            </SidebarLayout>
          </ThemeProvider>
        </Switch>
      </ShouldRender>
    </Fragment>
  );
};

export default combineHOCs(withProfile, withApolloProvider, withSidebar)(AuthRouter);
