import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { TalentTableHideColumns, ITalTableDisplay } from "utils";
import { DropdownAttributes } from "utils/constants/dashboard";
import * as Styled from "../styles";

interface Props {
  display: ITalTableDisplay;
  toggle: (key: string) => void;
}

export const TableColumnDisplay: FC<Props> = ({ display, toggle }): JSX.Element => {
  return (
    <Dropdown>
      <Styled.DropdownBtn title={DropdownAttributes.title}>
        {Object.keys(display).map((key) => (
          <Styled.Label key={key}>
            <input type="checkbox" checked={display[key]} onChange={(): void => toggle(key)} />
            <Styled.Span>{TalentTableHideColumns[key]}</Styled.Span>
          </Styled.Label>
        ))}
      </Styled.DropdownBtn>
    </Dropdown>
  );
};

export default TableColumnDisplay;
