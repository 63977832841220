import { FC, useState } from "react";
import { ViewVersionsModal } from "components/modals";
import * as Styled from "../styles";

interface Props {
  loading: boolean;
}

export const ViewVersionsBtn: FC<Props> = ({ loading }) => {
  const [showViewVersionsModal, setShowViewVersionsModal] = useState(false);

  const toggleViewVersionsModal = (): void => setShowViewVersionsModal((prev) => !prev);
  return (
    <>
      <Styled.ViewVersionsBtnWraper>
        <Styled.ViewVersionsBtn onClick={toggleViewVersionsModal} disabled={loading}>
          View Versions
        </Styled.ViewVersionsBtn>
        <ViewVersionsModal open={showViewVersionsModal} close={toggleViewVersionsModal} />
      </Styled.ViewVersionsBtnWraper>
    </>
  );
};

export default ViewVersionsBtn;
