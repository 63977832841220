import styled from "styled-components";

export const ReplyChatWrapper = styled.div`
  width: fit-content;
  padding: 12px 18px;
  min-width: fit-content;
  max-width: 290px;
  background: #eff4ff;
  border: none;
  border-radius: 10px 10px 10px 0px;
  margin: 0px auto 20px 0px;

  &.myMessage {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px 10px 0px 10px;
    margin: 0px 0px 20px auto;
  }
`;

export const ReplyChatText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
  color: #3e4463;
  max-width: 246px;
  margin: 0px auto 4px 0px;

  &.myMessage {
    color: #1c1b1f;
  }
`;

export const ReplyChatTime = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #7c7c7c;
  max-width: 246px;
  margin: 0px 0px 0px auto;
  text-align: right;
`;
