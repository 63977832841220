import { FC, Fragment, useMemo } from "react";
import { BtnLoader, ShouldRender } from "@remotebase/components";
import { EditBtnText, SaveBtnText } from "utils";
import { IFeedbackProps, IFetchTalentFeedbackProps } from "state/types/talentFeedback.interface";
import { withFetchTalent, withUpdateFeedback } from "state/talentFeedback";
import * as Styled from "./styles";

const SubmitFeedbackButton: FC<IFeedbackProps & IFetchTalentFeedbackProps> = ({
  talentState: { isEditable, saveFeedbackLoading },
  toggleIsEditable,
  saveFeedback,
}) => {
  const editOrSaveFeedback = (): void => {
    if (isEditable) saveFeedback();
    else toggleIsEditable();
  };

  const buttonContent = useMemo(() => (isEditable ? SaveBtnText : EditBtnText), [isEditable]);

  return (
    <Styled.EditButton onClick={editOrSaveFeedback}>
      <ShouldRender if={saveFeedbackLoading}>
        <BtnLoader />
      </ShouldRender>
      <ShouldRender if={!saveFeedbackLoading}>
        <Fragment>{buttonContent}</Fragment>
      </ShouldRender>
    </Styled.EditButton>
  );
};

export default withUpdateFeedback(withFetchTalent(SubmitFeedbackButton));
