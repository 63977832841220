import { feedbackType, TechDomainSkills } from "@remotebase/amplify-constants/API";

import { IFeedbackStateType, IFetchTalentStateType } from "state/types/talentFeedback.interface";

export const EditBtnText = "Edit";
export const SaveBtnText = "Save";

export const fetchTalentDefault: IFetchTalentStateType = {
  data: null,
  isEditable: false,
  fetchFeedbackLoading: false,
  saveFeedbackLoading: false,
  matchTalentLoading: false,
  matchedJobs: [],
};
export const feedbackDefault: IFeedbackStateType = {
  talentFeedback: [],
  resumeLink: null,
  matchedJobs: [],
  isNetwork: null,
  companies: [],
  jobs: {},
};
export const experienceLabel = "Experience(In Years)";
export const lockedLabel = "Locked?";
export const publishedLabel = "Published?";
export const rockstarLabel = "Rockstar?";
export const scoreLabel = "Score";
export const totalScoreLabel = "Total Score";
export const testPassedLabel = "Passed?";
export const talentMatchModalTitle = "Talent Match";
export const networkLabel = "Network";
export const sentimentLabel = "Sentiment";
export const selectCompanyLabel = "Select company";
export enum TalentProfileEnum {
  resumeLink = "resumeLink",
  talentFeedback = "talentFeedback",
  matchedJobs = "matchedJobs",
  isNetwork = "isNetwork",
}

export const feedBackTypeText = {
  HRFeedback: "HR Screening",
  MiddleTierFeedback: "Middle-Tier Feedback",
  FinalInterviewFeedback: "Final Tech Interview",
  CulturalFeedback: "Cultural Feedback",
};

export interface IFeedbackSummary {
  type: feedbackType;
  score: number;
}

export enum ResetAndPassTestsList {
  DomainTests = "Domain Tests",
  HRScreening = "HR Screening",
  ProblemSolving = "Problem Solving",
  FinalInterview = "Final Tech",
}

export interface IResetCardProp {
  title?: TechDomainSkills | ResetAndPassTestsList;
  isPass?: boolean | null;
  score?: number | null;
  percentage?: number | null;
  dateAttempted?: string | null;
  description?: string | null;
}
