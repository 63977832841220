import styled from "styled-components";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";

export const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 22px 32px 28px;
  margin-bottom: 46px;
  font-family: "Poppins", sans-serif;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProfileHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProfileBlockTitle = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -1px;
  margin-bottom: 24px;
  display: inline-flex;

  @media only screen and (max-width: 991px) {
    font-size: 30px !important;
    line-height: 1.125 !important;
  }

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
`;

export const ProfileBadge = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  padding: 5px 14px;
  display: inline-flex;
  border-radius: 8px;
  line-height: 1;
  margin: 8px 0 auto 8px;
`;

export const PositiveBadge = styled(ProfileBadge)`
  color: #fff;
  background-color: #3f73f6;
`;

export const NegativeBadge = styled(ProfileBadge)`
  color: #fff;
  background-color: #d8453e;
`;

export const NeutralBadge = styled(ProfileBadge)`
  color: #222;
  background-color: #aeafaf;
`;

export const DropDownList = styled.select`
  padding: 14px 25px !important;
  margin: 0;
  border: 2px solid #e5e5e5;
  height: 55px !important;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 27.5px !important;

  &.disable {
    background-color: #ededed;
  }
`;

export const ListItem = styled.option`
  list-style: none;
  margin-bottom: 0.8em;
  background-color: red;
  color: blue;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
`;

export const TalentRoleDiv = styled.div``;

export const TalentStatusDiv = styled.div`
  width: 225px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContainerStatus = styled.div`
  display: flex !important;
`;

export const ScoreInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
`;

export const FeedbackInputWrapper = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const HeadOpt = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 5px;
  margin-bottom: 11px;

  @media only screen and (max-width: 575px) {
    padding-top: 3px;
  }
`;

export const OptButton = styled.button`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: #3f73f6;
  border: none;
  transition: all 0.25s;
  margin-left: 13px;

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #e4e4e4;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }
`;

export const ScoreDropDownWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 20px;
`;

export const FeedbackInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
`;

export const DivWrapper = styled.div`
  opacity: 1;
`;

export const ActionIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;

export const MatchEngLabel = styled.label`
  font-size: 16px;
  color: #11142d;
  font-weight: 600;
  display: inline-flex;
  margin-bottom: 0;
`;

export const DivWrapperHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const NetworkHeadOpt = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const NetworkButton = styled(Button)`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #3f73f6;
  padding: 4px;
  margin-left: 8px;

  &:disabled {
    background-color: #ededed;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: #ededed;
      border: none;
      box-shadow: none;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #0069d9;
    border: none;
    box-shadow: none;
  }

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #fff;
    }
  }
`;

export const DropdownBtn = styled(DropdownButton)`
  width: 100%;
  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #dddfe2;
      color: #474d58;
    }
  }
  & .dropdown-toggle {
    font-size: 16px;
    color: #757575;
    width: 100%;
    height: 52px;
    background-color: #fff;
    border: 2px solid #dddfe2;
    padding: 8px 20px;
    border-radius: 27px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      height: 46px;
      font-size: 12px;
      padding: 4px 15px;
    }
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #fff !important;
      border: 1px solid #dddfe2 !important;
      color: #474d58 !important;
    }
  }
  & .dropdown-menu {
    border-radius: 6px;
    padding: 6px 0;
    width: 100%;
    left: 0 !important;
    box-shadow: 0 0 14px rgb(0 0 0 / 10%);
  }
  & .dropdown-item {
    color: #474d58;
    font-size: 16px;
    padding: 6px 12px;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 5px 10px;
    }
    &:hover,
    &:focus,
    &.active {
      color: black;
      background-color: #abe2fb;
    }
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  white-space: pre-wrap;
  &.active {
    background-color: #ededed !important;
  }
`;
