import { SidebarProps } from "utils";
import { ReferredGraph } from "components";
import * as Styled from "./styles";

const ReferredBy: React.FC<SidebarProps> = () => {
  return (
    <Styled.DashboardWrapper>
      <Styled.DashboardHead>
        <Styled.MainHeading>
          <Styled.SubHeading>Referred By Graph</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.DashboardHead>
      <Styled.DashboardInner>
        <ReferredGraph />
      </Styled.DashboardInner>
    </Styled.DashboardWrapper>
  );
};

export default ReferredBy;
