import React from "react";
import * as Styled from "./styles";

interface Props {
  value: string;
  small?: boolean;
  active: boolean;
}

export const ReadOnlyInput: React.FC<Props> = ({ value = "", small = false, active }) => {
  return (
    <>
      <Styled.InputGroup $small={small} $active={active}>
        <Styled.ReadOnlyText>{value}</Styled.ReadOnlyText>
      </Styled.InputGroup>
    </>
  );
};

export default ReadOnlyInput;
