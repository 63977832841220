export const imageUrl = {
  logoRemotebase: "/images/logo-remotebase.png",
  logoRemotebaseWhiteTxt: "/images/logo-white.png",
  loginPeopleWorking: "/images/login_people-working.png",
  entryCircle: "/images/entry-circle.svg",
  cube: "/images/cube.png",
  sidebarPic: "/images/sidebar-pic.svg",
  logo: "/images/logo.svg",
  editIcon: "/images/edit.svg",
  arrowLeftIcon: "/images/arrow-left.svg",
  arowRightIcon: "/images/arrow-right.svg",
  logoReact: "/images/react-logo.svg",
  logoMagento: "/images/magento.svg",
  logoDotnet: "/images/dotnet-icon.svg",
  logoPython: "/images/python-logo.svg",
  logoNode: "/images/nodejs-logo.svg",
  logoReactNode: "/images/react-node-logo.svg",
  logoAngular: "/images/angular-logo.svg",
  logoVue: "/images/vuejs-logo.svg",
  logoGraphql: "/images/graphQl-logo.svg",
  logoJavasctipt: "/images/javascript-logo.svg",
  logoRails: "/images/rails-logo.svg",
  logoIOS: "/images/ios-logo.svg",
  logoAndroid: "/images/android-logo.svg",
  logoGo: "/images/golang-logo.svg",
  logoDJango: "/images/django-logo.svg",
  logoAws: "/images/amazon-web-services-logo.svg",
  logoFlutter: "/images/flutter-logo.svg",
  reactNative: "/images/react-native.svg",
  devOps: "/images/dev-ops-logo.svg",
  java: "/images/java-logo.svg",
  userDefault: "/images/userDefault.png",
  talentVersionImg: "/images/purple-lines.png",
};

export default imageUrl;
