import React from "react";
import { TableHeader } from "components";
import { ShouldRender } from "@remotebase/components";
import { withClient } from "state/clients";
import * as Styled from "../styles";
import { WithClientProps } from "../../../utils";
import ClientTableData from "./clientTableData";

export const ClientsTable: React.FC<WithClientProps> = ({ clientState: { allData } }) => {
  const columns = {
    email: "Email",
    fullName: "Full Name",
    phoneNo: "Phone No",
    company: "Company Name",
    createdAt: "Date Created",
    clientID: "Client ID",
  };

  return (
    <Styled.Table>
      <TableHeader columnNames={columns} />
      <tbody>
        <ShouldRender if={allData?.length}>
          <ClientTableData data={allData} />
        </ShouldRender>
      </tbody>
    </Styled.Table>
  );
};

export default withClient(ClientsTable);
