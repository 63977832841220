export const apiInitialState = {
  isLoading: false,
  data: null,
  error: null,
  success: false,
};

export const initialAuthContext = {
  isLoggedIn: null,
  email: null,
  isVerified: null,
  userId: null,
  isLoading: false,
};
