import styled, { css } from "styled-components";
import { Form } from "react-bootstrap";
import { StyledProps } from "utils";

export const InputGroup = styled.div<{ $small: boolean; $active?: boolean }>`
  grid-column: span 2;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  display: flex;
  flex-direction: column;
  row-gap: 0.1rem;
  padding: 0.75rem 1.75rem;
  border-radius: 0.5rem;

  ${(props): StyledProps =>
    props.$small
      ? css`
          padding: 0.5rem 1.25rem !important;
          font-size: 0.9rem !important;
        `
      : css``}

  ${(props): StyledProps =>
    props.$active
      ? css`
          border: 2px solid #3617ba !important;
        `
      : css``}
`;

export const InputLabel = styled.p`
  text-transform: capitalize;
  color: #7c7c7c;
  font-size: 0.7rem;
  margin-bottom: 0;
`;

export const Input = styled(Form.Control)`
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  height: auto !important;
  padding: 0 !important;
  background: transparent;
`;

export const InputBtnCover = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

export const InputBtn = styled.div`
  background-color: #3617ba !important;
  border: none;
  color: #ffffff;
  box-shadow: none;
  position: relative;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  padding: 0.15rem 0.5rem;

  &:hover,
  &:active,
  &:focus {
    transition: all 0.2s;
    background-color: #3617ba !important;
    box-shadow: none !important;
    cursor: pointer;
  }
`;

export const ReadOnlyText = styled.p`
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0 !important;
  cursor: pointer;
`;

export const ErrorMessage = styled.small`
  color: red;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
