export const recruitersColumns = [
  {
    Header: "Recruiter Information",
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Country",
        accessor: "country",
      },
    ],
  },
  {
    Header: "Developers",
    columns: [
      {
        Header: "Signups",
        accessor: "devsInvited",
      },
      {
        Header: "Networked",
        accessor: "devsNetworked",
      },
      {
        Header: "Hired",
        accessor: "devsHired",
      },
    ],
  },
  {
    Header: "Developers Cleared",
    columns: [
      {
        Header: "Problem Solving",
        accessor: "devsClearedProblemSovlingTest",
      },
      {
        Header: "Domain Test",
        accessor: "devsClearedDomainTest",
      },
      {
        Header: "Talha's Interview",
        accessor: "devsClearedFinalTech",
      },
    ],
  },
];

export const marketingColumns = [
  {
    Header: "Recruiter Information",
    columns: [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Account Type",
        accessor: "accountType",
      },
    ],
  },
  {
    Header: "Developers",
    columns: [
      {
        Header: "Signups",
        accessor: "devsInvited",
      },
      {
        Header: "Hired",
        accessor: "devsHired",
      },
      {
        Header: "Countries count",
        accessor: "countryWise",
      },
    ],
  },
  {
    Header: "Developers Cleared",
    columns: [
      {
        Header: "Problem Solving",
        accessor: "devsClearedProblemSovlingTest",
      },
      {
        Header: "Domain Test",
        accessor: "devsClearedDomainTest",
      },
      {
        Header: "HR",
        accessor: "devsClearedHR",
      },
      {
        Header: "Talha's Interview",
        accessor: "devsClearedFinalTech",
      },
    ],
  },
];

export enum TableType {
  Recruiters = "recruiters",
  Marketing = "marketing",
}
