import { useLazyQuery } from "@apollo/client";
import {
  RecruiterByEmailQuery,
  RecruiterByEmailQueryVariables,
  GetRecruiterQuery,
  GetRecruiterQueryVariables,
  ListRecruitersQuery,
  ListRecruitersQueryVariables,
} from "@remotebase/amplify-constants/API";
import * as queries from "@remotebase/amplify-constants/graphql/queries";
import {
  GetRecruiterProfileProps,
  ReturnPropsRecruiterByEmail,
  ReturnPropsRecruiters,
} from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

export const useRecruiterList = (): ReturnPropsRecruiters => {
  const [getRecruiterList, { data, loading }] = useLazyQuery<
    ListRecruitersQuery,
    ListRecruitersQueryVariables
  >(getQuery(queries.listRecruiters));
  const recruitersList = data?.listRecruiters?.items || null;
  const nextToken = data?.listRecruiters?.nextToken || null;

  return { getRecruiterList, data: recruitersList, loading, nextToken };
};

export const useRecruiterByEmail = (): ReturnPropsRecruiterByEmail => {
  const [getRecruiterByEmail, { data, loading }] = useLazyQuery<
    RecruiterByEmailQuery,
    RecruiterByEmailQueryVariables
  >(getQuery(queries.recruiterByEmail));
  const recruiterByEmail = data?.recruiterByEmail?.items || null;
  const nextToken = data?.recruiterByEmail?.nextToken || null;

  return { getRecruiterByEmail, data: recruiterByEmail, loading, nextToken };
};

export const getRecruiterProfile = (): GetRecruiterProfileProps => {
  const [getProfile, { data, loading, error }] = useLazyQuery<
    GetRecruiterQuery,
    GetRecruiterQueryVariables
  >(getQuery(queries.getRecruiter));
  const profileData = data?.getRecruiter || null;
  const errorData = error || (profileData ? undefined : new Error("No Recruiter Found"));
  return { loading, getProfile, profileData, error: errorData };
};
