import styled from "styled-components";

export const ProfileBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fbf9f3;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 28px;
  font-family: "Poppins", sans-serif;

  @media only screen and (max-width: 575px) {
    padding: 22px 20px;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProfileBlockTitle = styled.h2`
  font-family: poppins600;
  display: inline-flex;
  font-weight: 600;

  font-style: normal;
  font-size: 18px;
  line-height: 27px;

  color: #212121;

  flex: none;
  order: 0;
  flex-grow: 0;

  @media only screen and (max-width: 991px) {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  @media only screen and (max-width: 575px) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
`;
