import React, { FC, useEffect, useMemo } from "react";
import { useErrorContext } from "state/error";
import { useCreateAdminUser } from "hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonType, CreateAdminUserForm, CreateAdminUserSchema } from "utils";
import { UserRoles } from "@remotebase/amplify-constants/API";
import { FormButton, FormInput, FormPassword, FormSelect } from "../inputs";
import * as Styled from "./styles";

const CreateAdminUser: FC = () => {
  const { showError } = useErrorContext();
  const { createUser, isLoading, user } = useCreateAdminUser();
  const formhook = useForm({ mode: "onChange", resolver: yupResolver(CreateAdminUserSchema) });

  const rolesDropdownItems = useMemo(
    () => [
      { key: "Admin", value: UserRoles.ADMIN },
      { key: "Super Admin", value: UserRoles.SUPERADMIN },
      { key: "Finance", value: UserRoles.FINANCE },
      { key: "Tech Recruiter", value: UserRoles.TECHRECRUITER },
      { key: "Customer Support", value: UserRoles.CUSTOMERSUPPORT },
      { key: "Customer Success", value: UserRoles.CUSTOMERSUCCESS },
      { key: "Sales", value: UserRoles.SALES },
    ],
    [],
  );

  const roleLabels = useMemo(
    () => ({
      [UserRoles.ADMIN]: "Admin",
      [UserRoles.SUPERADMIN]: "Super Admin",
      [UserRoles.FINANCE]: "Finance",
      [UserRoles.TECHRECRUITER]: "Tech Recruiter",
      [UserRoles.CUSTOMERSUPPORT]: "Customer Support",
      [UserRoles.CUSTOMERSUCCESS]: "Customer Success",
      [UserRoles.SALES]: "Sales",
    }),
    [],
  );

  useEffect(() => {
    if (isLoading || !user) return;

    // todo Perform additional task if needed e.g ending email
    formhook.reset();
    showError?.({
      title: "User Created",
      message: `${roleLabels[user.role]} user created successfully`,
    });
  }, [isLoading, user]);

  return (
    <form onSubmit={formhook.handleSubmit(createUser)}>
      <Styled.CreateForm>
        <FormInput
          title={CreateAdminUserForm.FIRST_NAME}
          handler={formhook}
          placeholder={"Enter First Name"}
        />
        <FormInput
          title={CreateAdminUserForm.LAST_NAME}
          handler={formhook}
          placeholder={"Enter Last Name"}
        />
        <FormInput
          title={CreateAdminUserForm.EMAIL}
          handler={formhook}
          placeholder={"Enter email"}
        />
        <FormPassword
          title={CreateAdminUserForm.PASSWORD}
          handler={formhook}
          placeholder={"Enter Password"}
          showGenerate={true}
        />
        <FormSelect
          title={CreateAdminUserForm.ROLE}
          handler={formhook}
          placeholder={"Select Role"}
          items={rolesDropdownItems}
        />
        <Styled.SubmitButtonCover>
          <FormButton label={"Create User"} isLoading={isLoading} type={ButtonType.SUBMIT} />
        </Styled.SubmitButtonCover>
      </Styled.CreateForm>
    </form>
  );
};

export default CreateAdminUser;
