import styled from "styled-components";

export const EditTalentBackIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 0px;
  cursor: pointer;
  &:hover,
  &:focus {
    & div {
      color: #000 !important;
      & svg {
        & path {
          stroke: #000;
        }
      }
    }
  }
`;

export const EditTalentBackIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 12px;
  background: transparent;
  cursor: pointer;
  & svg {
    width: 8px;
    height: 16px;
    & path {
      stroke: #1a0224;
    }
  }
  &:hover,
  &:focus {
    & svg {
      & path {
        stroke: #000;
      }
    }
  }
`;

export const EditTalentBackText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #4d526d;
  font-weight: 400;
  margin: 0px;
  &:hover,
  &:focus {
    color: #000;
  }
`;
