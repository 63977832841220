import { FC, useState, useMemo, useEffect, Fragment } from "react";
import moment from "moment";
import { Color } from "@remotebase/constants";
import { ActionBtn, ConfirmationModal, ModalComponent, ShouldRender } from "@remotebase/components";
import { PlainCaretIcon } from "assets/icons";
import {
  IResetCardProp,
  resetPassCancelText,
  resetPassConfirmText,
  testBlockList,
  resetBtnsWidth,
  passBtnText,
  resetBtnText,
  ResetAndPassTestsList,
} from "utils";
import Slider from "components/slider";
import * as S from "../styles";

interface IResetAndPassCard {
  resetCardProp: IResetCardProp;
  selectedTest: string;
  index?: number;
  isLoading: boolean;
  passTestHandler: (index?: number) => void;
  resetTestHandler: (index?: number) => void;
}

export const ResetAndPassCard: FC<IResetAndPassCard> = ({
  resetCardProp,
  selectedTest,
  index,
  isLoading,
  passTestHandler,
  resetTestHandler,
}) => {
  const { title, isPass, score, percentage, dateAttempted, description } = resetCardProp;
  const [showDetails, setShowDetails] = useState(!index);
  const [displayTitle, setDisplayTitle] = useState(title as string);
  const [logoUrl, setLogoUrl] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const passCurrentTest = (): void => {
    if (typeof index === "number") passTestHandler(index);
    else passTestHandler();
  };

  const resetCurrentTest = (): void => {
    if (typeof index === "number") resetTestHandler(index);
    else resetTestHandler();
  };

  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    handler: passCurrentTest,
  });

  const modalClickHandler = (isReset?: boolean): void => {
    setModalContent({
      title: `Update ${ResetAndPassTestsList[selectedTest]}`,
      message: `Are you sure you want to ${
        isReset ? "reset" : "pass"
      } "${title}" for this candidate?`,
      handler: isReset ? resetCurrentTest : passCurrentTest,
    });
    setOpenConfirmModal(true);
  };

  const closeConfirmModal = (): void => setOpenConfirmModal(false);

  useEffect(() => {
    if (selectedTest === "DomainTests") {
      const testFromBlock = testBlockList.find((item) => (item.skillName as string) === title);
      setDisplayTitle((testFromBlock?.title as string) || (title as string));
      setLogoUrl(testFromBlock?.icon || "");
    }
  }, [selectedTest, testBlockList, title]);

  const angleOfRotation = useMemo(() => {
    return showDetails ? 180 : 0;
  }, [showDetails]);

  const extractDateAndTime = (): { date: string; time: string } => {
    const timeFormat = moment(dateAttempted);
    const date = timeFormat.format("DD - MM - YYYY");
    const time = timeFormat.format("HH:mm:ss");
    return { date, time };
  };

  return (
    <Fragment>
      <S.ResetCardWrapper>
        <S.ResetCardTop>
          <S.TopLeft>
            <ShouldRender if={logoUrl.length > 0}>
              <S.ImgWrapper src={logoUrl} alt="Img" />
            </ShouldRender>
            <S.TestName>{displayTitle}</S.TestName>
          </S.TopLeft>
          <ShouldRender if={dateAttempted}>
            <S.TopCenter>
              <S.ResetParagraph>
                <S.TextTitle>Date</S.TextTitle>
                <S.TextValue> : </S.TextValue>
                <S.TextValue>{extractDateAndTime().date}</S.TextValue>
              </S.ResetParagraph>
              <S.ResetParagraph>
                <S.TextTitle>Time</S.TextTitle>
                <S.TextValue> : </S.TextValue>
                <S.TextValue>{extractDateAndTime().time}</S.TextValue>
              </S.ResetParagraph>
            </S.TopCenter>
          </ShouldRender>
          <S.TopRight>
            <ShouldRender if={isPass}>
              <S.ResetParagraph>Pass</S.ResetParagraph>
            </ShouldRender>
            <ShouldRender if={isPass === false}>
              <S.ResetParagraph>Fail</S.ResetParagraph>
            </ShouldRender>
            <PlainCaretIcon
              fill="#000"
              width="10px"
              transform={`rotate(${angleOfRotation})`}
              onClick={(): void => setShowDetails(!showDetails)}
            />
          </S.TopRight>
        </S.ResetCardTop>
        <ShouldRender if={showDetails}>
          <Fragment>
            <ShouldRender if={description}>
              <S.ResetDescription
                dangerouslySetInnerHTML={{ __html: description || " " }}
              ></S.ResetDescription>
            </ShouldRender>
            <S.CardCenter>
              <S.TestName>Test Score</S.TestName>

              <S.ResetParagraph>
                <S.TextTitle>Overall</S.TextTitle>
                <S.TextCircle></S.TextCircle>
                <S.TextValue>{`${percentage || score || 0} %`}</S.TextValue>
              </S.ResetParagraph>
              <Slider value={percentage || score || 0} draggable={false} hideValue readOnly />

              <S.WrapStatus>
                <S.TestName>Current Test status</S.TestName>
                <ShouldRender if={isPass}>
                  <S.ResetParagraph>Pass</S.ResetParagraph>
                </ShouldRender>
                <ShouldRender if={isPass === false}>
                  <S.ResetParagraph>Fail</S.ResetParagraph>
                </ShouldRender>
              </S.WrapStatus>
            </S.CardCenter>
            <S.BtnGroup>
              <ActionBtn
                btnText={passBtnText}
                clickHandler={!isPass ? modalClickHandler : undefined}
                width={resetBtnsWidth}
                color={!isPass ? Color.GREEN : `${Color.GREY} !important`}
                backgroundColor={`${Color.TRANSPARENT} !important`}
                border={`1px solid ${!isPass ? Color.GREEN : `${Color.GREY} !important`}`}
                disabled={!!isPass}
                hoverColor={!isPass ? Color.GREEN : `${Color.GREY} !important`}
              />
              <ActionBtn
                btnText={resetBtnText}
                clickHandler={(): void => modalClickHandler(true)}
                width={resetBtnsWidth}
                color={Color.WHITE}
                backgroundColor={Color.LIGHT_BLUE}
              />
            </S.BtnGroup>
          </Fragment>
        </ShouldRender>
      </S.ResetCardWrapper>
      <ModalComponent show={openConfirmModal} handleClose={closeConfirmModal} persist>
        <ConfirmationModal
          heading={modalContent.title}
          message={modalContent.message}
          cancelBtnText={resetPassCancelText}
          confirmBtnText={resetPassConfirmText}
          isLoading={isLoading}
          closeBtnHandler={closeConfirmModal}
          confirmBtnHandler={modalContent.handler}
        />
      </ModalComponent>
    </Fragment>
  );
};

export default ResetAndPassCard;
