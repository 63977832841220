import { TalentProfileVersion } from "@remotebase/amplify-constants/API";
import { BtnLoader, ShouldRender } from "@remotebase/components";
import { ArrowUp } from "assets/icons";
import { useCreateTalentProfileVersion, useUpdateTalentProfileVersion } from "hooks";
import { FC, Fragment, useEffect, useState } from "react";
import { useErrorContext } from "state/error";
import { useProfileVersionContext } from "state/profileVersion";
import { ITalentProfileVersion } from "state/types";
import * as Styled from "../styles";
import JustSaveVersion from "./justSave";
import SaveAsNewVersion from "./saveAsNew";

interface Props {
  setLoading: (value: boolean) => void;
}

export const SaveVersionBtn: FC<Props> = ({ setLoading }) => {
  const { setSelectedVersion, setProfileVersions, getTransformedData } = useProfileVersionContext();
  const [showSaveOptions, setShowSaveOptions] = useState(false);
  const { showError } = useErrorContext();

  const {
    createTalentVersion,
    data: createdData,
    loading: createLoading,
    error: createError,
  } = useCreateTalentProfileVersion();

  const {
    updateTalentVersion,
    data: updatedData,
    loading: updateLoading,
    error: updateError,
  } = useUpdateTalentProfileVersion();

  const saveProfileVersion = (profileVersion: ITalentProfileVersion): void => {
    const { id, talentID, version, ...profileVersionData } = getTransformedData(
      profileVersion as TalentProfileVersion,
    ) as ITalentProfileVersion;

    if (!id) {
      createTalentVersion({
        variables: {
          input: {
            ...profileVersionData,
            talentID,
          },
        },
      });
    } else {
      updateTalentVersion({
        variables: {
          input: {
            ...profileVersionData,
            id,
            expectedVersion: version,
          },
        },
      });
    }
    setShowSaveOptions(false);
  };

  useEffect(() => {
    if (!updateLoading && updatedData) {
      const transformedData = getTransformedData(updatedData) as ITalentProfileVersion;
      setSelectedVersion(transformedData);
      setProfileVersions((prev) =>
        prev.map((version) => (version.id === transformedData.id ? transformedData : version)),
      );
      showError?.({ title: "Version Updated", message: "Profile version updated successfully" });
    }

    setLoading(updateLoading);
  }, [updatedData, updateLoading]);

  useEffect(() => {
    if (!createLoading && createdData) {
      const transformedData = getTransformedData(createdData) as ITalentProfileVersion;
      setSelectedVersion(transformedData);
      setProfileVersions((prev) => [...prev, transformedData]);
      showError?.({
        title: "Version created",
        message: "New profile version created successfully",
      });
    }

    setLoading(createLoading);
  }, [createdData, createLoading]);

  useEffect(() => {
    if (createError || updateError) {
      const message = createError?.message || updateError?.message || "An error occured!";
      const title = createError ? "Create version error" : "Update version error";
      showError?.({ title, message });
    }
  }, [createError, updateError]);
  return (
    <>
      <Styled.SaveVersionsBtnWrapper>
        <Styled.SaveVersionsBtn
          onClick={(): void => setShowSaveOptions(!showSaveOptions)}
          disabled={createLoading || updateLoading}
        >
          <ShouldRender if={!createLoading && !updateLoading}>
            <Fragment>
              Save Version <ArrowUp />
            </Fragment>
          </ShouldRender>
          <ShouldRender if={createLoading || updateLoading}>
            <BtnLoader />
          </ShouldRender>
        </Styled.SaveVersionsBtn>
        <ShouldRender if={showSaveOptions}>
          <Styled.SaveOptionsDiv>
            <SaveAsNewVersion
              loading={createLoading || updateLoading}
              saveProfileVersion={saveProfileVersion}
            />
            <JustSaveVersion
              saveProfileVersion={saveProfileVersion}
              loading={createLoading || updateLoading}
            />
          </Styled.SaveOptionsDiv>
        </ShouldRender>
      </Styled.SaveVersionsBtnWrapper>
    </>
  );
};

export default SaveVersionBtn;
