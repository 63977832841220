import React, { Fragment } from "react";
import { Feedback, feedbackType } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { TalentProfileFeedback } from "components";

interface Props {
  talentFeedback: Array<{
    feedback?: Feedback | null;
    label: string;
    type: feedbackType;
  }>;
  isNetwork?: boolean | null;
}

const ProfileFeedback: React.FC<Props> = ({ talentFeedback, isNetwork }) => {
  return (
    <Fragment>
      {talentFeedback?.map((item, index) => (
        <ShouldRender key={index} if={true}>
          <TalentProfileFeedback {...item} isNetwork={!!isNetwork} />
        </ShouldRender>
      ))}
    </Fragment>
  );
};
export default ProfileFeedback;
