import React, { useMemo } from "react";
import { IFeedbackProps } from "state/types/talentFeedback.interface";
import { getTalentFeedback, withUpdateFeedback } from "state/talentFeedback";
import { ProfileFeedback } from "./components";
import * as Styled from "../styles";

const ProfileSpecs: React.FC<IFeedbackProps> = ({
  feedbackState: { talentFeedback, isNetwork },
}) => {
  const feedback = useMemo(() => getTalentFeedback(talentFeedback), [talentFeedback]);
  return (
    <Styled.ProfileSpecs>
      <ProfileFeedback talentFeedback={feedback} isNetwork={isNetwork} />
    </Styled.ProfileSpecs>
  );
};

export default withUpdateFeedback(ProfileSpecs);
