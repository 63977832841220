import { ApolloError, useMutation } from "@apollo/client";
import {
  CreateAndValidateInvoiceBatchMutation,
  CreateAndValidateInvoiceBatchMutationVariables,
  InvoiceBatch,
} from "@remotebase/amplify-constants/API";
/* eslint-disable-next-line max-len */
import { createAndValidateInvoiceBatch as createAndValidateInvoiceBatchMutation } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "hooks/utils";

interface ReturnPropsCreateAndValidateInvoiceBatch {
  createAndValidateInvoiceBatch: (data?: {
    variables: CreateAndValidateInvoiceBatchMutationVariables;
  }) => void;
  invoiceBatch?: InvoiceBatch | null;
  loading: boolean;
  error?: ApolloError;
}

export default function useCreateAndValidateInvoiceBatch(): ReturnPropsCreateAndValidateInvoiceBatch {
  const [createAndValidateInvoiceBatch, { data, loading, error }] = useMutation<
    CreateAndValidateInvoiceBatchMutation,
    CreateAndValidateInvoiceBatchMutationVariables
  >(getQuery(createAndValidateInvoiceBatchMutation));

  const invoiceBatch = data?.createAndValidateInvoiceBatch?.data || null;

  return { createAndValidateInvoiceBatch, invoiceBatch, loading, error };
}
