import { Feedback, feedbackType, JobMatch } from "@remotebase/amplify-constants/API";
import {
  hrScreeningFeedback,
  middleTierFeedback,
  technicalFeedback,
  culturalFeedback,
} from "@remotebase/constants";
import { ITalentJobsInput } from "state/types/talentFeedback.interface";

export const getTalentFeedback = (
  talentFeedback: Array<Feedback | null>,
): Array<{
  feedback?: Feedback | null;
  label: string;
  type: feedbackType;
}> => [
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.HRFeedback),
    label: hrScreeningFeedback,
    type: feedbackType.HRFeedback,
  },
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.MiddleTierFeedback),
    label: middleTierFeedback,
    type: feedbackType.MiddleTierFeedback,
  },
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.FinalInterviewFeedback),
    label: technicalFeedback,
    type: feedbackType.FinalInterviewFeedback,
  },
  {
    feedback: talentFeedback?.find((item) => item?.type === feedbackType.CulturalFeedback),
    label: culturalFeedback,
    type: feedbackType.CulturalFeedback,
  },
];

export const getTalentMatchJobs = (talentJobs: Array<JobMatch | null>): Array<ITalentJobsInput> => {
  return talentJobs.map(
    (job) =>
      ({
        job: job?.job,
        jobID: job?.jobID,
        isPublished: job?.isPublished,
        matchProcess: job?.process,
        matchStage: job?.stage,
        isRockstar: job?.isRockstar || false,
      } as ITalentJobsInput),
  );
};
