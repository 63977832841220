import moment from "moment";
import { ConversationResponse } from "./API";

export const getFormattedDay = (createdAt?: string | null): { day: string; time: string } => {
  const messageTime = moment(createdAt);
  const timeDifference = moment().diff(messageTime, "days");
  const difference = moment.duration(timeDifference, "days").asDays();
  const time = messageTime.format("hh:mm a");
  if (difference === 0) return { day: "TODAY", time };
  if (difference === 1) return { day: "YESTERDAY", time: `Yesterday ${time}` };
  const day = messageTime.format("DD/MM/YY");
  return { day, time: `${day} ${time}` };
};

export const imageUrl = {
  defaultImage: "/images/default-image.png",
  defaultCard: "/images/default-card.png",
  noCurrentUserImg: "/images/purple_folder.png",
  noUserListImg: "/images/message-img.png",
  talentlyLogoImg: "/images/talently-logo.svg",
  brandSetImg: "/images/landing/brand-set.png",
  cardsSetImg: "/images/landing/cards-set.png",
  connectingSnippetImg: "/images/landing/connecting-snippet.png",
  firefliesLogoImg: "/images/landing/fireflies-logo.svg",
  heartTickBlueImg: "/images/landing/heart-tick-blue.svg",
  heartTickWhiteImg: "/images/landing/heart-tick-white.svg",
  heroTalentImg: "/images/landing/hero-talent.png",
  placerLogoImg: "/images/landing/placer-logo.png",
  skillSetImg: "/images/landing/skill-set.png",
  solutionSnippetImg: "/images/landing/solution-snippet.png",
  swipeBlueImg: "/images/landing/swipe-blue.svg",
  swipeWhiteImg: "/images/landing/swipe-white.svg",
  tuneBlueImg: "/images/landing/tune-blue.svg",
  tuneWhiteImg: "/images/landing/tune-white.svg",
  userBlueImg: "/images/landing/user-blue.svg",
  userWhiteImg: "/images/landing/user-white.svg",
  userSearchBlueImg: "/images/landing/user-search-blue.svg",
  userSearchWhiteImg: "/images/landing/user-search-white.svg",
  userSelectBlueImg: "/images/landing/user-select-blue.svg",
  userSelectWhiteImg: "/images/landing/user-select-white.svg",
  agentnoonLogoImg: "/images/landing/agentnoon-logo.png",
  walnutLogoImg: "/images/landing/walnut-logo.png",
  fabricLogoImg: "/images/landing/fabric-logo.png",
  northwesternLogoImg: "/images/landing/northwestern-logo.png",
  campSocialLogoImg: "/images/landing/camp-social-logo.png",
  integrateLogoImg: "/images/landing/integrate-logo.png",
  flapjackLogoImg: "/images/landing/flapjack-logo.png",
  qureosLogoImg: "/images/landing/qureos-logo.png",
  surmountLogoImg: "/images/landing/surmount-logo.png",
  featureSnippetOneImg: "/images/landing/feature-snippet1.png",
  featureSnippetSecondImg: "/images/landing/feature-snippet2.png",
  featureSnippetThirdImg: "/images/landing/feature-snippet3.png",
  featureSnippetFourthImg: "/images/landing/feature-snippet4.png",
  flashImg: "/images/flash.png",
  appLogo: "/images/landing/app-logo.svg",
  markLogo: "/images/landing/mark-logo.svg",
};

export const sortUsersList = (
  inputList: Array<ConversationResponse | null>,
): Array<ConversationResponse | null> => {
  const arrayForSort = [...(inputList || [])];
  return arrayForSort?.sort((a, b) => {
    const firstDate = a?.messages?.[0]?.createdAt || a?.createdAt;
    const secondDate = b?.messages?.[0]?.createdAt || b?.createdAt;
    if (firstDate && secondDate && firstDate >= secondDate) return -1;
    return 1;
  });
};

export enum LoaderColors {
  Purple = "#4c40f7",
  White = "#FFF",
}
