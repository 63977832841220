import { AuthProps } from "utils";
import { AuthCommonLoginRegister, AuthCommonSetPass, ShouldRender } from "@remotebase/components";
import { useLogin, useNewPassword } from "hooks";
import { FC, useCallback, useEffect, useState } from "react";
import { withAuth } from "state/auth";
import { AuthType, PayloadType, UserType } from "@remotebase/constants";

const Login: FC<AuthProps> = ({ getAuth }) => {
  const [selectedAuthType, setSelectedAuthType] = useState(AuthType.SignIn);

  const {
    performAction: login,
    res: { success: loginSuccess, isLoading: loginLoading, data: user },
  } = useLogin();

  const {
    performAction: setPassword,
    res: { success: passwordSuccess, isLoading: passwordLoading },
  } = useNewPassword();

  const setPasswordHandler = useCallback(
    async ({ password }: PayloadType) => {
      if (!user) return;
      await setPassword({ user, password });
    },
    [user],
  );

  useEffect(() => {
    if (!loginSuccess || !user) return;
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") setSelectedAuthType(AuthType.NewPassword);
    else getAuth();
  }, [loginSuccess, user]);

  useEffect(() => {
    if (passwordSuccess && !passwordLoading) getAuth();
  }, [passwordSuccess, passwordLoading]);

  return (
    <>
      <ShouldRender if={selectedAuthType === AuthType.SignIn}>
        <AuthCommonLoginRegister
          formProps={{ isLoading: loginLoading, performAction: login }}
          userType={UserType.Admin}
          authType={selectedAuthType}
        />
      </ShouldRender>
      <ShouldRender if={selectedAuthType === AuthType.NewPassword}>
        <AuthCommonSetPass
          formProps={{ isLoading: passwordLoading, performAction: setPasswordHandler }}
        />
      </ShouldRender>
    </>
  );
};

export default withAuth(Login);
