import { FC } from "react";
import { useHistory } from "react-router-dom";
import { CreateAdminUser } from "components";
import { AuthRoutes } from "utils";
import * as PageStyled from "../../styles";

const CreateAdminUserPage: FC = () => {
  const { push } = useHistory();

  return (
    <PageStyled.PageWrapper>
      <PageStyled.PageHead>
        <PageStyled.MainHeading>
          <PageStyled.SubHeading>Create Admin</PageStyled.SubHeading>
        </PageStyled.MainHeading>
      </PageStyled.PageHead>
      <PageStyled.Back onClick={(): void => push(AuthRoutes.AdminUsers)}>BACK</PageStyled.Back>
      <CreateAdminUser />
    </PageStyled.PageWrapper>
  );
};

export default CreateAdminUserPage;
