import { FC, Fragment, useEffect, useState } from "react";
import { TalentData } from "hooks/types";
import { NoTableData, TableHeader } from "components";
import { TalentTableColumns, ReferredByColumn } from "utils";
import { ShouldRender } from "@remotebase/components";
import { Talent } from "@remotebase/amplify-constants/API";
import { cloneDeep } from "lodash";
import { TableRowRecruiter } from "components/recruiters/components";

interface ReferrerTableProps {
  data?: TalentData;
  showReferredBy?: boolean;
}

const ReferrerTable: FC<ReferrerTableProps> = (props) => {
  const [talentColumns, setTalentColumns] = useState(cloneDeep(TalentTableColumns));
  const { showReferredBy, data } = props;

  useEffect(() => {
    if (showReferredBy) setTalentColumns({ ...talentColumns, ...ReferredByColumn });
  }, []);

  return (
    <Fragment>
      <TableHeader columnNames={talentColumns} />
      <tbody>
        <ShouldRender if={data?.length}>
          <Fragment>
            {data?.map(
              (value: Talent, index: number): JSX.Element => (
                <TableRowRecruiter talent={value} index={index} {...props} />
              ),
            )}
          </Fragment>
        </ShouldRender>
        <ShouldRender if={!data?.length}>
          <NoTableData span={Object.keys(TalentTableColumns).length} />
        </ShouldRender>
      </tbody>
    </Fragment>
  );
};

export default ReferrerTable;
