import styled from "styled-components";

export const TalentProfileVersionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
`;

export default TalentProfileVersionWrapper;
