import { FC, Fragment, useState } from "react";
import { RecruiterProps } from "utils";
import { ShouldRender } from "@remotebase/components";
import { Recruiter } from "@remotebase/amplify-constants/API";
import { RecruitersModal } from "components";
import { withRecruiter } from "state/recruiters";
import { mapOutput } from "hooks/recruiter";
import GetRecruiter from "./GetRecruiter";

export const RecruiterTableBody: FC<RecruiterProps> = ({ recruiterState: { data } }) => {
  const [showModal, setShowModal] = useState(false);
  const [recruiterId, setRecruiterId] = useState("");
  const handleModalShow = (id: string): void => {
    setRecruiterId(id);
    setShowModal(!showModal);
  };
  const getRecruiter = (recruiter: Recruiter, index: number): JSX.Element => (
    <GetRecruiter recruiter={recruiter} key={index} handleModalShow={handleModalShow} />
  );
  const listRecruiterMember = mapOutput(data || [], getRecruiter);
  return (
    <tbody>
      <ShouldRender if={data?.length}>
        <Fragment>
          {listRecruiterMember}
          <ShouldRender if={showModal}>
            <RecruitersModal recruiterId={recruiterId} handleShow={handleModalShow} />
          </ShouldRender>
        </Fragment>
      </ShouldRender>
      <ShouldRender if={!data?.length}>
        <tr>
          <td colSpan={6} className="text-center">
            No Data available
          </td>
        </tr>
      </ShouldRender>
    </tbody>
  );
};
export default withRecruiter(RecruiterTableBody);
