import React, { useState } from "react";
import { ITalentJobsInput } from "state/types/talentFeedback.interface";
import { ShouldRender } from "@remotebase/components";
import { TalentMatchModal } from "components";
import { EyeIcon, PlusIcon } from "assets/icons";
import { Job } from "@remotebase/amplify-constants/API";
import * as Styled from "./styles";

interface Props {
  updateMatchedList: () => void;
  job?: Job | null;
  matchedJobs: Array<ITalentJobsInput | null>;
  isEdit: boolean;
  removeTalentMatch: (id: string) => void;
}

export const TalentMatchHeader: React.FC<Props> = ({
  updateMatchedList,
  matchedJobs,
  removeTalentMatch,
  job,
  isEdit,
}) => {
  const [talentMatchModal, setTalentMatchModal] = useState<boolean>(false);
  const handleModalShow = (): void => setTalentMatchModal(!talentMatchModal);

  return (
    <Styled.ActionIcons>
      <Styled.MatchEngLabel>Match Talent</Styled.MatchEngLabel>
      <Styled.NetworkHeadOpt>
        <Styled.NetworkButton disabled={!isEdit} onClick={handleModalShow} aria-label={"eye-icon"}>
          <EyeIcon />
        </Styled.NetworkButton>
        <ShouldRender if={talentMatchModal}>
          <TalentMatchModal
            handleShow={handleModalShow}
            matchedList={matchedJobs}
            removeTalentMatch={removeTalentMatch}
          />
        </ShouldRender>
        <Styled.NetworkButton
          disabled={!isEdit || !job}
          onClick={updateMatchedList}
          aria-label={"plus-icon"}
        >
          <PlusIcon />
        </Styled.NetworkButton>
      </Styled.NetworkHeadOpt>
    </Styled.ActionIcons>
  );
};

export default TalentMatchHeader;
