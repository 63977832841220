import styled from "styled-components";
import { Form } from "react-bootstrap";
import { StyledProps } from "utils";

export const DatePickerStyle = styled.div<{ $report?: boolean }>`
  max-width: ${(props): StyledProps => (props.$report ? "100%" : "140px")};
  max-height: 50px;
  align-items: center;
  width: auto;
`;

export const DatePickerText = styled.div<{ $report?: boolean }>`
  padding: 10px;
  padding-left: ${(props): StyledProps => (props.$report ? "10px" : "40px")};
  width: fit-content;
`;

export const DateAlign = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: right;
  padding-top: 70px;
  padding-bottom: 30px;
`;

export const DateAlignReport = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const EmailInputField = styled(Form.Control)`
  font-size: 14px !important;
  font-weight: normal !important;
  border: 1px solid #dddfe2 !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  height: 50px !important;
  width: 100% !important;
  max-width: 500px;
  min-width: 200px !important;
  padding: 8px 12px !important;
  margin: 0 16px !important;
  margin-bottom: 10px !important;
  &:focus {
    box-shadow: none !important;
  }
  @media only screen and (max-width: 1279px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 1199px) {
    margin-left: 0 !important;
  }
  @media only screen and (max-width: 575px) {
    margin: 0 0 16px 0 !important;
    min-width: 150px !important;
    padding: 8px 10px;
  }
`;

export const DateSelectorsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const BtnWrapper = styled.div`
  width: fit-content;
  margin: 2rem auto 0;
`;

export const PickerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
`;
