import styled from "styled-components";

const animate = `
animation: shimmer 4s infinite linear;
background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
background-size: 1000px 100%;`;

const shimmerStyle = `
background-color: #ededed;
border-radius: 4px;
align-items: center;
justify-content: center;`;

export const EditTalentBodyWrapperShimmer = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const EditTalentMainWrapperShimmer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${shimmerStyle}
  ${animate}
  border-radius: 20px;
  padding: 28px 0px;
  z-index: 2;

  @media only screen and (min-width: 992px) {
    padding: 32px 35px;
  }
`;

export const ProfileQualificationWrapperShimmer = styled.div`
  width: 100%;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1.15fr;
  }
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1.25fr;
  }
  @media only screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1.4fr;
  }
`;

export const ProfileAcademicWrapperShimmer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`;
