export const downloadAsCSV = ({
  data,
  columnDelimiter = ",",
  lineDelimiter = "\n",
}: {
  data: Array<Record<string, string>>;
  columnDelimiter?: string;
  lineDelimiter?: string;
}): void => {
  let result: BlobPart;
  let ctr: number;

  if (data === null || !data.length) {
    return;
  }

  const keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }

      result +=
        typeof item[key] === "string" && item[key].includes(columnDelimiter)
          ? `"${item[key]}"`
          : item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });
  const downloadLink = document.createElement("a");
  const blob = new Blob(["\ufeff", result]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = "data.csv";

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
export default downloadAsCSV;
