import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useMarkAsRead,
  useGetConvoMessages,
  useGetTotalChatCount,
  useGetPollingMessages,
  useGetAllChatList,
} from "hooks";
import withApolloProvider from "hooks/apollo/withApollo";
import { GetAllConversationsInput, MessageResponse } from "API";
import { ProfileContext } from "state/profileSteps";
import { clone, reverse } from "lodash";
import { useParams } from "react-router-dom";
import {
  MESSAGE_FETCH_LIMIT,
  SCROLL_TIMEOUT,
  POLL_INTERVAL,
  SUBSCRIPTION_LIMIT,
  sortUsersList,
} from "utils";
import { RecentMessagesContext, ChatUsersListContext } from "./chat.context";
import { getReadBy, scrollToEnd } from "./chat.helper";
import { CurrentChatProps, IChatParam } from "./chat.interface";

export function withChatMessage<T>(Component: React.FC<T & CurrentChatProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { conversationId } = useParams<IChatParam>();

    const chatBoxRef = useRef<HTMLDivElement | null>(null);
    const [firstCall, setFirstCall] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPoll, setIsPoll] = useState(false);
    const [chatMessages, setChatMessages] = useState<Array<MessageResponse | null>>([]);
    const [totalCount, setTotalCount] = useState(0);

    const {
      profileState: { data },
    } = useContext(ProfileContext);
    const { recentMessages, setRecentMessages } = useContext(RecentMessagesContext);
    const { allUsers, setAllUsers } = useContext(ChatUsersListContext);

    const {
      loading: usersListLoading,
      data: usersList,
      nextToken: nextPageChatToken,
      getMoreChats: fetchMoreChats,
    } = useGetAllChatList();
    const { nextToken, getMessages, loading, data: messageData } = useGetConvoMessages();
    const {
      getMessages: getPollingMessages,
      loading: pollingLoading,
      data: polledData,
      startPolling,
      stopPolling,
    } = useGetPollingMessages();
    const { getTotalMsgCount, data: messageCount } = useGetTotalChatCount();
    const { markAsRead, data: markAsReadRes } = useMarkAsRead();

    const updateRecentMessage = (message: MessageResponse | null): void => {
      if (message?.conversationId) {
        const index = recentMessages.findIndex(
          (item) => item.conversationId === message.conversationId,
        );
        const isRead = message ? getReadBy(message, data?.id || "") : true;
        if (index > -1) {
          const newState = clone(recentMessages);
          newState[index] = {
            conversationId: message?.conversationId,
            message,
            isReadConversation: isRead,
          };
          setRecentMessages(newState);
        } else {
          setRecentMessages((previous) => [
            ...previous,
            {
              conversationId: message.conversationId || "",
              message,
              isReadConversation: isRead,
            },
          ]);
        }
      }
    };

    const fetchMessages = (token: string | null): void => {
      if (conversationId)
        getMessages({
          variables: {
            input: { conversationId, nextToken: token, limit: MESSAGE_FETCH_LIMIT },
          },
        });
    };

    const getMoreChats = (token?: string): void => {
      const input: GetAllConversationsInput = { limit: 10 };
      if (token) {
        input.nextToken = token;
      }
      fetchMoreChats({
        variables: {
          input,
        },
      });
    };

    const fetchMore = (): void => {
      if (!loading && !isLoading && nextToken?.length) {
        setIsLoading(true);
        fetchMessages(nextToken);
      }
    };

    const markConvoAsRead = (messageId: string): void => {
      markAsRead({ variables: { input: { messageId } } });
    };

    const markMessageAsRead = (message: MessageResponse | null): void => {
      const index = recentMessages.findIndex((item) => item.conversationId === conversationId);
      const newState = clone(recentMessages);
      if (index > -1) {
        newState[index] = {
          conversationId: conversationId || "",
          message,
          isReadConversation: true,
        };
      } else {
        newState.push({
          conversationId: conversationId || "",
          message,
          isReadConversation: true,
        });
      }
      setRecentMessages(newState);
    };

    const resetStates = (): void => {
      setChatMessages([]);
      setIsLoading(true);
      setFirstCall(true);
      setTotalCount(0);
      setIsPoll(false);
    };

    const updateSubscribedMessage = (newMessage: MessageResponse): void => {
      if (newMessage.senderInfo?.id !== data?.id) {
        setTotalCount((prev) => prev + 1);
        setChatMessages([...chatMessages, newMessage]);
        updateRecentMessage(newMessage);
        setTimeout(() => scrollToEnd(chatBoxRef), SCROLL_TIMEOUT);
      } else {
        const index = chatMessages.findIndex((item) => item?.content === newMessage.content);
        const newState = clone(chatMessages);
        newState[index] = newMessage;
        setChatMessages(newState);
      }
    };

    const updateLocalMessage = (localMessage: MessageResponse | null): void => {
      setTotalCount((prev) => prev + 1);
      setChatMessages([...chatMessages, localMessage]);
      markMessageAsRead(localMessage);
      setTimeout(() => scrollToEnd(chatBoxRef), SCROLL_TIMEOUT);
    };

    const getUpdatedChatMessages = (
      updatedData: Array<MessageResponse | null>,
    ): Array<MessageResponse | null> => {
      let currentChatMessages = clone(chatMessages);
      if (isPoll && currentChatMessages.length) {
        const lastMessageWithId = reverse([...currentChatMessages]).find(
          (e) => e?.id !== undefined,
        ); // find first occurrence message in chat where id is not undefined
        if (lastMessageWithId) {
          // find index where we have to cut messages
          const updatedIndexToCut = updatedData.findIndex((e) => e?.id === lastMessageWithId.id);
          updatedData.splice(0, updatedIndexToCut + 1);
          const otherUpdatedMessages = updatedData.filter(
            (item) => item?.senderInfo?.id !== data?.id,
          ); // Our messages  removed from updated data
          if (otherUpdatedMessages.length) {
            // we need to sort later on when required on the basis of created At
            currentChatMessages = [...currentChatMessages, ...otherUpdatedMessages];
            updateRecentMessage(otherUpdatedMessages.at(-1) || null);
            setTimeout(() => scrollToEnd(chatBoxRef), SCROLL_TIMEOUT);
          }
        }
      }
      return currentChatMessages;
    };

    useEffect(() => {
      if ((chatMessages.length || nextToken === null) && firstCall) {
        setFirstCall(false);
        const lastMsg = chatMessages[chatMessages.length - 1];
        if (lastMsg) updateRecentMessage(lastMsg);
        setTimeout(() => scrollToEnd(chatBoxRef), SCROLL_TIMEOUT);
      }
    }, [chatMessages, firstCall]);

    useEffect(() => {
      if (conversationId && recentMessages.length) {
        const currentMessage = recentMessages.find((msg) => msg.conversationId === conversationId);
        const { isReadConversation, message } = currentMessage || {};
        if (isReadConversation === false && message?.id) markConvoAsRead(message.id);
      }
    }, [recentMessages, conversationId]);

    useEffect(() => {
      if (!loading && messageData && isLoading) {
        const updatedData = reverse([...messageData]);
        setIsLoading(false);
        setChatMessages([...updatedData, ...chatMessages]);
      }
    }, [messageData, loading]);

    useEffect(() => {
      if (!pollingLoading && polledData) {
        const updatedData = reverse([...polledData]);
        setChatMessages(getUpdatedChatMessages(updatedData));
      }
    }, [polledData, pollingLoading]);

    useEffect(() => {
      if (markAsReadRes) markMessageAsRead(markAsReadRes);
    }, [markAsReadRes]);

    useEffect(() => {
      if (isPoll && conversationId)
        setTimeout(() => {
          const variables = {
            input: { conversationId, nextToken: null, limit: MESSAGE_FETCH_LIMIT },
          };
          getPollingMessages({ variables });
          startPolling?.(POLL_INTERVAL);
        }, POLL_INTERVAL);
      return (): void => {
        if (isPoll) stopPolling?.();
      };
    }, [isPoll]);

    useEffect(() => {
      if (totalCount > SUBSCRIPTION_LIMIT) setIsPoll(true);
    }, [totalCount]);

    useEffect(() => {
      setTotalCount(messageCount?.count || 0);
    }, [messageCount]);

    useEffect(() => {
      if (conversationId) {
        resetStates();
        getTotalMsgCount({ variables: { input: { conversationId } } });
        fetchMessages(null);
      }
    }, [conversationId]);

    useEffect(() => {
      if (usersList && !usersListLoading) setAllUsers(sortUsersList([...allUsers, ...usersList]));
    }, [usersList, usersListLoading]);

    useEffect(() => {
      if (chatMessages && allUsers) {
        const convId = chatMessages[0]?.conversationId;
        const listToModify = [...allUsers];
        const modifiedList = listToModify.map((curr) => {
          if (curr && curr.id === convId) {
            const recent: (MessageResponse | null)[] = [];
            recent.push(chatMessages[chatMessages.length - 1]);
            return { ...curr, messages: recent };
          }
          return curr;
        });
        setAllUsers(sortUsersList(modifiedList));
      }
    }, [chatMessages]);

    useEffect(() => {
      getMoreChats();
    }, []);

    const chatProps: CurrentChatProps = {
      allUsers,
      usersList,
      chatMessages,
      hasMoreData: firstCall || !!nextToken?.length,
      chatBoxRef,
      nextPageChatToken,

      usersListLoading,
      isLoading,
      getMoreChats,
      fetchMore,
      updateLocalMessage,
      updateSubscribedMessage,
    };

    return <Component {...props} {...chatProps} />;
  });
}
export default withChatMessage;
