import * as yup from "yup";

export enum Form {
  email = "email",
  password = "password",
}

export const FormSchema = yup.object().shape({
  [Form.email]: yup.string().email().required().trim(),
  [Form.password]: yup.string().required().min(8).max(20),
});
