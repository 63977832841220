import { ReferralsCsvButton, TableFooter, TableHeader, TableShimmer } from "components";
import { ShouldRender } from "@remotebase/components";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import {
  TalentProps,
  talentTableHeading,
  TalentFilterType,
  TalentTableMainColumns,
  TalentTableDisplayInitial,
  ITalTableDisplay,
  ReferredByColumn,
} from "utils";
import { withTalent } from "state/talent";
import { TalentSearchDropdown, TalentTableBody } from "./components";
import * as Styled from "./styles";
import TableColumnDisplay from "./components/TableColumnDisplay";

interface Props {
  shouldShowFiltersList?: boolean;
  shouldShowReferredByColumn?: boolean;
  shouldHideLastPageBtn?: boolean;
}
const TalentListing: FC<Props & TalentProps> = ({
  talentState,
  shouldShowFiltersList,
  state: { nextBtnDisable, prevBtnDisable },
  nextBtnClick,
  prevBtnClick,
  shouldHideLastPageBtn,
  lastPageBtn,
  searchByFilter,
  shouldShowReferredByColumn,
}) => {
  const { data, loading, allData, recruiterId } = talentState;

  const tableFooterProps = {
    nextBtnClick,
    prevBtnClick,
    shouldHideLastPageBtn,
    lastPageBtn,
    nextBtnDisable,
    prevBtnDisable,
  };

  const [initialLoad, setInitialLoad] = useState(false);
  const [display, setDisplay] = useState<ITalTableDisplay>(TalentTableDisplayInitial);

  const tableHeaders = useMemo(
    () => ({
      ...TalentTableMainColumns,
      ...(shouldShowReferredByColumn ? ReferredByColumn : {}),
    }),
    [shouldShowReferredByColumn],
  );

  const handleSubmitForm = (event): void => {
    event.preventDefault();
  };

  const toggleTableColumn = (key: string): void => {
    setDisplay({ ...display, [key]: !display[key] });
  };

  useEffect(() => {
    if (!initialLoad && !loading) setInitialLoad(true);
  }, [loading]);

  useEffect(() => {
    if (shouldShowFiltersList) searchByFilter(TalentFilterType.ListTalent);
  }, []);
  return (
    <Styled.TableContent>
      <Styled.TableTop>
        <Styled.TableForm onSubmit={handleSubmitForm}>
          <Styled.SearchGroup>
            <Styled.TableSubHeading>{talentTableHeading}</Styled.TableSubHeading>
            <ShouldRender if={shouldShowFiltersList && initialLoad}>
              <Styled.SearchGroupBoth>
                <TalentSearchDropdown />
              </Styled.SearchGroupBoth>
            </ShouldRender>
            <ShouldRender if={!shouldShowFiltersList}>
              <ReferralsCsvButton data={allData} loading={loading} recruiterId={recruiterId} />
            </ShouldRender>
          </Styled.SearchGroup>
        </Styled.TableForm>
      </Styled.TableTop>
      <ShouldRender if={!talentState.loading && data}>
        <Fragment>
          <TableColumnDisplay display={display} toggle={toggleTableColumn} />
          <Styled.MainTable col={display} responsive>
            <TableHeader columnNames={tableHeaders} />
            <TalentTableBody />
            <TableFooter {...tableFooterProps} />
          </Styled.MainTable>
        </Fragment>
      </ShouldRender>
      <ShouldRender if={talentState.loading}>
        <TableShimmer />
      </ShouldRender>
    </Styled.TableContent>
  );
};

export default withTalent(TalentListing);
