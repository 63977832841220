import styled from "styled-components";

export const AboutHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AboutHeading = styled.h2`
  font-size: 30px;
  color: #11142d;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 24px;

  @media only screen and (max-width: 991px) {
    font-size: 30px !important;
    line-height: 1.125 !important;
  }

  @media only screen and (max-width: 575px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
`;

export const ProfileAbout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  padding: 28px 30px;

  @media only screen and (max-width: 575px) {
    padding: 26px 20px;
  }
`;
