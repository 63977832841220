import { FC } from "react";
import { useLocation } from "react-router-dom";
import { sidebarRoutes } from "state/sidebar";
import { Sidebar } from "utils";
import * as Styled from "./styles";

interface Props {
  text: string;
  testingId?: string;
  isSubMenu?: boolean;
}

const SidebarItem: FC<Props> = ({ text, testingId, isSubMenu }) => {
  const { pathname } = useLocation();

  const isActive = (): boolean => pathname === sidebarRoutes[text];

  return (
    <Styled.SidebarItem
      $active={isActive()}
      to={sidebarRoutes[text]}
      data-testid={testingId}
      $sub={isSubMenu}
      $pointer={Sidebar.RecruitersReports === text ? "#" : undefined}
    >
      <Styled.SidebarText>{text}</Styled.SidebarText>
    </Styled.SidebarItem>
  );
};

export default SidebarItem;
