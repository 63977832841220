import { FC } from "react";
import { withFetchTalent } from "state/talentFeedback";
import { ShouldRender } from "@remotebase/components";
import { IFetchTalentFeedbackProps } from "state/types/talentFeedback.interface";
import ProfileInfo from "../profileInfo";
import * as Styled from "../styles";
import FeedbackSummary from "../feedbackSummary";

const ProfileDetails: FC<IFetchTalentFeedbackProps> = ({ talentState: { data } }) => {
  const { talentFeedback } = data?.profile || {};
  return (
    <Styled.ProfileGridWrapper>
      <Styled.ProfileGrid $fullGrid={!talentFeedback?.length}>
        <ProfileInfo />
        <ShouldRender if={talentFeedback?.length}>
          <FeedbackSummary />
        </ShouldRender>
      </Styled.ProfileGrid>
    </Styled.ProfileGridWrapper>
  );
};

export default withFetchTalent(ProfileDetails);
