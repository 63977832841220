import { FC } from "react";
import * as Styled from "../styles";

export const TalentEmploymentSectionShimmer: FC = () => {
  return (
    <Styled.JobHistoryWrapperShimmer>
      <Styled.EmployementHeadShimmer>
        <Styled.JobHistoryTitleShimmer />
      </Styled.EmployementHeadShimmer>
      <Styled.JobHistoryContainerShimmer>
        <Styled.HistoryCardWrapperShimmer>
          <Styled.HistoryCardTopShimmer>
            <Styled.HistoryCardTitleShimmer />
          </Styled.HistoryCardTopShimmer>
          <Styled.HistoryCardDateShimmer />
          <Styled.HistoryCardParaShimmer>
            <Styled.HistoryCardParaShimmerText />
            <Styled.HistoryCardParaShimmerText />
            <Styled.HistoryCardParaShimmerText />
          </Styled.HistoryCardParaShimmer>
        </Styled.HistoryCardWrapperShimmer>
        <Styled.HistoryCardWrapperShimmer>
          <Styled.HistoryCardTopShimmer>
            <Styled.HistoryCardTitleShimmer />
          </Styled.HistoryCardTopShimmer>
          <Styled.HistoryCardDateShimmer />
          <Styled.HistoryCardParaShimmer>
            <Styled.HistoryCardParaShimmerText />
            <Styled.HistoryCardParaShimmerText />
            <Styled.HistoryCardParaShimmerText />
          </Styled.HistoryCardParaShimmer>
        </Styled.HistoryCardWrapperShimmer>
      </Styled.JobHistoryContainerShimmer>
    </Styled.JobHistoryWrapperShimmer>
  );
};

export default TalentEmploymentSectionShimmer;
