import { Sidebar, AuthRoutes as Route } from "utils";
import { UserRoles } from "@remotebase/amplify-constants/API";
import { ISidebarMenu } from "./sidebar.interface";

export const sidebarRoutes = {
  [Sidebar.Dashboard]: Route.Dashbord,
  [Sidebar.Talents]: Route.Talents,
  [Sidebar.Recruiters]: Route.Recruiters,
  [Sidebar.RecruitersReports]: Route.RecruitersReports,
  [Sidebar.RecruitersPerformance]: Route.RecruitersPerformance,
  [Sidebar.RecruitersLeaderboard]: Route.RecruitersLeaderboard,
  [Sidebar.RecruitersNewLeaderboard]: Route.RecruitersNewLeaderboard,
  [Sidebar.ReferredBy]: Route.ReferredBy,
  [Sidebar.Clients]: Route.Clients,
  [Sidebar.AdminUsers]: Route.AdminUsers,
  [Sidebar.PaymentsHistory]: Route.PaymentsHistory,
  [Sidebar.Chats]: Route.Chats,
  [Sidebar.Invoices]: Route.Invoices,
};

export const SidebarReportOptions = [
  Sidebar.RecruitersPerformance,
  Sidebar.RecruitersLeaderboard,
  Sidebar.RecruitersNewLeaderboard,
];

export const sidebarMenu: ISidebarMenu = {
  [UserRoles.SUPERADMIN]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Clients,
    Sidebar.Chats,
    Sidebar.AdminUsers,
    Sidebar.Invoices,
    Sidebar.PaymentsHistory,
  ],
  [UserRoles.ADMIN]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Clients,
    Sidebar.Chats,
  ],
  [UserRoles.TECHRECRUITER]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Chats,
  ],
  [UserRoles.SALES]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Chats,
  ],
  [UserRoles.FINANCE]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Chats,
    Sidebar.Clients,
    Sidebar.Invoices,
    Sidebar.PaymentsHistory,
  ],
  [UserRoles.CUSTOMERSUCCESS]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Chats,
  ],
  [UserRoles.CUSTOMERSUPPORT]: [
    Sidebar.Dashboard,
    Sidebar.Talents,
    Sidebar.Recruiters,
    Sidebar.RecruitersReports,
    SidebarReportOptions,
    Sidebar.ReferredBy,
    Sidebar.Chats,
  ],
};
