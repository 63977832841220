import { FC, Fragment } from "react";
import { JobMatch } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { CrossIcon, PaperPlaneIcon } from "assets/icons";
import { ITalentJobsInput } from "state/types/talentFeedback.interface";
import { NoTableData } from "./NoTableData";
import * as Styled from "./styles";

interface Props {
  matchedList: Array<JobMatch | ITalentJobsInput | null>;
  removeTalentMatch?: (jobID: string) => void;
}
export const TableBody: FC<Props> = (props) => {
  const { removeTalentMatch, matchedList } = props;

  return (
    <tbody>
      <ShouldRender if={matchedList.length}>
        <Fragment>
          {matchedList.map((item, index) => (
            <tr key={index}>
              <td>{item?.job?.company?.name || ""}</td>
              <td>{item?.job?.title}</td>
              <td>{item?.isRockstar ? <img src="/images/fire.svg" alt="fire-img" /> : ""}</td>
              <td>
                <Styled.EngPublishIcon>
                  {item?.isPublished ? <PaperPlaneIcon /> : ""}
                </Styled.EngPublishIcon>
              </td>
              <ShouldRender if={removeTalentMatch}>
                <td>
                  <Styled.EngRowCross>
                    <CrossIcon onClick={(): void => removeTalentMatch?.(item?.jobID || "")} />
                  </Styled.EngRowCross>
                </td>
              </ShouldRender>
            </tr>
          ))}
        </Fragment>
      </ShouldRender>
      <ShouldRender if={!matchedList.length}>
        <NoTableData />
      </ShouldRender>
    </tbody>
  );
};

export default TableBody;
