import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "./styles";

interface Props {
  type?: string;
  label?: string;
  title: string;
  handler: UseFormReturn;
  placeholder: string;
  small?: boolean;
}

export const FormInput: React.FC<Props> = ({
  label,
  title,
  type = "text",
  handler,
  placeholder = "",
  small = false,
}) => {
  const {
    register,
    formState: { errors },
  } = handler;

  return (
    <div>
      <Styled.InputGroup $small={small}>
        <ShouldRender if={!small}>
          <Styled.InputLabel>{label}</Styled.InputLabel>
        </ShouldRender>
        <Styled.Input type={type} placeholder={placeholder} {...register(title)} />
      </Styled.InputGroup>
      <ShouldRender if={errors[title]?.message}>
        <Styled.ErrorMessage>{errors[title]?.message}</Styled.ErrorMessage>
      </ShouldRender>
    </div>
  );
};

export default FormInput;
