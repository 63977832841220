import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { AddCircleIcon } from "assets/icons";
import { EducationEntry } from "@remotebase/amplify-constants/API";
import { useProfileVersionContext } from "state/profileVersion";
import {
  EducationFormEnum,
  EducationFormModal,
  EducationHistorySchema,
  EducationSection,
} from "@remotebase/components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../styles";

export const TalentEducationSection: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const formHook = useForm({
    mode: "onChange",
    resolver: yupResolver(EducationHistorySchema),
  });
  const { selectedVersion, setSelectedVersion } = useProfileVersionContext();

  const { educationHistory } = selectedVersion || {};

  const handleModalShow = (): void => setShowModal(!showModal);

  const editEducation = (index: number, education: EducationEntry): void => {
    const startDate = moment(education.startDate);
    const endDate = moment(education.endDate);

    formHook.reset({
      ...education,
      [EducationFormEnum.startMonth]: startDate.month(),
      [EducationFormEnum.startYear]: startDate.year(),
      [EducationFormEnum.endMonth]: endDate.month(),
      [EducationFormEnum.endYear]: endDate.year(),
      [EducationFormEnum.isEdit]: true,
      [EducationFormEnum.selectedEducationIndex]: index,
    });

    handleModalShow();
  };

  const addNewEducation = (): void => {
    formHook.reset({
      [EducationFormEnum.isEdit]: false,
    });

    handleModalShow();
  };

  const handleFormSubmission = (savedData: EducationEntry, index = -1): void => {
    if (!index && index !== 0) return;
    const updatedEducationHistory =
      index === -1
        ? [...(educationHistory || []), savedData]
        : [
            ...(educationHistory || []).slice(0, index),
            savedData,
            ...(educationHistory || []).slice(index + 1),
          ];

    setSelectedVersion((prev) =>
      prev
        ? {
            ...prev,
            educationHistory: updatedEducationHistory,
          }
        : prev,
    );
  };

  const deleteEductaion = (id: number): void => {
    if (educationHistory?.length)
      setSelectedVersion((prev) =>
        prev
          ? {
              ...prev,
              educationHistory: [
                ...educationHistory.slice(0, id),
                ...educationHistory.slice(id + 1),
              ],
            }
          : prev,
      );
  };

  const addEducationBtn = (): JSX.Element => {
    return (
      <Styled.AddEmployementBtn onClick={(): void => addNewEducation()}>
        <Styled.MatchTalentTopIcon>
          <AddCircleIcon />
        </Styled.MatchTalentTopIcon>
      </Styled.AddEmployementBtn>
    );
  };

  return (
    <Styled.EducationDetailWrapper>
      <EducationSection
        editEducation={editEducation}
        deleteEducation={deleteEductaion}
        educationHistory={educationHistory || []}
        addEducationBtn={addEducationBtn()}
        isAdmin
      />
      {showModal && (
        <EducationFormModal
          show={showModal}
          formHook={formHook}
          handleFormSubmission={handleFormSubmission}
          close={(): void => handleModalShow()}
        />
      )}
    </Styled.EducationDetailWrapper>
  );
};

export default TalentEducationSection;
