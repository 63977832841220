// eslint-disable-next-line import/prefer-default-export
export const referralByRecruiter = /* GraphQL */ `
  query ReferralByRecruiter(
    $recruiterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    referralByRecruiter(
      recruiterId: $recruiterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referrerId
        recruiterId
        recruiter {
          id
          fullName
          email
          phone
          country
          role
          profile {
            id
            currentEarnings
            createdAt
            updatedAt
            version
            recruiterProfileRecruiterId
          }
          createdAt
          updatedAt
          version
        }
        referred {
          id
          fullName
          email
          phone
          country
          role
          profile {
            id
            skills
            experience
            isAvailable
            social
            englishProficiency
            problemSolvingScore
            isPassPS
            isPassHR
            isPassFinalTech
            isNetwork
            isHired
            statusMeta
            interests
            resumeLink
            createdAt
            updatedAt
            takenDomainTests {
              skill
              score
              isPass
              provider
              testStart
              testEnd
              percentage
              totalScore
              testLink
              domainTestData
            }
            version
            talentProfileTalentId
          }
          createdAt
          updatedAt
          version
        }
        email
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
