import { ShouldRender } from "@remotebase/components";
import { FC, Fragment, useEffect, useState } from "react";
import { ConversationResponse } from "../API";
import { CurrentChatUserProps, CurrentChatProps, IChatMessage } from "../types";
import { sortUsersList } from "../utils";
import NoUsersList from "./noUsersList";
import SearchTalent from "./searchTalent";
import SingleTalent from "./singleTalent";
import * as S from "../styles";

const TalentsList: FC<CurrentChatProps & CurrentChatUserProps & { isAdmin?: boolean }> = (
  props,
) => {
  const {
    usersList,
    allUsers,
    getParticipant,
    recentMessages,
    openChat,
    addToRecentMessages,
    setRecentMessages,
    getMoreChats,
    nextPageChatToken,
    isAdmin,
  } = props;
  const [filreredUsers, setFilteredUsers] = useState(allUsers);

  const handleChange = (searchText: string): void => {
    if (allUsers.length) {
      if (searchText.length) {
        const filteredData = allUsers.filter((talent) => {
          if (isAdmin) {
            return (
              getParticipant(talent)?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
              (talent?.conversationParticipants &&
                talent?.conversationParticipants[1]?.fullName
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()))
            );
          }
          return getParticipant(talent)?.fullName?.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredUsers([...filteredData]);
      } else setFilteredUsers(allUsers);
    }
  };

  useEffect(() => {
    if (!allUsers?.length)
      setFilteredUsers(sortUsersList(usersList as (ConversationResponse | null)[]));
    else setFilteredUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    if (filreredUsers) {
      const mostRecent: IChatMessage[] = [];
      for (const recent of filreredUsers) {
        const message = recent?.messages?.[0];
        if (message) {
          mostRecent.push({
            conversationId: message?.conversationId as string,
            message,
            isReadConversation: true,
          });
        }
      }
      setRecentMessages(mostRecent);
    }
  }, [filreredUsers]);

  return (
    <S.ChatMenuWrapper>
      <S.ChatMenuInner>
        <SearchTalent onChange={handleChange} />
        <S.ChatMenuList>
          <ShouldRender if={filreredUsers.length}>
            <Fragment>
              {filreredUsers?.map((item, index) => (
                <SingleTalent
                  isAdmin={isAdmin}
                  data={item}
                  key={`${item?.id} singleTalent ${index}`}
                  getParticipant={getParticipant}
                  recentMessages={recentMessages}
                  openChat={openChat}
                  addToRecentMessages={addToRecentMessages}
                />
              ))}
            </Fragment>
          </ShouldRender>
          {nextPageChatToken && (
            <S.LoadMoreButton
              onClick={(): void => {
                if (getMoreChats) getMoreChats(nextPageChatToken);
              }}
            >
              Load More
            </S.LoadMoreButton>
          )}
          <ShouldRender if={!filreredUsers.length}>
            <NoUsersList />
          </ShouldRender>
        </S.ChatMenuList>
      </S.ChatMenuInner>
    </S.ChatMenuWrapper>
  );
};

export default TalentsList;
