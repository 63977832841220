import styled from "styled-components";

export const CheckBoxLabel = styled.label`
  display: flex;
  position: relative;
  padding-left: 1.75rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
`;
export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: #3617ba;
  }

  &:checked ~ span:after {
    display: block;
  }
`;

export const CheckMark = styled.span`
  position: absolute;
  display: flex;
  left: 0;
  width: 1.15rem;
  height: 1.15rem;
  background-color: #eee;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;

  &:after {
    content: url("/images/CheckMark.svg");
    position: relative;
    bottom: 0.075rem;
    display: none;
    width: 0.65rem;
  }
`;
