import styled from "styled-components";
import { StyledProps } from "../../utils";

export const ChatContentWrapper = styled.div<{ $mobileDisplay?: boolean }>`
  position: relative;
  display: ${(props): StyledProps => (props.$mobileDisplay ? "flex" : "none")};
  flex-grow: 1;
  width: 100%;
  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const ChatContentInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100vh - 85px);
  min-height: calc(100vh - 85px);
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  overflow: hidden;
  /* overflow-y: auto;
  padding: 35px 32px 20px 32px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  } */
  @media only screen and (min-width: 576px) {
    max-height: calc(100vh - 93px);
    min-height: calc(100vh - 93px);
  }
`;
