import { IRecruiterStateType, IRecruiterFilters } from "state/types/recruiter.interface";
import { FilterOptions, HocStateProps } from "utils";

export enum TableColumnsRecruiters {
  FullName = "Full Name",
  PhoneNo = "Phone No",
  Email = "Email",
  Country = "Country",
  CreatedAt = "Created At",
}

export const recruiterTableHeading = "Recruiter List";
export const recruiterModalTitle = "Referrals";
export const referredByModalTitle = "Referred By";
export const RecruiterByName = "Recruiter By Name";
export const RecruiterByEmail = "Recruiter By Email";
export interface RecruiterProps extends HocStateProps {
  recruiterState: IRecruiterStateType;
  searchByFilter: (filterOptions?: FilterOptions) => void;
  downloadCSV: () => void;
}

export const recruiterStateDefault: IRecruiterStateType = {
  loading: true,
  currentPtr: 0,
};

export const recruiterFilterDefault: IRecruiterFilters = {
  filterOptions: {},
};
