import styled from "styled-components";

const ShimmerStyle = `
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;`;

const animate = `
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
  background-size: 1000px 100%;

  @keyframes fullView {
  100% {
    width: 100%;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
  `;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 1580px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  padding-bottom: 45px;
  position: relative;

  @media only screen and (max-width: 1023px) {
    width: calc(100% - 96px);
    padding-bottom: 80px;
  }

  @media only screen and (max-width: 767px) {
    width: 100% !important;
    padding: 0px 24px 40px;
    margin-left: 0;
  }
`;

export const TableBox = styled.div`
  width: 100%;
  max-width: 840px;
`;

export const MainTable = styled.div`
  ${ShimmerStyle}
  ${animate}
  width: 100%;
  min-width: 960px;
  min-height: 60px;
  margin-bottom: 0;
`;
