import { createContext } from "react";
import { talentFilterDefault, talentStateDefault } from "utils";
import { ITalentContextType, ITalentFiltersContext } from "../types/talent.interface";

export const TalentContext = createContext<ITalentContextType>({
  talentState: talentStateDefault,
  setTalentState: (): void => {},
});

export const TalentFilterContext = createContext<ITalentFiltersContext>({
  filters: talentFilterDefault,
  setFilters: (): void => {},
});
