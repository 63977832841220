import React from "react";
import { CreateClient } from "components";
import { ClientProvider } from "state/clients";
import { useHistory } from "react-router-dom";
import { AuthRoutes } from "utils";
import * as Styled from "../styles";

const CreateClientPage: React.FC = () => {
  const { push } = useHistory();

  return (
    <Styled.ClientsWrapper>
      <Styled.ClientHead>
        <Styled.MainHeading>
          <Styled.SubHeading>Create Client</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.ClientHead>
      <Styled.Back onClick={(): void => push(AuthRoutes.Clients)}>BACK</Styled.Back>
      <ClientProvider>
        <CreateClient />
      </ClientProvider>
    </Styled.ClientsWrapper>
  );
};

export default CreateClientPage;
