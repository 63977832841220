import { TableHeader, TableShimmer } from "components";
import { FC, Fragment, useEffect, useState } from "react";
import { IDashboard } from "state/dashboard";
import {
  AnalyticsTableDisplayInitial,
  CheckboxesNames,
  DropdownAttributes,
  OperationDays,
  TableColumnsAnalytics,
  TimePeriodLabel,
} from "utils/constants/dashboard";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "react-bootstrap";
import withDashboard from "state/dashboard/withDashboardHoc";
import moment, { Moment } from "moment";
import { adjustDays } from "utils";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "../styles";
import { DatePickerComponent, RowData } from ".";

export const AnalyticsTable: FC<IDashboard> = ({ data, showData, dateHandler, removeRow }) => {
  const [display, setDisplay] = useState(AnalyticsTableDisplayInitial);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [fromDate, setFromDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (toDate && fromDate) {
      dateHandler(toDate, fromDate);
      setToDate(null);
      setFromDate(null);
    }
  }, [toDate, fromDate]);

  const clickFromDate = (date): void =>
    setFromDate(() => {
      if (date > moment()) return adjustDays(1, OperationDays.subtract, moment());
      if (toDate && date <= toDate) return adjustDays(1, OperationDays.subtract, toDate);
      return moment(date);
    });

  const clickToDate = (date): void => {
    setToDate(() => {
      if (date > moment()) return moment();
      if (fromDate && date <= fromDate) return adjustDays(1, OperationDays.add, fromDate);
      return moment(date);
    });
  };

  const toggleTableColumn = (key: string): void => {
    setDisplay({ ...display, [key]: !display[key] });
  };

  return (
    <Fragment>
      <Styled.DateAlign>
        <DatePickerComponent text="From" newDate={fromDate} changeDate={clickFromDate} />
        <DatePickerComponent text="To" newDate={toDate} changeDate={clickToDate} />
      </Styled.DateAlign>
      <Styled.MainTable col={display} responsive>
        <Styled.TableContent>
          <Dropdown>
            <Styled.DropdownBtn title={DropdownAttributes.title}>
              {Object.keys(display).map((key) => (
                <Styled.Label key={key}>
                  <input
                    type="checkbox"
                    checked={display[key]}
                    onChange={(): void => toggleTableColumn(key)}
                  />
                  <Styled.Span>{CheckboxesNames[key]}</Styled.Span>
                </Styled.Label>
              ))}
            </Styled.DropdownBtn>
          </Dropdown>
          <Fragment>
            <TableHeader columnNames={TableColumnsAnalytics} />
            <ShouldRender if={showData.loading}>
              <TableShimmer />
            </ShouldRender>
            <ShouldRender if={!showData.loading && !data.isData}>
              <Styled.NoData>No Data Available</Styled.NoData>
            </ShouldRender>
            <ShouldRender if={!showData.loading && data.isData && !showData.nextToken}>
              <tbody>
                <RowData data={data.weekdata} range={TimePeriodLabel.WeekLabel} />
                <RowData data={data.monthdata} range={TimePeriodLabel.MonthLabel} />
                {data.additionalData?.map((rowData, index) => (
                  <RowData key={index} data={rowData} index={index} removeRow={removeRow} />
                ))}
              </tbody>
            </ShouldRender>
          </Fragment>
        </Styled.TableContent>
      </Styled.MainTable>
    </Fragment>
  );
};

export default withDashboard(AnalyticsTable);
