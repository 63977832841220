import { AnalyticsInitial, TableColumnsAnalytics } from "utils/constants/dashboard";
import { IAnalyticsType, IGraph, IGraphAnalytics } from ".";

export const getChartData = (graphAnalytics: IGraphAnalytics): IGraph => {
  const dates: string[] = [];

  graphAnalytics.createdAt?.forEach((e) => {
    dates.push(new Date(e).toDateString());
  });
  const chartData: IGraph = {
    options: {
      chart: {
        marginTop: 50,
      },
      title: {
        text: " ",
      },
      yAxis: {
        title: {
          text: "Number",
        },
      },
      xAxis: {
        title: {
          text: "dates",
        },
        categories: dates,
      },
      series: [
        {
          name: TableColumnsAnalytics.ProfilesCreated,
          data: graphAnalytics.developersJoined,
          visible: false,
        },
        {
          name: TableColumnsAnalytics.ProfilesCompleted,
          data: graphAnalytics.developersCompletedProfiles,
        },
        {
          name: TableColumnsAnalytics.Hired,
          data: graphAnalytics.hiredToday,
          visible: false,
        },
        {
          name: TableColumnsAnalytics.Recruiters,
          data: graphAnalytics.recruitersJoined,
        },
        {
          visible: false,
          name: TableColumnsAnalytics.DomainTestsTaken,
          data: graphAnalytics.takenDomainTests,
        },
        {
          visible: false,
          name: TableColumnsAnalytics.DomainTestsPassed,
          data: graphAnalytics.passedDomainTests,
        },
        {
          visible: false,
          name: TableColumnsAnalytics.PSTestsTaken,
          data: graphAnalytics.takenProblemSolvingTests,
        },
        {
          visible: false,
          name: TableColumnsAnalytics.PSTestsPassed,
          data: graphAnalytics.passedProblemSolving,
        },
        {
          visible: false,
          name: TableColumnsAnalytics.ReferredByRecruiters,
          data: graphAnalytics.developersReferredByRecruiters,
        },
      ],
      exporting: {
        fallbackToExportServer: false,
        enabled: true,
        allowHTML: true,
        filename: "myFile",
        buttons: {
          contextButton: {
            symbol: "download",
            menuItems: ["downloadCSV"],
          },
        },
      },
    },
  };
  return chartData;
};

export default getChartData;

export const getTableData = (graphAnalytics: IGraphAnalytics, days: number): IAnalyticsType => {
  const totalValues = graphAnalytics.developersJoined.length;
  const sliceVal = totalValues - days > 0 ? totalValues - days : 0;
  const tableData = { ...AnalyticsInitial };
  Object.keys(graphAnalytics).forEach((key) => {
    tableData[key] = graphAnalytics[key].slice(sliceVal).reduce((a, b) => a + b, 0);
  });

  return tableData;
};
