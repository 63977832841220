import { FC, PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";
import { ShouldRender } from "@remotebase/components";
import { CancelBtnIcon } from "components/icon";
import * as Styled from "./styles";

interface Props {
  header: string;
  showModal: boolean;
  onClose: () => void;
  labelledBy?: string;
  additionalMenu?: JSX.Element | null;
}

export const CustomModal: FC<PropsWithChildren<Props>> = ({
  header,
  showModal,
  onClose,
  children,
  labelledBy,
  additionalMenu = null,
}) => {
  return (
    <Styled.CustomModal
      show={showModal}
      onHide={onClose}
      animation={false}
      scrollable={true}
      aria-labelledby={labelledBy}
    >
      <Styled.ModalHeaderIcon onClick={onClose}>
        <CancelBtnIcon />
      </Styled.ModalHeaderIcon>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
        <ShouldRender if={additionalMenu}>
          <Styled.ModalHeaderIcon>{additionalMenu}</Styled.ModalHeaderIcon>
        </ShouldRender>
      </Modal.Header>
      <Modal.Body>
        <div>{children}</div>
      </Modal.Body>
    </Styled.CustomModal>
  );
};

export default CustomModal;
