import React from "react";
import { RecruitersList } from "components";
import { TalentProvider } from "state/talent";
import { RecruiterProvider } from "state/recruiters";
import * as Styled from "./styles";

const Recruiters: React.FC = () => {
  return (
    <Styled.RecruitersWrapper>
      <Styled.RecruiterHead>
        <Styled.MainHeading>
          <Styled.SubHeading>Recruiters</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.RecruiterHead>
      <RecruiterProvider>
        <TalentProvider>
          <RecruitersList />
        </TalentProvider>
      </RecruiterProvider>
    </Styled.RecruitersWrapper>
  );
};

export default Recruiters;
