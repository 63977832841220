import styled from "styled-components";

export const WarningBadge = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 805px;
  width: 100%;
  padding: 12px 20px;
  background: #fff0ec;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

export const WarningBadgeContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const WarningBadgePara = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin: 0px !important;

  letter-spacing: -0.04em;

  color: #7c7c7c !important;
`;
