import styled from "styled-components";
import { Form } from "react-bootstrap";

export const CreateForm = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  margin-top: 1.5rem;

  & > * {
    grid-column: span 2;
  }
`;

export const Input = styled(Form.Control)`
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
`;
export const ErrorMessage = styled.p`
  color: red;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const SubmitButtonCover = styled.div`
  width: 100%;
  grid-column-start: 4;
  margin-top: auto;
  display: flex;
  justify-content: end;
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;
