import { FC, Fragment, ChangeEvent } from "react";
import * as Styled from "./styles";

interface Props {
  checked?: boolean;
  inputClassName?: string;
  label: string;
  onChange: (updatedValue: boolean) => void;
  type?: string;
  disabled?: boolean;
}
export const FeedbackInput: FC<Props> = ({
  checked,
  inputClassName,
  label,
  onChange,
  type,
  disabled,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.checked);
  };

  return (
    <Fragment>
      <Styled.FeedbackInputLabel id={`${label}-input`}>{label}</Styled.FeedbackInputLabel>
      <Styled.FeedbackInputField
        className={disabled ? `${inputClassName} disable` : inputClassName}
        checked={checked}
        onChange={handleChange}
        type={type}
        disabled={disabled}
        aria-labelledby={`${label}-input`}
      />
    </Fragment>
  );
};

export default FeedbackInput;
