import { useMutation } from "@apollo/client";
import {
  CreateOrUpdateJobMatchAdminMutation,
  CreateOrUpdateJobMatchAdminMutationVariables,
  JobMatch,
} from "@remotebase/amplify-constants/API";
import { createOrUpdateJobMatchAdmin } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "../utils";
import { ReturnPropsCreateJobMatch } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useCreateOrUpdateTalentJobMach = (): ReturnPropsCreateJobMatch => {
  const [createOrUpdateJobMatch, { data, loading, called }] = useMutation<
    CreateOrUpdateJobMatchAdminMutation,
    CreateOrUpdateJobMatchAdminMutationVariables
  >(getQuery(createOrUpdateJobMatchAdmin));

  const jobMatch = data?.createOrUpdateJobMatchAdmin?.data as JobMatch | null;

  return { createOrUpdateJobMatch, called, loading, jobMatch };
};
