import { FC } from "react";
import { LoaderColors } from "../utils";
import * as Styled from "./styles";

interface PropType {
  color?: LoaderColors;
}

export const BtnLoader: FC<PropType> = ({ color = "Purple" }) => {
  return <Styled.LoadingSpinner color={LoaderColors[color]} />;
};

export default BtnLoader;
