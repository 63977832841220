import { FC } from "react";
import { MessageResponse } from "../../API";
import { getFormattedDay } from "../../utils";
import * as S from "../../styles";

interface Props {
  isMine?: boolean;
  item?: MessageResponse;
}
const Message: FC<Props> = ({ isMine, item }) => {
  const chatClass = isMine ? "myMessage" : "";

  return (
    <S.ReplyChatWrapper className={chatClass} key={`${item?.id}messageBox`}>
      <S.ReplyChatText className={chatClass}>{item?.content}</S.ReplyChatText>
      <S.ReplyChatTime>{getFormattedDay(item?.updatedAt).time}</S.ReplyChatTime>
    </S.ReplyChatWrapper>
  );
};

export default Message;
