import styled from "styled-components";
import { StyledProps } from "utils";

export const ResetPassContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #e0e0e0;
  padding: 27px 48px;
`;

export const ResetPassHeader = styled.h4`
  font-family: "poppins600";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #212121;
  height: 29px;
  margin-bottom: 0;
`;

export const ResetPassContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0 0 10px 10px;
  padding: 42px 48px;
  z-index: 80;
`;

export const TabHeaders = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
`;

export const TabContent = styled.div<{ $active: boolean }>`
  width: 100%;
  padding: 24px 0;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
  border-bottom: ${(props): StyledProps => (props.$active ? "1px solid #3615bd" : "none")};
  background-color: ${(props): StyledProps =>
    props.$active ? "rgba(246, 248, 250, 0.5)" : "transparent"};

  &:last-child {
    border-right: none;
  }
`;

export const TabHeaderText = styled.p`
  margin: 0;
  text-align: center;
  color: #7b7b7b;
  font-family: Poppins;
`;

export const SelectSection = styled.div`
  width: 50%;
`;
