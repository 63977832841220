import { FC } from "react";
import * as Styled from "./styles";

export const TalentVersionHeaderShimmer: FC = () => {
  return (
    <>
      <Styled.EditTalentBackIconWrapperShimmer>
        <Styled.EditTalentBackIconShimmer />
        <Styled.EditTalentBackTextShimmer />
      </Styled.EditTalentBackIconWrapperShimmer>
    </>
  );
};

export default TalentVersionHeaderShimmer;
