import { createContext } from "react";
import { initialJobID } from "utils/constants/dashboard";
import { ProfileContextType } from "./profileSteps.interface";

export const ProfileContext = createContext<ProfileContextType>({
  profileState: { isLoading: false },
  setProfileState: (): void => {},
});

interface ClientJobContextType {
  jobID: string | null;
  setJobID: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ClientJobContext = createContext<ClientJobContextType>({
  jobID: initialJobID,
  setJobID: (): void => {},
});

export default ProfileContext;
