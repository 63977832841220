import { ShouldRender } from "@remotebase/components";
import { TableShimmer, TableFooter, TableHeader } from "components";
import { FC, useEffect, useState } from "react";
import { withRecruiter } from "state/recruiters";
import { RecruiterProps, recruiterTableHeading, TableColumnsRecruiters } from "utils";
import { RecruiterTableBody, RecruiterSearchDropdown } from "./components";
import * as Styled from "./styles";

const RecruiterListing: FC<RecruiterProps> = ({
  recruiterState,
  nextBtnClick,
  prevBtnClick,
  state: { nextBtnDisable, prevBtnDisable },
}) => {
  const { loading, data } = recruiterState;
  const [initialLoad, setInitialLoad] = useState(false);

  const submitHandler = (event): void => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!initialLoad && data?.length) setInitialLoad(true);
  }, [data]);

  return (
    <Styled.TableContent>
      <Styled.TableTop>
        <Styled.TableForm onSubmit={submitHandler}>
          <Styled.SearchGroup onSubmit={submitHandler}>
            <Styled.TableSubHeading>{recruiterTableHeading}</Styled.TableSubHeading>
            <ShouldRender if={initialLoad}>
              <RecruiterSearchDropdown />
            </ShouldRender>
          </Styled.SearchGroup>
        </Styled.TableForm>
      </Styled.TableTop>
      <ShouldRender if={!loading && data}>
        <Styled.MainTable responsive>
          <TableHeader columnNames={TableColumnsRecruiters} />
          <RecruiterTableBody />
          <TableFooter
            prevBtnClick={prevBtnClick}
            nextBtnClick={nextBtnClick}
            nextBtnDisable={nextBtnDisable}
            prevBtnDisable={prevBtnDisable}
          />
        </Styled.MainTable>
      </ShouldRender>
      <ShouldRender if={loading}>
        <TableShimmer />
      </ShouldRender>
    </Styled.TableContent>
  );
};

export default withRecruiter(RecruiterListing);
