import { createContext, useContext } from "react";
import { IErrorContextType } from "@remotebase/constants";

const ErrorContext = createContext<IErrorContextType>({
  setErrorState: () => {},
  errorState: [],
  showError: () => null,
});

export default ErrorContext;
export const useErrorContext = (): IErrorContextType => useContext(ErrorContext);
