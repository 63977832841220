import styled from "styled-components";

export const ResetCardWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 0.85rem 2rem 0.85rem 1.5rem;
`;

export const ResetCardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ImgWrapper = styled.img`
  width: 30px;
`;

export const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3125rem;
`;

export const TestName = styled.p`
  margin: 0;
  font-weight: 500;
  margin-right: 1.125rem;
`;

export const ResetParagraph = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0;
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const TextTitle = styled.span`
  font-weight: 500;
`;

export const TextValue = styled.span`
  font-weight: 400;
`;

export const TextCircle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000000;
`;

export const TopCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const TopRight = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const CardCenter = styled.div`
  display: flex;
  align-items: center;
  margin: 2.625rem 0;
`;

export const WrapStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4rem;
`;

export const SlideWrapper = styled.div`
  margin-right: 4rem;
  margin-left: 1.125rem;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

export const ResetDescription = styled.div`
  font-size: 0.75rem;
  color: #212121;
  margin-top: 2rem;

  * {
    margin-bottom: 0;
  }
`;
