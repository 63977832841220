import { FC } from "react";
import { ShouldRender } from "@remotebase/components";
import { CurrentChatProps, withChatMessage, withChatUser, CurrentChatUserProps } from "state/chat";
import { ChatMainShimmer, ChatBodyShimmer } from "@remotebase/components/chat/chatShimmer";
import * as S from "@remotebase/components/chat/styles";
import TalentList from "@remotebase/components/chat/talentsList";
import CurrentUserChatPanel from "./currentUserChatPanel";

export const Chat: FC<CurrentChatProps & CurrentChatUserProps> = (props) => {
  const { usersListLoading, usersList } = props;
  return (
    <S.ChatWrapper>
      <ShouldRender if={usersListLoading && !usersList?.length}>
        <ChatMainShimmer />
      </ShouldRender>
      <ShouldRender if={!usersListLoading || usersList?.length}>
        <S.ChatInner>
          <TalentList isAdmin={true} {...props} />
          <ShouldRender if={usersListLoading}>
            <ChatBodyShimmer />
          </ShouldRender>
          <ShouldRender if={!usersListLoading}>
            <CurrentUserChatPanel {...props} />
          </ShouldRender>
        </S.ChatInner>
      </ShouldRender>
    </S.ChatWrapper>
  );
};

export default withChatUser(withChatMessage(Chat));
