import { FC } from "react";
import * as Styled from "../styles";

export const ProfileDetailsSectionShimmer: FC = () => {
  return (
    <Styled.EditTalentInfoWrapperShimmer>
      <Styled.EditTalentInfoTopShimmer>
        <Styled.EditTalentInfoTopLeftShimmer>
          <Styled.EditTalentAvatarWrapperShimmer>
            <Styled.EditTalentAvatarShimmer />
            <Styled.EditTalentAvatarBadgeShimmer>
              <Styled.EditTalentAvatarBadgeTextShimmer />
            </Styled.EditTalentAvatarBadgeShimmer>
          </Styled.EditTalentAvatarWrapperShimmer>
          <Styled.EditTalentDetailShimmer>
            <Styled.EditTalentNameShimmer />
            <Styled.EditTalentDesignationShimmer />
            <Styled.EditTalentScoreShimmer />
          </Styled.EditTalentDetailShimmer>
        </Styled.EditTalentInfoTopLeftShimmer>
      </Styled.EditTalentInfoTopShimmer>
      <Styled.ExpertiseWrapperShimmer>
        <Styled.ExpertiseEditIconShimmer />
        <Styled.ExpertiseContainerShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
          <Styled.ExpertiseBoxShimmer>
            <Styled.ExpertiseBoxTextShimmer />
          </Styled.ExpertiseBoxShimmer>
        </Styled.ExpertiseContainerShimmer>
      </Styled.ExpertiseWrapperShimmer>
      <Styled.ProfileDetailWrapperShimmer>
        <Styled.ProfileDetailEditIconShimmer />
        <Styled.ProfileDetailListShimmer>
          <Styled.ProfileDetailItemShimmer>
            <Styled.ProfileDetailContentShimmer>
              <Styled.ProfileDetailSbHdShimmer />
              <Styled.ProfileDetailParaShimmer />
            </Styled.ProfileDetailContentShimmer>
          </Styled.ProfileDetailItemShimmer>
          <Styled.ProfileDetailItemShimmer>
            <Styled.ProfileDetailContentShimmer>
              <Styled.ProfileDetailSbHdShimmer />
              <Styled.ProfileDetailParaShimmer />
            </Styled.ProfileDetailContentShimmer>
          </Styled.ProfileDetailItemShimmer>
          <Styled.ProfileDetailItemShimmer>
            <Styled.ProfileDetailContentShimmer>
              <Styled.ProfileDetailSbHdShimmer />
              <Styled.ProfileDetailParaShimmer />
            </Styled.ProfileDetailContentShimmer>
          </Styled.ProfileDetailItemShimmer>
        </Styled.ProfileDetailListShimmer>
      </Styled.ProfileDetailWrapperShimmer>
    </Styled.EditTalentInfoWrapperShimmer>
  );
};

export default ProfileDetailsSectionShimmer;
