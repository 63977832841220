import { ShouldRender } from "@remotebase/components";
import { isEmpty } from "lodash";
import { FC, Fragment, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { withRecruiter } from "state/recruiters";
import {
  RecruiterByName,
  RecruiterByEmail,
  RecruiterProps,
  defaultDropdownValue,
  FilterOptions,
  dropdownLabel,
} from "utils";
import * as Styled from "../styles";

const RecruiterSearchDropdown: FC<RecruiterProps> = ({
  searchByFilter,
  recruiterState: { loading, allData },
  downloadCSV,
}) => {
  const [searchText, setSearchText] = useState("");
  const [dropdownSelect, setDropdownSelect] = useState<FilterOptions>({});
  const [dropdownTitle, setDropdownTitle] = useState(defaultDropdownValue);

  const getEventType = (event?: string): FilterOptions => {
    switch (event) {
      case RecruiterByName:
        setDropdownTitle(RecruiterByName);
        return { searchByName: true };
      case RecruiterByEmail:
        setDropdownTitle(RecruiterByEmail);
        return { searchByEmail: true };
      default:
        return {};
    }
  };
  const handleSelect = (event?: string): void => {
    const eventType = getEventType(event);
    setSearchText("");
    setDropdownSelect(eventType);
  };
  const handleSubmit = (): void => {
    searchByFilter({ ...dropdownSelect, searchText });
  };
  const handleReset = (): void => {
    handleSelect("");
    searchByFilter({});
  };
  const onClickDownload = (): void => {
    downloadCSV();
  };

  return (
    <Styled.SearchMain>
      <Styled.SearchGroupMain>
        <Styled.DropdownLabel>{dropdownLabel}</Styled.DropdownLabel>
        <Styled.DropdownBtn title={dropdownTitle} onSelect={handleSelect}>
          <Dropdown.Item eventKey={RecruiterByName} active={dropdownSelect.searchByName}>
            {RecruiterByName}
          </Dropdown.Item>
          <Dropdown.Item eventKey={RecruiterByEmail} active={dropdownSelect.searchByEmail}>
            {RecruiterByEmail}
          </Dropdown.Item>
        </Styled.DropdownBtn>
        <ShouldRender if={!loading && allData?.length}>
          <Styled.DownloadBtn onClick={onClickDownload} variant="outline-secondary" size="sm">
            Download CSV
          </Styled.DownloadBtn>
        </ShouldRender>
      </Styled.SearchGroupMain>
      <ShouldRender if={!isEmpty(dropdownSelect)}>
        <Fragment>
          <Styled.SearchGroupMain>
            <Styled.SearchGroupField
              type="text"
              value={searchText}
              placeholder="Enter Name or Email..."
              onChange={(e): void => setSearchText(e.target.value.trim())}
            />

            <Styled.SearchButton onClick={handleSubmit} disabled={!searchText.length}>
              Search
            </Styled.SearchButton>
          </Styled.SearchGroupMain>
          <Styled.ClearFilters onClick={handleReset}>Clear Filters</Styled.ClearFilters>
        </Fragment>
      </ShouldRender>
    </Styled.SearchMain>
  );
};
export default withRecruiter(RecruiterSearchDropdown);
