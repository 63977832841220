import { Table, Button as BpButton, Modal } from "react-bootstrap";
import styled from "styled-components";

export const Wrapper = styled.main``;

export const Header = styled.div`
  margin: 5px 0 3.5rem;
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  color: #11142d;
  font-weight: 600;
`;

export const Content = styled.section``;

export const ContentTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const ContentTitle = styled.h4`
  font-size: 1.125rem;
  color: #11142d;
  font-weight: 600;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const Button = styled(BpButton)`
  ${(props): string | false =>
    !props.variant &&
    "background: #3617ba; color: #fff; &:hover, &:active, &:focus { background: #3617ba; } "}
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  box-shadow: none;
`;

export const ReloadImg = styled.img`
  padding-bottom: 2px;
  height: 16px;
  width: 25px;
`;

export const ContentTable = styled(Table)`
  th {
    text-transform: capitalize;

    &:nth-child(4) {
      min-width: 190px;
    }
  }

  td {
    vertical-align: middle;
  }

  .select-action,
  .select-action [data-value] {
    padding: 0;
  }
`;

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem auto 0;
  width: 100%;
  column-gap: 1.5rem;
`;

export const InvoiceModal = styled(Modal)`
  .modal-dialog {
    min-width: 90%;
  }
`;

export const NoInvoiceBatch = styled.div`
  width: 25%;
  margin: 0 auto;
`;
