import React, { useState } from "react";
import { talentFilterDefault, talentStateDefault } from "utils";
import { ITalentStateType } from "../types/talent.interface";
import { TalentContext, TalentFilterContext } from ".";

export const TalentProvider: React.FC = (props) => {
  const [talentState, setTalentState] = useState<ITalentStateType>(talentStateDefault);

  const [filters, setFilters] = useState(talentFilterDefault);

  return (
    <TalentContext.Provider value={{ talentState, setTalentState }}>
      <TalentFilterContext.Provider value={{ filters, setFilters }}>
        {props.children}
      </TalentFilterContext.Provider>
    </TalentContext.Provider>
  );
};

export default TalentProvider;
