import { Auth } from "@aws-amplify/auth";
import { useCallback, useState } from "react";
import {
  ApiCustomHookStateType,
  ApiHookReturnType,
  apiInitialState,
  getSuccessResponse,
  getErrorResponse,
  ISignInResponse,
  ISetPasswordPayload,
} from "hooks/utils";
import { loginErrorHeading } from "utils";
import { useErrorContext } from "state/error";

export const useNewPassword = (): ApiHookReturnType<ISignInResponse, ISetPasswordPayload> => {
  const { showError } = useErrorContext();
  const [res, setRes] = useState<ApiCustomHookStateType<ISignInResponse>>(apiInitialState);

  const setNewPassword = useCallback(async (payload: ISetPasswordPayload): Promise<void> => {
    const { password, user } = payload;
    setRes({ ...apiInitialState, isLoading: true });
    try {
      const response: ISignInResponse = await Auth.completeNewPassword(user, password);
      setRes(getSuccessResponse(response));
    } catch (error) {
      const { message } = error;
      setRes(getErrorResponse(message));
      showError?.({ title: loginErrorHeading, message });
    }
  }, []);
  return { res, performAction: setNewPassword };
};

export default useNewPassword;
