import styled from "styled-components";

export const ChatHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  margin: 0px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  min-height: 102px;
  @media only screen and (min-width: 576px) {
    padding: 20px 45px 20px 38px;
  }
`;

export const ChatHeaderDetail = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ChatHeaderContent = styled.div`
  width: 100%;
`;

export const ChatHeaderAvatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 43px;
  height: 43px;
  border-radius: 2.26px;
  border: none;
  margin-right: 18px;
  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 2.26px;
    object-fit: cover;
  }
  &::after {
    position: absolute;
    // content: "";
    top: auto;
    bottom: -3px;
    left: auto;
    right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #d6d6d6;
    background: transparent;
  }
  &.active {
    &::after {
      border: none;
      background: #16f1ca;
    }
  }
`;

export const ChatHeaderName = styled.div`
  width: 100%;
  font-size: 17px;
  line-height: 22px;
  font-weight: 800;
  color: #1c1b1f;
  letter-spacing: 0.2px;
  margin: 0px 0px 3px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (min-width: 420px) {
    font-size: 18px;
    line-height: 23px;
  }
`;

export const ChatHeaderDesignation = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #344054;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0px;
`;

export const ChatHeaderToggleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  font-size: 18px;
  border: none;
  background: transparent;
  margin-left: 12px;
  cursor: pointer;
  & i {
    &::before {
      color: #667085;
    }
  }
  &:hover,
  &:focus {
    & i {
      &::before {
        color: #363835;
      }
    }
  }
`;

export const ChatHeaderCrossIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  font-size: 16px;
  border: none;
  background: transparent;
  margin-left: 12px;
  cursor: pointer;
  & i {
    &::before {
      color: #667085;
    }
  }
  &:hover,
  &:focus {
    & i {
      &::before {
        color: #363835;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
