import styled from "styled-components";

const animate = `
animation: shimmer 4s infinite linear;
background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
background-size: 1000px 100%;`;

const darkAnimate = `
animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #EDEDED 4%, #e2e2e2 25%, #EDEDED 36%);
  background-size: 1000px 100%;
`;

const shimmerStyle = `
background-color: #ededed;
border-radius: 4px;
align-items: center;
justify-content: center;`;

export const EditTalentBodyWrapperShimmer = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const EditTalentBackIconWrapperShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 0px;
`;

export const EditTalentBackIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 12px;
  ${shimmerStyle}
  ${animate}
`;

export const EditTalentBackTextShimmer = styled.div`
  ${shimmerStyle}
  ${animate}
  max-width: 20px;
  height: 14px;
`;

export const EditTalentMainWrapperShimmer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${shimmerStyle}
  ${animate}
  border-radius: 20px;
  padding: 28px 0px;
  z-index: 2;

  @media only screen and (min-width: 992px) {
    padding: 32px 35px;
  }
`;

// Talent profile Info

export const EditTalentInfoWrapperShimmer = styled.div`
  position: relative;
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  z-index: 2;
  padding: 20px 20px 40px 20px;
  @media only screen and (min-width: 576px) {
    padding: 20px 32px 40px 32px;
  }
  @media only screen and (min-width: 992px) {
    padding: 29px 40px 46px 40px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 29px 56px 46px 62px;
  }
`;

export const EditTalentInfoTopShimmer = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (min-width: 520px) {
    flex-direction: row;
  }
`;

export const EditTalentInfoTopLeftShimmer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (min-width: 520px) {
    flex-direction: row;
  }
`;

export const EditTalentAvatarWrapperShimmer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-top: 16px;

  @media only screen and (min-width: 520px) {
    width: auto;
    margin-right: 16px;
  }

  @media only screen and (min-width: 576px) {
    margin-right: 20px;
    margin-top: 0px;
  }

  @media only screen and (min-width: 1280px) {
    margin-right: 38px;
  }
`;

export const EditTalentAvatarShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 110px;
  height: 110px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  border: none;
  ${shimmerStyle}
  ${animate}
  border-radius: 50%;

  @media only screen and (min-width: 520px) {
    width: 90px;
    height: 90px;
  }

  @media only screen and (min-width: 576px) {
    width: 110px;
    height: 110px;
  }

  @media only screen and (min-width: 992px) {
    width: 137px;
    height: 137px;
  }
`;

export const EditTalentAvatarBadgeShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 110px;
  max-width: 110px;
  min-height: 24px;
  margin: 0px;
  border: none;
  text-align: center;
  padding: 6px 8px;
  overflow: hidden;
  padding: 0px;
  margin: -8px 0px 0px 0px;
  border: none;
  @media only screen and (min-width: 520px) {
    min-width: 90px;
    max-width: 90px;
  }

  @media only screen and (min-width: 576px) {
    min-width: 110px;
    max-width: 110px;
    font-size: 14px;
    line-height: 18px;
  }

  @media only screen and (min-width: 992px) {
    min-width: 131px;
    max-width: 131px;
  }
  ${shimmerStyle}
  ${animate}
`;

export const EditTalentAvatarBadgeTextShimmer = styled.div`
  width: 100%;
  max-width: 70px;
  height: 12px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const EditTalentDetailShimmer = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  overflow: hidden;
  @media only screen and (min-width: 520px) {
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
    min-width: 220px;
  }
`;

export const EditTalentNameShimmer = styled.div`
  width: 100%;
  max-width: 214px;
  height: 24px;
  margin: 0px;
  text-align: center;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 520px) {
    text-align: left;
  }
`;

export const EditTalentDesignationShimmer = styled.div`
  width: 100%;
  max-width: 210px;
  height: 14px;
  margin: 9px 0px 0px 0px;
  text-align: center;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 520px) {
    text-align: left;
  }
`;

export const EditTalentScoreShimmer = styled.div`
  width: 100%;
  max-width: 98px;
  height: 14px;
  margin: 17px 0px 0px 0px;
  text-align: center;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 520px) {
    text-align: left;
  }
`;

export const EditTalentFeedbackBtnShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  min-height: 47px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  font-weight: 500;
  color: #fff;
  padding: 4px 8px;
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  border: none;
  cursor: pointer;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 520px) {
    min-width: 110px;
  }
  @media only screen and (min-width: 576px) {
    min-width: 134px;
    font-size: 14px;
  }

  @media only screen and (min-width: 1200px) {
    min-width: 163px;
  }
`;

export const ExpertiseWrapperShimmer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 34px;
  ${shimmerStyle}
  ${animate}
  border-radius: 6px;
  padding: 20px 24px 4px 20px;
`;

export const ExpertiseEditIconShimmer = styled.div`
  position: absolute;
  top: 13px;
  bottom: auto;
  right: 16px;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  padding: 0px;
  margin: 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const ExpertiseContainerShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

export const ExpertiseBoxShimmer = styled.div`
  padding: 5px;
  margin: 0px 11px 14px 0px;
  text-align: center;
  ${shimmerStyle}
  ${darkAnimate}
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  max-width: 120px;
  height: 24px;
  border-radius: 20px;

  &:last-child {
    margin: 0px 0px 14px 0px;
  }
`;

export const ExpertiseBoxTextShimmer = styled.div`
  width: 100%;
  padding: 0px;
  text-align: center;
  ${shimmerStyle}
  ${animate}
  max-width: 90px;
  height: 10px;
  margin: 0px auto;
`;

export const ProfileDetailWrapperShimmer = styled.div`
  position: relative;
  width: 100%;
  ${shimmerStyle}
  ${animate}
  border-radius: 6px;
  padding: 20px;
  margin-top: 16px;
`;

export const ProfileDetailEditIconShimmer = styled.div`
  position: absolute;
  top: 13px;
  bottom: auto;
  right: 16px;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 13.4px;
  height: 13.4px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const EditTalentInfoIconShimmer = styled.div`
  display: inline-flex;
  cursor: pointer;
  width: 16px;
  height: 16px;
  ${shimmerStyle}
  ${animate}
`;

export const ProfileDetailListShimmer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin: 0px auto;

  @media only screen and (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    margin: 0px;
  }
`;

export const ProfileDetailItemShimmer = styled.div`
  width: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  margin: 0px auto;
  padding: 0px;
  @media only screen and (min-width: 576px) {
    width: 100%;
    justify-content: center;
    margin: 0px;
  }
  &:nth-child(2) {
    border-top: 0.5px solid rgba(217, 217, 217, 0.34);
    border-bottom: 0.5px solid rgba(217, 217, 217, 0.34);
    border-left: none;
    border-right: none;
    padding: 24px 0px;
    @media only screen and (min-width: 576px) {
      border-left: 0.5px solid rgba(217, 217, 217, 0.34);
      border-right: 0.5px solid rgba(217, 217, 217, 0.34);
      border-top: none;
      border-bottom: none;
      padding: 0px 10px;
    }
  }
`;

export const ProfileDetailContentShimmer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  text-align: left;
  margin: 0px;
  @media only screen and (min-width: 576px) {
    margin: 0px auto;
  }
`;

export const ProfileDetailSbHdShimmer = styled.div`
  width: 100%;
  max-width: 82px;
  height: 14px;
  margin: 0px 0px 12px 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const ProfileDetailParaShimmer = styled.div`
  width: 100%;
  max-width: 100px;
  height: 14px;
  margin: 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const ProfileSummaryWrapperShimmer = styled.div`
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  margin-top: 24px;
  padding: 40px 32px;
  @media only screen and (min-width: 576px) {
    padding: 40px 43px 40px 56px;
  }
`;

export const ProfileSummaryHdShimmer = styled.div`
  width: 100%;
  max-width: 100px;
  height: 24px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;

export const ProfileSummaryParaShimmer = styled.div`
  margin: 16px 0px 20px 0px;
  width: 100%;
  height: 14px;
  ${shimmerStyle}
  ${animate}
`;

export const ProfileSummaryLinkShimmer = styled.div`
  width: 100%;
  max-width: 70px;
  height: 14px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;

export const ProfileSummaryTextAreaShimmer = styled.div`
  width: 100%;
  height: 122px;
  margin: 16px 0px 14px 0px;
  padding: 22px;
  ${shimmerStyle}
  ${animate}
  border-radius: 7px;

  @media only screen and (min-width: 576px) {
    padding: 22px 27px;
  }
`;

export const SummarySaveBtnShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 99px;
  max-width: 99px;
  height: 33px;
  text-align: center;
  padding: 4px 8px;
  padding: 0px;
  margin: 0px;
  border: none;
  ${shimmerStyle}
  ${animate}
  border-radius: 8px;
`;

export const SummarySaveBtnTextShimmer = styled.div`
  width: 100%;
  max-width: 70px;
  height: 14px;
  text-align: center;
  margin: 0px;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 8px;
`;

export const CertificationDetailWrapperShimmer = styled.div`
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  padding: 32px 20px;
  @media only screen and (min-width: 1280px) {
    padding: 40px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 40px 56px;
  }
`;

export const CertificationDetailTopShimmer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
`;

export const CertificationDetailTitleShimmer = styled.div`
  width: 100%;
  max-width: 141px;
  height: 22px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 576px) {
    height: 24px;
  }
`;

export const CertificationDetailTopIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;

export const CertificationDetailListShimmer = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const CertificationDetailItemShimmer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 28px;
`;

export const CertificationDetailIconShimmer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #6fcf97;
  background: #219653;
  border: none;
  border-radius: 50%;
  margin-right: 12px;
  ${shimmerStyle}
  ${animate}
  & span {
    ${shimmerStyle}
    ${animate}
    width: 21px;
    height: 29px;
  }
`;

export const CertificationDetailContentShimmer = styled.div`
  width: 100%;
`;

export const CertificationDetailSbHdShimmer = styled.div`
  width: 100%;
  max-width: 179px;
  height: 16px;
  margin: 0px 0px 4px 0px;
  ${shimmerStyle}
  ${animate}
`;

export const CertificationDetailParaShimmer = styled.div`
  width: 100%;
  max-width: 159px;
  height: 14px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;

export const EducationDetailWrapperShimmer = styled.div`
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  padding: 32px 20px;
  @media only screen and (min-width: 1280px) {
    padding: 40px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 40px 56px;
  }
`;

export const EducationDetailTopShimmer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
`;

export const EducationDetailTopIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;

export const EducationDetailTitleShimmer = styled.div`
  margin: 0px;
  width: 100%;
  max-width: 117px;
  height: 22px;
  ${shimmerStyle}
  ${animate}
  @media only screen and (min-width: 576px) {
    height: 24px;
  }
`;

export const EducationDetailListShimmer = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const EducationDetailItemShimmer = styled.div`
  width: 100%;
  margin-top: 28px;
`;

export const EducationInnerShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  margin-bottom: 8px;
`;

export const EducationDetailSbHdShimmer = styled.div`
  width: 100%;
  max-width: 146px;
  height: 16px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;

export const EducationDetailParaShimmer = styled.div`
  width: 100%;
  margin: 0px;
`;

export const EducationDetailParaTextShimmer = styled.div`
  width: 100%;
  height: 14px;
  margin: 8px 0px 0px 0px;
  ${shimmerStyle}
  ${animate}
`;

export const JobHistoryWrapperShimmer = styled.div`
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  padding: 32px 20px;
  @media only screen and (min-width: 1280px) {
    padding: 40px;
  }
`;

export const EmployementHeadShimmer = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: flex-start;
`;

export const JobHistoryTitleShimmer = styled.div`
  width: 100%;
  max-width: 233px;
  height: 22px;
  text-transform: capitalize;
  padding: 0px;
  margin: 0px 0px 17px 0px;
  @media only screen and (min-width: 576px) {
    height: 24px;
  }
  ${shimmerStyle}
  ${animate}
`;

export const JobHistoryContainerShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const HistoryCardWrapperShimmer = styled.div`
  width: 100%;
  padding: 0px;
  margin-top: 14px;
  ${shimmerStyle}
  ${animate}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 24px;
`;

export const HistoryCardTopShimmer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HistoryCardTitleShimmer = styled.div`
  width: 100%;
  max-width: 220px;
  height: 16px;
  margin: 0px 10px 0px 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const HistoryTitleTextShimmer = styled.div`
  display: inline-block;
  margin-right: 5px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const HistoryCardDateShimmer = styled.div`
  width: 100%;
  max-width: 170px;
  height: 14px;
  color: #312a50;
  margin: 10px 0px 0px 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const HistoryCardParaShimmer = styled.div`
  width: 100%;
  margin: 0px;
`;

export const HistoryCardParaShimmerText = styled.div`
  height: 14px;
  width: 100%;
  margin: 8px 0px 0px 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const HistoryCardBadgeShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  min-height: 24px;
  margin: 0px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 400;
  color: #41b883;
  padding: 3px 6px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const AddEmployementBtnShimmer = styled.div`
  font-size: 14px;
  white-space: nowrap;
  color: #312a50;
  background: #f2f2f2;
  border: 1px solid #312a50;
  padding: 8px 12px;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  ${shimmerStyle}
  ${animate}
`;

export const HistoryEditIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0px;
  margin: 0px 0px 0px 6.8px;
  ${shimmerStyle}
  ${animate}
`;

export const MatchTalentWrapperShimmer = styled.div`
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  padding: 32px 20px;
  margin-top: 16px;
  @media only screen and (min-width: 1280px) {
    padding: 32px 40px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 37px 56px 52px 56px;
  }
`;

export const MatchTalentTopShimmer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MatchTalentTopIconWrapperShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MatchTalentTopIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  &:last-child {
    margin-left: 16px;
    @media only screen and (min-width: 992px) {
      margin-left: 27px;
    }
  }

  ${shimmerStyle}
  ${animate}
`;

export const MatchTalentTitleShimmer = styled.div`
  width: 100%;
  max-width: 160px;
  height: 22px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 576px) {
    height: 24px;
  }
`;

export const MatchTalentListShimmer = styled.div`
  width: 100%;
`;

export const MatchTalentItemShimmer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 46px; ;
`;

export const MatchTalentSbHdShimmer = styled.div`
  width: 100%;
  max-width: 216px;
  height: 22px;
  text-align: center;
  margin: 5.2px 0px 0px 0px;
  font-family: "Avenir LT Std";
  ${shimmerStyle}
  ${animate}
`;

export const MatchTalentParaShimmer = styled.div`
  width: 100%;
  max-width: 272px;
  height: 18px;
  text-align: center;
  margin: 16px 0px 0px 0px;
  font-family: "Avenir LT Std";
  ${shimmerStyle}
  ${animate}
`;

export const MatchTalentImgShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 109px;
  height: 109px;
  padding: 0px;
  margin: 0px auto;
  border: none;
  ${shimmerStyle}
  ${animate}
  border-radius: 50%;
`;

export const MatchTalentBtnShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 144px;
  min-height: 44px;
  padding: 4px 8px;
  overflow: hidden;
  padding: 0px;
  margin: 16px auto 0px auto;
  ${shimmerStyle}
  ${animate}
  border-radius: 5px;
`;

export const DownloadResumeWrapperShimmer = styled.div`
  width: 100%;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 16px;
  padding: 32px 20px;
  margin-top: 16px;
  @media only screen and (min-width: 1280px) {
    padding: 38px 40px;
  }
  @media only screen and (min-width: 1440px) {
    padding: 47px 56px 49px 56px;
  }
`;

export const DownloadResumeTitleShimmer = styled.div`
  width: 100%;
  max-width: 217px;
  height: 22px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}

  @media only screen and (min-width: 576px) {
    height: 24px;
  }
`;

export const DownloadResumeParaShimmer = styled.div`
  width: 100%;
  max-width: 373px;
  height: 14px;
  margin: 16px 0px 0px 0px;
  ${shimmerStyle}
  ${animate}
`;

export const DownloadResumeBoxShimmer = styled.div`
  flex-wrap: wrap;
  max-width: 555px;
  width: 100%;
  margin-top: 31px;
  border-radius: 4px;
  padding: 4px 12px 20px 12px;
  ${shimmerStyle}
  ${animate}
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 576px) {
    padding: 4px 20px 20px 20px;
  }
`;

export const DownloadResumeInfoShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const DownloadResumeInfoContentShimmer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-width: 90px;
  max-width: 110px;
`;

export const DownloadResumeImgShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 0px;
  margin: 0px 10px 0px 0px;
  border: none;
  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 50%;

  @media only screen and (min-width: 576px) {
    margin: 0px 13px 0px 0px;
    width: 56px;
    height: 56px;
  }
`;

export const DownloadResumeNameShimmer = styled.div`
  width: 100%;
  max-width: 90px;
  height: 16px;
  margin: 0px;
  ${shimmerStyle}
  ${darkAnimate}
`;

export const DownloadResumeDateShimmer = styled.div`
  width: 100%;
  max-width: 110px;
  height: 14px;
  ${shimmerStyle}
  ${darkAnimate}
  margin: 6px 0px 0px 0px;
`;

export const DownloadResumeBtnShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  text-align: center;
  padding: 4px 8px;
  padding: 0px;
  margin: 16px 0px 0px 0px;
  width: 100%;
  min-width: auto;

  @media only screen and (min-width: 420px) {
    width: auto;
    min-width: 143px;
  }

  ${shimmerStyle}
  ${darkAnimate}
  border-radius: 5px;
`;

export const DownloadResumeBtnTextShimmer = styled.div`
  width: 100%;
  max-width: 87px;
  height: 14px;
  ${shimmerStyle}
  ${animate}
`;

export const DownloadResumeBtnIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0px;
  margin: 0px 0px 0px 12px;
  width: 14px;
  height: 14px;
  ${shimmerStyle}
  ${animate}
`;

export const TalentProfileTopIconWrapperShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EditTalentInfoShimmer = styled.div`
  display: inline-flex;
  grid-gap: 10px 25px;
  align-items: center;
  margin-bottom: 9px;
`;

export const TalentProfileTopIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  background: #ffffff;
  border: 0.5px solid rgba(49, 42, 80, 0.64);
  border-radius: 4px;
  margin-left: 16px;
  @media only screen and (min-width: 992px) {
    margin-left: 20px;
  }
  ${shimmerStyle}
  ${animate}
`;

// Talent profile Info
