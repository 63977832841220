import React from "react";
import * as Styled from "./styles";
import TalentVersionBodyShimmer from "./talentVersionBodyShimmer";
import TalentVersionHeaderShimmer from "./talentVersionHeaderShimmer";

export const TalentProfileVersionShimmer: React.FC = () => {
  return (
    <>
      <Styled.EditTalentBodyWrapperShimmer>
        <TalentVersionHeaderShimmer />
        <Styled.EditTalentMainWrapperShimmer>
          <TalentVersionBodyShimmer />
        </Styled.EditTalentMainWrapperShimmer>
      </Styled.EditTalentBodyWrapperShimmer>
    </>
  );
};

export default TalentProfileVersionShimmer;
