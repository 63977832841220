import { useLazyQuery } from "@apollo/client";
import {
  ReferralByRecruiterQueryVariables,
  ReferralByRecruiterQuery,
} from "@remotebase/amplify-constants/API";
import { referralByRecruiter } from "@remotebase/amplify-constants/modified";
import { ReturnPropsReferralByRecruiter } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

// eslint-disable-next-line import/prefer-default-export
export const useReferralByRecruiter = (): ReturnPropsReferralByRecruiter => {
  const [getReferralsByRecruiter, { data, loading }] = useLazyQuery<
    ReferralByRecruiterQuery,
    ReferralByRecruiterQueryVariables
  >(getQuery(referralByRecruiter));
  const referralsList = data?.referralByRecruiter?.items || null;
  const nextToken = data?.referralByRecruiter?.nextToken || null;

  return { getReferralsByRecruiter, data: referralsList, loading, nextToken };
};
