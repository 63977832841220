import { TalentProfileVersion } from "components";
import { ProfileVersionProvider } from "state/profileVersion";
import { FC } from "react";
import * as Styled from "./styles";

export const TalentProfileVersionPage: FC = () => {
  return (
    <Styled.TalentProfileVersionWrapper>
      <ProfileVersionProvider>
        <TalentProfileVersion />
      </ProfileVersionProvider>
    </Styled.TalentProfileVersionWrapper>
  );
};

export default TalentProfileVersionPage;
