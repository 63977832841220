import withApolloProvider from "hooks/apollo/withApollo";
import useFetchReferral from "hooks/talent/useFetchReferrals";
import { TalentData } from "hooks/types";
import { compact } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { IReferralProps } from "state/types/referrals.interface";
import { pageLimit, getSlicedData, getLastPagePtr } from "utils";

export function withReferrals<T>(Component: React.FC<T & IReferralProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { fetchData, data, loading: referralLoading, nextToken } = useFetchReferral();
    const [referralsData, setReferralsData] = useState<TalentData>([]);
    const [currentPtr, setCurrentPtr] = useState(0);
    const [loading, setLoading] = useState(false);
    const [referrerId, setReferrerId] = useState<string>();

    const [apiCalled, setApiCalled] = useState(false);
    const [fetchAll, setFetchAll] = useState(false);

    const callAPI = (): void => {
      if (referrerId) {
        setLoading(true);
        fetchData(nextToken || null, referrerId);
      }
    };

    const prevBtnClick = (): void => {
      setCurrentPtr(currentPtr - pageLimit);
    };

    const nextBtnClick = (): void => {
      const nextPtr = currentPtr + pageLimit;
      setLoading(true);
      if (referralsData.length < nextPtr + pageLimit && nextToken) {
        setCurrentPtr(nextPtr);
        setApiCalled(true);
      } else {
        setTimeout(() => {
          setLoading(false);
          setCurrentPtr(nextPtr);
        }, 500);
      }
    };

    const searchByReferrerId = (referId: string): void => {
      setReferrerId(referId);
    };

    const resetState = (): void => {
      setApiCalled(false);
      setFetchAll(false);
      setLoading(false);
    };

    const isNextDisable = (): boolean =>
      !(nextToken || referralsData.length > currentPtr + pageLimit);

    const isPrevDisable = (): boolean => currentPtr - pageLimit < 0;

    const updateReferrerData = (referData: TalentData): void => {
      setReferralsData(compact([...referralsData, ...referData]));
    };

    const fetchMoreReferrals = (token: string): void => {
      updateReferrerData(data || []);
      setLoading(true);
      fetchData(token, referrerId || "");
    };

    const lastPageBtn = (): void => {
      setFetchAll(true);
      if (nextToken && referrerId) setApiCalled(true);
      else setCurrentPtr(getLastPagePtr(referralsData?.length));
    };

    useEffect(() => {
      if (currentPtr) setCurrentPtr(0);
      setReferralsData([]);
      setApiCalled(true);
    }, [referrerId]);

    useEffect(() => {
      if (!referralLoading && data !== undefined && apiCalled) {
        const referralsSize = referralsData.length;
        if (nextToken && (referralsSize + data.length < currentPtr + pageLimit || fetchAll))
          fetchMoreReferrals(nextToken || "");
        else {
          updateReferrerData(data || []);
          if (fetchAll) setCurrentPtr(referralsSize + getLastPagePtr(data?.length));
          resetState();
        }
      }
    }, [data, nextToken]);

    useEffect(() => {
      if ((!referralsData && referrerId) || apiCalled) {
        setApiCalled(true);
        callAPI();
      }
    }, [apiCalled]);

    const footerProps = useMemo(
      () => ({
        prevBtnDisable: isPrevDisable(),
        nextBtnDisable: isNextDisable(),
        lastPageBtn,
        prevBtnClick,
        nextBtnClick,
      }),
      [referralsData, currentPtr, nextToken],
    );

    const referralProps: IReferralProps = {
      referralState: {
        allData: referralsData,
        data: getSlicedData(referralsData, currentPtr, pageLimit),
        currentPtr,
        nextToken,
        loading,
      },
      searchByReferrerId,
      footerProps,
    };
    return <Component {...props} {...referralProps} />;
  });
}
export default withReferrals;
