import Highcharts from "highcharts";

export enum TableColumnsAnalytics {
  TimeFrame = "Time Period",
  ProfilesCreated = "Developer Signups",
  ProfilesCompleted = "Profiles Completed",
  Hired = "Hired",
  Recruiters = "Recruiters Signups",
  DomainTestsTaken = "Domain Tests Taken",
  DomainTestsPassed = "Passed Domain Tests",
  PSTestsTaken = "Taken PS Tests",
  PSTestsPassed = "Passed PS Tests",
  ReferredByRecruiters = "Referred By Recruiters",
  Remove = "Remove",
}

export enum DropdownAttributes {
  title = "Attributes",
}

export const analyticsEnum = {
  developersJoined: "developersJoined",
  developersCompletedProfiles: "developersCompletedProfiles",
  hiredToday: "hiredToday",
  recruitersJoined: "recruitersJoined",
  takenDomainTests: "takenDomainTests",
  passedDomainTests: "passedDomainTests",
  takenProblemSolvingTests: "takenProblemSolvingTests",
  passedProblemSolving: "passedProblemSolving",
  developersReferredByRecruiters: "developersReferredByRecruiters",
  createdAt: "createdAt",
};

export enum TimePeriod {
  Week = 7,
  Month = 30,
}

export enum OperationDays {
  add = "add",
  subtract = "subtract",
}

export enum OperationTime {
  startOf = "startOf",
  endOf = "endOf",
}

export enum ComponentType {
  Graph = "Graph",
  Table = "Table",
}

export enum TimePeriodLabel {
  WeekLabel = "last 7 days",
  MonthLabel = "last 30 days",
}

export enum CheckboxesNames {
  ProfilesCreated = "Developer Signups",
  ProfilesCompleted = "Profiles Completed",
  Hired = "Hired",
  Recruiters = "Recruiters Signups",
  DomainTestsTaken = "Domain Tests Taken",
  PSTestsTaken = "Taken PS Tests",
  DomainTestsPassed = "Passed Domain Tests",
  PSTestsPassed = "Passed PS Tests",
  ReferredByRecruiters = "Referred By Recruiters",
}

export const AnalyticsInitial = {
  developersJoined: 0,
  developersCompletedProfiles: 0,
  hiredToday: 0,
  recruitersJoined: 0,
  takenDomainTests: 0,
  passedDomainTests: 0,
  takenProblemSolvingTests: 0,
  passedProblemSolving: 0,
  developersReferredByRecruiters: 0,
};

export const AnalyticsTableDisplayInitial = {
  ProfilesCreated: true,
  ProfilesCompleted: true,
  Hired: true,
  Recruiters: true,
  DomainTestsTaken: false,
  DomainTestsPassed: false,
  PSTestsTaken: false,
  PSTestsPassed: true,
  ReferredByRecruiters: false,
};

export const AnalyticsDistributedInitial = {
  developersJoined: [],
  developersCompletedProfiles: [],
  hiredToday: [],
  recruitersJoined: [],
  takenDomainTests: [],
  passedDomainTests: [],
  takenProblemSolvingTests: [],
  passedProblemSolving: [],
  developersReferredByRecruiters: [],
  createdAt: [],
};

Highcharts.SVGRenderer.prototype.symbols.download = (
  x: number,
  y: number,
  w: number,
  h: number,
): (string | number)[] => {
  const path = [
    // Arrow stem
    "M",
    x + w * 0.5,
    y,
    "L",
    x + w * 0.5,
    y + h * 0.7,
    // Arrow head
    "M",
    x + w * 0.3,
    y + h * 0.5,
    "L",
    x + w * 0.5,
    y + h * 0.7,
    "L",
    x + w * 0.7,
    y + h * 0.5,
    // Box
    "M",
    x,
    y + h * 0.9,
    "L",
    x,
    y + h,
    "L",
    x + w,
    y + h,
    "L",
    x + w,
    y + h * 0.9,
  ];
  return path;
};

export const initialProfileState = { isLoading: false };

export const initialJobID = null;
