import { Referral } from "@remotebase/amplify-constants/API";
import {
  useTalentList,
  useRecruiterList,
  useReferralByRecruiter,
  useReferralByReferrer,
} from "hooks";
import { ReturnPropsReferredBy } from "hooks/types/referredBy";
import { useEffect, useMemo, useState } from "react";
import { IVariables } from "state/referredByGraph";
import { IReferralVariable } from "state/types/referrals.interface";

export const useRefferedBy = (): ReturnPropsReferredBy => {
  const [prevRecruiterData, setPrevRecruiterData] = useState<(Referral | null)[]>([]);
  const [prevReferrerData, setPrevReferrerData] = useState<(Referral | null)[]>([]);
  const [recruiterId, setRecruiterId] = useState<null | string>(null);
  const [referrerId, setReferrerId] = useState<null | string>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>("");
  const [sortedList, setSortedList] = useState<(Referral | null)[]>([]);
  const {
    getRecruiterList,
    data: recruitersList,
    loading: recruiterLoading,
    nextToken: recruiterToken,
  } = useRecruiterList();
  const {
    getTalentList,
    talentsList,
    loading: talentLoading,
    nextToken: talentToken,
  } = useTalentList();
  const {
    getReferralsByRecruiter,
    data: referralsList,
    loading: referralLoading,
    nextToken: referralToken,
  } = useReferralByRecruiter();
  const {
    getReferralsByReferrer,
    data: referralsByTalentList,
    loading: referralByTalentLoading,
    nextToken: referralByTalentToken,
  } = useReferralByReferrer();

  const queryVariables = useMemo(
    () =>
      (token?): IVariables => ({
        filter: {
          email: {
            eq: searchText,
          },
        },
        nextToken: token,
      }),
    [searchText],
  );

  const referralVariables = (recruiter, recId, refId, token): IReferralVariable => {
    return {
      ...(recruiter && { recruiterId: recId }),
      ...(!recruiter && { referrerId: refId }),
      nextToken: token,
    };
  };

  const handleSubmit = (): void => {
    if (searchText !== submitText) {
      setSubmitText(searchText);
      setSortedList([]);
      getRecruiterList({ variables: queryVariables() });
    }
  };

  const referralData = (data, token, recruiter): void => {
    if (data) {
      setSortedList([...sortedList, ...data]);
      if (token) {
        const variableReferrals = referralVariables(recruiter, recruiterId, referrerId, token);
        if (recruiter) getReferralsByRecruiter({ variables: variableReferrals });
        else getReferralsByReferrer({ variables: variableReferrals });
      } else if (!token) {
        if (recruiter) setPrevRecruiterData([...sortedList, ...data]);
        else setPrevReferrerData([...sortedList, ...data]);
      }
    }
  };

  const callAPI = (list, token, recruiter): void => {
    if (!list?.length && token) {
      if (recruiter) getRecruiterList({ variables: queryVariables(recruiterToken) });
      else {
        getTalentList({ variables: queryVariables(token) });
      }
    } else if (list?.length) {
      const profileId = list?.[0]?.id;
      if (profileId === recruiterId && recruiter) setSortedList(prevRecruiterData);
      if (profileId === referrerId && !recruiter) setSortedList(prevReferrerData);
      if (recruiter) setRecruiterId(profileId);
      else setReferrerId(profileId);
      const variableReferrals = referralVariables(recruiter, profileId, profileId, null);
      if (recruiter) {
        getReferralsByRecruiter({ variables: variableReferrals });
      } else {
        getReferralsByReferrer({ variables: variableReferrals });
      }
    }
    if (list?.length && token) {
      getTalentList({ variables: queryVariables(token) });
    }
    if (!list?.length && !token && recruiter) {
      getTalentList({ variables: queryVariables() });
    }
  };

  useEffect(() => {
    if (recruitersList) callAPI(recruitersList, recruiterToken, true);
  }, [recruitersList]);

  useEffect(() => {
    if (talentsList) callAPI(talentsList, talentToken, false);
  }, [talentsList, talentToken]);

  useEffect(() => {
    referralData(referralsList, referralToken, true);
  }, [referralsList]);

  useEffect(() => {
    referralData(referralsByTalentList, referralByTalentToken, false);
  }, [referralsByTalentList]);

  useMemo(() => {
    if (referralLoading || recruiterLoading || talentLoading || referralByTalentLoading)
      setLoading(true);
    else if (
      !(
        referralLoading ||
        recruiterLoading ||
        talentLoading ||
        referralByTalentLoading ||
        referralToken ||
        referralByTalentToken ||
        talentToken ||
        recruiterToken
      )
    )
      setLoading(false);
  }, [referralLoading, recruiterLoading, talentLoading, referralByTalentLoading]);

  return {
    data: sortedList,
    loading,
    searchfields: { setSearchText, searchText },
    fetchData: handleSubmit,
  };
};

export default useRefferedBy;
