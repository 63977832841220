import { FC, useEffect, useMemo } from "react";
import { CustomModal, FormInput, FormSelect } from "components";
import { useProfileVersionContext } from "state/profileVersion";
import { DropdownItems, UpdateVersionOtherInfoForm, UpdateVersionOtherInfoSchema } from "utils";
import { englishProficiency as EnglishProficiencyEnum } from "@remotebase/amplify-constants/API";
import { englishProficiencyMap } from "@remotebase/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../styles";

interface Props {
  open: boolean;
  close: () => void;
}

export const UpdateOtherInfo: FC<Props> = ({ open, close }) => {
  const { selectedVersion, setSelectedVersion } = useProfileVersionContext();

  const formHook = useForm({
    resolver: yupResolver(UpdateVersionOtherInfoSchema),
    mode: "onChange",
  });

  const { reset, getValues } = formHook;

  useEffect(() => {
    reset({
      [UpdateVersionOtherInfoForm.experience]: selectedVersion?.experience,
      [UpdateVersionOtherInfoForm.englishProficiency]: selectedVersion?.englishProficiency,
      [UpdateVersionOtherInfoForm.expectedSalaryInUsd]: selectedVersion?.expectedSalaryInUsd,
    });
  }, [selectedVersion]);

  const englishLevels = useMemo<DropdownItems>(
    () =>
      Object.values(EnglishProficiencyEnum).map((val) => ({
        value: val,
        key: englishProficiencyMap[val],
      })),
    [],
  );

  const experienceLevels = useMemo<DropdownItems>(
    () => [
      { value: "1", key: "1 Year" },
      { value: "2", key: "2 Years" },
      { value: "3", key: "3 Years" },
      { value: "4", key: "4 Years" },
      { value: "5", key: "5 Years" },
      { value: "6", key: "6 Years" },
      { value: "7+", key: "7+ Years" },
    ],
    [],
  );

  const updateVersionInfo = (): void => {
    const [experience, englishProficiency, expectedSalaryInUsd] = getValues([
      UpdateVersionOtherInfoForm.experience,
      UpdateVersionOtherInfoForm.englishProficiency,
      UpdateVersionOtherInfoForm.expectedSalaryInUsd,
    ]);

    setSelectedVersion((prev) =>
      prev ? { ...prev, experience, englishProficiency, expectedSalaryInUsd } : null,
    );

    close();
  };

  return (
    <CustomModal showModal={open} onClose={close} header={"Update Other Information"}>
      <Styled.OtherInfoCover>
        <Styled.FormGroup>
          <FormSelect
            label={"Experience"}
            title={UpdateVersionOtherInfoForm.experience}
            handler={formHook}
            placeholder={"Select Experience Level"}
            items={experienceLevels}
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <FormSelect
            label={"English Level"}
            title={UpdateVersionOtherInfoForm.englishProficiency}
            handler={formHook}
            placeholder={"Select English Level"}
            items={englishLevels}
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <FormInput
            label={"Salary"}
            title={UpdateVersionOtherInfoForm.expectedSalaryInUsd}
            handler={formHook}
            placeholder={"Enter Salary"}
            type={"number"}
          />
        </Styled.FormGroup>
        <Styled.SubmitButton onClick={updateVersionInfo}>Save and Update</Styled.SubmitButton>
      </Styled.OtherInfoCover>
    </CustomModal>
  );
};

export default UpdateOtherInfo;
