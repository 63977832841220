import { Fragment, FC } from "react";
import { ShouldRender } from "@remotebase/components";
import { AdminUserData } from "hooks/types";
import AdminUserTableRowData from "./adminUserTableRowData";

interface Props {
  data?: AdminUserData[];
}

export const AdminUserTableData: FC<Props> = ({ data }) => {
  return (
    <ShouldRender if={data}>
      <Fragment>
        {data?.map((user: AdminUserData, id: number) => (
          <AdminUserTableRowData user={user} key={`admin-${id}`} />
        ))}
      </Fragment>
    </ShouldRender>
  );
};

export default AdminUserTableData;
