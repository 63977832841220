export const listReferralsOptimized = /* GraphQL */ `
  query ListReferrals($filter: ModelReferralFilterInput, $limit: Int, $nextToken: String) {
    listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        recruiter {
          id
          fullName
          email
          country
          role
        }
        referrer {
          id
          fullName
          email
          role
          country
        }
        referred {
          id
          fullName
          email
          country
          profile {
            problemSolvingScore
            isPassPS
            isPassHR
            isPassFinalTech
            isNetwork
            isHired
            statusMeta
            takenDomainTests {
              isPass
            }
          }
        }
      }
      nextToken
    }
  }
`;
export default listReferralsOptimized;
