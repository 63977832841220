import styled from "styled-components";

const Styles = styled.div`
  padding: 1rem;
  text-align: center;

  table {
    border-spacing: 0;
    border: 1px solid #5e5e5e;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #5e5e5e;
      border-right: 1px solid #5e5e5e;

      :last-child {
        border-right: 0;
      }
    }
  }
`;
export default Styles;
