import { useLazyQuery } from "@apollo/client";
import { ListAnalyticsQuery, ListAnalyticsQueryVariables } from "@remotebase/amplify-constants/API";
import { listAnalytics } from "@remotebase/amplify-constants/graphql/queries";
import { ReturnPropsAnalytics } from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

// eslint-disable-next-line import/prefer-default-export
export const useAnalyticsList = (): ReturnPropsAnalytics => {
  const [getAnalyticList, { data: AnalyticsList, loading, error }] = useLazyQuery<
    ListAnalyticsQuery,
    ListAnalyticsQueryVariables
  >(getQuery(listAnalytics));

  const analyticList = AnalyticsList?.listAnalytics?.items || null;
  const nextToken = AnalyticsList?.listAnalytics?.nextToken || null;
  const errorData = error || (analyticList ? undefined : new Error("No Analytics Found"));

  return { getAnalyticList, analyticsList: analyticList, loading, nextToken, errorData };
};
