import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import withError from "state/error/withErrorHoc";
import { Badge, Modal } from "react-bootstrap";
import { Invoice } from "@remotebase/amplify-constants/API";
import { useFetchInvoices } from "hooks";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "pages/invoices/styles";
import { ErrorProps } from "@remotebase/constants";

type Props = {
  handleOnHide: () => void;
  batchId?: string;
} & ErrorProps;

const LIMIT = 5;

function InvoiceListModal({ handleOnHide, batchId, showError }: Props): ReturnType<FC> {
  const { fetchInvoices, loading, result, error } = useFetchInvoices();
  const [invoices, setInvoices] = useState<(Invoice | null)[] | undefined>();
  const [updateTokens, setUpdateTokens] = useState(false);
  const [clientPrevToken, setClientPrevToken] = useState<string | null | undefined>(undefined);
  const [clientNextToken, setClientNextToken] = useState<string | null | undefined>(undefined);

  const [startIndex, setStartIndex] = useState(0);
  const endIndex = useMemo(() => startIndex + LIMIT, [startIndex]);
  const canGoNext = useMemo(() => Boolean(invoices?.slice(endIndex)?.length), [endIndex, invoices]);
  const canGoPrev = useMemo(
    () => Boolean(invoices?.slice(startIndex - LIMIT, startIndex)?.length),
    [invoices, startIndex],
  );
  const [fetchType, setFetchType] = useState<string | undefined>(undefined);
  const nextToken = useMemo(() => {
    if (fetchType === "prev") return clientPrevToken;
    return clientNextToken;
  }, [fetchType, clientNextToken, clientPrevToken]);

  const getInvoices = async (e?: SyntheticEvent<HTMLElement>): Promise<void> => {
    if (!batchId) return;
    const type = e?.currentTarget?.dataset?.fetch;
    setFetchType(type);

    if (type === "next") setStartIndex(startIndex + LIMIT);
    if (type === "prev") setStartIndex(startIndex - LIMIT);
  };

  useEffect(() => {
    if (
      (invoices?.slice(startIndex).length || 0) > LIMIT ||
      (fetchType === "next" && !clientNextToken) ||
      fetchType === "prev"
    )
      return;

    fetchInvoices({
      variables: {
        limit: LIMIT * 2,
        nextToken,
        invoiceBatchId: batchId,
      },
    });
    setUpdateTokens(true);
  }, [startIndex, endIndex]);

  useEffect(() => {
    if (!loading && error) {
      showError({ title: "Invoice Fetch", message: "Error fetching invoices" });
      return;
    }
    if (!result?.items.length || !updateTokens) return;
    if (clientNextToken) setClientPrevToken(clientNextToken);
    setClientNextToken(result?.nextToken);
    setInvoices((prev) => {
      if (!prev) return result?.items;
      return [...prev, ...result?.items];
    });
    setUpdateTokens(false);
  }, [loading, error, result, clientNextToken, updateTokens]);

  useEffect(() => {
    if (!batchId) {
      setInvoices(undefined);
      return;
    }

    // Fetch invoices
    getInvoices();
  }, [batchId]);

  if (!loading && error) return null;

  return (
    <Styled.InvoiceModal show={Boolean(batchId)} onHide={handleOnHide} fullscreen>
      <ShouldRender if={loading}>
        <Modal.Body>Loading invoices...</Modal.Body>
      </ShouldRender>
      <ShouldRender if={!loading && invoices}>
        <Modal.Body>
          <h3>Batch {invoices?.[0]?.invoiceBatch?.invoiceBatchNumber}</h3>
          <Styled.ContentTable responsive="md">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Status</th>
                <th>Amount (USD)</th>
                <th>Client Details</th>
                <th>CreatedAt</th>
                <th>Invoice</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {invoices
                ? invoices.slice(startIndex, endIndex).map(
                    (invoice) =>
                      invoice && (
                        <tr key={invoice.id}>
                          <td>{invoice.invoiceNumber}</td>
                          <td>
                            <Badge>{invoice.status}</Badge>
                          </td>
                          <td>
                            {Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(invoice.amount / 100)}
                          </td>
                          <td>
                            {invoice.client?.fullName} - {invoice.client?.email}
                          </td>
                          <td>
                            {Intl.DateTimeFormat("en-US").format(new Date(invoice.createdAt))}
                          </td>
                          <td>
                            {invoice.stripeInvoicePdfUrl ? (
                              <a target="blank" href={`${invoice.stripeInvoicePdfUrl}`}>
                                {"download"}
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{invoice.reason}</td>
                        </tr>
                      ),
                  )
                : ""}
            </tbody>
          </Styled.ContentTable>
          <Styled.Pages>
            <Styled.Button disabled={!canGoPrev} onClick={getInvoices} data-fetch="prev">
              Prev
            </Styled.Button>
            <Styled.Button disabled={!canGoNext} onClick={getInvoices} data-fetch="next">
              Next
            </Styled.Button>
          </Styled.Pages>
        </Modal.Body>
      </ShouldRender>
    </Styled.InvoiceModal>
  );
}

export default withError(InvoiceListModal);
