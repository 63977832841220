import styled from "styled-components";

export const ChatBodyWrapper = styled.div`
  position: relative;
  display: none;
  flex-grow: 1;
  width: 100%;
  height: 508px;
  max-height: 508px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (min-width: 576px) {
    display: flex;
  }
`;

export const ChatBodyInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100vh - 273px);
  min-height: calc(100vh - 273px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 43px 45px 23px 40px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (min-width: 576px) {
    max-height: calc(100vh - 281px);
    min-height: calc(100vh - 281px);
  }
`;
