import { ConversationResponse, PersonInfo } from "API";
import React, { useState } from "react";
import {
  RecentMessagesContext,
  IChatMessage,
  CurrentChatUserContext,
  ChatUsersListContext,
} from "state/chat";

export const ChatProvider: React.FC = (props) => {
  const [recentMessages, setRecentMessages] = useState<Array<IChatMessage>>([]);
  const [currentUser, setCurrentUser] = useState<PersonInfo | null>(null);
  const [allUsers, setAllUsers] = useState<Array<ConversationResponse | null>>([]);

  return (
    <RecentMessagesContext.Provider value={{ recentMessages, setRecentMessages }}>
      <CurrentChatUserContext.Provider value={{ currentUser, setCurrentUser }}>
        <ChatUsersListContext.Provider value={{ allUsers, setAllUsers }}>
          {props.children}
        </ChatUsersListContext.Provider>
      </CurrentChatUserContext.Provider>
    </RecentMessagesContext.Provider>
  );
};

export default ChatProvider;
