import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ITalentProfileVersion } from "state/types";
import {
  EducationEntry,
  EmploymentEntry,
  SocialProfile,
  TalentProfileVersion,
  Talent,
} from "@remotebase/amplify-constants/API";
import { ProfileVersionContext } from "./profileVersion.context";

const ProfileVersionProvider: FC = ({ children }) => {
  const [talent, setTalent] = useState<Talent | null>();
  const [selectCurrentVersion, setSelectCurrentVersion] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<ITalentProfileVersion | null>(null);
  const [profileVersions, setProfileVersions] = useState<Array<ITalentProfileVersion>>([]);

  const getTransformedData = useCallback(
    (profileVersion: TalentProfileVersion): ITalentProfileVersion => {
      return {
        id: profileVersion.id,
        name: profileVersion.name,
        talentID: profileVersion.talentID,
        isAvailable: profileVersion.isAvailable,
        interests: profileVersion.interests,
        educationHistory: profileVersion.educationHistory?.map((education) =>
          education
            ? ({
                degree: education.degree,
                school: education.school,
                startDate: education.startDate,
                endDate: education.endDate,
              } as EducationEntry)
            : education,
        ),
        employmentHistory: profileVersion.employmentHistory?.map((employment) =>
          employment
            ? ({
                title: employment.title,
                summary: employment.summary,
                startDate: employment.startDate,
                endDate: employment.endDate,
                company: employment.company,
                currentJob: employment.currentJob,
              } as EmploymentEntry)
            : null,
        ),
        englishProficiency: profileVersion.englishProficiency,
        expectedSalary: profileVersion.expectedSalary,
        expectedSalaryInUsd: profileVersion.expectedSalaryInUsd,
        bio: profileVersion.bio,
        imgLink: profileVersion.imgLink,
        experience: profileVersion.experience,
        skills: profileVersion.skills,
        social: profileVersion.social,
        socialProfiles: profileVersion.socialProfiles?.map((profile) =>
          profile
            ? ({
                url: profile.url,
                urlType: profile.urlType,
              } as SocialProfile)
            : null,
        ),
        title: profileVersion.title,
        version: profileVersion.version,
      };
    },
    [],
  );

  const hasUnsavedData = useMemo(() => {
    if (profileVersions?.length && selectedVersion) {
      const activeFromVersions = profileVersions?.find(
        (version) => version?.id === selectedVersion?.id,
      );
      const transformedActiveVersion = getTransformedData(
        (activeFromVersions || selectedVersion) as TalentProfileVersion,
      );
      const transformedSelectedVersion = getTransformedData(
        selectedVersion as TalentProfileVersion,
      );

      return (
        JSON.stringify(transformedActiveVersion) !== JSON.stringify(transformedSelectedVersion)
      );
    }
    return false;
  }, [profileVersions, selectedVersion]);

  const discardChanges = useCallback(() => {
    const activeFromVersions = profileVersions?.find(
      (version) => version?.id === selectedVersion?.id,
    );
    setSelectedVersion(activeFromVersions as ITalentProfileVersion);
  }, [profileVersions, selectedVersion]);

  useEffect(() => {
    if (selectCurrentVersion) {
      if (!profileVersions?.length) return;
      setSelectCurrentVersion(false);
      setSelectedVersion(profileVersions[profileVersions.length > 1 ? 1 : 0]);
    }
  }, [selectCurrentVersion, profileVersions]);

  return (
    <ProfileVersionContext.Provider
      value={{
        talent,
        setTalent,
        selectedVersion,
        profileVersions,
        setProfileVersions,
        setSelectCurrentVersion,
        hasUnsavedData,
        setSelectedVersion,
        getTransformedData,
        discardChanges,
      }}
    >
      {children}
    </ProfileVersionContext.Provider>
  );
};

export default ProfileVersionProvider;
