import { AuthRoutes, SidebarProps } from "utils";
import React, { useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { SidebarContext } from "./sidebar.context";

export function withSidebar<T>(Component: React.FC<T & SidebarProps>): React.FC<T> {
  const SidebarConsumer: React.FC = (props: T) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const { sidebarType, setSidebarType } = useContext<SidebarProps>(SidebarContext);
    if (pathname === AuthRoutes.RecruitersReports) history.push(AuthRoutes.RecruitersPerformance);

    const sidebarProps = {
      sidebarType,
      setSidebarType,
    };

    return <Component {...props} {...sidebarProps} />;
  };
  return SidebarConsumer;
}
export default withSidebar;
