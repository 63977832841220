import { FC } from "react";
import * as Styled from "../styles";

export const DownloadResumeSectionShimmer: FC = () => {
  return (
    <Styled.DownloadResumeWrapperShimmer>
      <Styled.DownloadResumeTitleShimmer />
      <Styled.DownloadResumeParaShimmer />
      <Styled.DownloadResumeBoxShimmer>
        <Styled.DownloadResumeInfoShimmer>
          <Styled.DownloadResumeImgShimmer></Styled.DownloadResumeImgShimmer>
          <Styled.DownloadResumeInfoContentShimmer>
            <Styled.DownloadResumeNameShimmer />
            <Styled.DownloadResumeDateShimmer />
          </Styled.DownloadResumeInfoContentShimmer>
        </Styled.DownloadResumeInfoShimmer>
        <Styled.DownloadResumeBtnShimmer>
          <Styled.DownloadResumeBtnTextShimmer />
          <Styled.DownloadResumeBtnIconShimmer />
        </Styled.DownloadResumeBtnShimmer>
      </Styled.DownloadResumeBoxShimmer>
    </Styled.DownloadResumeWrapperShimmer>
  );
};

export default DownloadResumeSectionShimmer;
