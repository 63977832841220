import styled from "styled-components";
import { Button } from "react-bootstrap";

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;
export const SubmitButton = styled(Button)`
  background: #3617ba;
  border: none;
  box-shadow: none;
  position: relative;
  font-size: 0.9rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;

  &:hover,
  &:active,
  &:focus {
    transition: all 0.2s;
    background-color: #3617ba !important;
    box-shadow: none !important;
  }
`;
