import React from "react";
import { Company } from "@remotebase/amplify-constants/API";
import * as Styled from "./styles";

interface Props {
  setSelectedCompany: (value: Company | null) => void;
  company: Company | null;
  companies: Array<Company | null>;
  disabled: boolean;
}
export const CompaniesList: React.FC<Props> = ({
  setSelectedCompany,
  disabled,
  companies,
  company,
}) => {
  const handleChange = (id: string): void => {
    const selectedCompany = companies.find((c) => c?.id === id);
    setSelectedCompany(selectedCompany || null);
  };

  return (
    <Styled.DivWrapper>
      <Styled.DropdownBtn
        disabled={disabled}
        title={company?.name || "select company"}
        onSelect={handleChange}
        aria-label={"dropdown-select"}
      >
        {companies?.map(({ name, id }: Company) => (
          <Styled.DropdownItem key={id} eventKey={id} value={id}>
            {name}
          </Styled.DropdownItem>
        ))}
      </Styled.DropdownBtn>
    </Styled.DivWrapper>
  );
};
export default CompaniesList;
