import { FC, useMemo } from "react";
import { Recruiter } from "@remotebase/amplify-constants/API";
import { defaultCountryCode, formattedDate } from "utils";
import { codeToCountry } from "@remotebase/constants";

interface Props {
  recruiter: Recruiter;
  handleModalShow: (id: string) => void;
}

const GetRecruiter: FC<Props> = ({ recruiter, handleModalShow }) => {
  const { id, fullName, phone, email, createdAt, country } = recruiter;
  const countryName = useMemo(() => codeToCountry[country || defaultCountryCode], [country]);
  return (
    <tr>
      <td onClick={(): void => handleModalShow(id)}>{fullName}</td>
      <td>{phone}</td>
      <td>{email}</td>
      <td>{countryName}</td>
      <td>{formattedDate({ date: createdAt })}</td>
    </tr>
  );
};

export default GetRecruiter;
