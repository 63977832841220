import React, { useState } from "react";
import { IFeedbackStateType, IFetchTalentStateType } from "state/types/talentFeedback.interface";
import { fetchTalentDefault, feedbackDefault } from "utils";
import { FetchTalentContext, FeedbackContext } from "./talentFeedback.context";

export const TalentFetchProvider: React.FC = (props) => {
  const [talentState, setTalentState] = useState<IFetchTalentStateType>(fetchTalentDefault);

  return (
    <FetchTalentContext.Provider value={{ talentState, setTalentState }}>
      {props.children}
    </FetchTalentContext.Provider>
  );
};

export const FeedbackProvider: React.FC = (props) => {
  const [feedbackState, setFeedbackState] = useState<IFeedbackStateType>(feedbackDefault);

  return (
    <FeedbackContext.Provider value={{ feedbackState, setFeedbackState }}>
      {props.children}
    </FeedbackContext.Provider>
  );
};
