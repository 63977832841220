import styled, { css } from "styled-components";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { StyledProps } from "utils";

export const DropdownItem = styled(Dropdown.Item)`
  &.active {
    background-color: #ededed !important;
    color: #000;
  }
  text-transform: capitalize;
`;

export const DropdownInputCover = styled.div`
  .dropdown__control {
    border: none transparent !important;
    box-shadow: none !important;
    background: transparent;
    min-height: auto;

    .dropdown__single-value,
    .dropdown__input-container,
    .dropdown__value-container {
      margin: 0;
      padding: 0;
    }

    .dropdown__input {
      height: auto !important;
    }

    .dropdown__indicator {
      padding: 0;
    }
  }

  .dropdown__indicator-separator {
    display: none;
  }

  .dropdown__menu-list {
    max-height: 11rem;
  }
`;

export const DropdownBtn = styled(DropdownButton)<{ $small: boolean }>`
  width: 100%;

  & .dropdown-toggle {
    width: 100%;
    text-align: left;
    background: #ffffff;
    box-shadow: none;
    color: #000;
    border: none !important;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;

    &:after {
      margin-left: auto;
    }

    &:active:focus,
    &:focus,
    &:active,
    &:active:focus-visible {
      background: #ffffff !important;
      box-shadow: none !important;
      border: none !important;
      color: #000000;
    }

    ${(props): StyledProps =>
      props.$small
        ? css`
            font-size: 0.9rem;
          `
        : css``}
  }

  &.show {
    & .dropdown-toggle {
      background: #ffffff;
      color: #000;
    }
  }

  & .dropdown-menu {
    max-height: 10rem;
    overflow-y: auto;
  }
`;
