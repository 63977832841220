import { useLazyQuery } from "@apollo/client";
import {
  JobMatch,
  ListJobMatchesQuery,
  ListJobMatchesQueryVariables,
} from "@remotebase/amplify-constants/API";
import { listJobMatches } from "@remotebase/amplify-constants/graphql/queries";
import { getQuery } from "../utils";
import { ReturnPropsMatchJobsList } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useFetchTalentJobMatches = (): ReturnPropsMatchJobsList => {
  const [getTalentMatches, { data, loading }] = useLazyQuery<
    ListJobMatchesQuery,
    ListJobMatchesQueryVariables
  >(getQuery(listJobMatches));

  const talentJobMatches = (data?.listJobMatches?.items || null) as Array<JobMatch | null> | null;
  const nextToken = data?.listJobMatches?.nextToken;

  return { getTalentMatches, talentJobMatches, loading, nextToken };
};
