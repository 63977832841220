import SliderComponent from "rc-slider";
import { FC } from "react";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "./styles";
import "rc-slider/assets/index.css";

interface CustomSliderProps {
  setValue?: (number: number) => void;
  value: number;
  label?: string;
  disabled?: boolean;
  draggable: boolean;
  hideValue?: boolean;
  readOnly?: boolean;
}

export const Slider: FC<CustomSliderProps> = ({
  setValue,
  value,
  label,
  disabled,
  draggable,
  hideValue,
  readOnly,
}): JSX.Element => {
  const handleChange = (sliderCurrentValue: number): void => setValue?.(sliderCurrentValue);
  return (
    <Styled.SliderWrapper>
      <ShouldRender if={label}>
        <Styled.NumberInputLabel>{label}</Styled.NumberInputLabel>
      </ShouldRender>
      <Styled.SliderContainer>
        <ShouldRender if={!hideValue}>
          <Styled.SliderValue>{value}</Styled.SliderValue>
        </ShouldRender>
        <ShouldRender if={!readOnly}>
          <SliderComponent
            min={0}
            max={100}
            defaultValue={value}
            onChange={handleChange}
            disabled={disabled}
            value={value}
            handleStyle={{ cursor: draggable ? "grab" : "not-allowed" }}
          />
        </ShouldRender>
        <ShouldRender if={readOnly}>
          <SliderComponent
            min={0}
            max={100}
            draggableTrack={false}
            defaultValue={value}
            onChange={handleChange}
            disabled={disabled}
            value={value}
            trackStyle={{ backgroundColor: "#27ae60", height: "8px" }}
            railStyle={{ backgroundColor: "#e0e0e0", height: "8px" }}
            handleStyle={{ display: "none" }}
          />
        </ShouldRender>
      </Styled.SliderContainer>
    </Styled.SliderWrapper>
  );
};
export default Slider;
