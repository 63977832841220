import styled from "styled-components";

export const TalentBadge = styled.div`
  display: flex;
  column-gap: 0.5rem;
  padding: 12px 20px;
  background: #fff0ec;
  border-radius: 8px;
`;

export const TalentBadgeIcon = styled.div`
  max-width: 21px;
  max-height: 25px;
`;

export const TalentBadgePara = styled.p`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-bottom: 0 !important;
  font-size: 0.9rem;
  color: #7c7c7c !important;
`;
