import { createContext } from "react";
import {
  IFeedbackContextType,
  IFetchTalentContextType,
} from "state/types/talentFeedback.interface";
import { feedbackDefault, fetchTalentDefault } from "utils";

export const FetchTalentContext = createContext<IFetchTalentContextType>({
  talentState: fetchTalentDefault,
  setTalentState: (): void => {},
});

export const FeedbackContext = createContext<IFeedbackContextType>({
  feedbackState: feedbackDefault,
  setFeedbackState: (): void => {},
});
