import React, { useMemo } from "react";
import { DocViewerRenderers } from "react-doc-viewer";
import * as Styled from "../styles";

interface Props {
  resumeUrl?: string;
}
export const Resume: React.FC<Props> = ({ resumeUrl }) => {
  const documents = useMemo(() => [{ uri: resumeUrl || "" }], [resumeUrl]);
  return (
    <Styled.ProfileBlock>
      <Styled.ProfileBlockTitle>Resume</Styled.ProfileBlockTitle>
      <Styled.ProfileResume>
        <Styled.ResumeViewer
          config={{ header: { disableFileName: true } }}
          documents={documents}
          pluginRenderers={DocViewerRenderers}
        />
      </Styled.ProfileResume>
    </Styled.ProfileBlock>
  );
};

export default Resume;
