import { TechDomainSkills } from "API";
import { imageUrl } from "./imagesUrls";

export interface ITestArray {
  skillName: TechDomainSkills;
  color: string;
  icon: string;
  title: string;
}

export const testBlockList: Array<ITestArray> = [
  {
    skillName: TechDomainSkills.ReactAndNode,
    color: "cerise",
    icon: imageUrl.logoReactNode,
    title: "React + Node",
  },
  {
    skillName: TechDomainSkills.NodeJs,
    color: "sushi",
    icon: imageUrl.logoNode,
    title: "NodeJS",
  },
  {
    skillName: TechDomainSkills.React,
    color: "malibu",
    icon: imageUrl.logoReact,
    title: "ReactJS",
  },
  {
    skillName: TechDomainSkills.Ror,
    color: "cream-can",
    icon: imageUrl.logoRails,
    title: "Rails",
  },
  {
    skillName: TechDomainSkills.Python,
    color: "shandy",
    icon: imageUrl.logoPython,
    title: "Python",
  },
  {
    skillName: TechDomainSkills.Angular,
    color: "fireBrick",
    icon: imageUrl.logoAngular,
    title: "Angular",
  },
  {
    skillName: TechDomainSkills.DotNet,
    icon: imageUrl.logoDotnet,
    color: "dotnetcolor",
    title: ".NET",
  },
  {
    skillName: TechDomainSkills.Golang,
    icon: imageUrl.logoGo,
    color: "gopher-blue",
    title: "GoLang",
  },
  {
    skillName: TechDomainSkills.Vue,
    icon: imageUrl.logoVue,
    color: "ocean-green",
    title: "VueJS",
  },
  {
    skillName: TechDomainSkills.Django,
    icon: imageUrl.logoDJango,
    color: "dark-green",
    title: "Django",
  },
  {
    skillName: TechDomainSkills.Aws,
    icon: imageUrl.logoAws,
    color: "orange-yellow",
    title: "AWS",
  },
  {
    skillName: TechDomainSkills.Flutter,
    icon: imageUrl.logoFlutter,
    color: "flutter-blue",
    title: "Flutter",
  },
  {
    skillName: TechDomainSkills.IOS,
    icon: imageUrl.logoIOS,
    color: "IOS-dark",
    title: "IOS",
  },
  {
    skillName: TechDomainSkills.ReactNative,
    icon: imageUrl.reactNative,
    color: "reactNative-blue",
    title: "React Native",
  },
  {
    skillName: TechDomainSkills.Android,
    icon: imageUrl.logoAndroid,
    color: "android-green",
    title: "Android",
  },
  {
    skillName: TechDomainSkills.DevOps,
    icon: imageUrl.devOps,
    color: "devops-green",
    title: "DevOps",
  },
  {
    skillName: TechDomainSkills.Java,
    icon: imageUrl.java,
    color: "java-blue",
    title: "Java",
  },
  {
    skillName: TechDomainSkills.Magento,
    icon: imageUrl.logoMagento,
    color: "magento-orange",
    title: "Magento",
  },
];
