import { FC, Fragment, useContext, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import {
  adjustDays,
  generateRecruitersReportError,
  generateRecruitersReportSuccess,
  recruitersLeaderBoardBtnText,
  recruitersPerformanceBtnText,
  successMessage,
  restoreOffsetDate,
} from "utils";
import { OperationDays } from "utils/constants/dashboard";
import { Button, Spinner } from "react-bootstrap";
import { useGenerateRecruitersReport, useGenerateLeaderBoardReport } from "hooks";
import { updateErrorState } from "@remotebase/components";
import { IErrorContextType } from "@remotebase/constants";
import ErrorContext from "state/error/error.context";
import * as Styled from "./styles";
import { DatePickerComponent } from "./components";

const GenerateRecruitersReport: FC<{ isLeaderboard: boolean }> = ({ isLeaderboard }) => {
  const { generateReport, data, isLoading, error } = useGenerateRecruitersReport();
  const {
    generateLeaderBoard,
    data: leaderBoardData,
    isLoading: leaderBoardLoading,
    error: leaderBoardError,
  } = useGenerateLeaderBoardReport();
  const { setErrorState } = useContext<IErrorContextType>(ErrorContext);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [fromDate, setFromDate] = useState<Moment | null>(null);

  const clickFromDate = (date: Date): void => {
    setFromDate(() => {
      if (toDate && date >= toDate.toDate()) return adjustDays(1, OperationDays.subtract, toDate);
      if (
        date.getTime() >= moment().toDate().getTime() ||
        (date.getFullYear() === moment().toDate().getFullYear() &&
          date.getMonth() === moment().toDate().getMonth() &&
          date.getDate() === moment().toDate().getDate())
      )
        return adjustDays(1, OperationDays.subtract, moment());
      return moment(date);
    });
  };

  const clickToDate = (date: Date): void => {
    setToDate(() => {
      if (date > moment().toDate()) return moment();
      if (fromDate && date <= fromDate.toDate()) return adjustDays(1, OperationDays.add, fromDate);
      return moment(date);
    });
  };

  const download = async (): Promise<void> => {
    if (!fromDate || !toDate) return;
    if (!isLeaderboard) {
      generateReport({
        variables: {
          input: {
            fromDate: restoreOffsetDate(fromDate),
            toDate: restoreOffsetDate(toDate),
          },
        },
      });
    } else {
      generateLeaderBoard({
        variables: {
          input: {
            fromDate: restoreOffsetDate(fromDate),
            toDate: restoreOffsetDate(toDate),
          },
        },
      });
    }
  };

  useEffect(() => {
    const response = data?.generateRecruitersReport || leaderBoardData?.generateLeaderBoardReport;
    const isSuccess = !!response?.data;

    if (isSuccess) {
      updateErrorState(
        { title: successMessage, message: generateRecruitersReportSuccess },
        setErrorState,
      );
    } else if (response && !isSuccess) {
      updateErrorState(
        {
          title: generateRecruitersReportError,
          message: !isLeaderboard
            ? (data?.generateRecruitersReport?.message as string)
            : (leaderBoardData?.generateLeaderBoardReport?.message as string),
        },
        setErrorState,
      );
    }
  }, [data, leaderBoardData, isLoading, leaderBoardLoading]);

  useEffect(() => {
    if (!error || !leaderBoardError || isLoading || leaderBoardLoading) return;
    updateErrorState(
      { title: generateRecruitersReportError, message: "accountCreationFailed" },
      setErrorState,
    );
  }, [error, isLoading, leaderBoardLoading, leaderBoardError]);

  return (
    <Fragment>
      <Styled.DateAlignReport>
        <Styled.DateSelectorsWrapper>
          <Styled.PickerWrapper>
            <DatePickerComponent
              isReport
              text="From"
              newDate={fromDate}
              changeDate={clickFromDate}
            />
          </Styled.PickerWrapper>
          <Styled.PickerWrapper>
            <DatePickerComponent isReport text="To" newDate={toDate} changeDate={clickToDate} />
          </Styled.PickerWrapper>
        </Styled.DateSelectorsWrapper>
        <Styled.BtnWrapper>
          <Button
            onClick={download}
            variant="outline-primary"
            style={{ height: 50, marginLeft: 5 }}
            size="sm"
          >
            {isLeaderboard ? recruitersLeaderBoardBtnText : recruitersPerformanceBtnText}{" "}
            {(isLoading || leaderBoardLoading) && <Spinner animation="border" size="sm" />}
          </Button>
        </Styled.BtnWrapper>
      </Styled.DateAlignReport>
    </Fragment>
  );
};

export default GenerateRecruitersReport;
