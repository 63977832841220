import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AuthRoutes, recruitersLeaderBoardHeading, recruitersPerformanceHeading } from "utils";
import { GenerateRecruitersReport } from "components";
import * as Styled from "./styles";

const Page: React.FC = () => {
  const { pathname } = useLocation();

  const { heading, isLeaderboard } = useMemo(() => {
    if (pathname === AuthRoutes.RecruitersNewLeaderboard) {
      return { heading: recruitersLeaderBoardHeading, isLeaderboard: true };
    }
    return { heading: recruitersPerformanceHeading, isLeaderboard: false };
  }, []);

  return (
    <Styled.RecruiterReportWrapper>
      <Styled.RecruiterReportHead>
        <Styled.MainHeading>
          <Styled.SubHeading>{heading}</Styled.SubHeading>
        </Styled.MainHeading>
      </Styled.RecruiterReportHead>
      <Styled.RecruiterReportInner>
        <GenerateRecruitersReport isLeaderboard={isLeaderboard} />
      </Styled.RecruiterReportInner>
    </Styled.RecruiterReportWrapper>
  );
};

export default Page;
