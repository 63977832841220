import React from "react";
import { withClient } from "state/clients";
import { AuthRoutes, createClientLabel, WithClientProps } from "utils";
import { ShouldRender } from "@remotebase/components";
import { FormButton } from "components";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles";
import { TableShimmer } from "../shimmerPages";
import { ClientsTable } from "./components";

const ClientList: React.FC<WithClientProps> = ({ clientState, downloadCsv }) => {
  const { loading } = clientState;
  const { push } = useHistory();
  return (
    <Styled.TableCover>
      <Styled.TableIntro>
        <Styled.ActionButtons>
          <FormButton
            label={createClientLabel}
            small={true}
            onClick={(): void => push(AuthRoutes.CreateClient)}
          />
          <FormButton label={"DOWNLOAD CSV"} small={true} onClick={downloadCsv} />
        </Styled.ActionButtons>
      </Styled.TableIntro>
      <ShouldRender if={!loading}>
        <ClientsTable />
      </ShouldRender>
      <ShouldRender if={loading}>
        <TableShimmer />
      </ShouldRender>
    </Styled.TableCover>
  );
};

export default withClient(ClientList);
