import React from "react";

interface Props {
  columnNames: Record<string, string>;
}
export const TableHeader: React.FC<Props> = (props) => {
  const { columnNames } = props;
  return (
    <thead>
      <tr>
        {Object.values(columnNames).map((data: string, index: number) => (
          <th key={index}>{data}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
