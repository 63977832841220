import React, { Fragment, useEffect, useState } from "react";
import { useFileUpload } from "hooks";
import { withFetchTalent } from "state/talentFeedback";
import { TalentProfileEnum } from "utils";
import { IFetchTalentFeedbackProps } from "state/types/talentFeedback.interface";
import { Resume, ShouldRender, DocumentUploaderCard } from "@remotebase/components";
import * as Styled from "../../styles";

interface Props {
  resumeLink?: string | null;
  updateTalentProfile: (label, value) => void;
}

interface IResumeState {
  file?: File;
  isUploaded: boolean;
  isSubmitted?: boolean;
}

const initialResumeState: IResumeState = { isUploaded: false };

const ResumeInfo: React.FC<Props & IFetchTalentFeedbackProps> = ({
  talentState: { isEditable },
  resumeLink,
  updateTalentProfile,
}) => {
  const [resume, setResume] = useState(initialResumeState);

  const {
    res: { isLoading, data },
    uploadResume,
  } = useFileUpload();
  const handleSubmit = (file: File): void => {
    if (file) uploadResume(file).then();
  };

  useEffect(() => {
    if (!isEditable) setResume(initialResumeState);
    if (isEditable && !isLoading && data) {
      setResume({ ...resume, isUploaded: false, isSubmitted: true });
      updateTalentProfile(TalentProfileEnum.resumeLink, data);
    }
  }, [isLoading, data, isEditable]);

  return (
    <Fragment>
      <ShouldRender if={resumeLink && !isEditable}>
        <Fragment>
          <Styled.ResumeWrapper>
            <Styled.ResumeContainer>
              <Resume resumeUrl={`${resumeLink}#toolbar=0`} />
            </Styled.ResumeContainer>
          </Styled.ResumeWrapper>
        </Fragment>
      </ShouldRender>

      <ShouldRender if={isEditable}>
        <Styled.UploadResumeWrapper>
          <Styled.UploadBoxHeading>Upload Resume</Styled.UploadBoxHeading>
          <DocumentUploaderCard
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            document={resume}
            setDocument={setResume}
          />
        </Styled.UploadResumeWrapper>
      </ShouldRender>
    </Fragment>
  );
};

export default withFetchTalent<Props>(ResumeInfo);
