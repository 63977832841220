import { FC, useState } from "react";
import { EmploymentEntry } from "@remotebase/amplify-constants/API";
import { AddCircleIcon } from "assets/icons";
import { useProfileVersionContext } from "state/profileVersion";
import { useForm } from "react-hook-form";
import {
  EmploymentFormEnum,
  EmploymentFormModal,
  EmploymentHistorySchema,
  EmploymentSection,
} from "@remotebase/components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../styles";

export const TalentEmploymentSection: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const formHook = useForm({
    mode: "onChange",
    resolver: yupResolver(EmploymentHistorySchema),
  });
  const { selectedVersion, setSelectedVersion } = useProfileVersionContext();
  const { employmentHistory } = selectedVersion || {};
  const { reset } = formHook;

  const handleModalShow = (): void => setShowModal(!showModal);

  const addNewEmployement = (): void => {
    reset({
      [EmploymentFormEnum.isEdit]: false,
    });

    handleModalShow();
  };

  const initEdit = (index: number, employment: EmploymentEntry): void => {
    const { __typename: typeName, ...employmentData } = employment;

    reset({
      ...employmentData,
      [EmploymentFormEnum.isEdit]: true,
      [EmploymentFormEnum.selectedIndex]: index,
    });

    handleModalShow();
  };

  const deleteEmployment = (id: number): void => {
    if (employmentHistory?.length)
      setSelectedVersion((prev) =>
        prev
          ? {
              ...prev,
              employmentHistory: [
                ...employmentHistory.slice(0, id),
                ...employmentHistory.slice(id + 1),
              ],
            }
          : prev,
      );
  };

  const handleFormSubmission = (savedData: EmploymentEntry, index = -1): void => {
    if (!index && index !== 0) return;
    const updatedEmployment =
      index === -1
        ? [...(employmentHistory || []), savedData]
        : [
            ...(employmentHistory || []).slice(0, index),
            savedData,
            ...(employmentHistory || []).slice(index + 1),
          ];

    setSelectedVersion((prev) => (prev ? { ...prev, employmentHistory: updatedEmployment } : null));
  };

  const addEmployementBtn = (): JSX.Element => {
    return (
      <Styled.AddEmployementBtn onClick={(): void => addNewEmployement()}>
        <Styled.MatchTalentTopIcon>
          <AddCircleIcon />
        </Styled.MatchTalentTopIcon>
      </Styled.AddEmployementBtn>
    );
  };

  return (
    <>
      <Styled.EmploymentContainer>
        <EmploymentSection
          editEmployment={initEdit}
          deleteEmployment={deleteEmployment}
          employmentHistory={employmentHistory || []}
          addEmployementBtn={addEmployementBtn()}
          isAdmin
        />
      </Styled.EmploymentContainer>
      {showModal && (
        <EmploymentFormModal
          show={showModal}
          formHook={formHook}
          close={(): void => setShowModal(false)}
          handleFormSubmission={handleFormSubmission}
        />
      )}
    </>
  );
};

export default TalentEmploymentSection;
