import { FC, useMemo } from "react";
import { imageUrls } from "@remotebase/components";
import { DownloadIcon } from "assets/icons";
import { useProfileVersionContext } from "state/profileVersion";
import * as Styled from "../styles";

export const DownloadResumeSection: FC = () => {
  const { talent, selectedVersion } = useProfileVersionContext();

  const resumeName = useMemo(() => {
    if (talent?.profile?.resumeLink) {
      const elems = talent?.profile?.resumeLink.split("/");
      const [fileCode, ...fileName] = elems[elems.length - 1].split("-");
      return fileName.length ? fileName.join("-") : fileCode;
    }
    return "";
  }, [talent?.profile?.resumeLink]);
  return (
    <Styled.DownloadResumeWrapper>
      <Styled.DownloadResumeTitle>Download Resume</Styled.DownloadResumeTitle>
      <Styled.DownloadResumePara>
        You can download resume upon clicking button below
      </Styled.DownloadResumePara>
      <Styled.DownloadResumeBox>
        <Styled.DownloadResumeInfo>
          <Styled.DownloadResumeImg>
            <img src={selectedVersion?.imgLink ?? imageUrls.userDefault} alt="profile-img" />
          </Styled.DownloadResumeImg>
          <Styled.DownloadResumeInfoContent>
            <Styled.DownloadResumeName>{talent?.fullName}</Styled.DownloadResumeName>
            <Styled.DownloadResumeDate>{resumeName}</Styled.DownloadResumeDate>
          </Styled.DownloadResumeInfoContent>
        </Styled.DownloadResumeInfo>
        <Styled.DownloadResumeBtn href={talent?.profile?.resumeLink || ""} target="_blank">
          Download file
          <Styled.DownloadResumeBtnIcon>
            <DownloadIcon />
          </Styled.DownloadResumeBtnIcon>
        </Styled.DownloadResumeBtn>
      </Styled.DownloadResumeBox>
    </Styled.DownloadResumeWrapper>
  );
};

export default DownloadResumeSection;
