import { Payment } from "@remotebase/amplify-constants/API";
import moment from "moment";
import { FC, Fragment } from "react";

interface TableRowProps {
  payment: Payment;
  index: number;
}

const TableRow: FC<TableRowProps> = ({ payment, index }) => {
  if (payment) {
    const { id, amount } = payment;
    const pdfUrl = payment.invoice?.stripeInvoicePdfUrl;
    const downloadElem = pdfUrl ? (
      <a target="blank" href={`${pdfUrl}`}>
        {"download"}
      </a>
    ) : (
      "N/A"
    );

    return (
      <tr key={id}>
        <td>
          {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount / 100)}
        </td>
        <td>{moment(payment.createdAt).format("DD/MM/YYYY")}</td>
        <td>{payment.invoice?.client?.company?.name}</td>
        <td>{payment.invoice?.client?.fullName}</td>
        <td>{payment.invoice?.client?.email}</td>
        <td>{payment.paymentStatus}</td>
        <td>{downloadElem}</td>
      </tr>
    );
  }
  return <Fragment key={index} />;
};

export default TableRow;
