import styled from "styled-components";
import { StyledProps } from "utils";

export const ProfileSpecs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

export const BackLink = styled.a`
  font-size: 16px;
  color: #808191;
  font-weight: 500;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #3c1fc9;

    & svg {
      & path {
        fill: #3c1fc9;
      }
    }
  }
`;

export const BackLinkIcon = styled.span`
  width: 20px;
  height: 14px;
  display: inline-flex;
  margin-right: 4px;

  & svg {
    & path {
      fill: #808191;
    }
  }
`;

export const BackLinkText = styled.span`
  display: inline-flex;
`;

export const ProfileGridWrapper = styled.div`
  width: 100%;
`;

export const ProfileGrid = styled.div<{
  $fullGrid?: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props): StyledProps => (!props.$fullGrid ? "2fr 1fr" : "1fr")};
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  margin-bottom: 38px;

  @media only screen and (max-width: 1279px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 575px) {
    grid-row-gap: 32px;
    margin-bottom: 32px;
  }
`;
