import { UseFormReturn } from "react-hook-form";
import { FormSelect } from "components/inputs";
import { useTalentJobMatchContext } from "state/talentjobMatch";
import { useListCompanies } from "hooks";
import { cleanArray } from "@remotebase/components";
import { FC, useEffect, useMemo, useState } from "react";
import { companyAssociation } from "@remotebase/amplify-constants/API";

interface Props {
  handler: UseFormReturn;
  title: string;
}
// eslint-disable-next-line import/prefer-default-export
export const CompaniesSelect: FC<Props> = ({ handler, title }) => {
  const [fetchCompanies, setFetchCompanies] = useState(true);
  const { companies, setCompanies, jobsLoading } = useTalentJobMatchContext();

  const companiesDropdown = useMemo(
    () => companies.map((val) => ({ key: val.name, value: val.id })),
    [companies],
  );

  const {
    getCompanies,
    companiesList,
    loading: companiesLoading,
    nextToken: companyNextToken,
  } = useListCompanies();

  // fetch companies
  useEffect(() => {
    if (fetchCompanies) {
      setFetchCompanies(false);
      setCompanies([]);
      getCompanies({ variables: { associatedWith: companyAssociation.client } });
    }
  }, [fetchCompanies, getCompanies]);

  // save and fetch more companies if any
  useEffect(() => {
    if (!companiesLoading && companiesList) {
      setCompanies((prev) => [...(prev ?? []), ...cleanArray(companiesList)]);
      if (companyNextToken)
        getCompanies({
          variables: { nextToken: companyNextToken, associatedWith: companyAssociation.client },
        });
    }
  }, [companiesLoading, companiesList, companyNextToken]);

  return (
    <FormSelect
      title={title}
      handler={handler}
      placeholder={"Company"}
      items={companiesDropdown}
      disabled={jobsLoading}
    />
  );
};
