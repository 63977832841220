import { useMutation } from "@apollo/client";
import {
  DeleteJobMatchMutation,
  DeleteJobMatchMutationVariables,
  JobMatch,
} from "@remotebase/amplify-constants/API";
import { deleteJobMatch as deleteJobMatchMutation } from "@remotebase/amplify-constants/graphql/mutations";
import { getQuery } from "../utils";
import { ReturnPropsDeleteJobMatch } from "../types";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteJobMatch = (): ReturnPropsDeleteJobMatch => {
  const [deleteJobMatch, { data, loading }] = useMutation<
    DeleteJobMatchMutation,
    DeleteJobMatchMutationVariables
  >(getQuery(deleteJobMatchMutation));

  const jobMatch = data?.deleteJobMatch as JobMatch | null;

  return { deleteJobMatch, jobMatch, loading };
};
