import withApolloProvider from "hooks/apollo/withApollo";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useFetchClient } from "hooks";
import { ClientContext } from "state/clients";
import { IClientContextType } from "state/types/client.interface";
import {
  companyLabel,
  createdAtLabel,
  emailLabel,
  formattedDate,
  fullNameLabel,
  phoneLabel,
  WithClientProps,
} from "utils";
import downloadAsCSV from "@remotebase/helpers/downloadAsCSV";

export function withClient<T>(Component: React.FC<T & WithClientProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { clientState, setClientState } = useContext<IClientContextType>(ClientContext);
    const { fetchData, data, loading, nextToken } = useFetchClient();
    const [apiCalled, setApiCalled] = useState<boolean>(false);
    const [downloadCsv, setDownloadCsv] = useState<boolean>(false);

    const callAPI = (): void => {
      setClientState((prev) => ({ ...prev, loading: true }));
      fetchData(nextToken);
    };

    const paginatedBtnState = useMemo(() => {
      return {
        nextBtnDisabled: true,
        prevBtnDisabled: true,
      };
    }, [data, nextToken]);

    useEffect(() => {
      if (data?.length && !loading) {
        setClientState((prev) => ({
          ...prev,
          allData: data,
          loading,
          nextToken,
        }));
      }
    }, [data, nextToken, loading]);

    useEffect(() => {
      if (loading || !clientState.allData) return;

      if (downloadCsv) {
        const formattedData: Record<string, string>[] = [];

        for (const client of clientState.allData) {
          if (client) {
            formattedData.push({
              [emailLabel]: client.email,
              [fullNameLabel]: client.fullName,
              [phoneLabel]: client.phone,
              [companyLabel]: client.company?.name || "",
              [createdAtLabel]: formattedDate({ date: client.createdAt, showTime: true }),
            });
          }
        }

        downloadAsCSV({ data: formattedData });
        setDownloadCsv(false);
      }
    }, [downloadCsv, loading, clientState.allData]);

    useEffect(() => {
      if (!clientState.allData || apiCalled) {
        setApiCalled(true);
        callAPI();
      }
    }, [apiCalled]);

    const fetch = (filter): void => {
      fetchData(nextToken, filter);
    };

    const nextBtnClick = (): void => {};

    const prevBtnClick = (): void => {};

    const initDownloadCsv = (): void => {
      setDownloadCsv(true);
      fetchData(nextToken, {}, true);
    };

    const clientProps: WithClientProps = {
      clientState,
      nextBtnClick,
      prevBtnClick,
      state: paginatedBtnState,
      fetch,
      downloadCsv: initDownloadCsv,
    };

    return <Component {...props} {...clientProps} />;
  });
}

export default withClient;
