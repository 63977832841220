import { CSVLink } from "react-csv";
import { ShouldRender } from "@remotebase/components";
import ClientInvoiceModal from "components/modals/clientInvoiceModal";
import { useState } from "react";
import withError from "state/error/withErrorHoc";
import { useSWRConfig } from "swr";
import { ErrorProps } from "@remotebase/constants";
import reloadSvg from "assets/icons/reload.svg";
import { Spinner } from "react-bootstrap";
import InvoiceBatches from "./invoiceBatches";
import * as Styled from "./styles";
import { LIST_BATCH_KEY, csvLink } from "./utils";

function Invoices({ showError }: ErrorProps): ReturnType<React.FC> {
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = (): void => setShowModal(true);
  const handleModalClose = (): void => setShowModal(false);
  const [loading, setLoading] = useState(false);
  const { mutate } = useSWRConfig();
  const handleRefetchBatches = async (): Promise<void> => {
    setLoading(true);
    showError({ title: "Invoice Batches", message: "Fetching invoice batches..." });

    try {
      await mutate(LIST_BATCH_KEY);
      showError({ title: "Invoice Batches", message: "Invoice batches fetched successfully" });
    } catch (error) {
      showError({ title: "Invoice Batches", message: "Error fetching invoice batches" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>Invoices</Styled.Title>
      </Styled.Header>

      <Styled.Content>
        <Styled.ContentTop>
          <Styled.ContentTitle>Invoice Batches</Styled.ContentTitle>
          <Styled.ButtonGroup>
            <Styled.Button onClick={handleRefetchBatches} disabled={loading}>
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <Styled.ReloadImg src={reloadSvg} alt="reload" />
              )}
            </Styled.Button>
            <CSVLink {...csvLink}>
              <Styled.Button>Download Template</Styled.Button>
            </CSVLink>
            <Styled.Button onClick={handleModalShow}>Upload CSV</Styled.Button>
          </Styled.ButtonGroup>
        </Styled.ContentTop>
        <InvoiceBatches />

        <ShouldRender if={showModal}>
          <ClientInvoiceModal shouldShow={showModal} handleOnHide={handleModalClose} />
        </ShouldRender>
      </Styled.Content>
    </Styled.Wrapper>
  );
}

export default withError(Invoices);
