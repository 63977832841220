import { Auth } from "@aws-amplify/auth";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IErrorContextType } from "@remotebase/constants";
import { updateErrorState } from "@remotebase/components";
import { Client, Company, EmailTypes, UserRoles } from "API";
import { useClient, useListCompanies, useSendEmail } from "hooks";
import ErrorContext from "state/error/error.context";
import {
  accountCreationFailed,
  clientCreationError,
  clientCreatedMessage,
  successMessage,
} from "utils";
import { CreateClientPayloadType, ReturnPropsCreateClient } from "hooks/types";
import * as AWS from "@aws-sdk/client-cognito-identity-provider";
import {
  ApiCustomHookStateType,
  apiInitialState,
  getErrorResponse,
  getSuccessResponse,
} from "../utils";

export const useCreateClientAccount = (): ReturnPropsCreateClient => {
  const [res, setRes] = useState<ApiCustomHookStateType<Client>>(apiInitialState);
  const [companies, setCompanies] = useState<Array<Company | null>>([]);
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const { setErrorState, errorState } = useContext<IErrorContextType>(ErrorContext);
  const { createInfo, isLoading: createClientLoading, data, error } = useClient();
  const { loading: fetchCompanyLoading, companiesList, getCompanies } = useListCompanies();
  const { sendEmail } = useSendEmail();

  const isLoading = useMemo(() => {
    return fetchCompanyLoading || createClientLoading || res.isLoading;
  }, [fetchCompanyLoading, createClientLoading, res.isLoading]);

  useEffect(() => {
    if (!data || isLoading) return;
    updateErrorState({ title: successMessage, message: clientCreatedMessage }, setErrorState);

    sendEmail({
      variables: {
        input: {
          toEmail: data.email,
          emailType: EmailTypes.NewClient,
          messageData: JSON.stringify({
            clientPassword: currentPassword,
            companyName: data.company?.name,
            clientName: data.fullName,
            clientEmail: data.email,
          }),
        },
      },
    });

    setRes(getSuccessResponse<Client>(data));
  }, [data, isLoading]);

  useEffect(() => {
    if (!error || isLoading) return;
    setRes(getErrorResponse(accountCreationFailed));
    updateErrorState({ title: clientCreationError, message: accountCreationFailed }, setErrorState);
  }, [error, isLoading]);

  useEffect(() => {
    if (companiesList?.length) setCompanies(companiesList);
  }, [companiesList]);

  const createClient = useCallback(
    async (payload: CreateClientPayloadType): Promise<void> => {
      const { email, password, fullName, phone, country, companyID } = payload;
      setCurrentPassword(password);
      setRes({ ...apiInitialState, isLoading: true });

      try {
        const credentials = await Auth.Credentials.get();
        const { userPoolId: UserPoolId, region } = await Auth.Credentials.Auth._config;

        const cognitoIDProvider = new AWS.CognitoIdentityProvider({
          apiVersion: "latest",
          region,
          credentials,
        });

        const response = await cognitoIDProvider.adminCreateUser({
          Username: email,
          UserPoolId,
          MessageAction: "SUPPRESS",
          DesiredDeliveryMediums: ["EMAIL"],
          UserAttributes: [
            { Name: "email", Value: email },
            { Name: "email_verified", Value: "true" },
            { Name: "custom:role", Value: UserRoles.CLIENT },
          ],
        });

        if (!response.User?.Username) {
          setRes(getErrorResponse(accountCreationFailed));
          updateErrorState(
            { title: clientCreationError, message: accountCreationFailed },
            setErrorState,
          );
        }

        await cognitoIDProvider.adminSetUserPassword({
          Password: password,
          Permanent: false,
          Username: email,
          UserPoolId,
        });

        // add user to CLIENT Group on cognito
        if (response.User?.Username) {
          await cognitoIDProvider.adminAddUserToGroup({
            GroupName: UserRoles.CLIENT,
            Username: response.User.Username,
            UserPoolId,
          });
        }

        createInfo({
          variables: {
            input: {
              email,
              fullName,
              phone,
              country,
              companyID,
              role: UserRoles.CLIENT,
              id: response.User?.Username,
            },
          },
        });

        setRes((prev) => ({ ...prev, isLoading: false }));
      } catch (e) {
        const { message } = e;
        setRes(getErrorResponse(message));
        updateErrorState({ title: clientCreationError, message }, setErrorState);
      }
    },
    [errorState],
  );

  return {
    createClient,
    client: res.data,
    companies,
    isLoading,
    success: res.success,
    error: res.error,
    getCompanies,
  };
};

export default useCreateClientAccount;
