import { FC, Fragment } from "react";
import Highcharts from "highcharts";
import { FullPageLoader, ShouldRender } from "@remotebase/components";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import { Container } from "react-bootstrap";
import { IRefferedHoc, withReferred } from "state/referredByGraph";
import * as Styled from "./styles";
import { SearchField } from "./components";

exporting(Highcharts);
exportData(Highcharts);

export const ReferredGraph: FC<IRefferedHoc> = (props) => {
  const { searchFields, graphDataFinal, sortedList, loading } = props;
  return (
    <Fragment>
      <SearchField {...searchFields} />
      <ShouldRender if={loading}>
        <Styled.Loading>
          <FullPageLoader />
        </Styled.Loading>
      </ShouldRender>
      <ShouldRender if={!sortedList?.length && !loading}>
        <Styled.NoData>No Data Available</Styled.NoData>
      </ShouldRender>
      <ShouldRender if={graphDataFinal?.options && sortedList?.length && !loading}>
        <Container fluid>
          <Styled.GraphButton highcharts={Highcharts} options={graphDataFinal?.options} />
        </Container>
      </ShouldRender>
    </Fragment>
  );
};

export default withReferred(ReferredGraph);
