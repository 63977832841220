import {
  ConversationResponse,
  Talent,
  TechDomainSkillsProgress,
} from "@remotebase/amplify-constants/API";
import downloadAsCSV from "@remotebase/helpers/downloadAsCSV";
import moment, { Moment } from "moment";
import { IJWTDecodeType } from "state/types/talentFeedback.interface";
import jwt from "jsonwebtoken";
import {
  failed,
  FilterOptions,
  isPending,
  passed,
  QueryfilterType,
  FormatDate,
  pageLimit,
  AuthRoutes,
} from "utils";
import { OperationDays, OperationTime } from "./constants/dashboard";

export const getQueryFilter = (filterOptions: FilterOptions): QueryfilterType => {
  const filterBuilder: QueryfilterType = {};
  if (filterOptions.isPassHR) filterBuilder.isPassHR = { eq: filterOptions.isPassHR };
  if (filterOptions.isPassPS) filterBuilder.isPassPS = { eq: filterOptions.isPassPS };
  if (filterOptions.searchByName) filterBuilder.fullName = { contains: filterOptions.searchText };
  if (filterOptions.searchByEmail || filterOptions.searchRecruiter)
    filterBuilder.email = { eq: filterOptions.searchText };
  return filterBuilder;
};

export const validateEmailFormat = (email: string): RegExpMatchArray | null => {
  return String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const adjustDays = (days: number, operation: OperationDays, date?: Moment): Moment =>
  moment(date)[operation](days, "day");

export const momentFormatted = (date: Moment): string => moment(date).format("ll");

export const getDate = (e: null | { createdAt: string }): number => moment(e?.createdAt).valueOf();

export const edgeOfDay = (operation: OperationTime, date: Moment): string =>
  date[operation]("day").toISOString();

export const formattedDate = ({ date, showTime }: FormatDate): string => {
  let formatVariable = "MMMM Do YYYY";
  if (showTime) formatVariable += " h:mm:ss a";
  const outputDate = moment(date).format(formatVariable);
  return outputDate;
};

export const getLastPagePtr = (num?: number): number =>
  Math.floor(((num || 0) - 1) / pageLimit) * pageLimit;

export function getSlicedData<T>(
  collection: Array<T>,
  startingIndex: number,
  limit: number,
): Array<T> {
  return collection.slice(startingIndex, startingIndex + limit);
}

// eslint-disable-next-line
export function getStateData(state: any, limit: number): any {
  return {
    data: getSlicedData(state?.data || [], state?.currentPtr || 0, limit),
    allData: getSlicedData(state?.data || [], 0, state?.data?.length),
    currentPtr: getLastPagePtr(state.data?.length),
  };
}

export const getFormatedDate = (date: string): string => {
  const dateformatted = date && moment(date).toISOString();
  return dateformatted?.length ? dateformatted : "N/A";
};

export const isPassDomain = (testsTaken: Array<TechDomainSkillsProgress | null>): boolean | null =>
  testsTaken.length ? !!testsTaken?.find((test) => test?.isPass) : null;

export const domainTestStartTime = (domainTest: TechDomainSkillsProgress | null): string =>
  getFormatedDate(domainTest?.testStart || "");

export const inString = (elem?: boolean | null): string => {
  if (elem !== null && elem !== undefined) return elem ? passed : failed;
  return isPending;
};

export const downloadReferralsCsv = async (data?: Array<Talent | null>): Promise<void> => {
  const dataCsv = data
    ?.filter((d) => d)
    ?.map((eng) => {
      const talentMeta = eng?.profile?.statusMeta && JSON.parse(eng?.profile?.statusMeta);
      if (eng) {
        const { createdAt, ...talent } = eng;
        return {
          ...talent,
          signupAt: eng?.createdAt,
          isPassDomain: inString(isPassDomain(eng?.profile?.takenDomainTests || [])),
          dtTime: domainTestStartTime(eng?.profile?.takenDomainTests?.[0] || null),
          isPassPS: inString(eng?.profile?.isPassPS),
          psTime: getFormatedDate(talentMeta?.dateOfPSStatus),
          isPassHR: inString(eng?.profile?.isPassPS),
          isPassFinalTech: inString(eng?.profile?.isPassFinalTech),
          isHired: inString(eng?.profile?.isHired),
          hiredAt: getFormatedDate(talentMeta?.dateOfHiring),
        };
      }
      return null;
    });
  downloadAsCSV({ data: dataCsv as unknown as Record<string, string>[] });
};

export const decodeJWT = (talentId: string): string => {
  const secret: string = process.env.REACT_APP_JWT || "";
  const decoded = jwt.verify(talentId, secret) as IJWTDecodeType;
  return decoded.talentId;
};

export const sortUsersList = (
  inputList: Array<ConversationResponse | null>,
): Array<ConversationResponse | null> => {
  const arrayForSort = [...(inputList || [])];
  return arrayForSort?.sort((a, b) => {
    const firstDate = a?.messages?.[0]?.createdAt || a?.createdAt;
    const secondDate = b?.messages?.[0]?.createdAt || b?.createdAt;
    if (firstDate && secondDate && firstDate >= secondDate) return -1;
    return 1;
  });
};

export const getDyanamicPath = (url: AuthRoutes, path: string): string => {
  const parts = url.split(":");
  return parts[0] + path;
};

export const autoScrollOnTop = (): void => {
  window.scrollTo(0, 0);
};

export const restoreOffsetDate = (date: moment.Moment | null): string => {
  const tzOffset = new Date().getTimezoneOffset() * 60000;
  const dateValue = date ? moment(date).valueOf() : moment().valueOf();
  const dateValueWithOffset = dateValue - tzOffset;
  return moment(dateValueWithOffset).toISOString();
};
