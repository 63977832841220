import { useFetchTalent, useFetchTalentMatch } from "hooks";
import { decodeJWT } from "utils";
import React, { useContext, useEffect } from "react";
import {
  IFeedbackContextType,
  IFetchTalentContextType,
  IFetchTalentFeedbackProps,
} from "state/types/talentFeedback.interface";
import { FeedbackContext, FetchTalentContext } from "./talentFeedback.context";
import { getTalentMatchJobs } from "./profileSpecs.helper";

interface DefaultProps {
  talentId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleResetAndPass: (input: any) => void;
}

export const withFetchTalent = <T,>(Component: React.FC): React.FC<T> => {
  return (props: T & DefaultProps): JSX.Element => {
    const { talentId, handleResetAndPass } = props;
    const { getTalent, data: talent, loading } = useFetchTalent();
    const { fetchData, talentMatch, companies } = useFetchTalentMatch();
    const { talentState, setTalentState } = useContext<IFetchTalentContextType>(FetchTalentContext);
    const { setFeedbackState } = useContext<IFeedbackContextType>(FeedbackContext);

    const setInitialData = (): void => {
      if (talentState?.data?.profile) {
        const { talentFeedback, resumeLink, isNetwork } = talentState.data.profile || {};
        setFeedbackState((prev) => ({
          ...prev,
          talentFeedback: talentFeedback || [],
          resumeLink,
          matchedJobs: getTalentMatchJobs(talentState.matchedJobs),
          isNetwork,
        }));
      }
    };

    const toggleIsEditable = (): void =>
      setTalentState((previous) => ({ ...previous, isEditable: !previous.isEditable }));

    useEffect(() => {
      if (talentMatch?.length && !talentState.matchedJobs.length) {
        setTalentState((previous) => ({
          ...previous,
          matchedJobs: [...talentMatch],
        }));

        setFeedbackState((prev) => ({
          ...prev,
          matchedJobs: getTalentMatchJobs(talentMatch),
        }));
      }
    }, [talentMatch]);

    useEffect(() => {
      if (companies?.length) {
        setFeedbackState((prev) => ({
          ...prev,
          companies,
        }));
      }
    }, [companies]);

    useEffect(() => {
      if (talent && !talentState.data) {
        setTalentState((previous) => ({
          ...previous,
          data: talent,
        }));

        if (talent?.profile) {
          const { talentFeedback, resumeLink, isNetwork } = talent?.profile || {};
          setFeedbackState((prev) => ({
            ...prev,
            talentFeedback: talentFeedback || [],
            resumeLink,
            isNetwork,
          }));
        }

        fetchData(talent.id);
      }
    }, [talent]);

    useEffect(() => {
      setTalentState((previous) => ({
        ...previous,
        fetchFeedbackLoading: loading,
      }));
    }, [loading]);

    useEffect(() => {
      if (talentId) {
        let id = talentId;
        if (talentId.length > 36) id = decodeJWT(talentId);
        getTalent({ variables: { id } });
      }
    }, [talentId]);

    const talentProps: IFetchTalentFeedbackProps = {
      talentState,
      setInitialData,
      toggleIsEditable,
      handleResetAndPass,
    };

    return <Component {...({ ...talentProps, ...props } as T)} />;
  };
};

export default withFetchTalent;
