import { FC } from "react";
import { ChevronLeftIcon } from "assets/icons";
import { useHistory } from "react-router-dom";
import { AuthRoutes } from "utils";
import * as Styled from "./styles";

export const TalentVersionHeader: FC = () => {
  const history = useHistory();
  return (
    <>
      <Styled.EditTalentBackIconWrapper onClick={(): void => history.replace(AuthRoutes.Talents)}>
        <Styled.EditTalentBackIcon>
          <ChevronLeftIcon />
        </Styled.EditTalentBackIcon>
        <Styled.EditTalentBackText>Go Back</Styled.EditTalentBackText>
      </Styled.EditTalentBackIconWrapper>
    </>
  );
};

export default TalentVersionHeader;
