import { TableHeader } from "components/table";
import { useEffect, useState, Fragment, FC } from "react";
import { PaymentTableMainColumns } from "utils";
import { withTalent } from "state/talent";
import { TalentTableBody } from "./components";
import * as Styled from "./styles";

const PaymentListing: FC = () => {
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setDisplay(true);
  });

  return (
    <Styled.TableContent>
      <Styled.TableTop>
        <Fragment>
          <Styled.MainTable col={display} responsive>
            <TableHeader columnNames={PaymentTableMainColumns} />
            <TalentTableBody />
          </Styled.MainTable>
        </Fragment>
      </Styled.TableTop>
    </Styled.TableContent>
  );
};

export default withTalent(PaymentListing);
