import { FC, useCallback, useEffect, useState } from "react";
import { useTalentJobMatchContext } from "state/talentjobMatch";
// import { CrossIcon } from "assets/icons";
import { useCreateOrUpdateTalentJobMach, useDeleteJobMatch } from "hooks";
import { useErrorContext } from "state/error";
import { Color } from "@remotebase/constants";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "../styles";

// eslint-disable-next-line import/prefer-default-export
export const ViewTalentJobMatches: FC = () => {
  const [deleting, setDeleting] = useState<string | null>(null);
  const [updating, setUpdating] = useState<number | null>(null);
  const [showActions, setShowActions] = useState<{ show: boolean; index: number | null }>({
    show: false,
    index: null,
  });

  const { deleteJobMatch, loading, jobMatch } = useDeleteJobMatch();

  const {
    createOrUpdateJobMatch,
    loading: updateLoading,
    jobMatch: updateJobMatch,
  } = useCreateOrUpdateTalentJobMach();

  const { jobMatches, setJobMatches } = useTalentJobMatchContext();
  const { showError } = useErrorContext();

  const showActionsOptions = (index: number): void =>
    setShowActions((prev) => ({ show: !prev.show, index }));

  const removeTalentMatch = useCallback(
    (id: string, expectedVersion: number) => {
      if (loading) return;

      setDeleting(id);
      deleteJobMatch({ variables: { input: { id, expectedVersion } } });
      setShowActions((prev) => ({ show: !prev.show, index: prev.index }));
    },
    [loading],
  );

  const publishJobMatch = useCallback(
    (index: number, jobID: string, talentID: string, isPublished: boolean | null) => {
      if (updateLoading || isPublished) return;

      setUpdating(index);
      createOrUpdateJobMatch({
        variables: {
          input: {
            jobID,
            talentID,
            isPublished: !isPublished,
            lastActivity: new Date().toISOString(),
          },
        },
      });
      setShowActions((prev) => ({ show: !prev.show, index: prev.index }));
    },
    [updateLoading],
  );

  useEffect(() => {
    if (!loading && jobMatch) {
      showError?.({ title: "Deleted", message: "Job Match deleted successfully" });
      setJobMatches((prev) => prev.filter((match) => match.id !== jobMatch.id));
      setDeleting(null);
    }
  }, [loading, jobMatch]);

  useEffect(() => {
    if (!updateLoading && updateJobMatch && updating !== null) {
      showError?.({
        title: "Published",
        message: "The profile has been successfully sent to client",
      });
      const newJobMatches = [...jobMatches];
      newJobMatches[updating] = updateJobMatch;
      setJobMatches(newJobMatches);
      setUpdating(null);
    }
  }, [updateLoading, updateJobMatch]);

  return (
    <Styled.ViewJobTableWrapper>
      <Styled.TalentTable responsive>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Job Name</th>
            <th>Version Name</th>
            <th>Match Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jobMatches.map((item, index) => (
            <tr key={`match-${index}`}>
              <td>{item.job?.company?.name || ""}</td>
              <td>{item.job?.title}</td>
              <td>{item.talentProfileVersion?.name ?? "Original Profile"}</td>
              <td>{item.isPublished ? "Matched" : "Created"}</td>
              <td>
                {deleting === item.id || updating === index ? (
                  <Styled.LoadingSpinner color={Color.PRIMARY} />
                ) : (
                  <Styled.MatchStatusActionDiv>
                    <Styled.OptionsSelector onClick={(): void => showActionsOptions(index)}>
                      <Styled.OptionsEllipse>. . .</Styled.OptionsEllipse>
                    </Styled.OptionsSelector>
                    <ShouldRender if={showActions.show && showActions.index === index}>
                      <Styled.ActionsOptionsDiv>
                        <Styled.ActionOption
                          onClick={(): void => removeTalentMatch?.(item.id, item.version)}
                        >
                          Delete Match
                        </Styled.ActionOption>
                        <Styled.ActionOption
                          margin={true}
                          onClick={(): void =>
                            publishJobMatch(index, item.jobID, item.talentID, item.isPublished)
                          }
                          $disabled={item.isPublished}
                        >
                          Send to Client
                        </Styled.ActionOption>
                      </Styled.ActionsOptionsDiv>
                    </ShouldRender>
                  </Styled.MatchStatusActionDiv>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Styled.TalentTable>
    </Styled.ViewJobTableWrapper>
  );
};
