import { FC } from "react";
import { ViewProfileInfo } from "@remotebase/components";
import { withFetchTalent } from "state/talentFeedback";
import { IFetchTalentFeedbackProps } from "state/types/talentFeedback.interface";

export const ProfileInfo: FC<IFetchTalentFeedbackProps> = ({ talentState: { data } }) => {
  return <ViewProfileInfo data={data} />;
};

export default withFetchTalent(ProfileInfo);
