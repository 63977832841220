import { FC, useMemo } from "react";
import { Talent } from "@remotebase/amplify-constants/API";
import * as Styled from "../styles";
import { imageUrls, ShouldRender } from "../..";
import { FireIcon } from "../../assets";

interface Props {
  data: Talent | null;
  isRockstar?: boolean | null;
}

export const ViewProfileInfo: FC<Props> = ({ data, isRockstar }) => {
  const { fullName, profile, role } = data || {};
  const { imgLink, experience } = profile || {};

  const userImg = useMemo(() => imgLink ?? imageUrls.userDefault, [imgLink]);

  return (
    <Styled.ProfileInfo>
      <Styled.ProfileImgCanvas>
        <Styled.ProfileImg src={userImg} alt="Img" />
      </Styled.ProfileImgCanvas>
      <Styled.ProfileDetail>
        <Styled.ProfileNameWrapper>
          <Styled.ProfileName>{fullName}</Styled.ProfileName>
          <ShouldRender if={isRockstar}>
            <Styled.Icon>
              <FireIcon />
            </Styled.Icon>
          </ShouldRender>
        </Styled.ProfileNameWrapper>
        <Styled.ProfileRole>{role}</Styled.ProfileRole>
        <Styled.ProfileExperience>{experience} years of experience</Styled.ProfileExperience>
      </Styled.ProfileDetail>
    </Styled.ProfileInfo>
  );
};

export default ViewProfileInfo;
