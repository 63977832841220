import {
  ConversationResponse,
  PersonInfo,
  MessageResponse,
} from "@remotebase/amplify-constants/API";
import { ShouldRender } from "@remotebase/components";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { IChatMessage, IChatParam } from "../types";
import { getFormattedDay, imageUrl } from "../utils";
import * as S from "../styles";

interface Props {
  data: ConversationResponse | null;
  recentMessages: IChatMessage[];
  getParticipant: (
    talent?: ConversationResponse | null | undefined,
  ) => PersonInfo | null | undefined;
  openChat: (convoId?: string | null | undefined, user?: PersonInfo | null | undefined) => void;
  addToRecentMessages: (message?: MessageResponse | null | undefined) => void;
  isAdmin?: boolean;
}
const SingleTalent: FC<Props> = ({
  data,
  recentMessages,
  getParticipant,
  openChat,
  addToRecentMessages,
  isAdmin,
}) => {
  const { conversationId } = useParams<IChatParam>();

  const [participant, setParticipant] = useState<PersonInfo | null>();
  const [isFirstRendered, setIsFirstRendered] = useState(false);

  const handleClick = (): void => {
    if (participant && data?.id) openChat(data?.id, participant);
  };

  useEffect(() => {
    if (data?.id && !isFirstRendered) {
      setIsFirstRendered(true);
      addToRecentMessages(data.messages?.[0]);
      const user = getParticipant(data);
      setParticipant(user);
      if (conversationId === data.id) openChat(data.id, user);
    }
  }, [data, isFirstRendered, conversationId]);

  const msgToShow = useMemo(
    () => recentMessages.filter((item) => item.conversationId === data?.id)[0],
    [recentMessages],
  );
  const isMessageRead = msgToShow?.isReadConversation;

  return (
    <S.ChatPreviewWrapper onClick={handleClick}>
      <S.ChatPreviewAvatar className="active">
        <img src={imageUrl.defaultImage} />
      </S.ChatPreviewAvatar>
      <S.ChatPreviewContent>
        <S.ChatPreviewTop>
          <S.ChatPreviewName>{participant?.fullName}</S.ChatPreviewName>
          <S.ChatPreviewDay>
            {getFormattedDay(msgToShow?.message?.createdAt || data?.createdAt || "").day}
          </S.ChatPreviewDay>
        </S.ChatPreviewTop>
        {isAdmin ? (
          <S.ChatPreviewDesignation>
            {data?.conversationParticipants && data?.conversationParticipants[1]?.fullName}
          </S.ChatPreviewDesignation>
        ) : (
          <S.ChatPreviewDesignation>{participant?.role}</S.ChatPreviewDesignation>
        )}
        {/* <S.ChatPreviewDesignation>{participant?.role}</S.ChatPreviewDesignation> */}
        <S.ChatPreviewBottom>
          <S.ChatPreviewText className={!isMessageRead ? "" : "read__msg"}>
            {msgToShow?.message?.content}
          </S.ChatPreviewText>
          <ShouldRender if={isMessageRead}>
            <S.ChatPreviewTickIcon>
              <i className="icon-tick" />
            </S.ChatPreviewTickIcon>
          </ShouldRender>
        </S.ChatPreviewBottom>
      </S.ChatPreviewContent>
    </S.ChatPreviewWrapper>
  );
};

export default SingleTalent;
