import { FC } from "react";

interface NoTableProps {
  span?: number;
}

export const NoTableData: FC<NoTableProps> = ({ span = 6 }): JSX.Element => {
  return (
    <tr>
      <td colSpan={span} className="text-center">
        No Data available
      </td>
    </tr>
  );
};

export default NoTableData;
