import { MessageResponse } from "@remotebase/amplify-constants/API";
import { FC, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { withProfile } from "state/profileSteps";
import { ProfileProps } from "utils";
import { BtnLoader } from "@remotebase/components/chat/btnLoader";
import Message from "@remotebase/components/chat/currentUserChatPanel/chatPanel/message";
import * as S from "@remotebase/components/chat/styles";

interface Props {
  chatMessages: Array<MessageResponse | null>;
  hasMoreData: boolean;
  chatBoxRef: React.MutableRefObject<HTMLDivElement | null>;
  fetchMore: (convoId: string) => void;
  isLoading: boolean;
}

const ChatPanel: FC<Props & ProfileProps> = ({
  // profileState: { data },
  chatMessages,
  hasMoreData,
  chatBoxRef,
  fetchMore,
  isLoading,
}) => {
  const [defaultUserId] = useState(chatMessages[0]?.senderInfo?.id);
  return (
    <S.ChatBodyWrapper>
      <S.ChatBodyInner ref={chatBoxRef}>
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          useWindow={false}
          isReverse
          hasMore={hasMoreData}
        >
          {isLoading && <BtnLoader key="infinite scroll loader" />}
          {chatMessages.map((item: MessageResponse) => {
            return (
              <Message item={item} key={item.id} isMine={defaultUserId === item?.senderInfo?.id} />
            );
          })}
        </InfiniteScroll>
      </S.ChatBodyInner>
    </S.ChatBodyWrapper>
  );
};

export default withProfile(ChatPanel);
