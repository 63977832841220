import { createContext, useContext } from "react";
import { IUsersContext } from "../types";

export const UserContext = createContext<IUsersContext>({
  users: [],
  setUsers: () => null,
  addUser: () => null,
});

export const useUsersContext = (): IUsersContext => useContext(UserContext);
