import { FC, useEffect, useMemo, useState } from "react";
import { CustomDropdown, CustomModal, TagInput } from "components";
import { useProfileVersionContext } from "state/profileVersion";
import { SelectOption } from "utils";
import * as Styled from "../styles";

interface Props {
  open: boolean;
  close: () => void;
}

export const UpdateSkillModal: FC<Props> = ({ open, close }) => {
  const [selectedSkills, setSelectedSkills] = useState<Array<string>>([]);
  const { selectedVersion, setSelectedVersion, talent } = useProfileVersionContext();

  const availableSkills = useMemo(
    () =>
      (talent?.profile?.talentType?.skills || []).reduce<SelectOption[]>((prev, data) => {
        if (!data || selectedSkills.includes(data.title || "")) return prev;
        return [...prev, { value: data.name, label: data.title }];
      }, []),
    [selectedSkills, talent?.profile?.talentType?.skills],
  );

  const onSkillSelect = (val: string): void => setSelectedSkills((prev) => [...prev, val]);

  const removeSkill = (index: number): void =>
    setSelectedSkills((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);

  const updateVersionSkills = (): void => {
    setSelectedVersion((prev) => (prev ? { ...prev, skills: selectedSkills } : prev));
    close();
  };

  useEffect(() => {
    if (selectedVersion?.skills) setSelectedSkills(selectedVersion.skills);
  }, [selectedVersion]);

  return (
    <CustomModal showModal={open} onClose={close} header={"Update Skill Set"}>
      <CustomDropdown
        placeholder={"Select Skill Set"}
        items={availableSkills}
        onSelect={onSkillSelect}
      />
      <Styled.TagsCover>
        <TagInput title={"skills"} removeTag={removeSkill} tags={selectedSkills} />
      </Styled.TagsCover>
      <Styled.SubmitButton onClick={updateVersionSkills}>Save and Update</Styled.SubmitButton>
    </CustomModal>
  );
};

export default UpdateSkillModal;
