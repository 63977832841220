import styled from "styled-components";

const animate = `
animation: shimmer 4s infinite linear;
background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
background-size: 1000px 100%;`;

const shimmerStyle = `
background-color: #ededed;
border-radius: 4px;
align-items: center;
justify-content: center;`;

export const EditTalentBackIconWrapperShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 0px;
`;

export const EditTalentBackIconShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 12px;
  background: transparent;
  ${shimmerStyle}
  ${animate}
`;

export const EditTalentBackTextShimmer = styled.div`
  width: 100%;
  max-width: 60px;
  height: 14px;
  margin: 0px;
  ${shimmerStyle}
  ${animate}
`;
