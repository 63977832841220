import { FC } from "react";
import { AdminUsersList } from "components";
import { UserProvider } from "state/user";

const AdminUsers: FC = () => {
  return (
    <UserProvider>
      <AdminUsersList />
    </UserProvider>
  );
};

export default AdminUsers;
