import withApolloProvider from "hooks/apollo/withApollo";
import React, { useEffect, useState } from "react";
import { IReferredByProps } from "state/types/referrals.interface";
import { pageLimit, getSlicedData } from "utils";
import { Recruiter, Talent } from "@remotebase/amplify-constants/API";
import { useFetchReferredBy } from "../../hooks/talent/useFetchReferredBy";

export function withReferredBy<T>(Component: React.FC<T & IReferredByProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { fetchData, data, loading: referralLoading, stopFetching } = useFetchReferredBy();
    const [referredByData, setReferredByData] = useState<Array<Talent | Recruiter>>([]);
    const [loading, setLoading] = useState(false);
    const [talentId, setTalentId] = useState<string>("");
    const [shouldCallAPI, setShouldCallAPI] = useState<boolean>(false);

    const callAPI = (): void => fetchData(talentId);

    const updateReferredByData = (referData: Array<Talent | Recruiter>): void =>
      setReferredByData([...referredByData, ...referData]);

    useEffect(() => {
      if (!talentId) return;

      setLoading(true);
      setReferredByData([]);
      setShouldCallAPI(true);
    }, [talentId]);

    useEffect(() => {
      const hasCalledAPI = !referralLoading && data;
      if (!hasCalledAPI) return;
      if (stopFetching) {
        setLoading(false);
        return;
      }
      if (!data?.length) {
        setShouldCallAPI(true);
        return;
      }

      setShouldCallAPI(false);
      updateReferredByData(data);
      setLoading(false);
    }, [referralLoading, data]);

    useEffect(() => {
      if (shouldCallAPI) {
        setShouldCallAPI(false);
        callAPI();
      }
    }, [shouldCallAPI]);

    const referredByProps: IReferredByProps = {
      referredByState: {
        data: getSlicedData(referredByData, 0, pageLimit),
        loading,
      },
      setTalentId,
    };
    return <Component {...props} {...referredByProps} />;
  });
}
export default withReferredBy;
