import React, { Fragment, useEffect, useState } from "react";
import { FeedbackInput, ShouldRender } from "@remotebase/components";
import {
  rockstarLabel,
  publishedLabel,
  feedbackHeading,
  TalentProfileEnum,
  JobNotSelectedError,
} from "utils";
import { withUpdateFeedback } from "state/talentFeedback";
import { IFeedbackProps } from "state/types/talentFeedback.interface";
import withError from "state/error/withErrorHoc";
import { ErrorProps } from "@remotebase/constants";
import { matchProcess, matchStage, Company, Job } from "@remotebase/amplify-constants/API";
import CompanyList from "./CompanyList";
import JobList from "./JobsList";
import * as Styled from "./styles";
import TalentMatchHeader from "./TalentMatchHeader";

interface Props {
  isEdit: boolean;
}

export const TalentMatchStatus: React.FC<Props & IFeedbackProps & ErrorProps> = ({
  feedbackState: { matchedJobs, companies, jobs },
  showError,
  updateTalentProfile,
  isEdit,
  fetchJobs,
  removeTalentMatch,
}) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [job, setJob] = useState<Job | null>(null);
  const [companyJobs, setCompanyJobs] = useState<Array<Job | null>>([]);
  const [rockstar, setRockstar] = useState<boolean>(false);
  const [publish, setPublish] = useState<boolean>(false);

  const resetData = (): void => {
    setCompany(null);
    setJob(null);
    setRockstar(false);
    setPublish(false);
  };

  const updateMatchedList = (): void => {
    if (!job) {
      showError({ title: feedbackHeading, message: JobNotSelectedError });
      return;
    }

    const updatedData = {
      job,
      jobID: job.id,
      isPublished: publish,
      isRockstar: rockstar,
      matchProcess: matchProcess.manual,
      matchStage: matchStage.referred,
    };

    const existingIndex = matchedJobs.findIndex((item) => item?.jobID === job.id);
    if (existingIndex > -1) {
      const newMatchedJobs = [...matchedJobs];
      newMatchedJobs[existingIndex] = updatedData;
      updateTalentProfile(TalentProfileEnum.matchedJobs, newMatchedJobs);
    } else updateTalentProfile(TalentProfileEnum.matchedJobs, [...matchedJobs, updatedData]);

    resetData();
    showError({ title: feedbackHeading, message: "Talent Match updated" });
  };

  useEffect(() => {
    setJob(null);
    if (company) fetchJobs(company.id);
  }, [company]);

  useEffect(() => {
    if (!company) return;

    const foundCompanyJobs = jobs[company.id];
    if (!foundCompanyJobs) setCompanyJobs([]);
    else setCompanyJobs(foundCompanyJobs);
  }, [company, jobs]);

  return (
    <Fragment>
      <TalentMatchHeader
        isEdit={isEdit}
        job={job}
        matchedJobs={matchedJobs}
        updateMatchedList={updateMatchedList}
        removeTalentMatch={removeTalentMatch}
      />
      <Styled.ScoreDropDownWrapper>
        <Styled.DivWrapper>
          <CompanyList
            disabled={!isEdit}
            setSelectedCompany={setCompany}
            company={company}
            companies={companies}
          />
          <ShouldRender if={company}>
            <JobList disabled={!isEdit} setSelectedJob={setJob} job={job} jobs={companyJobs} />
          </ShouldRender>
        </Styled.DivWrapper>
        <Styled.DivWrapper>
          <Styled.ContainerStatus>
            <FeedbackInput
              disabled={!isEdit}
              checked={rockstar}
              inputClassName="mt-2 ml-2"
              label={rockstarLabel}
              onChange={(): void => setRockstar((prev): boolean => !prev)}
              type="checkbox"
            />
          </Styled.ContainerStatus>
          <Styled.ContainerStatus>
            <FeedbackInput
              disabled={!isEdit}
              checked={publish}
              inputClassName="mt-2 ml-2"
              label={publishedLabel}
              onChange={(): void => setPublish((prev): boolean => !prev)}
              type="checkbox"
            />
          </Styled.ContainerStatus>
        </Styled.DivWrapper>
      </Styled.ScoreDropDownWrapper>
    </Fragment>
  );
};

export default withUpdateFeedback<Props>(withError(TalentMatchStatus));
