import { TalentData, FilteredRecProfileType, ReturnPropsFetchTalent } from "hooks/types";
import { TalentProfile, Referral } from "@remotebase/amplify-constants/API";
import { useEffect, useMemo, useState } from "react";
import {
  useRecruiterList,
  useTalentList,
  useTalentByEmail,
  useTalentsProfileList,
  useReferralByRecruiter,
} from "hooks";
import { TalentFilterType as type, getQueryFilter, pageLimit, QueryfilterType } from "utils";
import { isEmpty } from "lodash";
import { ITalentFilters } from "state/types/talent.interface";

export const useFetchTalentList = (): ReturnPropsFetchTalent => {
  const {
    data: recruitersData,
    loading: recruitersLoading,
    nextToken: recruitersNextToken,
    getRecruiterList,
  } = useRecruiterList();
  const {
    talentsList,
    loading: loadingTalent,
    nextToken: talentNextToken,
    getTalentList,
  } = useTalentList();
  const {
    talentByEmail,
    loading: loadingTalentByEmail,
    nextToken: talentByEmailNextToken,
    getTalentByEmail,
  } = useTalentByEmail();
  const {
    data: profileData,
    loading: profileLoading,
    nextToken: profileNextToken,
    getTalentProfileList,
  } = useTalentsProfileList();
  const {
    data: referralsData,
    loading: referralsLoading,
    nextToken: referralsNextToken,
    getReferralsByRecruiter,
  } = useReferralByRecruiter();

  const [data, setData] = useState<TalentData>();
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [queryVariables, setQueryVariables] = useState({});
  const [searchedRecruiter, setSearchedRecruiter] = useState<FilteredRecProfileType>();
  const [queryFilterRecruiter, setQueryFilterRecruiter] = useState<QueryfilterType>();
  const [allData, setAllData] = useState(false);
  const loading = useMemo(() => {
    return loadingTalent || profileLoading || referralsLoading || loadingTalentByEmail;
  }, [loadingTalent, profileLoading, referralsLoading, loadingTalentByEmail]);

  const getDataFromProfile = (rawData: Array<TalentProfile | null>): TalentData =>
    rawData.map((engpd) => {
      const talent = engpd?.talent;
      if (talent) {
        return {
          ...talent,
          profile: engpd,
        };
      }
      return null;
    });
  const getDataFromReferral = (rawData: Array<Referral | null>): TalentData =>
    rawData.map((referredEntry) => referredEntry?.referred || null);

  const getReferral = (recruiterId?: string): void => {
    const variables = {
      recruiterId,
      nextToken: referralsNextToken || null,
    };
    getReferralsByRecruiter({ variables });
  };

  const getEmailTalent = (email?: string): void => {
    const variables = {
      email,
      nextToken: talentByEmailNextToken || null,
    };
    getTalentByEmail({ variables });
  };

  const resetState = (): void => {
    setData([]);
    setNextToken(null);
  };
  useEffect(() => {
    if (talentsList?.length) {
      setData(talentsList);
      setNextToken(talentNextToken);
    } else if (talentsList && !talentsList.length && queryVariables && talentNextToken) {
      getTalentList({ variables: { ...queryVariables, nextToken: talentNextToken } });
    } else if (allData) setData([]);
    else resetState();
  }, [talentsList]);

  useEffect(() => {
    if (profileData?.length) {
      setData(getDataFromProfile(profileData));
      setNextToken(profileNextToken);
    } else resetState();
  }, [profileData]);

  useEffect(() => {
    if (talentByEmail?.length) {
      setData(talentByEmail);
      setNextToken(talentByEmailNextToken);
    } else resetState();
  }, [talentByEmail]);

  useEffect(() => {
    if (!recruitersLoading && recruitersData?.length) {
      const recruiterProfile = recruitersData?.[0];
      if (recruiterProfile) {
        const { email, id } = recruiterProfile || {};
        setSearchedRecruiter({ email, id });
        getReferral(id);
      } else resetState();
    } else if (!recruitersData?.length && recruitersNextToken) {
      const variables = {
        nextToken: recruitersNextToken,
        filter: queryFilterRecruiter,
      };
      getRecruiterList({ variables });
    }
  }, [recruitersData, recruitersLoading]);

  useEffect(() => {
    if (referralsData?.length) {
      setData(getDataFromReferral(referralsData));
      setNextToken(referralsNextToken);
    } else if (referralsData !== null) {
      resetState();
    }
  }, [referralsData]);

  const getRecruitersTalent = (filteredEmail, variables): void => {
    const { email, id } = searchedRecruiter || {};
    if (id && email === filteredEmail) getReferral(id);
    else getRecruiterList({ variables });
  };

  const fetchData = (
    nextTokenId: string | null,
    filters: ITalentFilters,
    limit: number = pageLimit,
    all: false,
  ): void => {
    const { filterType, filterOptions } = filters;
    const filter = getQueryFilter(filterOptions);
    const isFilter = isEmpty(filter);
    const variables = {
      nextToken: nextTokenId,
      filter: !isFilter ? filter : null,
      limit: isFilter ? limit : null,
    };
    setAllData(all);
    switch (filterType) {
      case type.ListTalent:
        setQueryVariables(variables);
        return getTalentList({ variables });
      case type.TalentByEmail:
        return getEmailTalent(filterOptions.searchText);
      case type.SearchTalentProfile:
        return getTalentProfileList({ variables });
      case type.GetReferrals:
        return getReferral(filterOptions.searchText);
      case type.SearchRecruiter:
        setQueryFilterRecruiter(filter);
        return getRecruitersTalent(filterOptions.searchText, variables);
      default:
        return undefined;
    }
  };

  return {
    data,
    nextToken,
    loading,
    fetchData,
  };
};

export default useFetchTalentList;
